import { PersistenceLevel, Store } from '@/core/flux.service';

export class AxiosStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqAxiosStore';

  initialize() {
    this.state = this.immutable({
      pendingRequests: 0,
    });
  }

  get pendingRequests(): number {
    return this.state.get('pendingRequests');
  }

  // A traditional flux handler is not used because this is invoked via axios interceptors which may already be calling
  // flux dispatch which results in an error
  setPendingRequests({ nRequests }: { nRequests: number }) {
    if (nRequests < 0) {
      throw new Error(`The number of requests cannot be set to ${nRequests}. Check increment/decrement logic.`);
    }

    this.state.set('pendingRequests', nRequests);
  }

  protected readonly handlers = {};
}
