// @ts-strict-ignore
import { PersistenceLevel, Store } from '@/core/flux.service';

export class PdfExportStore extends Store {
  initialize() {
    this.state = this.immutable({
      showModal: false,
    });
  }

  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqPdfExportStore';

  get showModal() {
    return this.state.get('showModal');
  }

  protected readonly handlers = {
    PDF_EXPORT_SET_SHOW_MODAL: (payload: { showModal: boolean }) => {
      this.state.set('showModal', payload.showModal);
    },
  };
}
