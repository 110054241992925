import React, { useEffect, useRef, useState } from 'react';
import TopLoadingBar from 'react-top-loading-bar';
import { Icon } from '@seeqdev/qomponents';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { sqAxiosStore } from '@/core/core.stores';

const LOADING_BAR_COLOR = '#2299DD';

export const LoadingBar = () => {
  const { pendingRequests } = useFlux(sqAxiosStore);
  const nRequests = useRef(pendingRequests);
  const spinnerTimeout = useRef<number>();
  const [progress, setProgress] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (nRequests.current > pendingRequests) {
      if (pendingRequests === 0) {
        setProgress(100);
      } else {
        setProgress((1 / pendingRequests) * 100);
      }
    } else {
      if (pendingRequests === 1) {
        setProgress(50);
      }
    }
    nRequests.current = pendingRequests;
  }, [pendingRequests]);

  useEffect(() => {
    clearTimeout(spinnerTimeout.current);
    if (progress > 0 && progress < 100) {
      setShowSpinner(true);
    } else {
      spinnerTimeout.current = window.setTimeout(() => {
        setShowSpinner(false);
      }, 1000);
    }
  }, [progress]);

  return (
    <>
      <TopLoadingBar color={LOADING_BAR_COLOR} progress={progress} height={3} />
      {showSpinner && (
        <Icon
          id="loading-bar-spinner"
          icon="fc-loading-notch"
          extraClassNames="fa-spin"
          type="color"
          color={LOADING_BAR_COLOR}
        />
      )}
    </>
  );
};
