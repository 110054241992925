// @ts-strict-ignore
import _ from 'lodash';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { SMOOTHING_ALGORITHMS, SMOOTHING_DEFAULTS } from '@/tools/signalSmoothing/signalSmoothing.constants';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';

export class SignalSmoothingStore extends BaseToolStore {
  static readonly storeName = 'sqSignalSmoothingStore';
  type = TREND_TOOLS.SIGNAL_SMOOTHING;
  parameterDefinitions = {
    inputSignal: { predicate: ['name', 'inputSignal'] },
  };

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, this.parameterDefinitions, {
        algorithmSelectedValue: SMOOTHING_ALGORITHMS.SEEQ_AGILE.VALUE,
        samplingRate: { value: undefined, units: undefined },
        smoothingWindow: { value: undefined, units: undefined },
        cutoff: { value: undefined, units: undefined },
        polynomialFactor: SMOOTHING_DEFAULTS.POLYNOMIAL_FACTOR,
        isSamplingRateAuto: true,
        isCutoffAuto: true,
        isTau: true,
        tau: SMOOTHING_DEFAULTS.TAU,
        alpha: SMOOTHING_DEFAULTS.ALPHA,
        isMigratedLowPassFilter: false,
        color: '',
      }),
    );
  }

  get color(): string {
    return this.state.get('color');
  }

  get inputSignal() {
    return this.state.get('inputSignal');
  }

  get algorithmSelectedValue(): string {
    return this.state.get('algorithmSelectedValue');
  }

  get samplingRate(): ValueWithUnitsItem & { valid?: boolean } {
    return this.state.get('samplingRate');
  }

  get smoothingWindow(): ValueWithUnitsItem & { valid?: boolean } {
    return this.state.get('smoothingWindow');
  }

  get cutoff(): ValueWithUnitsItem & { valid?: boolean } {
    return this.state.get('cutoff');
  }

  get polynomialFactor(): number {
    return this.state.get('polynomialFactor');
  }

  get isSamplingRateAuto(): boolean {
    return this.state.get('isSamplingRateAuto');
  }

  get isCutoffAuto(): boolean {
    return this.state.get('isCutoffAuto');
  }

  get isTau(): boolean {
    return this.state.get('isTau');
  }

  get tau(): ValueWithUnitsItem & { valid?: boolean } {
    return this.state.get('tau');
  }

  get alpha(): number {
    return this.state.get('alpha');
  }

  get isMigratedLowPassFilter(): boolean {
    return this.state.get('isMigratedLowPassFilter');
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the references panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    ...this.baseHandlers,

    SIGNAL_SMOOTHING_SET_ALGORITHM_SELECTED_VALUE: ({ algorithmSelectedValue }: { algorithmSelectedValue: string }) => {
      this.state.set('algorithmSelectedValue', algorithmSelectedValue);
    },

    SIGNAL_SMOOTHING_SET_SAMPLING_RATE: ({ samplingRate }: { samplingRate: { value?: number; units?: string } }) => {
      this.state.set('samplingRate', samplingRate);
    },

    SIGNAL_SMOOTHING_SET_SMOOTHING_WINDOW: ({
      smoothingWindow,
    }: {
      smoothingWindow: { value?: number; units?: string };
    }) => {
      this.state.set('smoothingWindow', smoothingWindow);
    },

    SIGNAL_SMOOTHING_SET_CUTOFF: ({ cutoff }: { cutoff: { value?: number; units?: string } }) => {
      this.state.set('cutoff', cutoff);
    },

    SIGNAL_SMOOTHING_SET_POLYNOMIAL_FACTOR: ({ polynomialFactor }: { polynomialFactor: number }) => {
      this.state.set('polynomialFactor', polynomialFactor);
    },

    SIGNAL_SMOOTHING_SET_IS_SAMPLE_RATE_AUTO: ({ isSamplingRateAuto }: { isSamplingRateAuto: boolean }) => {
      this.state.set('isSamplingRateAuto', isSamplingRateAuto);
    },

    SIGNAL_SMOOTHING_SET_IS_CUTOFF_AUTO: ({ isCutoffAuto }: { isCutoffAuto: boolean }) => {
      this.state.set('isCutoffAuto', isCutoffAuto);
    },

    SIGNAL_SMOOTHING_SET_IS_TAU: ({ isTau }: { isTau: boolean }) => {
      this.state.set('isTau', isTau);
    },

    SIGNAL_SMOOTHING_SET_TAU: ({ tau }: { tau: number }) => {
      this.state.set('tau', tau);
    },

    SIGNAL_SMOOTHING_SET_ALPHA: ({ alpha }: { alpha: number }) => {
      this.state.set('alpha', alpha);
    },

    SIGNAL_SMOOTHING_SET_COLOR: ({ color }: { color: string }) => {
      this.state.set('color', color);
    },

    SIGNAL_SMOOTHING_SET_IS_MIGRATED_LOW_PASS_FILTER: ({
      isMigratedLowPassFilter,
    }: {
      isMigratedLowPassFilter: boolean;
    }) => {
      this.state.set('isMigratedLowPassFilter', isMigratedLowPassFilter);
    },
  };
}
