// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import _ from 'lodash';
import { Button, Icon } from '@seeqdev/qomponents';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { SelectAssetModal } from '@/core/SelectAssetModal.molecule';
import { itemIconClass } from '@/utilities/utilities';
import { getAssetFromAncestors } from '@/utilities/httpHelpers.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqItemsApi } from '@/sdk';

export interface SelectSearchItemIF extends ValidatingFormComponent<any> {
  component: 'SelectSearchItemFormComponent';
  iconPartialTooltipKey: string;
  scopeIds?: string[];
  excludeGloballyScoped?: boolean;
  helpTextKey?: string;
  customErrorText?: string;
  title: string;
  notSelectedMessage: string;
  searchTypes?: string[];
  isMultiple?: boolean;
  onRemove?: (item: any) => void;
  showAddIcon?: boolean;
  onDelete?: () => void;
  canSelectAll?: boolean;
  showPinnedAssets?: boolean;
  onlyValidateWhenNotSelectingItems?: boolean;
  disableInput?: boolean;
  showOnlyConditions?: boolean;
  showOnlyScopedAssets?: boolean;
  showSwitchToLocallyScoped?: boolean;
  onSwitchToLocallyScoped?: () => void;
  showModal?: boolean;
  setShowModal?: (value: boolean) => void;
}
interface FormattedItem {
  id: string[];
  name: string[];
  datasource: string[];
}

export const SelectSearchItemFormComponent: React.FunctionComponent<SelectSearchItemIF> = (props) => {
  const {
    name,
    //currently selected items in the form after save is clicked in the modal
    value,
    testId = 'selectAsset',
    validation,
    extendValidation,
    onChange,
    iconPartialTooltipKey,
    helpTextKey = undefined,
    customErrorText,
    scopeIds = undefined,
    excludeGloballyScoped = false,
    title,
    notSelectedMessage,
    searchTypes,
    //determines if the component will handle single or multiple item selection in the modal
    isMultiple = false,
    onRemove,
    showAddIcon = false,
    required = true,
    onDelete,
    canSelectAll = false,
    showPinnedAssets = false,
    onlyValidateWhenNotSelectingItems = false,
    disableInput = false,
    showOnlyConditions = false,
    showOnlyScopedAssets = false,
    showSwitchToLocallyScoped = false,
    onSwitchToLocallyScoped = _.noop,
    showModal = false,
    setShowModal = _.noop,
  } = props;
  const { t } = useTranslation();

  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    (_.has(formState.dirtyFields, name) || _.has(formState.dirtyFieldsSinceLastSubmit, name)) &&
    formState.hasValidationErrors;
  const [isSelectingItem, setIsSelectingItem] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState(value);
  const [formattedItem, setFormattedItem] = useState<FormattedItem[]>([]);

  useEffect(() => {
    if (showModal) {
      setIsSelectingItem(true);
    }
  }, [showModal]);

  useEffect(() => setSelectedItemIds(value), [value]);

  //Is called on a radio button/checkbox click
  const setSelectedItemsInModal = (item) => {
    const itemId = item?.id ?? item;
    if (isMultiple) {
      if (_.includes(selectedItemIds, itemId)) {
        setSelectedItemIds(_.filter(selectedItemIds, (id) => itemId !== id));
      } else {
        if (selectedItemIds) {
          setSelectedItemIds([...selectedItemIds, itemId]);
        } else {
          setSelectedItemIds(itemId ? [itemId] : []);
        }
      }
    } else {
      setSelectedItemIds(itemId);
    }
  };

  /** To determine whether to show "select all" or "unselect all" */
  const areAllSelected = (itemIds: string[]) => !itemIds?.some((itemId) => !_.includes(selectedItemIds, itemId));

  /** If all items are already in the list of selectedItems, unselect all, otherwise select All. */
  const selectAllCallback = (itemIds: string[]) => {
    if (!areAllSelected(itemIds)) {
      setSelectedItemIds(selectedItemIds ? [...selectedItemIds, ...itemIds] : itemIds);
    } else {
      setSelectedItemIds(_.reject(selectedItemIds, (id) => _.includes(itemIds, id)));
    }
  };

  useEffect(() => {
    //Once items selection is saved, get its properties to show in the selection box once the modal is closed.
    if (value) {
      const names: string[] = [];
      const datasources: string[] = [];
      const items = _.castArray(value);
      const ids: string[] = [];
      items.map(async (item) => {
        if (!item?.id) {
          const result = await sqItemsApi.getItemAndAllProperties({ id: item });
          names.push(result.data.name);
          ids.push(result.data.id);
          datasources.push(result.data?.datasource?.name);
          setFormattedItem([
            {
              name: names,
              id: ids,
              datasource: datasources,
            },
          ]);
        }
      });
    }
  }, [value]);

  const defaultValidation = (value: any) => required && !value;
  const validationFunction = getValidationFunction(defaultValidation, extendValidation, validation);
  const validateWhenNotSelectingItemFunction = !isSelectingItem ? validationFunction : () => _.noop();
  const appliedValidation = onlyValidateWhenNotSelectingItems
    ? validateWhenNotSelectingItemFunction
    : validationFunction;

  const validateItem = (item) => Promise.resolve(!appliedValidation(item));
  const [canSave, setCanSave] = useState(false);
  useEffect(() => {
    if (!isSelectingItem) {
      return;
    }

    Promise.all(
      _.map(selectedItemIds ? _.castArray(selectedItemIds) : [], async (itemId) => {
        const item = await sqItemsApi.getItemAndAllProperties({ id: itemId });
        return validateItem(item.data);
      }),
    ).then((validationResults) => {
      setCanSave(_.every(validationResults, (validationResult) => validationResult === true));
    });
  }, [selectedItemIds, isSelectingItem, validateItem]);

  const clearAndClose = () => {
    setIsSelectingItem(false);
    if (setShowModal) {
      setShowModal(false);
    }
  };

  const canShowModal = isSelectingItem || showModal;

  const onSave = () => {
    isMultiple ? onChange(_.uniq(selectedItemIds)) : onChange(selectedItemIds);
    setIsSelectingItem(false);
    setShowModal(false);
  };

  const itemIcon = (item: any) => {
    return (
      <Icon
        icon={itemIconClass(item)}
        testId={`${testId}-icon`}
        type="inherit"
        extraClassNames="sq-fairly-dark-gray pl5 pr10"
        large={true}
      />
    );
  };

  const noSelectedItemResult = () => {
    return (
      <div className="sq-fairly-dark-gray text-italic" data-testid={`${testId}-notSelected`}>
        <span>{t(notSelectedMessage)}</span>
      </div>
    );
  };

  const formatItemName = (item: any, index = undefined) => {
    if (item?.id) {
      return item.name;
    }
    const indexToUse = Array.isArray(formattedItem) ? formattedItem[0]?.id.indexOf(item) : index;
    return formattedItem[0]?.name?.[indexToUse ?? 0];
  };

  const formatItemAncestors = (item: any, index = undefined) => {
    if (item?.ancestors) {
      return getAssetFromAncestors(item.ancestors).formattedName;
    }
    const indexToUse = Array.isArray(formattedItem) ? formattedItem[0]?.id.indexOf(item) : index;
    return item?.id ? item?.datasource?.name : formattedItem[0]?.datasource?.[indexToUse ?? 0];
  };

  const searchResult = (item: any) => (
    <div className="flexColumnContainer flexSpaceBetween flexFill ptb2">
      {(item && (
        <>
          <div className="flexColumnContainer flexCenter">{itemIcon(item)}</div>
          <div className="flexFill" data-testid={`${testId}-selected`}>
            <div className="searchResultName">
              <span className="simple-word-break">{formatItemName(item)}</span>
            </div>
            <div className="xsmall pb2 sq-fairly-dark-gray text-italic simple-word-break">
              {formatItemAncestors(item)}
            </div>
          </div>
        </>
      )) ||
        noSelectedItemResult()}
    </div>
  );

  const searchResultMultiple = (itemIds: any) => (
    <div className="flexRowContainer flexSpaceBetween flexFill ptb2">
      {itemIds?.length > 0
        ? _.map(itemIds, (itemId, index) => {
            return (
              <div key={`key_${itemId}`} className="flexRowContainer flexFill removableSearchResult">
                <div className="flexColumnContainer flexCenter flexFill">
                  {itemIcon(itemId)}
                  <div className="searchResultName flexFill">
                    <span className="simple-word-break">{formatItemName(itemId, index)}</span>
                    <span className="xsmall ml5 pb2 sq-fairly-dark-gray text-italic simple-word-break">
                      {formatItemAncestors(itemId, index)}
                    </span>
                  </div>
                  <Icon
                    icon="fa-close"
                    type="text"
                    onClick={() => onRemove(itemId)}
                    testId="removeSearchResult"
                    tooltip={t('Remove item from list')}
                    extraClassNames="mr10 cursorPointer"
                  />
                </div>
              </div>
            );
          })
        : noSelectedItemResult()}
    </div>
  );

  const body = helpTextKey ? (
    <div className="alert alert-info" data-testid={`${testId}-helpText`}>
      {t(helpTextKey)}
    </div>
  ) : (
    <></>
  );

  const footer = (
    <div>
      <Button onClick={clearAndClose} label={t('CANCEL')} extraClassNames="mr5" testId={`${testId}-cancel`} />
      <Button
        extraClassNames={canSave ? '' : 'noMouse'}
        onClick={onSave}
        label={t('SAVE')}
        variant="theme"
        disabled={!canSave}
        testId={`${testId}-save`}
      />
    </div>
  );

  return (
    <>
      <FormFieldWrapper
        wrapperClassNames="flexFill"
        testId={testId}
        showError={showError}
        customErrorText={customErrorText}>
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const formFieldProps = getFormFieldProps(formState, input, meta, props);
            return (
              <InputGroup>
                <div
                  className={classNames(
                    'width-maximum form-control ptb0 flexColumnContainer flexAlignCenter cursorPointer multiSelectSearchResult',
                    formFieldProps.extraClassNames,
                    { disabledInputField: disableInput },
                  )}
                  onClick={() => {
                    if (!disableInput && (!isMultiple || (isMultiple && !value?.length))) {
                      setIsSelectingItem(true);
                    }
                  }}
                  data-testid={`${testId}-value`}>
                  {isMultiple ? searchResultMultiple(formFieldProps.value) : searchResult(formFieldProps.value)}
                </div>
                <InputGroup.Append>
                  {onDelete && value && (
                    <Button icon="fa-trash" iconStyle="theme" testId={`${testId}-delete`} onClick={() => onDelete()} />
                  )}
                  <Button
                    icon={showAddIcon ? 'fa-plus' : 'fa-pencil'}
                    iconStyle="theme"
                    testId={`${testId}-edit`}
                    onClick={() => !disableInput && setIsSelectingItem(true)}
                    disabled={disableInput}
                  />
                </InputGroup.Append>
              </InputGroup>
            );
          }}
        </Field>
      </FormFieldWrapper>
      {canShowModal && (
        <SelectAssetModal
          isMultiple={isMultiple}
          header={{
            title: t(title),
          }}
          body={body}
          footer={footer}
          onClose={clearAndClose}
          selectedAssetIds={selectedItemIds}
          iconPartialTooltipKey={iconPartialTooltipKey}
          onSelect={setSelectedItemsInModal}
          validateItem={validateItem}
          scopeIds={scopeIds}
          excludeGloballyScoped={excludeGloballyScoped}
          testId={`${testId}-modal`}
          searchTypes={searchTypes ?? [SeeqNames.Types.Asset]}
          selectAllCallback={canSelectAll ? selectAllCallback : undefined}
          areAllSelected={areAllSelected}
          showPinnedAssets={showPinnedAssets}
          showOnlyScopedAssets={showOnlyScopedAssets}
          showOnlyConditions={showOnlyConditions}
          showSwitchToLocallyScoped={showSwitchToLocallyScoped}
          onSwitchToLocallyScoped={onSwitchToLocallyScoped}
        />
      )}
    </>
  );
};
