import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MinimapInner } from '@/scatterPlot/minimap/MinimapInner.molecule';
import { MinimapDragLayer } from '@/scatterPlot/minimap/MinimapDragLayer.atom';
import { SerializedRange } from '@/datetime/dateTime.utilities';

export type RegionProps = {
  start: number;
  end: number;
  color: string;
  id: string;
};

export interface ScatterPlotMinimapProps {
  displayRange: SerializedRange;
  xValue: number | null;
  plotSeries: any[];
  regions: RegionProps[];
  selectorLow: number;
  selectorHigh: number;
  setSelectors: any;
  useSelectors: boolean;
  backgroundColor?: string;
  isContent?: boolean;
}

/**
 * Wraps the Minimap with a DnDProvider and incorporates the MinimapDragLayer at the same level as the
 *  ScatterPlotMinimap, which is the drag container.
 **/
export const ScatterPlotMinimap: React.FunctionComponent<ScatterPlotMinimapProps> = (props) => (
  <DndProvider backend={HTML5Backend} context={window}>
    <div className="flexFill flexRowContainer">
      <div className="minimapDragLayer">
        <MinimapDragLayer />
      </div>
      <MinimapInner {...props} />
    </div>
  </DndProvider>
);
