import React, { ReactNode } from 'react';

/**
 * This is a wrapper that captures a variety of clicks
 */
export const EventCapture: React.FunctionComponent<{ children?: ReactNode; extraClassnames?: string }> = (props) => {
  return (
    <div
      className={props.extraClassnames || ''}
      onKeyDown={(e) => e.stopPropagation()}
      onKeyUp={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      onFocus={(e) => e.stopPropagation()}
      onMouseOver={(e) => e.stopPropagation()}>
      {props.children}
    </div>
  );
};
