/**
 * This module applies highchart modules and custom highcharts plugins used by Seeq
 *
 * @exports configured Highcharts object
 */
import Highcharts, {
  PlotColumnrangeOptions,
  PointOptionsObject,
  SeriesColumnOptions,
  SeriesOptions,
  SeriesPieOptions,
} from 'highcharts';
import { browserIsFirefox } from '@/utilities/browserId';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import HC_More from 'highcharts/highcharts-more';
import HC_brokenAxis from 'highcharts/modules/broken-axis';
import HC_HeatMap from 'highcharts/modules/heatmap';
import HC_Boost from 'highcharts/modules/boost';

HC_More(Highcharts);
HC_brokenAxis(Highcharts);
HC_HeatMap(Highcharts);
HC_Boost(Highcharts);

/**
 * Extend the Axis.getLinePath method in order to visualize breaks with two parallel
 * slanted lines. For each break, the slanted lines are inserted into the line path.
 */
Highcharts.wrap(Highcharts.Axis.prototype, 'getLinePath', function (proceed, lineWidth) {
  const axis = this;
  const path = proceed.call(axis, lineWidth);
  const start = path[0];
  const y = start[2];

  (this.brokenAxis?.breakArray || []).forEach(function (brk) {
    if (axis.horiz) {
      const x = axis.toPixels(brk.from);
      path.splice(
        3,
        0,
        ['L', x - 4, y], // stop
        ['M', x - 9, y + 5],
        ['L', x + 1, y - 5], // lower slanted line
        ['M', x - 1, y + 5],
        ['L', x + 9, y - 5], // higher slanted line
        ['M', x + 4, y],
      );
    }
  });

  return path;
});

// http://stackoverflow.com/questions/42772038/highcharts-tooltip-background-according-to-line-without-setting-backgroundcolor
Highcharts.wrap(Highcharts.Tooltip.prototype, 'refresh', function (p, point, mouseEvents) {
  p.call(this, point, mouseEvents);

  const label = this.label;

  if (this.options.backgroundColor === 'series' && point && label) {
    label.attr({
      fill: point.series.color,
    });
  }
});

/**
 * Allows negative values for logarithmic Y axes. Taken from:
 * https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/yaxis/type-log-negative/
 */
Highcharts.addEvent(Highcharts.Axis, 'afterInit', function () {
  // @ts-ignore logarithmic property should exist
  const logarithmic = this.logarithmic;
  // @ts-ignore custom.allowNegativeLog property should exist
  if (logarithmic && this.options.custom?.allowNegativeLog) {
    // Avoid errors on negative numbers on a log axis
    // @ts-ignore positiveValuesOnly property should exist
    this.positiveValuesOnly = false;

    // Override the converter functions
    logarithmic.log2lin = (num) => {
      const isNegative = num < 0;

      let adjustedNum = Math.abs(num);

      if (adjustedNum < 10) {
        adjustedNum += (10 - adjustedNum) / 10;
      }

      const result = Math.log(adjustedNum) / Math.LN10;
      return isNegative ? -result : result;
    };

    logarithmic.lin2log = (num) => {
      const isNegative = num < 0;

      let result = Math.pow(10, Math.abs(num));
      if (result < 10) {
        result = (10 * (result - 1)) / (10 - 1);
      }
      return isNegative ? -result : result;
    };
  }
});

// Ignore Highcharts error #12 (CRAB-21149)
Highcharts.addEvent(Highcharts.Chart, 'displayError', function (event: any) {
  if (event.code === 12) {
    event.preventDefault();
  }
});

// The heatmap color axis fill is set using the url. However, the url used by Highcharts didn't get updated
// when it changed, such as when we switched worksheets. This ensures the url gets updated before we redraw the chart.
// The fix comes from https://github.com/highcharts/highcharts/pull/14874/files,
// for the issue https://github.com/highcharts/highcharts/issues/5244
Highcharts.addEvent(Highcharts.Chart, 'beforeRedraw', function () {
  const chart = this;

  // @ts-ignore renderer.url property should exist
  if (chart.renderer.url !== window.location.href) {
    // Get all elements with a URL clip-path
    const elements = chart.container
      // @ts-ignore renderer.url property should exist
      .querySelectorAll('[clip-path*="' + chart.renderer.url + '"]');

    // Update the clip-path with the new location.href
    elements.forEach(function (element: Element): void {
      const currentValue = element.getAttribute('clip-path');
      if (currentValue) {
        element.setAttribute(
          'clip-path',
          // @ts-ignore renderer.url property should exist
          currentValue.replace(chart.renderer.url, window.location.href),
        );
      }
    });

    // Update renderer URL
    // @ts-ignore renderer.url property should exist
    chart.renderer.url =
      // May also need to include WebKit here
      browserIsFirefox && document.getElementsByTagName('base').length
        ? window.location.href
            .split('#')[0] // remove the hash
            .replace(/<[^>]*>/g, '') // wing cut HTML
            // escape parantheses and quotes
            .replace(/([\('\)])/g, '\\$1')
            // replace spaces (needed for Safari only)
            .replace(/ /g, '%20')
        : '';
  }
});

// Flamegraph configuration with addition of labels with ellipses.
// See: https://github.com/highcharts/highcharts/issues/125270
Highcharts.seriesType(
  'flame',
  'columnrange',
  {
    cursor: 'pointer',
    dataLabels: {
      enabled: true,
      format: '{point.name}',
      inside: true,
      align: 'center',
      crop: true,
      overflow: 'none',
      color: 'black',
      padding: 1,
      style: {
        textOutline: 'none',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
      },
    },
    pointPadding: 0,
    groupPadding: 0,
  },
  {
    // @ts-ignore Hidden property
    drawDataLabels: Highcharts.seriesTypes.line.prototype.drawDataLabels,
    alignDataLabel(point, dataLabel, options) {
      if (point && dataLabel && options.style.textOverflow === 'ellipsis') {
        const dlBox = point.dlBox || point.shapeArgs;
        dataLabel.css({
          width: this.chart.inverted ? dlBox.height : dlBox.width,
          textOverflow: options.style.textOverflow,
        });
      }

      // @ts-ignore Hidden property
      // eslint-disable-next-line prefer-rest-params
      return Highcharts.seriesTypes.column.prototype.alignDataLabel.apply(this, arguments);
    },
  },
);

export default Highcharts;

export interface ColumnChartOptions extends Highcharts.Options {
  series: SeriesColumnOptions[];
}

export interface PieChartOptions extends Highcharts.Options {
  series: SeriesPieOptions[];
}

interface SeriesFlameOptions extends PlotColumnrangeOptions, SeriesOptions {
  type: 'flame';
  data: PointOptionsObject[];
}

export interface FlameChartOptions extends Highcharts.Options {
  series: SeriesFlameOptions[];
}

export interface Extremes {
  min: number | null;
  max: number | null;
}

/* This shims the .highcharts() helper on the dom node instead of jquery! */
Highcharts.wrap(Highcharts.Chart.prototype, 'getContainer', function (proceed) {
  proceed.apply(this);
  const renderTo = this.renderTo;
  renderTo.highcharts = () => this;
});

// We use some fields that were not exposed via Highcharts types, but we know are on Highcharts objects from examining
// various .js files in Highcharts. We also ADD some fields to highcharts objects to facilitate our own custom logic
// around charts. Below, Highcharts object interfaces are extended to give us access to these fields in the given
// types. The fields that are not from Highcharts, that we added, are the result of legacy decisions before we used
// types. We DO NOT plan to add more. If we choose to modify highcharts objects in the future (gross), then we will
// explicitly create new extended types instead of using Module Augmentation as have below
declare module 'highcharts' {
  interface SeriesOptionsRegistry {
    SeriesFlameOptions: SeriesFlameOptions;
  }

  interface Axis {
    /** Not exposed by Highcharts, but is used on this type. See Highcharts.Axis.prototype in src */
    translate: (val) => number | undefined;
    mouseDownValue: number;
    pos: number;
    len: number;

    labelGroup: { element: HTMLElement };
    labelOffset: number;
    axisTitleMargin: number;

    brokenAxis: {
      // we end up needing to check this to avoid some underlying Highcharts issue, so we expose it here
      hasBreaks: boolean;
    };
    // Non-public field on Axis which we need access to for Minimap.
    plotLinesAndBands: PlotLineOrBand[];
  }

  interface Series {
    group: {
      clip: (svgElement: Highcharts.SVGElement) => any;
    };
    markerGroup: {
      clip: (svgElement: Highcharts.SVGElement) => any;
    };
  }

  interface Chart {
    /** Not exposed in Highcharts types but we use it for optimization */
    plotBox: { height: number; width: number; x: number; y: number };
    /** Not exposed in Highcharts types but we use it for optimization */
    clipBox: { height: number; width: number; x: number; y: number };
    margin: number[];
  }

  interface SVGElement {
    /** Internal to Highcharts, needed for custom labels on cursors */
    xSetter: (value: any) => void;
    /** Internal to Highcharts, needed for custom labels on cursors */
    ySetter: (value: any) => void;
    width: number;
    height: number;
    x: number;
    y: number;
  }

  interface Point {
    /** We use this private field to label capsules, so we expose it here **/
    plotX?: number;
    /** Added to track the data label for a capsule **/
    dataLabelString?: string;
  }

  interface YAxisOptions {
    /** Added by us to track which signal we are referring to */
    signalId?: string;
    lane?: number;
    seeqDisallowZoom?: boolean;
    capsuleSetId?: string;
    customValue?: number;
    mouseDownValue?: number;
  }

  interface ZAxisOptions {
    /** Added by us to track which signal we are referring to */
    signalId?: string;
    mouseDownValue?: number;
  }

  interface XAxisOptions {
    /** Added by us to track which signal we are referring to */
    signalId?: string;
    isXAxis?: boolean;
    mouseDownValue?: number;
  }

  interface PlotHeatmapOptions {
    minPadding?: number;
    maxPadding?: number;
  }

  interface SeriesLineOptions {
    numberFormat?: string;
  }

  interface SeriesOptions {
    itemType?: ITEM_TYPES;
    lane?: number;
  }

  /**
   * Contains the extra fields that we use to extend any SeriesOptions object from Highcharts
   */
  interface SeriesOptions {
    xNumberFormat?: string;
    yNumberFormat?: string;
    xSignalName?: string;
    ySignalName?: string;
    lineWidth?: number;
  }

  interface Point {
    dataLabelString?: string;
  }
}
