import {
  ColumnInputOptions,
  ColumnRuleWithMetadata,
  DropdownInputOptions,
  NumberInputOptions,
  TextInputOptions,
} from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.types';
import { ColumnRule } from '@/tableDefinitionEditor/columnRules/columnRule.constants';
import { ColumnRulesWithMetaData } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.constants';

export function isDropdownInputOptions(input: any): input is DropdownInputOptions {
  return input.type === 'optionDropdown';
}

export function isTextInputOptions(input: any): input is TextInputOptions {
  return input.type === 'text';
}

export function isColumnInputOptions(input: any): input is ColumnInputOptions {
  return input.type === 'columnDropdown';
}

export function isNumberOptions(input: any): input is NumberInputOptions {
  return input.type === 'number';
}

export function getColumnRuleWithMetaData(rule: ColumnRule): ColumnRuleWithMetadata | undefined {
  return ColumnRulesWithMetaData.find((columnRule) => columnRule.rule === rule);
}
