import React from 'react';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import classNames from 'classnames';
import _ from 'lodash';
import { ImageSelect, ImageSelectOption } from '@/core/ImageSelect.molecule';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';

export interface ImageSelectIF extends ValidatingFormComponent<string> {
  component: 'ImageSelectFormComponent';
  label: string;
  selectOptions: ImageSelectOption[];
  placeholder: string;
  imageSelectClassName?: string;
  customErrorText?: string;
}

export const ImageSelectFormComponent: React.FunctionComponent<ImageSelectIF> = (props) => {
  const {
    name,
    testId = 'image-select',
    label,
    placeholder,
    value,
    selectOptions,
    validation,
    extendValidation,
    extraClassNames,
    customErrorText,
    imageSelectClassName,
  } = props;

  const defaultValidation = () => _.isUndefined(value);

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();
  const showError = _.has(formState.errors, name) && _.has(formState.dirtyFields, name);

  return (
    <FormFieldWrapper
      testId={testId}
      label={label}
      wrapperClassNames="flexFill"
      extraClassNames={extraClassNames}
      showError={showError}
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const formFieldProps = getFormFieldProps(formState, input, meta, props);

          return (
            <ImageSelect
              name={name}
              placeholder={placeholder}
              value={formFieldProps.value}
              selectOptions={selectOptions}
              extraClassNames={classNames(imageSelectClassName, formFieldProps.className)}
              onChange={formFieldProps.onChange}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
