import DOMPurify from 'dompurify';
import { STRING_UOM } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';

/**
 * Represents an item that has units. valueUnitOfMeasure is required, but other fields are optional.
 */
export interface ItemWithUnits {
  displayUnit?: string;
  sourceValueUnitOfMeasure?: string;
  valueUnitOfMeasure: string;
}

/**
 * Represents what unit should be displayed for an item. Has additional metadata to give extra context when displaying.
 */
export interface UnitOfMeasure {
  value: string;
  fromSourceUom: boolean;
  isDisplay: boolean;
}

/**
 * Encompasses multiple methods that a Display Unit might be accessible on an Item.
 */
interface ItemAndMaybeDisplayUnit {
  includedProperties?: {
    [SeeqNames.Properties.DisplayUnit]?: {
      value: string;
    };
  };
  properties?: any[];
}
export function extractDisplayUnitFromIncludedProperties(item: ItemWithUnits & ItemAndMaybeDisplayUnit): ItemWithUnits {
  return {
    displayUnit:
      item.properties?.find((x) => x.name === SeeqNames.Properties.DisplayUnit)?.value ??
      item.includedProperties?.[SeeqNames.Properties.DisplayUnit]?.value,
    // Even though the type of valueUom is string, we pass item: any around so much that we need to check for undefined.
    valueUnitOfMeasure: item.valueUnitOfMeasure ?? '',
    sourceValueUnitOfMeasure: item.sourceValueUnitOfMeasure,
  };
}

/**
 * Determines what unit of measure should be displayed for an item plus metadata for tooltips.
 */
export function resolveUnitOfMeasure(item: ItemWithUnits): UnitOfMeasure {
  // use displayUnit if present
  if (item.displayUnit) {
    // Sanitize displayUnits as they can be arbitrary strings.
    return { value: DOMPurify.sanitize(item.displayUnit), fromSourceUom: false, isDisplay: true };
  }
  // valueUom is actually used by Seeq, may be unitless (empty string).
  // sourceValueUom is how the string came into Seeq from the datasource.
  // If valueUom is empty and sourceValueUom is not, then use the sourceUom.
  const valueUom = item.valueUnitOfMeasure;
  const sourceValueUom = item.sourceValueUnitOfMeasure;
  const fromSourceUom: boolean = sourceValueUom !== undefined && valueUom === '' && valueUom !== sourceValueUom;
  let value: string;
  if (fromSourceUom) {
    // Sanitize sourceValueUom as they can be arbitrary strings.
    value = DOMPurify.sanitize(sourceValueUom!);
  } else {
    // In CRAB-8217, users found the 'string' unit of measure as confusing, so we hide it.
    if (valueUom === STRING_UOM) {
      value = '';
    } else {
      value = valueUom;
    }
  }
  return { value, fromSourceUom, isDisplay: false };
}

/**
 * Returns a string with the unit of measure in parentheses with a leading space, or empty string if no unit.
 */
export function getParenthesizedUnitString(item: ItemWithUnits): string {
  const unitOfMeasure = resolveUnitOfMeasure(item);
  return unitOfMeasure.value ? ` (${unitOfMeasure.value})` : '';
}
