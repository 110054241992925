// @ts-strict-ignore
import { APPSERVER_API_CONTENT_TYPE } from '@/main/app.constants';
import _ from 'lodash';

export function postInterceptor(config) {
  // this is necessary as defining it on the axiosInstance does not work
  if (_.lowerCase(config.method) === 'post') {
    config.headers['content-type'] = APPSERVER_API_CONTENT_TYPE;
  }
  return config;
}
