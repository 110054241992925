// @ts-strict-ignore
import _ from 'lodash';
import { PersistenceLevel, Store } from '@/core/flux.service';

export class PluginsAdminStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqPluginsAdminStore';

  initialize() {
    this.state = this.immutable({
      plugins: [],
      tableLoading: false,

      // state for for ServerSideFilteringAndPaginationTable
      searchParams: {},
      sortAscending: true,
      sortProperty: 'name',
      // state for for Pagination.organism
      pluginsTotal: 0,
      pageSize: 15,
      pageNumber: 1,
    });
  }

  get plugins() {
    return this.state.get('plugins');
  }

  get isTableLoading() {
    return this.state.get('tableLoading');
  }

  // Interface for ServerSideFilteringAndPaginationTable.tsx

  get searchParams() {
    return this.state.get('searchParams');
  }

  get sortAscending() {
    return this.state.get('sortAscending');
  }

  get sortProperty() {
    return this.state.get('sortProperty');
  }

  // Interface for Pagination.organism.tsx

  getItemTotalForTable() {
    return this.state.get('pluginsTotal');
  }

  getPageSizeByTable() {
    return this.state.get('pageSize');
  }

  getPageNumberForTable() {
    return this.state.get('pageNumber');
  }

  protected readonly handlers = {
    PLUGINSADMIN_SET_PLUGINS: ({ plugins }) => {
      this.state.set('plugins', plugins);
    },

    PLUGINSADMIN_SET_TABLE_LOADING: ({ isLoading }: { isLoading: boolean }) => {
      this.state.set('tableLoading', isLoading);
    },

    PLUGINSADMIN_SET_SEARCH_PARAMS: ({ field, value }: { field: string; value: string }) => {
      const searchParams = this.state.get('searchParams');

      if (value === '') {
        this.state.set('searchParams', _.omit(searchParams, field));
      } else {
        this.state.set('searchParams', _.assign({}, searchParams, { [field]: value }));
      }
    },

    PLUGINSADMIN_SET_SORT: ({ field, sortAscending }: { field: string; sortAscending: boolean }) => {
      this.state.merge({ sortProperty: field, sortAscending });
    },

    PLUGINSADMIN_SET_PLUGINS_TOTAL: ({ count }: { count: number }) => {
      this.state.set('pluginsTotal', count);
    },

    PLUGINSADMIN_SET_PAGE_SIZE: ({ size }: { size: number }) => {
      this.state.set('pageSize', size);
    },

    PLUGINSADMIN_SET_PAGE_NUMBER: ({ pageNumber }: { pageNumber: number }) => {
      this.state.set('pageNumber', pageNumber);
    },

    /**
     * Removes plugins from the array of plugins
     *
     * @param {String[]} ids - The ids of the plugins to be removed
     */
    PLUGINSADMIN_REMOVE_PLUGINS: (ids: string[]) => {
      _.forEach(ids, (id) => {
        const index = _.findIndex(this.state.get('plugins'), ['id', id]);

        if (index > -1) {
          this.state.splice('plugins', [index, 1]);
        }
      });
    },
  };
}
