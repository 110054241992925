// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { sqWorkbenchStore } from '@/core/core.stores';
import { DatasourcesStatus } from '@/footer/DatasourcesStatus.organism';
import { useTranslation } from 'react-i18next';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { subscribe } from '@/utilities/socket.utilities';
import {
  GetFeedback,
  getFeedbackButton,
  getFeedbackScript,
  shouldFeedbackDisplay,
} from '@/getFeedback/GetFeedback.atom';
import { seeqFullReleaseIdentifier } from '@/services/systemConfiguration.utilities';
import { ButtonWithPopover } from '@seeqdev/qomponents';
import { isAdmin } from '@/services/authorization.service';

interface FooterProps {
  // Number of messages to receive before we can determine that the Feedback javascript has either loaded or not
  messageThresholdForFeedbackLoading?: number;
}

export const Footer: React.FunctionComponent<FooterProps> = ({ messageThresholdForFeedbackLoading = 1 }) => {
  const { t } = useTranslation();
  const [threadPoolUsage, setThreadPoolUsage] = useState({
    description: '',
  });
  const interactiveSessionId = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.interactiveSessionId);
  const isDev = process.env.NODE_ENV === 'development';

  const messageCount = useRef(0);
  const provideThreadPoolUsage = (message) => {
    messageCount.current += 1;
    setThreadPoolUsage({
      ...message?.serverLoad,
      description: `${message?.serverLoad.description} ${message?.consumption}`,
    });
  };

  useEffect(() => {
    const unsubscribe = subscribe({
      channelId: [SeeqNames.Channels.RequestsProgress, sqWorkbenchStore.interactiveSessionId],
      onMessage: provideThreadPoolUsage,
      useSubscriptionsApi: false,
    });
    return unsubscribe;
  }, [interactiveSessionId]);

  // Because we support sending feedback only to a percentage of users just enabling feedback doesn't guarantee
  // there is a feedback button - in those cases we don't want the server health to be displayed strangely indented.
  // Unfortunately mopininon does not have a way for us to know when the feedback button is done loading, so we have
  // to check on every re-render to make sure things look right :)
  const feedback = shouldFeedbackDisplay();
  const feedbackScriptLoading =
    feedback && (getFeedbackScript() == null || messageCount.current <= messageThresholdForFeedbackLoading);
  const feedbackButtonShown = feedback && getFeedbackButton() != null;

  return (
    <footer
      id="footer"
      data-testid="footer"
      className={classNames('mainFooter', 'flexColumnContainer', {
        footerWithFeedback: feedbackScriptLoading || feedbackButtonShown,
      })}>
      <GetFeedback />
      <div id="seeqVersion" className="inlineBlock">
        {seeqFullReleaseIdentifier()} {isDev && '[DEV]'}
      </div>
      <div className="flexColumnContainer autoMarginLeft">
        <DatasourcesStatus />

        {isAdmin() && !_.isEmpty(threadPoolUsage) && (
          <ButtonWithPopover
            placement="top"
            hasArrow
            tooltipPlacement="top"
            tooltip={t('THREAD_POOL_USAGE.TOOLTIP')}
            trigger={
              <div className="text-nowrap flexColumnContainer flexAlignCenter cursorPointer">
                <span className="list-group-item-text cursorDefault ml5 mr5">|</span>
                <span className="list-group-item-text pr5">{t('THREAD_POOL_USAGE.TITLE')}</span>
              </div>
            }>
            <div id="threadPoolUsagePopOver" className="datasourcesPopup min-width-500 pt10">
              <div>
                <div className="flexColumnContainer flexCenter">
                  <div className="text-pre-wrap text-monospace">{threadPoolUsage.description}</div>
                </div>
                <div className="text-center pt7 pb7">
                  <a
                    href="https://support.seeq.com/kb/latest/cloud/troubleshooting-performance#TroubleshootingPerformance-ThreadPoolUsagePop-Up"
                    target="_blank"
                    rel="noopener">
                    <span>{t('THREAD_POOL_USAGE.KB_LINK_DESCRIPTION')}</span>
                  </a>
                </div>
              </div>
            </div>
          </ButtonWithPopover>
        )}
      </div>
    </footer>
  );
};
