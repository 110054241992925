import { SeeqNames } from '@/main/app.constants.seeqnames';
import { CONTENT_LOADING_CLASS } from '@/reportEditor/report.constants';
import { sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';

export const IGNORE_CK_PAGINATION_VIEW_SELECTOR = 'div#reportEditor ';

/**
 * Returns a Node list object of all pieces of Seeq content that are currently in an error state.
 *
 * @returns {JQuery<TElement extends Node>}
 */
export function getContentInErrorState() {
  return document.querySelectorAll(
    `${IGNORE_CK_PAGINATION_VIEW_SELECTOR} [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}].${CONTENT_LOADING_CLASS.ERROR}`,
  );
}

/**
 * Returns a Node list object of all pieces of Seeq content that are currently loading.
 *
 * @returns {NodeListOf<Element>} object of all Seeq content elements that are still loading
 */
export function getLoadingContent(): NodeListOf<Element> {
  return document.querySelectorAll(
    `${IGNORE_CK_PAGINATION_VIEW_SELECTOR} [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]:not(.${CONTENT_LOADING_CLASS.LOADED}):not(.${CONTENT_LOADING_CLASS.ERROR}):not(.${CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR})`,
  );
}

/**
 * Returns a Node list object of all pieces of Seeq content, regardless of their state.
 *
 * @returns {NodeListOf<Element>} object of all Seeq content elements
 */
export function getAllContent(): NodeListOf<Element> {
  return document.querySelectorAll(
    `${IGNORE_CK_PAGINATION_VIEW_SELECTOR} [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`,
  );
}

type ContentStatus = {
  key: 'LOADED' | 'IN_PROGRESS' | 'FAILED';
  count: number;
  percent: number;
  class: string;
};
export type ContentProgress = {
  contentProgress: ContentStatus[];
  contentTotal: number;
  contentLoaded: number;
  contentInError: number;
  isContentLoading: boolean;
};

/**
 * Returns the status of all Seeq content in the document, suitable for displaying a progress bar. Note that the
 * numbers may be incorrect until `isContentLoading` becomes true, specifically while CK is loading.
 *
 * @returns The count for each content state
 */
export function getContentProgress(): ContentProgress {
  const failed = getContentInErrorState().length;
  const inProgress = getLoadingContent().length;
  const loaded = getAllContent().length - failed - inProgress;
  const totalContent = failed + inProgress + loaded;
  return {
    contentProgress: [
      {
        key: 'LOADED',
        count: loaded,
        percent: (100 * loaded) / totalContent,
        class: 'success',
      },
      {
        key: 'IN_PROGRESS',
        count: inProgress,
        percent: (100 * inProgress) / totalContent,
        class: 'info',
      },
      {
        key: 'FAILED',
        count: failed,
        percent: (100 * failed) / totalContent,
        class: 'danger',
      },
    ],
    contentTotal: totalContent,
    contentLoaded: loaded,
    contentInError: failed,
    isContentLoading: !isAllContentFinishedLoading(),
  };
}

/**
 * Returns false if any content in the browser is still being loaded, otherwise true. Used by the screenshot service to
 * determine when the document is ready to be captured. To ensure the content is fully loaded there are three
 * conditions that must be met:
 * - CK must be finished loading. Without this check the page will be empty as it loads which could lead to a
 * false-positive if it takes a while for CK to load.
 * - There can be no instances of an empty reactWrapper because that indicates an inner component has not yet
 * finished rendering.
 * - Finally, all Content must either be in a loaded or failed (considered ok for the purposes of this check) state.
 *
 * @returns True if all content has finished loading
 */
export function isAllContentFinishedLoading(): boolean {
  return (
    (!sqWorkbookStore.isReportBinder ||
      isCKEditorLoadedInReport() ||
      !!sqWorkbookStore.getWorksheet(sqWorkbenchStore.stateParams.worksheetId)?.isDashboard) &&
    document.querySelectorAll(`.${CONTENT_LOADING_CLASS.REACT_WRAPPER}:empty`).length === 0 &&
    getLoadingContent().length === 0
  );
}

let isCKLoaded = false;
export const isCKEditorLoadedInReport = () => isCKLoaded;

export const setCKEditorLoadedInReport = (isLoaded: boolean) => {
  isCKLoaded = isLoaded;
};
