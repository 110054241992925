import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@seeqdev/qomponents';

interface ConfirmUnsubscribeNotLoggedInProps {
  creator: string;
  admin: string;
  notificationName: string;
  userEmail: string;
  handleUnsubscribeClick: () => void;
}

export const ConfirmUnsubscribeLoggedIn: React.FunctionComponent<ConfirmUnsubscribeNotLoggedInProps> = ({
  creator,
  admin,
  notificationName,
  userEmail,
  handleUnsubscribeClick,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div data-testid="unsubscribeLoggedIn" className="text-center">
        <h2>{t('UNSUBSCRIBE.ARE_YOU_SURE_UNSUBSCRIBE')}</h2>
      </div>
      <div data-testid="mainContent" className="ml20 mt40 mb40">
        <div className="flexColumnContainer ">
          <h4 className="text-bolder mb10">{t('UNSUBSCRIBE.UNSUBSCRIBE_FROM')} </h4>
          <h4 className="ml5">{notificationName}</h4>
        </div>
        <div className="flexColumnContainer">
          <h4 className="text-bolder ">{t('UNSUBSCRIBE.EMAIL_ADDRESS')}</h4>
          <h4 className="ml5">{userEmail}</h4>
        </div>
      </div>
      <div className="text-center flexColumnContainer">
        <h4>{t('UNSUBSCRIBE.NOT_YOU_CONTACT', { creator, admin })}</h4>
      </div>
      <div className="text-center mt20">
        <Button
          testId="submitUnsubscribe"
          label={t('SUBMIT')}
          size="lg"
          variant="theme"
          onClick={handleUnsubscribeClick}
        />
      </div>
    </div>
  );
};
