import React, { useState } from 'react';
import { FORM_ERROR, FormElement } from '@/formbuilder/formBuilder.constants';
import { useAuth } from '@/main/auth/hooks/useAuth.hook';
import { MAX_NAME_LENGTH } from '@/main/app.constants';
import { SimpleSaveFormBuilder } from '@/formbuilder/SimpleSaveFormBuilder.page';
import { UpdateDomainFn } from '@/main/auth/hooks/useLogin.hook';
import { getPasswordErrorText, validPassword } from '@/utilities/password.utilities';

export type RegisterFormProps = {
  seeqDomain: any;
  domain: any;
  updateDomain: UpdateDomainFn;
  toggleDisplay: () => void;
};

export const RegisterForm: React.FunctionComponent<RegisterFormProps> = ({
  seeqDomain,
  domain,
  updateDomain,
  toggleDisplay,
}) => {
  const {
    updateFormData,
    formData: { firstName, lastName, username, password, passwordCheck },
    serverMessage,
    register,
  } = useAuth({ seeqDomain, domain, updateDomain });

  const [newUsernameFocused, setNewUsernameFocused] = useState(false);

  const formDefinition: FormElement[] = [
    {
      component: 'FormControlFormComponent',
      name: 'firstName',
      testId: 'firstName',
      label: 'USER.FIRST_NAME',
      placeholder: 'USER.FIRST_NAME_PROMPT',
      value: firstName,
      onChange: (firstName) => updateFormData({ firstName: firstName as string }),
      size: 'lg',
      autoComplete: 'given-name',
      maxLength: MAX_NAME_LENGTH.NAME,
      required: true,
      customErrorText: firstName.length > MAX_NAME_LENGTH.NAME ? 'NAME_LENGTH_ERROR' : '',
      customErrorParams: { count: MAX_NAME_LENGTH.NAME },
      autoFocus: true,
    },
    {
      component: 'FormControlFormComponent',
      name: 'lastName',
      testId: 'lastName',
      label: 'USER.LAST_NAME',
      placeholder: 'USER.LAST_NAME_PROMPT',
      value: lastName,
      onChange: (lastName) => updateFormData({ lastName: lastName as string }),
      size: 'lg',
      autoComplete: 'family-name',
      maxLength: MAX_NAME_LENGTH.NAME,
      required: true,
      customErrorText: lastName.length > MAX_NAME_LENGTH.NAME ? 'NAME_LENGTH_ERROR' : '',
      customErrorParams: { count: MAX_NAME_LENGTH.NAME },
    },
    {
      component: 'FormControlFormComponent',
      name: 'newUsername',
      testId: 'newUsername',
      label: 'USER.EMAIL',
      placeholder: 'USER.EMAIL_PROMPT',
      type: 'email',
      value: username,
      onChange: (username) => updateFormData({ username: username as string }),
      size: 'lg',
      autoComplete: 'username',
      onFocus: () => setNewUsernameFocused(true),
      onBlur: () => setNewUsernameFocused(false),
      required: true,
      customErrorText: newUsernameFocused ? 'USER.EMAIL_INVALID' : '',
    },
    {
      component: 'FormControlFormComponent',
      name: 'password',
      testId: 'password',
      label: 'USER.PASSWORD',
      placeholder: 'USER.PASSWORD_PROMPT',
      type: 'password',
      value: password,
      onChange: (password) => updateFormData({ password: password as string }),
      size: 'lg',
      autoComplete: 'new-password',
      validation: (password: string | number) => !validPassword(password as string),
      required: true,
      customErrorText: getPasswordErrorText(password),
    },
    {
      component: 'FormControlFormComponent',
      name: 'passwordCheck',
      testId: 'passwordCheck',
      label: 'USER.PASSWORD_CHECK',
      placeholder: 'USER.PASSWORD_CHECK_PROMPT',
      type: 'password',
      value: passwordCheck,
      onChange: (passwordCheck) => updateFormData({ passwordCheck: passwordCheck as string }),
      size: 'lg',
      autoComplete: 'new-password',
      required: true,
      validation: (passwordCheck: string | number) => (passwordCheck as string) !== password,
      extendValidation: true,
      customErrorText: passwordCheck !== password ? 'USER.PASSWORD_MISMATCH' : '',
    },
    {
      component: 'ErrorMessageFormComponent',
      name: 'serverErrorMessage',
      testId: 'specServerErrorMessage',
      type: FORM_ERROR,
      value: serverMessage,
      failForm: false,
      includeIf: !!serverMessage,
    },
  ];

  return (
    <SimpleSaveFormBuilder
      testId="register"
      formDefinition={formDefinition}
      submitFn={register}
      closeFn={toggleDisplay}
      buttonExtraClassNames="mt25"
      submitBtnLabel="LOGIN_PANEL.REGISTER"
    />
  );
};
