import React from 'react';
import { resetLayout, setLayoutLocked } from '@/main/layout.actions';
import { useTranslation } from 'react-i18next';
import { ButtonWithDropdown, Icon } from '@seeqdev/qomponents';
import { sqWorkbenchLayoutStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { doResetLayout } from '@/main/layout.utilities';

export const LayoutControls: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const layoutLocked = useFluxPath(sqWorkbenchLayoutStore, () => sqWorkbenchLayoutStore.layoutLocked);

  const actions = [
    {
      href: '#',
      icon: 'fa-window-restore',
      onClick: doResetLayout,
      label: t('LAYOUT.RESET_LAYOUT'),
      testId: 'resetLayout',
    },
    {
      href: '#',
      icon: layoutLocked ? 'fa-lock-open' : 'fa-lock',
      label: layoutLocked ? t('LAYOUT.UNLOCK_LAYOUT') : t('LAYOUT.LOCK_LAYOUT'),
      onClick: () => {
        setLayoutLocked(!layoutLocked);
      },
      testId: 'lockLayout',
    },
  ];

  return (
    <div
      className="ml5 flexColumnContainer cursorPointer sq-text-color flexNoGrowNoShrink flexAlignCenter"
      data-testid="layoutMenu">
      <ButtonWithDropdown
        tooltip={t('LAYOUT.CONFIGURE')}
        tooltipPlacement="bottom"
        dropdownItems={actions}
        triggerIcon={<Icon icon="fa-window-restore" type="white" extraClassNames="fa-xlg flexNoGrowNoShrink mr5" />}
      />
    </div>
  );
};
