import _ from 'lodash';
import { AnnotationInputV1 } from 'sdk/model/AnnotationInputV1';
import { sqAnnotationsApi } from '@/sdk/api/AnnotationsApi';
import { AnnotationOutputV1 } from 'sdk/model/AnnotationOutputV1';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { CONTENT_MODEL_ATTRIBUTES } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { errorToast } from '@/utilities/toast.utilities';
import { logError } from '@/utilities/logger';
import { formatMessage } from '@/utilities/logger.utilities';

function annotationInputFromOutput(output: AnnotationOutputV1, type: string): AnnotationInputV1 {
  return {
    description: output.description,
    interests: _.chain(output.interests)
      .uniqBy((interest) => [interest.item?.id, interest.capsule?.id].join(''))
      .map((interest) => ({ interestId: interest.item?.id ?? '', detailId: interest.capsule?.id }))
      .value(),
    name: output.name,
    type,
    reportInput:
      type === 'Report'
        ? {
            cronSchedule: output.cronSchedule,
            background: output.background,
            enabled: output.enabled,
          }
        : undefined,
    discoverable: output.discoverable,
  };
}

/**
 * Toggles the editor to the other version, saving or loading backups as needed so that when the page is refreshed
 * it is a good state.
 *
 * @param annotationId - The id of the annotation being migrated
 * @return Promise with the migrated or reverted document HTML.
 */
export function toggleEditor(annotationId: string): Promise<string | undefined> {
  const handleError = (error: Error) => {
    errorToast({ httpResponseOrError: error, displayForbidden: true });
    return Promise.reject(error);
  };

  return Promise.all(
    // We need to get the item properties to get the type to provide some additional metadata data to the
    // annotation input object
    [sqAnnotationsApi.getAnnotation({ id: annotationId }), sqItemsApi.getItemAndAllProperties({ id: annotationId })],
  )
    .then(
      ([
        { data },
        {
          data: { type },
        },
      ]) => {
        if (data.ckEnabled) {
          return sqItemsApi
            .setProperty(
              { value: false },
              {
                id: annotationId,
                propertyName: SeeqNames.Properties.IsCkEnabled,
              },
            )
            .then(() =>
              sqItemsApi.getProperty({
                id: annotationId,
                propertyName: SeeqNames.Properties.FroalaBackup,
              }),
            )
            .catch((error) => {
              // If the document was empty on conversion we don't want to produce an error
              if (error.status === 404 && _.includes(error.data.statusMessage, SeeqNames.Properties.FroalaBackup)) {
                return Promise.resolve({ data: { value: undefined } });
              }
              return Promise.reject(error);
            })
            .then(({ data: { value } }) =>
              sqAnnotationsApi
                .updateAnnotation(
                  {
                    ...annotationInputFromOutput(data, type),
                    document: value ?? '',
                  },
                  { id: annotationId },
                )
                .then(() => value ?? ''),
            )
            .catch(handleError);
        } else {
          return Promise.resolve()
            .then(() => {
              try {
                return migrateDocumentFromFroalaToCK(data.document as string);
              } catch (e) {
                // Very unlikely the migration would fail, but if it it does the user should still get their document
                logError(formatMessage`Failed to migrate annotation ${annotationId} to CK: ${e}`);
                return data.document;
              }
            })
            .then((migratedDocument) =>
              sqAnnotationsApi
                .migrateAnnotationToCkEditor(
                  {
                    document: migratedDocument,
                  },
                  { id: annotationId },
                )
                .catch((e) => {
                  // Annotation may fail to save, such as if it has cross-linked annotation images
                  logError(formatMessage`Error saving annotation ${annotationId} while migrating: ${e}`);
                })
                .then(() => migratedDocument),
            );
        }
      },
    )
    .catch(handleError);
}

/**
 * Migrates a document from Froala to CK.
 *
 * @param originalDocument - The Froala document to migrate
 */
export function migrateDocumentFromFroalaToCK(originalDocument: string): string | undefined {
  if (!originalDocument) {
    return undefined;
  }

  // Adding a wrapper div makes getting the actual html easier at the end
  const javascriptDocument = document.createElement('div');
  javascriptDocument.innerHTML = originalDocument;

  // Convert Froala page breaks to CK page breaks
  javascriptDocument.querySelectorAll('hr.fr-page-break').forEach((hrElement) => {
    const newElement = document.createElement('div');
    // Do NOT convert this to something like newElement.style.pageBreakAfter because that will get translated into
    // HTML differently based on the browser and CK relies on the style being exactly right (CRAB-36902)
    newElement.innerHTML =
      '<div class="page-break" style="page-break-after:always;"><span style="display:none;">&nbsp;</span></div>';
    hrElement.parentNode?.replaceChild(newElement.childNodes[0], hrElement);
  });
  //region table styles
  // The below styles were found by creating an equivalent table in CK and copying the styles it used
  // We don't have to remove the froala specific classes because CK will remove any class it doesn't have a
  // converter for.

  const dashedBorders = '1px dashed #DDD';
  const noBorders = '1px solid hsl(0, 0%, 100%)';

  javascriptDocument.querySelectorAll('table').forEach((element) => {
    let alignment = '';
    if (element.classList.contains('center-froala-table')) {
      // this is how froala centers it's tables
      alignment = '';
    } else if (!_.isEmpty(element.style.marginLeft) && _.isEmpty(element.style.marginRight)) {
      alignment = 'right';
    } else if (_.isEmpty(element.style.marginLeft) && !_.isEmpty(element.style.marginRight)) {
      alignment = 'left';
    } else if (!_.isEmpty(element.style.marginLeft) && !_.isEmpty(element.style.marginRight)) {
      // format for 'width' is 12% - we need to extract only the number
      const remainingWidth = 100 - _.toNumber(_.head(element.style['width'].match(/^\d+/g)));
      // format for 'margin-left' is calc(45%) - we need to extract only the number
      const percentFromLeft =
        (_.toNumber(_.head(element.style.marginLeft.substring(5).match(/^\d+/g))) / remainingWidth) * 100;
      // format for 'margin-right' is calc(25%) - we need to extract only the number
      const percentFromRight =
        (_.toNumber(_.head(element.style.marginRight.substring(5).match(/^\d+/g))) / remainingWidth) * 100;
      alignment = '';
      if (percentFromLeft > 75) {
        alignment = 'right';
      } else if (percentFromRight > 75) {
        alignment = 'left';
      }
    }

    const wrapElement = document.createElement('figure');
    wrapElement.classList.add('table');

    if (alignment !== '') {
      wrapElement.style.float = alignment;
    }

    element.parentNode?.insertBefore(wrapElement, element);
    wrapElement.appendChild(element);
  });

  // In Froala, dashed and no borders could be on at the same time, but it would create a weird super thick dashed
  // border. Here, we just pick one to win out based on ordering.

  // No Borders
  const allNoBorderTables = javascriptDocument.querySelectorAll('table.table-no-border');
  allNoBorderTables.forEach(((tableElement: HTMLElement) => {
    tableElement.style.border = noBorders;
    tableElement.querySelectorAll('td').forEach((tdElement) => {
      tdElement.style.border = noBorders;
    });
  }) as any);

  // Dashed Borders
  const allDashedTables = javascriptDocument.querySelectorAll('table.fr-dashed-borders');
  allDashedTables.forEach(((dashedTablesElement: HTMLElement) => {
    dashedTablesElement.style.border = dashedBorders;
    dashedTablesElement.querySelectorAll('td').forEach((tdElement: HTMLElement) => {
      tdElement.style.border = dashedBorders;
    });
  }) as any);

  // Highlighted Cells
  javascriptDocument.querySelectorAll('td.fr-highlighted').forEach(((element: HTMLElement) => {
    element.style.border = '1px double red';
  }) as any);

  // Thick Cells
  javascriptDocument.querySelectorAll('td.fr-thick').forEach(((element: HTMLElement) => {
    element.style.borderWidth = '2px';
  }) as any);

  // Striping
  javascriptDocument.querySelectorAll('table.fr-alternate-rows').forEach(((element: HTMLElement) => {
    element.parentElement?.classList.add('seeqTableStriped');
  }) as any);

  // Certain ways of adding alignment and other stylings add extra divs to tables. This copies any relevant styles
  // to the parent td of the div and removes the div
  javascriptDocument.querySelectorAll('td > div').forEach(((divElement: HTMLElement) => {
    const divStyle = divElement.getAttribute('style') ?? '';
    const parentStyle = divElement.parentElement?.getAttribute('style') ?? '';
    divElement.parentElement?.setAttribute('style', `${divStyle} ${parentStyle}`);
  }) as any);

  // Removes extraneous divs that add extra spacing in CK
  javascriptDocument.querySelectorAll('td > div').forEach(((divElement: HTMLElement) => {
    if (divElement && divElement.parentNode) {
      // move all children out of the element
      while (divElement.firstChild) {
        divElement.parentNode.insertBefore(divElement.firstChild, divElement);
      }
      // remove the empty element
      divElement.remove();
    }
  }) as any);

  //remove last br from each td element
  javascriptDocument.querySelectorAll('td > br:last-child').forEach(((lastBr: HTMLElement) => {
    lastBr.remove();
  }) as any);

  // wrap br in p in order to not be converted in double br.
  javascriptDocument.querySelectorAll('td > br').forEach(((brElement: HTMLElement) => {
    const pElement = document.createElement('p');
    brElement.parentNode?.insertBefore(pElement, brElement);
    pElement.appendChild(brElement);
  }) as any);

  // Froala cell colors propagated to children that didn't have a color assigned. In CK each cell requires
  // specific colors.
  const backgroundColorKey = 'background-color';
  javascriptDocument.querySelectorAll(`td[style*="${backgroundColorKey}"]`).forEach(((tdElement: HTMLElement) => {
    const backgroundColor = tdElement.style.backgroundColor;
    tdElement.querySelectorAll('td').forEach((innerTd: HTMLElement) => {
      if (!innerTd.style.backgroundColor) {
        innerTd.style.setProperty(backgroundColorKey, backgroundColor);
      }
    });
  }) as any);

  //endregion

  //region content migration

  // Remove surrounding a tags
  javascriptDocument.querySelectorAll(`a [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`).forEach(((
    aElement: HTMLElement,
  ) => {
    const parentElement = aElement.parentElement;
    if (parentElement && parentElement.parentNode) {
      // move all children out of the element
      while (parentElement.firstChild) {
        parentElement.parentNode.insertBefore(parentElement.firstChild, parentElement);
      }
      // remove the empty element
      parentElement.remove();
    }
  }) as any);

  // Migrate border attribute
  javascriptDocument
    .querySelectorAll(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}].report-image-border`)
    .forEach(((element: HTMLElement) => {
      element.setAttribute(CONTENT_MODEL_ATTRIBUTES.BORDER, 'true');
    }) as any);

  // Migrate no margin
  javascriptDocument
    .querySelectorAll(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}].report-no-margin`)
    .forEach(((element: HTMLElement) => {
      element.setAttribute(CONTENT_MODEL_ATTRIBUTES.NO_MARGIN, 'true');
    }) as any);

  // height, width, and width percent
  javascriptDocument.querySelectorAll(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`).forEach(((
    content: HTMLElement,
  ) => {
    if (!_.isEmpty(content.style.maxWidth)) {
      content.setAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH, `${parseFloat(content.style.maxWidth)}`);
    }
    if (!_.isEmpty(content.style.maxHeight)) {
      content.setAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT, `${parseFloat(content.style.maxHeight)}`);
    }
    if (!_.isEmpty(content.style.width)) {
      content.setAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT, `${content.style.width}`);
    }
  }) as any);

  //endregion

  return javascriptDocument.innerHTML;
}
