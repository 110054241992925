import React from 'react';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import { initializeTrackers } from '@/track/track.service';
import _ from 'lodash';

/**
 * Tracker is responsible for initializing the Telemetry trackers.
 *
 * This component was created to ensure that all tracking events use current store data.
 */
export const Tracker: React.FunctionComponent = () => {
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);
  const darkMode = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.darkMode);
  const userLanguage = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userLanguage);
  const license = useFluxPath(sqLicenseManagementStore, () => sqLicenseManagementStore.license);

  if (!_.isEmpty(license) && currentUser) {
    initializeTrackers(currentUser, darkMode, userLanguage, license);
  }

  return null;
};
