import _ from 'lodash';
import i18next from 'i18next';
import { ChartSettingsInterface } from '@/tableBuilder/tableViewer/ChartSettings.molecule';
import { getCellDisplayValue, getCellValue, isTextColumn } from '@/utilities/tableBuilderHelper.utilities';
import { TREND_COLORS } from '@/trendData/trendData.constants';
import { ParametersMap } from '@/utilities/formula.constants';
import { ColumnOrRowWithDefinitions, SimpleTableCell, SimpleTableRow } from '@/tableBuilder/tableBuilder.types';

export interface TableChartDataIF {
  isTransposed: boolean;
  categories: string[];
  values: ValueIF[];
  colors: string[];
}

interface ValueIF {
  name: string;
  data: { value: number; uom: string; displayValue?: string }[];
}

const removePercentIfPresent = (stringToBeParsed?: string): string | undefined =>
  _.isString(stringToBeParsed) && stringToBeParsed.slice(-1) === '%'
    ? stringToBeParsed.substring(0, stringToBeParsed.length - 1)
    : stringToBeParsed;

/**
 * Takes the simple table data, which has a row for each item, and picks out the columns the user has selected to form
 * the raw data for the chart. Also picks out the category (text) columns that the user selected and uses those to form
 * the legend. If user has selected no columns it treats it as though they have selected all columns.
 *
 * @param simpleTableData - Data from simple table
 * @param storeSettings - Chart settings interface
 * @param columns - Simple table columns
 * @param isTransposed - Whether to transpose the data or not
 * @returns Formatted data for HighCharts
 */
export function simpleTableToHighChartsData(
  simpleTableData: SimpleTableRow[],
  storeSettings: ChartSettingsInterface,
  columns: ColumnOrRowWithDefinitions[],
  isTransposed: boolean,
  useSignalColorsInChart: boolean,
  chartColors: ParametersMap,
): TableChartDataIF {
  // valueIndices is an "allow list" of columns the user checked in Chart Settings menu
  // track them by index, so we know which indices to keep/skip later on
  const valueIndices = _.reduce(
    columns,
    (indices: number[], column, i) =>
      (_.isEmpty(storeSettings.columns) && !isTextColumn(column)) || _.includes(storeSettings.columns, column.key)
        ? indices.concat(i)
        : indices,
    [],
  );

  const allowedColumns = _.at(columns, valueIndices);

  const filteredRows = _.filter(
    simpleTableData,
    (row) => _.isEmpty(storeSettings.rows) || _.includes(storeSettings.rows, row.itemId),
  );

  const calculateCategories = () =>
    isTransposed
      ? _.map(filteredRows, (row) => buildLabelFromCategories(row.cells, columns, storeSettings.categoryColumns))
      : _.map(
          allowedColumns,
          (item) => item.header || (!_.isUndefined(item.shortTitle) ? i18next.t(item.shortTitle) : ''),
        );

  const calculateColors = (): string[] =>
    _.isEmpty(chartColors) ||
    !useSignalColorsInChart ||
    isTransposed ||
    _.some(filteredRows, (item) => !chartColors[item.itemId])
      ? TREND_COLORS
      : _.map(filteredRows, (item) => chartColors[item.itemId]);

  const transformCells = (row: SimpleTableRow) =>
    _.chain(row.cells)
      .at(valueIndices)
      .map((cell: SimpleTableCell) => ({
        value: getCellValue(cell),
        uom: cell?.units ?? '',
        displayValue: getCellDisplayValue(cell),
      }))
      .value();

  const calculateValuesAndLabels = (): ValueIF[] => {
    return (
      isTransposed
        ? _.map(allowedColumns, (column, idx) => ({
            name: !_.isUndefined(column.shortTitle) ? i18next.t(column.shortTitle) : '',
            data: _.map(filteredRows, (row) => transformCells(row)[idx]),
          }))
        : _.map(filteredRows, (row) => ({
            name: buildLabelFromCategories(row.cells, columns, storeSettings.categoryColumns),
            data: transformCells(row),
          }))
    ) as ValueIF[];
  };

  return {
    isTransposed,
    categories: calculateCategories(),
    values: calculateValuesAndLabels(),
    colors: calculateColors(),
  };
}

/**
 *  Builds the label for chart view that is used in the legend
 *
 * @param cells - Series selected in chart settings
 * @param columns - Simple table columns, series and categories
 * @param categoryColumns - Series names selected in chart settings
 * @returns Label constructed from cell data associated with user-selected categories
 */
export function buildLabelFromCategories(
  cells: SimpleTableCell[],
  columns: ColumnOrRowWithDefinitions[],
  categoryColumns: string[],
): string {
  const columnIndices = _.reduce(
    columns,
    (indices: number[], column, i) =>
      _.isEmpty(categoryColumns) || (_.includes(categoryColumns, column.key) && isTextColumn(column))
        ? indices.concat(i)
        : indices,
    [],
  );
  // User chose no categoryColumns: use columnIndices as-is (label order determined by cell order)
  // Else, use categoryColumns to determine order of label strings
  return _.isEmpty(categoryColumns)
    ? _.chain(cells).at(columnIndices).map('value').join(' ').value()
    : _.chain(cells)
        .at(
          _.chain(categoryColumns)
            .map((category) => columns.findIndex((col) => col.key === category))
            .filter((index) => columnIndices.includes(index))
            .value(),
        )
        .map('value')
        .join(' ')
        .value();
}
