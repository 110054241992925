import ReactGridLayout from 'react-grid-layout';

export enum ContentTypes {
  Visual,
  Text,
}

export const HEADER_HEIGHT = 6;

export interface DashboardContent {
  type: ContentTypes;
  /** user given name; prefilled on content insertion */
  title: string;
  /**  indicates if title should be displayed or not */
  showTitle: boolean;
  /**  indicates if a border should be displayed or not */
  showBorder?: boolean;
  /**  id of the content for the given tile */
  id: string;
  heading?: boolean;
}

export type DashboardTile = DashboardContent & ReactGridLayout.Layout;

export interface DashboardMenuAction {
  translationKey: string;
  action: () => Promise<void> | void;
  iconClass: string;
}
