import _ from 'lodash';
import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import { COLUMNS_AND_STATS, ITEM_TYPES } from '@/trendData/trendData.constants';
import { formatNumber, FormatOptions } from '@/utilities/numberHelper.utilities';
import { isStringSeries } from '@/utilities/utilities';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import i18next from 'i18next';
import {
  AUTO_SIZE_MENU_ACTIONS,
  CONDITION_EXTRA_COLUMNS,
  DEFAULT_BACKGROUND_DARK,
  DEFAULT_BACKGROUND_LIGHT,
  DEFAULT_TEXT_COLOR_DARK,
  NULL_PLACEHOLDER,
  STRIPED_CELL_COLOR,
  STRIPED_CELL_COLOR_DARK,
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableCellStyle,
  TextHeaderMenuAction,
} from '@/tableBuilder/tableBuilder.constants';
import { ANALYSIS_COLORS } from '@/trend/trendViewer/trendViewer.constants';
import {
  ColumnOrRow,
  ColumnOrRowWithDefinitions,
  ConditionTableHeader,
  SimpleTableCell,
  TableBuilderHeaders,
} from '@/tableBuilder/tableBuilder.types';
import { RangeExport } from '@/trendData/duration.store';
import type { AgGridReact } from '@ag-grid-community/react';
import { isDurationValid } from '@/datetime/dateTime.utilities';
import { Signal } from '@/utilities/items.types';

const isReadableColorCache = new Map();

/**
 * Formats the text for a column header.
 *
 * @param headerInfo - Header formatting information
 * @param headerInfo.type - One of TableBuilderHeaderType enumeration
 * @param headerInfo.format - Format string to use if .type is a format involving date/times
 * @param property - Custom property
 * @param startTime - Start time for the cell
 * @param endTime - End time for the cell
 * @param timezone - timezone to run the table for
 * @returns The formatted header
 */
export function formatHeader(
  headerInfo: { type: TableBuilderHeaderType; format: string },
  property: string | undefined,
  startTime: number,
  endTime: number,
  timezone: { name: string },
): string {
  const formatDate = (date: number, isStart: boolean) =>
    _.isNil(date)
      ? i18next.t(`TABLE_BUILDER.${isStart ? 'STARTS_OUT_OF_RANGE' : 'ENDS_OUT_OF_RANGE'}`)
      : moment.utc(date).tz(timezone.name).format(headerInfo.format);
  if (headerInfo.type === TableBuilderHeaderType.None) {
    return '';
  } else if (headerInfo.type === TableBuilderHeaderType.CapsuleProperty) {
    return property ?? '';
  } else if (headerInfo.type === TableBuilderHeaderType.Start) {
    return formatDate(startTime, true);
  } else if (headerInfo.type === TableBuilderHeaderType.End) {
    return formatDate(endTime, false);
  } else {
    return `${formatDate(startTime, true)} - ${formatDate(endTime, false)}`;
  }
}

/**
 * Formats a metric value for display in the scorecard table.
 *
 * @param value - The value of the metric
 * @param formatOptions - the format options to be used when formatting the cell
 * @param [column] - One of the columns from COLUMNS
 * @returns The formatted value
 */
export function formatMetricValue(value: any, formatOptions: FormatOptions, column: any = {}): string {
  if (_.isNil(value)) {
    return column.style === 'string' ? '' : NULL_PLACEHOLDER;
  } else if (_.isNumber(value)) {
    return formatNumber(
      value,
      {
        ...formatOptions,
        format: column.format || formatOptions?.format,
      },
      _.noop,
    );
  } else {
    // A regex which replaces <br>, <br />, and either of the previous with extra whitespace with a newline
    return value.toString().replace(/<br\s*\/?>/g, '\n');
  }
}

/**
 * Computes the style for cell.
 *
 * @param backgroundColor - Background color.
 * @param textColor - Text color
 * @param textStyle - An array with text styles
 * @param textAlign - Text align
 * @param priorityBackgroundColor - Overwrites the backgroundColor.
 * @param fallbackBackgroundColor - Fallback background color. It is used if backgroundColor and
 *   priorityBackgroundColor are not present
 * @param darkMode - True if application is in dark mode
 * @param whiteSpace - The white space style to use
 * @returns An object containing HTML styles attributes and values
 */
export function computeCellStyle(
  backgroundColor: string | undefined = undefined,
  textColor: string | undefined = undefined,
  textStyle: string[] = [],
  textAlign = 'left',
  priorityBackgroundColor: string | undefined = undefined,
  fallbackBackgroundColor: string | undefined = undefined,
  darkMode = false,
  whiteSpace: string | undefined = undefined,
): TableCellStyle {
  const hasPriorityColor = !_.isNil(priorityBackgroundColor);
  const appliedTextColor = textColor ? textColor : 'var(--ag-header-foreground-color)';
  let noPriorityBackgroundColor: string | undefined;
  if (!_.isNil(backgroundColor)) {
    noPriorityBackgroundColor = backgroundColor;
  } else if (!_.isNil(fallbackBackgroundColor)) {
    noPriorityBackgroundColor = fallbackBackgroundColor;
  }

  const resultBackgroundColor = hasPriorityColor ? priorityBackgroundColor : noPriorityBackgroundColor;

  const blackOrWhite = tinycolor(priorityBackgroundColor).isDark() ? '#fff' : '#000';

  // check readability when we have metric color (priorityBackgroundColor) and overwrite text color if necessary
  const resultTextColor =
    hasPriorityColor && !isReadableColor(priorityBackgroundColor, appliedTextColor) ? blackOrWhite : appliedTextColor;

  const style: TableCellStyle = {
    backgroundColor: resultBackgroundColor,
    color: resultTextColor,
    fontWeight: _.includes(textStyle, 'bold') ? 'bold' : 'normal',
    fontStyle: _.includes(textStyle, 'italic') ? 'italic' : 'normal',
    textDecoration: _.filter(textStyle, (textDecoration) =>
      _.includes(['overline', 'line-through', 'underline'], textDecoration),
    ).join(' '),
    textAlign,
  };

  if (whiteSpace) {
    style.whiteSpace = whiteSpace;
  }
  return style;
}

/**
 * Cached version of {@code tinycolor.isReadable}. The original function is not particularly slow but when calling
 * it many times in tables with thousands of cells the cache improves performance.
 * @param backgroundColor - The background color.
 * @param textColor - The text color.
 * @return True if the text is readable on the specified background
 */
function isReadableColor(backgroundColor: string, textColor: string): boolean {
  const cacheKey = backgroundColor + textColor;
  let isReadable = isReadableColorCache.get(cacheKey);
  if (_.isUndefined(isReadable)) {
    isReadable = tinycolor.isReadable(backgroundColor, textColor);
    isReadableColorCache.set(cacheKey, isReadable);
  }
  return isReadable;
}

/**
 * Computes a foreground that contrasts with that color so that it is readable.
 *
 * @param backgroundColor - The background color. Default to white if not specified.
 * @param darkMode - true if dark mode is used to render the application
 * @return Styles for the background and foreground colors
 */
export function computeCellColors(
  backgroundColor: string | undefined,
  darkMode: boolean | undefined,
): {
  backgroundColor: string;
  color: string;
} {
  const defaultBackgroundColor = darkMode ? DEFAULT_BACKGROUND_DARK : DEFAULT_BACKGROUND_LIGHT;
  const color = backgroundColor ?? defaultBackgroundColor;
  const defaultTextColor = darkMode ? DEFAULT_TEXT_COLOR_DARK : '#000';
  return {
    backgroundColor: color,
    color: isReadableColor(color, defaultTextColor) ? defaultTextColor : '#fff',
  };
}

/**
 * Gets the striped color based on the current state of isTableStriped and the row index passed in
 */
export function getStripedColor(isStriped: boolean, rowIndex: number, darkMode: boolean): string | undefined {
  const stripedCellColor = darkMode ? STRIPED_CELL_COLOR_DARK : STRIPED_CELL_COLOR;
  return isStriped && rowIndex % 2 === 0 ? stripedCellColor : undefined;
}

export function hasNumericAndStringItems(items: { itemType: string }[], itemType: ITEM_TYPES) {
  return _.chain(items)
    .filter((item) => item.itemType === itemType)
    .partition((signal) => isStringSeries(signal as unknown as Signal))
    .every((group) => !_.isEmpty(group))
    .value();
}

export function getMostReadableIconType(background = '#fff') {
  const mostReadable = tinycolor.mostReadable(background, [ANALYSIS_COLORS.DARK_PRIMARY, ANALYSIS_COLORS.LIGHT_COLOR]);
  return mostReadable.toString() === ANALYSIS_COLORS.DARK_PRIMARY ? 'theme' : 'theme-light';
}

const tryCopyToClipboard = (tableRef: Node | AgGridReact, copy: () => void) => {
  try {
    if (!tableRef) {
      errorToast({ messageKey: 'TABLE_BUILDER.COPY_TO_CLIPBOARD_EMPTY' });
    } else copy();
    successToast({ messageKey: 'TABLE_BUILDER.COPY_TO_CLIPBOARD_SUCCESS' });
  } catch (err) {
    errorToast({ messageKey: 'TABLE_BUILDER.COPY_TO_CLIPBOARD_ERROR' });
  }
};

export function copyAgGridTableToClipboard(agGridRef: AgGridReact, includeHeaders = true) {
  tryCopyToClipboard(agGridRef, () => {
    const gridApi = agGridRef.api;
    gridApi.selectAll();
    gridApi.copySelectedRowsToClipboard({ includeHeaders });
  });
}

export function isPropertyOrStatColumn(column: ColumnOrRow): boolean {
  return isPropertyColumnType(column) || !!column.signalId || _.includes(CONDITION_EXTRA_COLUMNS, column.key);
}

export function isPropertyOrStatOrMetricColumn(column: ColumnOrRow): boolean {
  return isPropertyOrStatColumn(column) || !!column.metricId;
}

export function isPropertyColumnType(column: ColumnOrRow): boolean {
  return _.includes([TableBuilderColumnType.Property, TableBuilderColumnType.CapsuleProperty], column.type);
}

export function findParent(node: HTMLElement, type: string, className?: string): HTMLElement {
  let parentNode = node?.parentElement;
  while (
    parentNode?.parentElement &&
    !(parentNode.nodeName === type && (!className || parentNode.className.split(' ').includes(className)))
  ) {
    parentNode = parentNode.parentElement;
  }
  return parentNode as HTMLElement;
}

/**
 * Finds the size of the earliest parent node of the given node with the given type
 * @param node
 * @param type
 * @param className
 * @returns The height and width of the parent node, or undefined
 */
export function findParentSize(
  node: HTMLElement,
  type: string,
  className?: string,
): { height: number; width: number } | undefined {
  const parentNode = findParent(node, type, className);
  return parentNode ? { height: parentNode.clientHeight, width: parentNode.clientWidth } : undefined;
}

/**
 * Utility method that determines if a given table column is a text column
 * @param column table column
 * @returns True if the given column is a text column, false otherwise
 */
export const isTextColumn = (column: ColumnOrRowWithDefinitions) =>
  _.isUndefined(column.style) ? false : ['assets', 'string', 'fullpath'].includes(column.style);

export const isStartOrEndColumn = (column: ColumnOrRow | undefined) =>
  _.includes([COLUMNS_AND_STATS.startTime.key, COLUMNS_AND_STATS.endTime.key], column?.key);

export const getTextValueForConditionHeader = (
  header: ConditionTableHeader,
  column: ColumnOrRowWithDefinitions | undefined,
) => {
  if (column) {
    return _.cond([
      [
        _.matches({ type: TableBuilderColumnType.Text }),
        (column: ColumnOrRowWithDefinitions) => column.cells && column.cells[header.key],
      ],
      [_.matches({ key: COLUMNS_AND_STATS.name.key }), () => header.name],
      [_.matches({ key: COLUMNS_AND_STATS.valueUnitOfMeasure.key }), () => header.units],
    ])(column);
  }
  return '';
};

export const getSimpleTableMenuActions = ({
  columnType,
  isPresentationMode,
  isViewOnlyMode,
  hideAutoSize,
  isTransposed,
  isGroupingEnabled,
  isInteractiveContent,
}: {
  columnType: string | undefined;
  isPresentationMode: boolean;
  isViewOnlyMode: boolean;
  hideAutoSize: boolean;
  isTransposed: boolean;
  isGroupingEnabled: boolean;
  isInteractiveContent: boolean;
}) => {
  if (isPresentationMode) {
    const options: TextHeaderMenuAction[] = [];
    return columnType === TableBuilderColumnType.Text
      ? options
      : options.concat(isInteractiveContent ? [TextHeaderMenuAction.Filter, TextHeaderMenuAction.Sort] : []);
  }

  if (isViewOnlyMode) {
    return columnType === TableBuilderColumnType.Text ? [] : [TextHeaderMenuAction.Filter, TextHeaderMenuAction.Sort];
  }

  const actionsToExclude: TextHeaderMenuAction[] = [];
  if (columnType === TableBuilderColumnType.Text) {
    actionsToExclude.push(TextHeaderMenuAction.Filter, TextHeaderMenuAction.Sort);
  }
  if (hideAutoSize) {
    actionsToExclude.push(...AUTO_SIZE_MENU_ACTIONS);
  }
  if (isTransposed) {
    actionsToExclude.push(TextHeaderMenuAction.GroupByColumn);
  }
  if (!isGroupingEnabled || isTransposed) {
    actionsToExclude.push(TextHeaderMenuAction.AggregateByColumn);
  }

  return Object.values(TextHeaderMenuAction).filter((action) => !actionsToExclude.includes(action));
};

export const getExtraHeaderProps = (
  column: any,
  headers: TableBuilderHeaders,
  displayRange: RangeExport,
  timezone: { name: string },
): { isInput: boolean; textValue: string; isStatic?: boolean } => {
  if (column.headerOverridden) {
    const textValue = formatHeader(
      headers,
      column.property,
      displayRange.start.valueOf(),
      displayRange.end.valueOf(),
      timezone,
    );
    return { textValue, isInput: false, isStatic: true };
  }
  // check for undefined. Empty string is OK - the user removed the title
  const textValue =
    column.header ?? (column.type === TableBuilderColumnType.Property ? column.key : i18next.t(column.shortTitle));

  return { textValue, isInput: true };
};

/**
 * Takes in cell data and determines what the display value should be that will be shown in the chart. If the cell
 * is a duration, it will be converted to hours and then displayed in the chart. If the cell is not a duration but
 * does have a value, the value will be displayed. Otherwise, a 0 will be displayed.
 * @returns the display value for the cell
 */
export function getCellDisplayValue(cell: SimpleTableCell): string | number {
  if (cell?.value && !_.isUndefined(cell?.units)) {
    return cell.value;
  }
  if (isDurationValid(cell?.value) && cell?.rawValue) {
    const totalHours = (cell?.rawValue as number) / 3600;
    const truncatedValue = (cell.value as string).slice(0, -2);

    if (totalHours < 60 / 3600) {
      // If the duration is less than a minute, display it as its cell value in hour format
      return `0:00:${truncatedValue}`;
    } else if (totalHours < 24) {
      // If the duration is over a minute and less than a day, display it as its cell value
      return truncatedValue;
    } else {
      // If the duration is more than a day, display it as the cell value and the total number of hours
      return `${truncatedValue} (${totalHours.toFixed(0)} ${i18next.t('UNITS.HOURS_plural')})`;
    }
  }

  return '0';
}

/**
 * Takes in cell data and determines what the value of the cell should be that will be used in the chart. If the
 * cell is a number and not a duration, it will return the cell's raw value. If the cell is a duration, it will
 * return the cell's raw value converted to hours. If the cell is not a number, it will return 0.
 * @returns the value for the cell
 */
export function getCellValue(cell: SimpleTableCell): number {
  if (_.isNumber(cell?.rawValue)) {
    if (!_.isUndefined(cell?.units)) {
      return cell.rawValue;
    } else if (isDurationValid(cell?.value)) {
      return (cell.rawValue as number) / 3600;
    }
  }
  return 0;
}
