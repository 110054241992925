// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Table, TableColumn } from '@/core/Table.atom';
import Pagination, {
  PaginationAndFilteringSupportingActions,
  PaginationAndFilteringSupportingStore,
} from '@/core/Pagination.organism';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';

/**
 * This is a table component that supports server side filtering and pagination.
 * Most of the "Magic" that makes sorting and filtering happen is found in the store and actions. Actions and stores
 * must be instances of PaginationAndFilteringSupportingStores/PaginationAndFilteringSupportingActions!
 *
 * An example using this table component can be found in @core/UserTable.page.tsx.
 *
 * @param {TableColumn []} columns - an array of TableColumn instances that describe the table columns. see
 * @TableColumn for more information.
 *
 * @param {{id: string} []} items - an array of items that are rendered as table contents. An item must at least
 * have an id. To successfully render an item the item object keys have to match the column.accessors
 *
 * @param {string[]} selectedIds - an array of item ids that are selected. NOTE: to enable row selection you must
 * have exactly one TableColumn of type ROW_SELECTION
 *
 * @param {({ id: string }) => void()} rowSelectionCallback - a function triggered on row selection. The selected
 * item is provided to the function as a parameter.
 *
 * @param {({ field: string, value:string }) => void()} filterCallback - a function triggered on column filtering. The
 *  column.accessor as well as the filter value are provided to the function
 *
 * @param {({ field: string, sortAscending:boolean }) => void()} sortCallback - a function triggered on column sorting.
 *   The column.accessor as well as the sort order (true for asc, false for desc) are provided to the function
 *
 * @param {ids: string[]} selectAllCallback - a function triggered when the ROW_SELECTION header is clicked. If all are
 *   selected then an array of all the selected ids is provided to this function. If none are selected and empty array
 *   is passed.
 *
 * @param {PaginationAndFilteringSupportingStore} store - a flux store that implements all the functions of the
 * PaginationAndFilteringSupportingStore. These functions are required to make sorting and filtering work.
 *
 * @param {PaginationAndFilteringSupportingActions} actions - actions that implement all the functions of the
 * PaginationAndFilteringSupportingActions. These functions are required to make sorting and filtering work.
 *
 * @param {(offset: number, limit: number, searchParams?) => void()} loadTable - the function that loads the items
 * for the current page (offset is calculated based on the current page number), limit is the selected page size
 * (provided by the store)
 *
 * @param {string} testId? - a testId can be provided and will be used as the data-testId that enables easy
 * targeting of tables for testing purposes.
 */

interface ServerSideFilteringAndPaginatingTableProps {
  columns: TableColumn[];
  items: { id: string }[];
  selectedIds: string[];
  rowSelectionCallback: (item: { id: string }) => void;
  filterCallback: (option: { value: any }, field: string) => void;
  sortCallback: (field: string, sortAscending: boolean) => void;
  selectAllCallback: (ids: string[]) => void;
  store: PaginationAndFilteringSupportingStore;
  actions: PaginationAndFilteringSupportingActions;
  loadTable: (offset: number, limit: number, searchParams?: any) => void;
  rowClickCallback?: (item: any) => void;
  insideModal?: boolean;
  testId?: string;
  tableClass?: string;
}

/**
 * This wrapper adds server side pagination, filtering and sorting using the standard paginator.
 */
export const ServerSideFilteringAndPaginatingTable: React.FunctionComponent<
  ServerSideFilteringAndPaginatingTableProps
> = ({
  testId,
  store,
  actions,
  loadTable,
  selectedIds,
  columns,
  rowSelectionCallback,
  sortCallback,
  selectAllCallback,
  items,
  filterCallback,
  rowClickCallback,
  insideModal,
  tableClass,
}) => {
  useFluxPath(store, () => store.searchParams);
  useFluxPath(store, () => store.sortAscending);
  useFluxPath(store, () => store.sortProperty);

  const sortBy = store.sortProperty;
  const sortAscending = store.sortAscending;

  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    setAllSelected(items?.length === selectedIds?.length && selectedIds?.length !== 0);
  }, [selectedIds, items]);

  const onSelectAll = () => {
    selectAllCallback(allSelected ? [] : _.map(items, 'id'));
    setAllSelected(!allSelected);
  };

  const sortableColumns = _.map(columns, (column) => _.assign({ sortable: true, filterable: true }, column));

  return (
    <>
      <Table
        testId={testId}
        tableClass={tableClass}
        sortProperty={sortBy}
        sortAscending={sortAscending}
        sortTableCallback={sortCallback}
        filterTableCallback={filterCallback}
        onRowClickCallback={rowClickCallback}
        onRowSelectCallback={rowSelectionCallback}
        selectedIds={selectedIds}
        columns={sortableColumns}
        items={items}
        selectAllCallback={onSelectAll}
        selectAll={allSelected}
        searchParams={store.searchParams}
      />

      <Pagination store={store} actions={actions} loadTable={loadTable} insideModal={insideModal} />
    </>
  );
};
