import type { AgGridReactProps } from '@ag-grid-community/react';

export interface SubMenuItem {
  iconClass: string;
  translationKey: string;
  action?: () => void;
  disabled?: boolean;
  tooltip?: string;
}

export interface ColumnAction<ActionKeyType> extends SubMenuItem {
  actionKey: ActionKeyType;
  subMenuItems?: SubMenuItem[];
}

export interface AgGridAsyncProps extends Omit<AgGridReactProps, 'modules'> {
  modules: AgGridModules[];
  isServerSideRowModel?: boolean;
}

export enum AgGridModules {
  ClipboardModule,
  RangeSelectionModule,
  SetFilterModule,
  MenuModule,
  ColumnsToolPanelModule,
  MultiFilterModule,
  RowGroupingModule,
  GridChartsModule,
}
