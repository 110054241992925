import _ from 'lodash';
import ssf from 'ssf';
import i18next from 'i18next';
import { defaultNumberFormat } from '@/services/systemConfiguration.utilities';

export const DISPLAY_NUMBER_LENGTH = 6;

/**
 * Options that can be set for formatting a number.
 */
export interface FormatOptions {
  // An ECMA-376 spreadsheet format code or the AUTO_FORMAT
  format?: string;
  // If true, format the number as a percent.
  isPercent?: boolean;
}

export const AUTO_FORMAT = 'auto';

export const NO_FORMAT = 'none';
export const SIGFIG_FORMAT_REGEX = /sigfig:(\d+)/i;
export const DECP_FORMAT_REGEX = /decp:(\d+)/i;

export const NUMBER_FORMATS = [
  { name: 'PROPERTIES.NUMBER_FORMAT.AUTO', format: AUTO_FORMAT },
  { name: 'PROPERTIES.NUMBER_FORMAT.NUMBER', format: '#,##0.00' },
  {
    name: 'PROPERTIES.NUMBER_FORMAT.SCIENTIFIC_NOTATION',
    format: '0.0000E+0',
  },
  { name: 'PROPERTIES.NUMBER_FORMAT.SIG_FIG', format: 'SigFig:4' },
];

/**
 * Helper function that returns the power of the supplied number
 *
 * @param number - a number
 * @returns the power of the supplied number
 */
export function powerOf(number: number): number {
  if (number === 0) {
    return 0;
  }
  return Math.abs(Math.floor(Math.log(Math.abs(number)) / Math.log(10)));
}

/**
 * A helper function that trims the fractional part of a number string to a pleasing
 * number of digits according to the following rules, where 'number' is the absolute
 * value of the integer portion of the number string.
 *  1 <= number < 10 : three digits after decimal place
 *  10 <= number < 100 : two digits after decimal place
 *  100 <= number < 10000 : 1 digits after decimal place
 *
 * @param numString - the number string to be trimmed
 * @param length - the max length of the trimmed string
 * @returns the trimmed number string or the original number string if trimming
 * was not required.
 */

export function trimFractionalPart(numString: string, length: number): string {
  let digitsFracPart, allNines;
  const TRIM_PERIOD_REGEX = /^\.|\.$/g;
  let modifiedString = numString.replace(TRIM_PERIOD_REGEX, '');
  const num = +modifiedString;
  const sign = num >= 0 ? '' : '-';
  const signCount = num < 0 ? 1 : 0;
  const decimalCount = 1;
  const intPart = trunc(Math.abs(num));
  let fracPart = Math.abs(num) % 1;
  if (fracPart !== 0) {
    digitsFracPart = Math.max(1, length - signCount - decimalCount - Math.max(1, powerOf(intPart)));

    // Determine if the entire fractional part of the string to be displayed is made up of all nines
    allNines = _.reduce(
      _.range(digitsFracPart),
      function (result, i) {
        return result && fracPart.toString()[i + 2] === '9'; // add 2 to i to skip leading "0." characters
      },
      true,
    );

    if (allNines) {
      fracPart = +fracPart.toString().slice(0, digitsFracPart + 2); // if all nines then just truncate string
    } else {
      fracPart = +fracPart.toFixed(digitsFracPart);
    }
    modifiedString = sign + intPart + fracPart.toString().slice(1); // trim leading zero of fractional part
  }

  // Use our own trunc function until IE11 supports Math.trunc()
  function trunc(x: number) {
    return x < 0 ? Math.ceil(x) : Math.floor(x);
  }

  if (modifiedString.length > length) {
    modifiedString = (+modifiedString).toPrecision(length - signCount - decimalCount).toString();
  }

  TRIM_PERIOD_REGEX.lastIndex = 0; // ensure regex is reset before second use
  return modifiedString.replace(TRIM_PERIOD_REGEX, '');
}

/**
 * Returns a string representation of a number with x or fewer characters. This function takes into account the
 * decimal place, negative signs, and other non-numeric characters.
 *
 * @param num - the number to be processed
 * @param length - the max length of the returned string including non-numeric characters
 * @return String representation of the number with the specified length or shorter
 */
export function roundFloatToCharacterLimit(num: number | string, length: number): string {
  let decimalLength;
  let modifiedPrecision, negativeSignCount, exponentCharCount, nonExpMin, nonExpMax, value, valueStr;
  let processedLabel = '';
  const EXPONENTIAL_SYMBOLS_COUNT = 2; // e.g. e+ or e-
  const LEADING_NUM_AND_DECIMAL_COUNT = 2; // e.g. 1.

  if (!isNumeric(num)) {
    return '';
  }

  value = +(+num).toPrecision(length);
  valueStr = value.toString();

  negativeSignCount = value < 0 ? 1 : 0;
  nonExpMin = Math.pow(10, -length + negativeSignCount + 2);
  nonExpMax = Math.pow(10, length - negativeSignCount);

  if (valueStr.length <= length || value === 0) {
    processedLabel = valueStr;
  } else if (Math.abs(value) < nonExpMax && Math.abs(value) >= nonExpMin) {
    decimalLength = +valueStr % 1 !== 0 ? 1 : 0;
    valueStr = valueStr.length > length ? (+(+num).toPrecision(length - decimalLength)).toString() : valueStr;
    processedLabel = trimFractionalPart(valueStr, length);
  } else {
    exponentCharCount = powerOf(value).toString().length;
    modifiedPrecision =
      length - LEADING_NUM_AND_DECIMAL_COUNT - EXPONENTIAL_SYMBOLS_COUNT - negativeSignCount - exponentCharCount;
    modifiedPrecision = Math.min(20, Math.max(0, modifiedPrecision));
    processedLabel = value.toExponential(modifiedPrecision);
  }
  return processedLabel;
}

/**
 * Returns a string representation of a number with x or fewer significant digits
 *
 * @param num - the number to be processed
 * @param length - the number of significant digits
 * @return {string} String representation of the number with length x or less
 */
export function roundFloatToSignificantDigits(num: number | string, length: number | string): string {
  if (!isNumeric(num)) {
    return '';
  }

  let processedLabel;
  // To be consistent with the "auto" format, these values are set so that large and small numbers transition to
  // e-notation at similar thresholds (i.e when abs(value) > MAX_EXP_THRESHOLD and when abs(value) < MIN_EXP_THRESHOLD
  const MIN_EXP_THRESHOLD = 1e-5;
  const MAX_EXP_THRESHOLD = 1e6;

  const valueStr = (+num).toPrecision(_.toNumber(length));
  const value = +valueStr;

  const shouldBeExponential =
    (Math.abs(value) >= MAX_EXP_THRESHOLD || Math.abs(value) < MIN_EXP_THRESHOLD) && value !== 0;
  const isExponential = valueStr.includes('e');
  const getDecimalFormatOutput = () => value.toString();
  const getExponentialFormatOutput = () => value.toExponential(_.toNumber(length) - 1);

  // num.toPrecision(sigFigs) outputs numbers in a string representation with the appropriate number of significant
  // digits. For cases where the number of sigFigs is less than the magnitude of the number, a string in
  // exponential form will be returned (e.g. (1234).toPrecision(2) = '1.2e3'). This is not the desired behavior,
  // so the following checks ensure that the value is exponential only when we want it to be exponential and is
  // otherwise in decimal format.
  if (shouldBeExponential) {
    processedLabel = getExponentialFormatOutput();
  } else if (isExponential) {
    processedLabel = getDecimalFormatOutput();
  } else {
    processedLabel = valueStr;
  }
  return processedLabel;
}

/**
 * Rounds a floating point number to a desired precision.
 * Taken from "PHP-Like rounding Method" section of
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
 *
 * @param  number - the number to round
 * @param  precision - the precision to round with
 * @return rounded to precision
 */
export function roundWithPrecision(number: number, precision: number): number {
  let factor = Math.pow(10, precision);
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  factor = factor !== 0 ? factor : 1;
  return roundedTempNumber / factor;
}

/**
 * Determines if input represents a number. If input is a string it uses regex since built-in methods like
 * parseFloat() have too many edge cases.
 *
 * @param input - The input to test
 * @returns True if it is positive or negative whole or floating number, false otherwise
 */
export function isNumeric(input: unknown): boolean {
  return _.isFinite(input) || (_.isString(input) && !_.isEmpty(input) && /^-?\d*(\.\d+)?$/.test(input));
}

/**
 * Converts a valid number representation to a number type or returns undefined if it is not a number.
 *
 * @param number - A value that may represent a number
 * @returns The value converted a number or undefined if it is not numeric
 */
export function toNumber(number: unknown): number | undefined {
  return isNumeric(number) ? _.toNumber(number) : undefined;
}

/**
 * Formats a number according to the options set and returns a string representation of that number.
 *
 * @param number - the number to be formatted
 * @param options - the options specifying how the number should be formatted
 * @param error - a callback that is called with an error message string if an error occurs
 * @return a string representation of the number as specified by the options
 */
export function formatNumber(number: any, options?: FormatOptions, error: (message: string) => void = _.noop): string {
  const opts: FormatOptions = _.defaults({}, options, {
    format: defaultNumberFormat(),
    isPercent: false,
  });
  const AUTO_FORMAT_LENGTH = 6;

  if (!_.isFinite(number)) {
    return '';
  } else if (opts.isPercent) {
    // Legacy behavior that can be replaced once percents are no longer units. CRAB-11201
    // Once percent is no longer a unit, numbers can be formatted as a percent with the '%' character in the SSF
    // format string.
    return i18next.t('PERCENT', { PERCENT: formatNumber(number) });
  } else if (opts.format === AUTO_FORMAT) {
    return roundFloatToCharacterLimit(number, AUTO_FORMAT_LENGTH);
  } else if (SIGFIG_FORMAT_REGEX.test(opts.format ?? '')) {
    const results = SIGFIG_FORMAT_REGEX.exec(opts.format as string) as string[];
    return roundFloatToSignificantDigits(number, results[1]);
  } else if (DECP_FORMAT_REGEX.test(opts.format ?? '')) {
    const results = DECP_FORMAT_REGEX.exec(opts.format as string) as string[];
    return _.round(number, +results[1]).toString();
  } else {
    try {
      return ssf.format(opts.format as string, number);
    } catch (err) {
      error((err as Error).toString());
      return number.toString();
    }
  }
}

const UNITS_BASE_TEN = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'] as const;
export const humanReadableByteCount = (bytes: number, forceUnits?: typeof UNITS_BASE_TEN[number]): string => {
  const { amount, units } = humanScaledByteCount(bytes, forceUnits);
  return `${amount} ${units}`;
};

/**
 * Converts bytes to a suitable unit for display. Largely a copy of similar backend code in SystemInfo.java
 *
 * @param bytes - The number of bytes
 * @param forceUnits - If provided, value will always be converted to the specified units
 * @returns The bytes converted to the correct amount and the units
 */
export const humanScaledByteCount = (
  bytes: number,
  forceUnits?: typeof UNITS_BASE_TEN[number],
): { amount: number; units: typeof UNITS_BASE_TEN[number] } => {
  const baseKilo = 1000;

  if (bytes < baseKilo && !forceUnits) {
    return { amount: bytes, units: 'B' };
  }

  const exp = forceUnits ? UNITS_BASE_TEN.indexOf(forceUnits) : Math.floor(Math.log(bytes) / Math.log(baseKilo));
  const number = bytes / Math.pow(baseKilo, exp);

  const units = UNITS_BASE_TEN[exp];

  return { amount: parseFloat(number.toFixed(2)), units };
};
