import { sqWorkbenchStore } from '@/core/core.stores';
import { UserInputV1 } from 'sdk/model/UserInputV1';
import { sqUsersApi } from '@/sdk/api/UsersApi';
import { UserOutputV1 } from 'sdk/model/UserOutputV1';
import { AxiosPromise } from 'axios';
import { WORKBENCH_SCHEMA_VERSION } from '@/workbench/workbench.constants';
import { DehydratedState } from '@/services/stateSynchronizer.service';
import { isInWorkbookRoute, onHomeScreen } from '@/main/routing.utilities';
import { isInWorkbookRouteAndWorkbookLoaded, themeType } from '@/utilities/utilities';

/**
 * Get the current user's workbench state. If there is no state, it returns an empty object.
 * @returns {Object} state for the current user's workbench.
 */
export function getWorkbench() {
  if (sqWorkbenchStore.currentUser.workbench) {
    return JSON.parse(sqWorkbenchStore.currentUser.workbench).state;
  }
  return {};
}

/**
 * Set the workbench state for the current user
 * @param {Object} state - The workbench state object
 * @returns {Promise} A promise that will resolve when the current user's workbench state has been set.
 */
export function setWorkbench(state: DehydratedState): AxiosPromise<UserOutputV1> {
  const workbenchData = {
    workbench: JSON.stringify({
      version: WORKBENCH_SCHEMA_VERSION,
      state,
    }),
  } as UserInputV1;
  return sqUsersApi.updateUser(workbenchData, {
    id: sqWorkbenchStore.currentUser.id,
  });
}

/**
 * Get the theme for the current page
 * @param isVantage
 * @param isAnalysis
 * @param isTopic
 * @returns A string that represents the theme for the current page.
 */
export function getPageTheme(isVantage: boolean, isAnalysis: boolean, isTopic: boolean): themeType {
  if (isInWorkbookRoute() && !isInWorkbookRouteAndWorkbookLoaded() && !onHomeScreen()) {
    return 'default';
  }

  if (isInWorkbookRouteAndWorkbookLoaded() && !onHomeScreen()) {
    if (isAnalysis) {
      return 'analysis';
    } else if (isVantage) {
      return 'vantage';
    } else if (isTopic) {
      return 'topic';
    }

    return 'default';
  }

  return 'topic';
}
