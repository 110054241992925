import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Button, Icon, TextField } from '@seeqdev/qomponents';
import SelectItem from '@/core/SelectItem.organism';
import { AddFormulaParameterModal } from '@/formula/AddFormulaParameterModal.molecule';
import { EditableText } from '@/core/EditableText.atom';
import { errorToast, warnToast } from '@/utilities/toast.utilities';
import { isItemRedacted } from '@/utilities/redaction.utilities';
import { sqItemsApi } from '@/sdk';

const ITEM_SELECTOR_WIDTH = 200;
const PADDING_AND_ICON_WIDTH = 145;

export interface FormulaEditorParam {
  name: string;
  item: { id: string; name: string; type?: string; contextConditionId?: string };
  identifier?: string;
  listParameters?: FormulaEditorParam[];
}

interface FormulaParametersTableProps {
  resizeEnabled?: boolean;
  parameters: FormulaEditorParam[];
  updateParameterCallback: (updated: FormulaEditorParam, originalParam: FormulaEditorParam) => void;
  removeParameterCallback: (identifier: string) => void;
  insertParameter?: (param: string) => void;
  additionalItems?: any[];
  excludeStoreItems?: boolean;
  onItemSelect: (idx: any, item: any) => void;
  includeAddToDisplayPane?: boolean;
  suppressAddOrRemoveOrChangeParameter?: boolean;
  addFormulaParameter?: (params: {
    name: any;
    item: any;
    identifier: any;
    listParameters?: FormulaEditorParam[] | undefined;
  }) => void;
  listParametersEditable?: boolean;
  convertStringToParametersList?: (listParametersString: string) => FormulaEditorParam[];
  latestParameterListAdded?: string;
  setLatestParameterListAdded?: (identifier: string | undefined) => void;
}

export const FormulaParametersTable: React.FunctionComponent<FormulaParametersTableProps> = ({
  resizeEnabled = true,
  additionalItems,
  insertParameter,
  parameters,
  onItemSelect,
  excludeStoreItems,
  updateParameterCallback,
  removeParameterCallback,
  includeAddToDisplayPane = true,
  suppressAddOrRemoveOrChangeParameter = false,
  addFormulaParameter,
  listParametersEditable = false,
  convertStringToParametersList,
  latestParameterListAdded,
  setLatestParameterListAdded,
}) => {
  const { t } = useTranslation();
  const [formulaParameterModalItem, setFormulaParameterModalItem] = useState<FormulaEditorParam | undefined>(undefined);

  const iconClass = resizeEnabled ? 'ml10' : 'ml5';

  const updateParameter = (updatedParameter: FormulaEditorParam, originalParameter: FormulaEditorParam) => {
    if (
      updatedParameter.name !== originalParameter.name &&
      _.some(parameters as any, { identifier: updatedParameter.identifier })
    ) {
      warnToast({ messageKey: 'FORMULA.VARIABLE_UNIQUE' });
    } else {
      updateParameterCallback(updatedParameter, originalParameter);
    }
  };

  const contextParameters = parameters.filter((param) => param.item.contextConditionId);
  const onlyIds = parameters.map((param) => param.item.id);
  const needSpacingForContextButton =
    contextParameters.length > 0 &&
    contextParameters.filter(
      (withContext) => withContext.item.contextConditionId && onlyIds.includes(withContext.item.contextConditionId),
    ).length !== contextParameters.length;

  const updateParameterList = (parameter: FormulaEditorParam, parameterList: string) => {
    if (!parameterList.match(/^(\$?\w+)(,\s*\$?\w+)*$/g)) {
      errorToast({
        messageKey: 'FORMULA.LIST_PARAMETER_INVALID',
        messageParams: { identifier: `$${parameter.identifier}`, variableListString: parameterList },
      });
      return;
    }
    const listParameters = convertStringToParametersList ? convertStringToParametersList(parameterList) : undefined;

    updateParameter(
      {
        ...parameter,
        item: { id: parameter.item.id, name: listParameters?.map((param) => `$${param.identifier}`).join(', ') ?? '' },
        listParameters,
      },
      parameter,
    );
  };

  const clickRef = React.useCallback(
    (node: HTMLParagraphElement) => {
      if (node) {
        node.focus();
        node.click();
        if (setLatestParameterListAdded) {
          setLatestParameterListAdded(undefined);
        }
      }
    },
    [setLatestParameterListAdded],
  );

  return (
    <>
      <table className="table table-striped table-condensed mb3 fixedHeaderTable overflowXHidden">
        <thead>
          <tr>
            <th className="min-width-47">{t('NAME')}</th>
            <th className="max-width-370">{t('ITEM')}</th>
            <th className="width-minimum" />
          </tr>
        </thead>
        <tbody>
          {_.isEmpty(parameters) && (
            <tr className="text-center text-italic">
              <td colSpan={3} className="flexCenter flexFill">
                {t('FORMULA.NO_VARIABLES')}
              </td>
            </tr>
          )}
          {_.map(parameters, (parameter, idx) => (
            <tr key={parameter.identifier} data-testid={`formulaParameter_${idx}`}>
              <td className="text-valign-middle flexColumnContainer">
                <strong>$</strong>
                <EditableText
                  testId="rowName"
                  value={parameter.identifier as string}
                  inputClasses="flexFill text-bold"
                  textClasses="textAlignLeft text-bold"
                  maxDisplayChars={500}
                  onUpdate={(identifier) => updateParameter({ ...parameter, identifier, name: identifier }, parameter)}
                />
              </td>
              <td className={classNames(resizeEnabled ? `min-width-${ITEM_SELECTOR_WIDTH}` : 'flexFill')}>
                {resizeEnabled && !suppressAddOrRemoveOrChangeParameter ? (
                  <SelectItem
                    testId={`select_${idx}`}
                    selectedItemId={parameter.item.id}
                    excludeStoreItems={excludeStoreItems}
                    showAddToDisplayPane={includeAddToDisplayPane}
                    additionalItems={additionalItems}
                    paramIndex={`${idx}`}
                    identifier={parameter.identifier}
                    extraClassNames={`select_${parameter.name}`}
                    onSelect={onItemSelect}
                    selectByIdAndGroup={true}
                  />
                ) : (
                  <span>
                    {isItemRedacted(parameter.item) && (
                      <Icon
                        tooltip={t('NO_ITEM_ACCESS')}
                        icon="fa-exclamation-triangle"
                        extraClassNames="sq-text-danger"
                      />
                    )}
                    {parameter.listParameters !== undefined && listParametersEditable ? (
                      <EditableText
                        testId="parameterList"
                        value={parameter.item.name}
                        inputClasses="flexFill"
                        textClasses="textAlignLeft"
                        maxDisplayChars={500}
                        onUpdate={(parameterList) => updateParameterList(parameter, parameterList)}
                        placeholder={t('FORMULA.LIST_PARAMETER_PLACEHOLDER')}
                        clickRef={latestParameterListAdded === parameter.identifier ? clickRef : undefined}
                      />
                    ) : (
                      parameter.item.name
                    )}
                  </span>
                )}
              </td>
              <td className="nowrap text-valign-middle">
                {/* eslint-disable-next-line no-nested-ternary */}
                {parameter.item.contextConditionId &&
                parameters.filter((param) => param.item.id === parameter.item.contextConditionId).length === 0 ? (
                  <Icon
                    icon="fa-comment-dots"
                    testId={`contextParam_${parameter.identifier}`}
                    extraClassNames={iconClass}
                    onClick={() => {
                      sqItemsApi
                        .getItemAndAllProperties({ id: parameter.item.contextConditionId as string })
                        .then(({ data }) =>
                          addFormulaParameter?.({
                            item: data,
                            name: data.name,
                            identifier: `${parameter.identifier}_context`,
                          }),
                        );
                    }}
                    tooltip={t('FORMULA.ADD_CONTEXT_CONDITION')}
                  />
                ) : needSpacingForContextButton ? (
                  <i className={resizeEnabled ? 'ml23' : 'ml18'} />
                ) : null}
                <Icon
                  icon="fa-plus"
                  testId={`insertParam_${parameter.identifier}`}
                  extraClassNames={iconClass}
                  onClick={() => insertParameter?.(`$${parameter.identifier}`)}
                  tooltip={t('ADD')}
                />
                {!suppressAddOrRemoveOrChangeParameter && (
                  <Icon
                    icon="fa-pencil"
                    testId={`editParam_${parameter.identifier}`}
                    extraClassNames={iconClass}
                    onClick={() => setFormulaParameterModalItem(parameter)}
                    tooltip={t('EDIT')}
                  />
                )}
                {(!suppressAddOrRemoveOrChangeParameter ||
                  (listParametersEditable && parameter.listParameters !== undefined)) && (
                  <Icon
                    icon="fa-remove"
                    testId={`removeParam_${parameter.identifier}`}
                    extraClassNames={iconClass}
                    onClick={() => removeParameterCallback(parameter.identifier as string)}
                    tooltip={t('REMOVE')}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formulaParameterModalItem !== undefined ? (
        <AddFormulaParameterModal
          parameters={parameters}
          parameter={formulaParameterModalItem}
          onSave={updateParameter}
          onClose={() => setFormulaParameterModalItem(undefined)}
          testId="functionParamModal"
        />
      ) : null}
    </>
  );
};
export default FormulaParametersTable;
