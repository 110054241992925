import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { IconSelect } from '@/core/IconSelect.molecule';
import { getFormFieldProps, getFormFieldWrapperProps } from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import classNames from 'classnames';

export interface IconSelectIF extends ValidatingFormComponent<any> {
  component: 'IconSelectFormComponent';
  selectOptions: { text: string; value: any; icon?: string; label?: any }[];
  onChange: (option: { value: any }, property?: string) => void;
  onBlur?: any;
  formattedOptions?: boolean;
  placeholder?: string;
  testId?: string;
  insideModal?: boolean;
  appendToBody?: boolean;
  disabled?: boolean;
  wrapperClasses?: string;
  isMultipleSelect?: boolean;
  isSearchable?: boolean;
  onRemove?: (option: { value: any }) => void;
  // if true component will not be memoized. this should be used SPARINGLY. Should only be necessary if you need to
  // access state that would be stale if your component is memoized (see PeriodicCondition)
  skipMemo?: boolean;
  onTooltipClick?: () => void;
  creatable?: boolean;
  labelClassNames?: string;
}

export const IconSelectFormComponent: React.FunctionComponent<IconSelectIF> = (props) => {
  const { name, validation, testId = 'iconSelect', wrapperClassNames, customErrorText, labelClassNames } = props;

  const defaultValidation = _.isEmpty;
  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    formState.hasValidationErrors &&
    ((formState.modified && formState.modified[name]) || formState.submitFailed);

  return (
    <FormFieldWrapper
      {...getFormFieldWrapperProps(props, ['wrapperClassNames', 'testId'])}
      testId={testId}
      labelClassNames={labelClassNames}
      wrapperClassNames={classNames('flexFill', wrapperClassNames)}
      showError={showError}
      customErrorText={customErrorText}>
      <Field name={name} validate={validation || defaultValidation}>
        {({ input, meta }) => (
          <IconSelect {...getFormFieldProps(formState, input, meta, props)} testId={`${testId}Field`} />
        )}
      </Field>
    </FormFieldWrapper>
  );
};
