import { getShortIdentifier } from '@/utilities/utilities';
import _ from 'lodash';
import { CalculatedChartCapsuleProperty } from '@/trendData/trendData.types';

export const TABLE_ROWS_LIMIT_PARTIAL_ERROR_MESSAGE = 'Table cannot have more than ';
/**
 * Generates a unique value that can be used as the id of a capsule.  By combining the capsuleId and capsuleSetId
 * a unique value is guaranteed because a capsule set will not have two capsules with the same capsuleId (which
 * is a hash code of the start time, end time, and properties).
 *
 * @param capsuleSetId - The id of the capsule set to which the capsule belongs
 * @param capsuleId - The id of a capsule that is supplied by the backend
 * @return A unique id
 */
export function getUniqueId(capsuleSetId: string, capsuleId: string | number): string {
  return `${capsuleSetId}_${capsuleId}`;
}

/**
 * Checks if we have multiple Y axes in any lane of the chart. Since gridlines are only shown in one color,
 * it can be confusing/look ugly if we show gridlines for multiple axes in the same lane.
 * @param alignedItems: items that could be aligned on the chart
 * @return true if no lane has multiple Y axes, false otherwise
 */
export function shouldGridlinesBeShown(alignedItems: { lane: number; axisAlign: string }[]) {
  // Filter out items in the same lane with different Y axis alignments
  const numUniqueLaneAlignments = _.chain(alignedItems)
    .uniqWith((item1, item2) => item1.lane === item2.lane && item1.axisAlign !== item2.axisAlign)
    .size()
    .value();

  // If we didn't filter any items out above, we're good to go with gridlines
  return numUniqueLaneAlignments === _.size(alignedItems);
}

/**
 * Possible alignments if `max` alignable items had their own axis
 */
export function getAlignments(max: number) {
  return _.times(max, (idx) => _.toUpper(getShortIdentifier(idx)));
}

export function isCapsuleAggregated(capsuleProperties: CalculatedChartCapsuleProperty[]) {
  return _.some(capsuleProperties, ['name', 'Count']);
}

export const isBackendRowsLimitError = (error: any) => {
  return (
    (error?.data?.errorType === 'SYNTAX_ERROR' &&
      error?.data?.statusMessage.indexOf(TABLE_ROWS_LIMIT_PARTIAL_ERROR_MESSAGE) !== -1) ||
    (typeof error === 'string' && error.indexOf(TABLE_ROWS_LIMIT_PARTIAL_ERROR_MESSAGE) !== -1)
  );
};
