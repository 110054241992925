import { PersistenceLevel, Store } from '@/core/flux.service';
import { DashboardContent, DashboardTile, HEADER_HEIGHT } from './dashboard.types';
import _ from 'lodash';

/**
 * This store manages the data for dashboards. The "content" in this store is an Array of DashboardTile objects that
 * combine the data necessary to create the react-grid-layout as well as some information that defines the content
 * of the displayed tiles. The actual tile content pieces (the images and interactive content pieces) are managed by the
 * sqReportStore to ensure that DateRanges and AssetSelection continue to work as expected.
 */
export class DashboardStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqDashboardStore';

  initialize() {
    this.state = this.immutable({
      columnCount: 12,
      content: [],
    });
  }

  get content(): DashboardTile[] {
    return this.state.get('content');
  }

  get columnCount(): number {
    return this.state.get('columnCount');
  }

  protected readonly handlers = {
    // This handler is triggered by a react-grid-layout callback. react-grid-layout strips out custom properties (like
    // the title, id and the type); we need to make sure to only merge the grid changes onto the existing content
    // entries, or we will lose important information).
    DASHBOARD_MERGE_LAYOUT_CHANGES: ({ layout }: { layout: ReactGridLayout.Layout[] }) => {
      const content = this.state.get('content');
      // need to merge onto content:
      this.state.set(
        'content',
        layout.map((gridItem) => {
          const existingItem = _.find(content, { id: gridItem.i });
          return { ...existingItem, ...gridItem };
        }),
      );
    },

    DASHBOARD_ADD_TO_DASHBOARD: ({ tile, heading }: { tile: DashboardTile; heading: boolean }) => {
      let content = this.state.get('content');
      if (heading) {
        // shift all the content down so the header is on the top:
        content = content.map((item: DashboardTile) => {
          return { ...item, y: item.y + HEADER_HEIGHT };
        });
      }
      this.state.set('content', [...content, tile]);
    },

    DASHBOARD_UPDATE_TILE: ({ id, property, value }: { id: string; value: unknown; property: string }) => {
      const content = this.state.get('content').find((item: DashboardContent) => item.id === id);
      const updatedContent = [...this.state.get('content')].filter((item: DashboardContent) => item.id !== id);
      this.state.set('content', [...updatedContent, { ...content, [property]: value }]);
    },

    DASHBOARD_REMOVE_CONTENT: ({ id }: { id: string }) => {
      this.state.set('content', _.reject([...this.state.get('content')], { id }));
    },
  };

  dehydrate() {
    return this.state.serialize();
  }

  rehydrate(dehydratedState: any) {
    this.state.merge(dehydratedState);
  }
}
