// @ts-strict-ignore
import moment from 'moment-timezone';
import { TimezonesService } from '@/datetime/timezone.service';

export const sqTimezones = new TimezonesService();

// NOTE: The backend represents time as an signed long in nanoseconds since the Unix Epoch. This provides us about
// ~292.27 years before and after 1970 (2^64 / 1,000,000,000 / 3,600 / 24 / 365.25). However we can't request the
// entire range of ~1680 to ~2260 because of an overflow problem when requesting a range over ~292.27 years. So
// instead the beginning and end of time here are a chosen to be a range of 290 years.
export const BEGINNING_OF_TIME = moment.utc('1900-01-01T00:00:00.000Z');
export const END_OF_TIME = moment.utc('2192-01-01T00:00:00.000Z');
export const S_PER_DAY = moment.duration(1, 'days').as('seconds');
export const MIN_PER_DAY = moment.duration(1, 'days').as('minutes');
export const H_PER_DAY = moment.duration(1, 'days').as('hours');
export const S_PER_MIN = moment.duration(1, 'minutes').as('seconds');
export const S_PER_H = moment.duration(1, 'hours').as('seconds');

export const CAPSULE_GROUP_DATE_FORMAT = 'l LT';

export interface CapsuleProperty {
  /** name of the property (first argument of `setProperty`) */
  name: string;
  /** value of the property in a scalar type */
  value: number | string | boolean;
  /** an optional unit, this is omitted if there is no units or the value is a boolean */
  unitOfMeasure?: string;
}

/** A capsule used in a `condition` formula */
export interface Capsule {
  /** An id may be encoded as a property (CAPSULE_SOURCE_ID_PROPERTY_NAME) in the capsule */
  id?: string;
  /** Beginning datetime for the capsule in ms */
  startTime: number;
  /** End datetime for the capsule in ms */
  endTime: number;
  /** An array of properties if any */
  properties?: CapsuleProperty[];
}
