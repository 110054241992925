// @ts-strict-ignore
import React from 'react';
import { DateTimeRange, RangeField } from '@/core/DateTimeRange.molecule';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { Timezone } from '@/datetime/timezone.service';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import _ from 'lodash';

export interface DateTimeRangeIF extends ValidatingFormComponent<RangeField<moment.Moment>> {
  component: 'DateTimeRangeFormComponent';
  hideTimezone?: boolean;
  fieldIdentifier?: RangeField<string>;
  timezone: Timezone;
  onStartTimeChange?: (value: moment.Moment) => void;
  onEndTimeChange?: (value: moment.Moment) => void;
  disabled?: boolean;
}

export const DateTimeRangeFormComponent: React.FunctionComponent<DateTimeRangeIF> = (props) => {
  const {
    label,
    name,
    testId = 'dateTimeRange',
    extraClassNames,
    value,
    hideTimezone,
    fieldIdentifier,
    timezone,
    customErrorText,
    extendValidation,
    validation,
    onStartTimeChange,
    onEndTimeChange,
    disabled,
  } = props;

  const formState = useForm().getState();
  const showError = _.has(formState.errors, name) && _.has(formState.dirtyFields, name);

  const defaultValidation = (value: RangeField<moment.Moment>) =>
    !value || !value.start || !value.end || value.start.isAfter(value.end);
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <FormFieldWrapper
      testId={testId}
      label={label}
      extraClassNames={extraClassNames}
      showError={showError}
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const { onChange } = getFormFieldProps(formState, input, meta, props);

          return (
            <DateTimeRange
              startTime={value.start}
              endTime={value.end}
              onChange={onChange}
              onStartTimeChange={onStartTimeChange}
              onEndTimeChange={onEndTimeChange}
              hideTimezone={hideTimezone}
              fieldIdentifier={fieldIdentifier}
              timezone={timezone}
              disabled={disabled}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
