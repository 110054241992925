// @ts-strict-ignore
import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';

/**
 * Text input field with a default value and an edit mode to avoid overwriting the
 * value typed by the user. This is useful for formatted number inputs.
 */
interface InputWithFormatterProps {
  /** value to display in the input box prior to editing */
  value: any;
  /** text to set for the "data-testid" property */
  testId: string;
  /** class name(s) for the FormControl component */
  inputClassName: string;
  /** type of the input (e.g. "number") */
  inputType: string;
  /** action that takes an input (same type as value) and returns a string with the desired formatting applied */
  formatter: (inputValue: any) => string;
  /** action to take when the input value changes (i.e. the user types into the field) */
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
  maxLength?: number;
  isInvalid?: boolean;
}

export const InputWithFormatter: React.FunctionComponent<InputWithFormatterProps> = (props) => {
  const { value, testId, inputClassName, inputType, formatter, onChange, maxLength, isInvalid } = props;

  const [isEditing, setIsEditing] = useState(false);

  return (
    <FormControl
      className={`input ${inputClassName}`}
      name={testId}
      type={inputType}
      size="sm"
      value={isEditing ? undefined : formatter(value)}
      onChange={(event) => onChange(event as any)}
      onFocus={(event) => {
        event.target.select();
        setIsEditing(true);
      }}
      onBlur={() => setIsEditing(false)}
      data-testid={testId}
      maxLength={maxLength}
      isInvalid={isInvalid}
    />
  );
};
