import _ from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CursorsService } from '@/trend/trendViewer/cursors.service';
import {
  sqCursorStore,
  sqDurationStore,
  sqTrendSeriesStore,
  sqTrendStore,
  sqWorkbenchStore,
  sqWorksheetStore,
} from '@/core/core.stores';

const DEFAULT_Y_AXIS_CONFIG = {
  title: {
    enabled: true,
    text: '',
  },
  gridLineWidth: 0,
  maxPadding: 0,
  minPadding: 0,
  visible: false,
};

/**
 * Generate n + 1 axes based on the number of series. For all the axis we should
 * have an id "y-axis-<n>" so that it can be uniquely identified by highcharts
 * it should always return at least 1 axis
 */
function generateYAxesConfig(series: any[]) {
  return _.times(Math.max(1, series.length), getSingleAxisConfiguration);
}

/**
 * Return a properly formatter axis configuration along with a unique id
 */
function getSingleAxisConfiguration(index: number) {
  return { ...DEFAULT_Y_AXIS_CONFIG, id: `y-axis-${index}` };
}

interface ScatterPlotMinimapChartProps {
  chart: Highcharts.Chart | null;
  setChart: (chart: Highcharts.Chart) => void;
  chartElementRef: { current: any };
  trendStart: number;
  trendEnd: number;
  xValue: number | null;
  plotSeries: any[];
  selectorLow: number;
  selectorHigh: number;
  updateBoxes: () => void;
  backgroundColor?: string;
}

/**
 * The Highcharts Chart that is the minimap. A line chart with plotBands.
 */
export const ScatterPlotMinimapChart: React.FunctionComponent<ScatterPlotMinimapChartProps> = ({
  chart,
  setChart,
  chartElementRef,
  trendStart,
  trendEnd,
  xValue,
  plotSeries,
  updateBoxes,
  backgroundColor,
}) => {
  const CURSOR_COLOR = '#6d6f70';

  /**
   * Filter and format plot series so that they are on different y-axis scales and the chart spans the expected range
   */
  const getDisplaySeries = (series: any[]) => {
    const displaySeries = _.chain(series).filter('data').cloneDeep().value();
    for (let i = 0; i < displaySeries.length; i++) {
      displaySeries[i].yAxis = `y-axis-${i}`;
      const seriesData = displaySeries[i].data;
      const hasData = seriesData?.length > 0;
      // Insert null samples if necessary so the chart shows the whole display range
      if (hasData && seriesData[0][0] > trendStart) {
        displaySeries[i].data.unshift([trendStart, null]);
      }
      if (hasData && seriesData[seriesData.length - 1][0] < trendEnd) {
        displaySeries[i].data.push([trendEnd, null]);
      }
    }

    return displaySeries;
  };
  const sqCursorsRef = useRef(new CursorsService());

  useEffect(() => {
    if (_.isEmpty(chart) || _.isNull(chart)) {
      return;
    }
    chart.update(
      {
        xAxis: {
          min: trendStart,
          max: trendEnd,
        },
      },
      true,
      false,
      false,
    );
    updateBoxes();
  }, [trendStart, trendEnd]);

  /**
   * Update X position pointer line
   */
  useEffect(() => {
    if (_.isEmpty(chart) || _.isNull(chart)) {
      return;
    }
    if (_.isFinite(xValue)) {
      const xPixel = chart.xAxis[0].toPixels(xValue as number, true);
      // TODO: Refactor me and give me love - CRAB-29350
      sqCursorsRef.current.drawHoverCursor({
        chart,
        xPixel,
        xValue: xValue as number,
        yValues: [],
        capsuleLaneHeight: 0,
        trendData: sqTrendStore,
        durationData: sqDurationStore,
        longestCapsuleSeriesDuration: sqTrendSeriesStore.longestCapsuleSeriesDuration,
        cursorData: sqCursorStore,
        timezoneName: sqWorksheetStore.timezone.name,
        itemsWithLaneInfo: [],
        darkMode: sqWorkbenchStore.darkMode,
        showXLabel: false,
        crosshairColor: CURSOR_COLOR,
      });
    } else {
      sqCursorsRef.current.clearHoverCursor();
    }
  }, [chart, xValue]);

  /**
   * Updates the trend lines displayed on the minimap.
   */
  useEffect(() => {
    if (_.isEmpty(chart)) {
      return;
    }
    const newChartConfig = {
      series: getDisplaySeries(plotSeries),
      yAxis: generateYAxesConfig(plotSeries),
    };
    chart?.update(newChartConfig, true, true, false);
  }, [plotSeries]);

  useEffect(() => {
    if (_.isEmpty(chart)) {
      return;
    }
    chart?.update({ chart: { backgroundColor } }, true, false, false);
  }, [chart, backgroundColor]);

  const initialChartConfig: Highcharts.Options = useMemo(
    () => ({
      chart: {
        height: 35,
        borderWidth: 2,
        spacing: [0, 0, 0, 0],
        borderColor: '#7BAAAF',
        backgroundColor: backgroundColor ?? '#ffffff',
        alignTicks: false,
        animation: false,
        ignoreHiddenSeries: false,
        zoomType: undefined,
        boost: {
          enabled: false,
        },
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
        events: {},
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        series: {
          type: 'line',
          lineWidth: 1,
          turboThreshold: 0,
          animation: false,
          marker: { enabled: false },
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              enabled: false,
            },
          },
          borderWidth: 0,
        },
      },
      credits: { enabled: false },
      title: { text: undefined },
      xAxis: {
        ordinal: false,
        type: 'datetime',
        maxPadding: 0,
        minPadding: 0,
        labels: {
          enabled: false,
        },
        tickWidth: 0,
        min: trendStart,
        max: trendEnd,
      },
      // We need a separate y-axis for each of the signals
      // We add an extra yAxis to prevent Highcharts error 18 on initial load
      yAxis: generateYAxesConfig(plotSeries),
      series: getDisplaySeries(plotSeries),
    }),
    [plotSeries, trendStart, trendEnd],
  );

  /**
   * Callback method to be passed into the HighchartsReact object so we have access to the Highcharts Chart
   *
   * @param highchartsChart - the chart object from Highcharts
   */
  const afterChartCreated = (highchartsChart: Highcharts.Chart) => {
    setChart(highchartsChart);
  };

  return (
    <div className="minimap" ref={chartElementRef} data-testid="minimapChart">
      <HighchartsReact
        highcharts={Highcharts}
        options={initialChartConfig}
        // Don't let the chart auto-update because we have to handle updates in a custom way (see chart update effect)
        allowChartUpdate={false}
        callback={afterChartCreated}
        containerProps={{ className: 'flexFill' }}
      />
    </div>
  );
};
