import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { IconSelect } from '@/core/IconSelect.molecule';
import { useTranslation } from 'react-i18next';
import { CompositeLogic } from '@/tools/compositeSearch/compositeSearch.constants';

interface CompositeSelectorIF {
  selectedLogic: CompositeLogic;
  logicFormulas: CompositeLogic[];
  onChangeLogic: (logic: string) => void;
  extraClassNames?: string;
}

export const CompositesSelectorUnwrapped: React.FunctionComponent<CompositeSelectorIF> = (props) => {
  const { selectedLogic, logicFormulas, onChangeLogic, extraClassNames } = props;
  const { t } = useTranslation();

  const formattedLabels = _.map(logicFormulas, (logic) => ({
    text: logic.key,
    value: logic.key,
    label: (
      <>
        <div data-testid="optionText">
          <strong>{t(`COMPOSITE_SEARCH.${logic.key}.LABEL`)}</strong>
        </div>
        <small>{t(`COMPOSITE_SEARCH.${logic.key}.DESCRIPTION`)}</small>
      </>
    ),
  }));

  return (
    <IconSelect
      testId="logicSelectTestId"
      onChange={(option) => onChangeLogic(_.toString(option.value))}
      name="compositeLogic"
      selectOptions={formattedLabels}
      formattedOptions={true}
      placeholder="COMPOSITE_SEARCH.SELECT_LOGIC"
      value={selectedLogic?.key}
      extraClassNames={classNames(extraClassNames, 'specLogicSelect', 'overflowVisible', {
        'min-height-40 pt2': !_.isUndefined(selectedLogic?.key),
      })}
    />
  );
};

export const CompositesSelector = React.memo(
  CompositesSelectorUnwrapped,
  (prev, next) =>
    !(
      !_.isEqual(prev.selectedLogic, next.selectedLogic) ||
      !_.isEqual(prev.logicFormulas, next.logicFormulas) ||
      prev.extraClassNames !== next.extraClassNames
    ),
);
