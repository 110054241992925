// @ts-strict-ignore
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { sqExploreWorkbookModalStore, sqReportContentStore } from '@/core/core.stores';
import {
  evaluateVisualizationOptions,
  getWorksheetUrlParams,
  isWorksheetUrl,
} from '@/annotation/reportContent.utilities';
import { setSourceUrl, setWorkbookId, setWorksheetId, setWorkstepId } from '@/reportEditor/reportContent.actions';
import { ExplorerModalBody } from '@/explorer/ExplorerModalBody.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import * as actions from '@/explorer/exploreWorkbookModal.actions';

interface ReportContentWorksheetAndUrlProps {
  selectOnlyWorkbooks?: boolean;
  setSelectedItem?: (item: any) => void;
}

export const ReportContentWorksheetAndUrl: React.FunctionComponent<ReportContentWorksheetAndUrlProps> = ({
  selectOnlyWorkbooks = false,
  setSelectedItem,
}) => {
  const { t } = useTranslation();
  const { sourceUrl } = useFlux(sqReportContentStore);
  const [invalidReason, setInvalidReason] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [currentContentUrl, setCurrentContentUrl] = useState(sourceUrl);

  const validateContentUrl = (url: string) => {
    setCurrentContentUrl(url);
    setInvalidReason('');
    if (_.isEmpty(url)) {
      setIsInvalid(false);
      return;
    }
    if (!_.isEmpty(url) && !isWorksheetUrl(url)) {
      setIsInvalid(true);
      setSourceUrl(undefined);
    } else {
      setSourceUrl(url);
      getWorksheetUrlParams(url)
        .then((params) => {
          setIsInvalid(false);
          setWorkbookId(params.workbookId);
          setWorksheetId(params.worksheetId);
          setWorkstepId(params.workstepId);
          evaluateVisualizationOptions(params.workbookId, params.worksheetId, params.workstepId);
        })
        .catch((e) => {
          setIsInvalid(true);
          setInvalidReason(`${e?.data?.statusMessage || e} -`);
          setSourceUrl(undefined);
        });
    }
  };

  return (
    <div className={`flexRowContainer ${selectOnlyWorkbooks && 'small-width-modal'}`}>
      <ExplorerModalBody
        show={true}
        setSelectedItem={setSelectedItem}
        isSelectable={selectOnlyWorkbooks}
        selectOnlyWorkbooks={selectOnlyWorkbooks}
        next={() => {
          setSourceUrl(undefined);
          setWorkbookId(sqExploreWorkbookModalStore.workbookId);
        }}
        store={sqExploreWorkbookModalStore}
        actions={actions}
        allowedTypes={[SeeqNames.Types.Folder, SeeqNames.Types.Analysis]}
      />
      {!selectOnlyWorkbooks && (
        <div>
          <div className="modalSeparation mt20">
            <h5>
              <span>OR</span>
            </h5>
          </div>
          <div className="reportContentUrl flexRowContainer" data-testid="report-content-link">
            <div>
              <h4>
                {t('REPORT.CONTENT.LINK')}
                <Icon
                  icon="fa-question-circle"
                  extraClassNames="mr5 ml5 text-interactive"
                  tooltip={t('REPORT.CONTENT.LINK_HELP')}
                  tooltipPlacement="top"
                  testId="linkHelpIcon"
                />
              </h4>
            </div>
            <textarea
              className={classNames('flexFill mb5 ;', {
                'sq-bg-danger': isInvalid,
                'sq-bg-success ': !isInvalid && !_.isEmpty(currentContentUrl),
              })}
              placeholder={t('REPORT.CONTENT.LINK_PLACEHOLDER')}
              autoFocus
              onChange={(e) => validateContentUrl(e.target.value)}
              value={currentContentUrl}
            />
          </div>
        </div>
      )}
    </div>
  );
};
