// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { getFormFieldProps, getFormFieldWrapperProps } from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import TimeZoneSelector from '@/core/TimeZoneSelector.molecule';
import classNames from 'classnames';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import { Timezone } from '@/datetime/timezone.service';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { FakeLink } from '@/core/FakeLink';

export interface TimeZoneSelectorIF extends ValidatingFormComponent<any> {
  component: 'TimeZoneSelectorFormComponent';
  onChange: (timezone: any) => void;
  value: Timezone;
  optional?: boolean;
  disabled?: boolean;
  timezoneSet?: boolean;
  defaultTimeZone?: { name: string };
  direction?: 'top' | 'bottom' | 'auto';
  enableAutoDetect?: boolean;
  tooltipPlacement?: TooltipPosition;
}

export const TimeZoneSelectorFormComponent: React.FunctionComponent<TimeZoneSelectorIF> = (props) => {
  const {
    name,
    validation,
    defaultTimeZone,
    skipStore,
    value,
    testId,
    enableAutoDetect = true,
    tooltipPlacement = 'left',
  } = props;

  const { t } = useTranslation();
  const defaultValidation = (timezoneObj) => _.isEmpty(timezoneObj?.name);
  const formState = useForm().getState();
  const timezoneSet = !_.isEmpty(formState.values[name]);

  return (
    <FormFieldWrapper {...getFormFieldWrapperProps(props, ['wrapperClassNames'])} wrapperClassNames="flexFillOverflow">
      <Field name={name} validate={validation || defaultValidation}>
        {({ input, meta }) => {
          const formFieldProps = getFormFieldProps(formState, input, meta, props);
          const { onChange } = formFieldProps;

          return (
            <>
              <TimeZoneSelector
                {...formFieldProps}
                onSelect={onChange}
                timezone={skipStore ? input.value : value}
                testId={testId ? `${testId}Field` : ''}
              />

              {enableAutoDetect && defaultTimeZone && (
                <div className="ml5 mt5 flexColumnContainer">
                  <HoverTooltip placement={tooltipPlacement} text="USER.RESET_TOOLTIP">
                    <FakeLink
                      extraClassNames={classNames('cursorPointer', 'text-italic', {
                        'font-size-smaller': timezoneSet,
                        'sq-text-danger': !timezoneSet,
                        'text-underline': !timezoneSet,
                        'force-link-look': timezoneSet,
                      })}
                      onClick={() => onChange(defaultTimeZone)}>
                      {t('USER.RESET')}
                    </FakeLink>
                  </HoverTooltip>
                </div>
              )}
            </>
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
