import React from 'react';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { Field } from 'react-final-form';
import { getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormulaEditorParam } from '@/formula/FormulaParametersTable.molecule';
import { FormulaErrorInterface } from '@/formula/formula.types';
import { FormulaEditorAsync } from '@/formula/FormulaEditorAsync.molecule';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';

export interface FormulaEditorIF extends ValidatingFormComponent<string> {
  component: 'FormulaEditorFormComponent';
  formula: string;
  constants: any[];
  operators: any[];
  showLineNumbers: boolean;
  exposeFormulaToParent: (formula: string) => void;
  parameters: FormulaEditorParam[];
  onSave: (formula: string) => any;
  formulaErrors: FormulaErrorInterface[];
  generalFormulaError: string;
  setFormulaErrors?: (errors: any) => void;
  readOnly?: boolean;
}

/**
 * A wrapper around the `FormulaEditor` component for use with `FormBuilder`
 */
export const FormulaEditorFormComponent: React.FunctionComponent<FormulaEditorIF> = ({
  name,
  testId = 'formulaEditor',
  formula,
  constants,
  operators,
  showLineNumbers,
  exposeFormulaToParent,
  parameters,
  onSave,
  formulaErrors,
  generalFormulaError,
  setFormulaErrors,
  readOnly,
  extendValidation,
  validation,
}) => {
  const defaultValidation = () => {
    return !_.isEmpty(formulaErrors);
  };

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <div className="flexRowContainer flexFill mb8" data-testid={testId}>
      <Field name={name} validate={appliedValidation}>
        {({ input }) => (
          <FormulaEditorAsync
            formula={formula}
            constants={constants}
            operators={operators}
            showLineNumbers={showLineNumbers}
            exposeFormulaToParent={(formulaValue) => {
              if (formulaValue !== formula) {
                exposeFormulaToParent(formulaValue);
                input.onChange(formulaValue);
              }
            }}
            parameters={parameters}
            onSave={onSave}
            formulaErrors={formulaErrors}
            setFormulaErrors={setFormulaErrors}
            readOnly={readOnly}
          />
        )}
      </Field>
      {generalFormulaError && (
        <div className="flexColumnContainer alert-danger pt5 pr5 pb5 pl5 sq-formula-error">
          <ContainerWithHTML content={generalFormulaError} />
        </div>
      )}
    </div>
  );
};
