import _ from 'lodash';
import { getCapsuleFormula } from '@/datetime/dateTime.utilities';
import { findItemIn } from '@/trend/trendDataHelper.utilities';
import { sqTreesApi } from '@/sdk/api/TreesApi';
import { encodeParameters, getShortIdentifier } from '@/utilities/utilities';
import { errorToast } from '@/utilities/toast.utilities';
import { cancelGroup } from '@/requests/pendingRequests.utilities';
import { TreemapItemOutputV1 } from '@/sdk/model/TreemapItemOutputV1';
import { TreemapItem } from '@/treemap/TreemapChart.atom';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE, PushOption } from '@/core/flux.service';
import {
  sqDurationStore,
  sqTreemapStore,
  sqTrendConditionStore,
  sqTrendScalarStore,
  sqTrendSeriesStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { priorityColors } from '@/services/systemConfiguration.utilities';
import { catchItemDataFailure } from '@/trendData/trend.actions';
import { CapsuleSelectionValue } from '@/reportEditor/report.constants';

/**
 * Sets the parent asset for swapping, the children underneath it will be displayed in the treemap.
 *
 * @param {Object} asset - The parent asset
 * @param {String} asset.id - The ID of the asset
 * @param {String} [option] - A push constant, such as PUSH_IGNORE
 *
 * @return {Promise<void>} - Resolved when the treemap is fetched
 */
export function setParent(asset: { id: string } | undefined, option?: PushOption): Promise<void> {
  flux.dispatch('TREEMAP_SET_PARENT', _.pick(asset, ['id']), option);

  return fetchTreemap();
}

interface SetStatisticPayload {
  /** The id of the signal to use for calculating the statistic */
  id?: string | number | CapsuleSelectionValue;
  /** One of the statistic keys from SAMPLE_FROM_SCALARS.VALUE_METHODS */
  key: string | null;
}

/**
 * Set a statistic to be displayed on each asset node.
 *
 * @param {Number} index - Which statistic is being set
 * @param {SetStatisticPayload} payload - Will include the id and the key props
 *
 * @return {Promise<void>} - Resolved when the treemap is fetched
 */
export function setStatistic(index: number, payload: SetStatisticPayload): Promise<void> {
  flux.dispatch('TREEMAP_SET_STATISTIC', { ...payload, index });

  return fetchTreemap();
}

/**
 * Updates the number of pixels of the chart so that the auto-update interval calculates correctly.
 *
 * @param {Number} width - The width, in pixels, of the chart
 */
export function setDisplayPixels(width: number): void {
  // A rough estimate of what the width on the trend chart is, just so that the auto-update calculation is roughly
  // the same between views
  const Y_AXIS_WIDTH = 40;
  flux.dispatch('AUTO_UPDATE_SET_DISPLAY_PIXELS', { displayPixels: width + Y_AXIS_WIDTH }, PUSH_IGNORE);
}

/**
 * Fetches the treemap based on the configuration settings.
 */
export function fetchTreemap(): Promise<void> {
  const options = {} as any;
  const conditions = sqTrendConditionStore.items;
  const signalValueMethods = _.filter(
    SAMPLE_FROM_SCALARS.VALUE_METHODS,
    _.flow(_.property('input'), _.partial(_.includes, _, 'sample')),
  );
  if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREEMAP) {
    return Promise.resolve();
  }

  if (_.isEmpty(sqTreemapStore.priorityColors)) {
    const colors = _.chain(priorityColors())
      .filter((priority: any) => priority.level >= 0)
      .map('color')
      .tap((colors: any[]) => {
        const neutralColor = _.last(colors);
        // R21 moved to white as the neutral color, but treemap should still use green unless otherwise specified
        colors[colors.length - 1] = _.toLower(neutralColor) === '#ffffff' ? '#068C45' : neutralColor;
      })
      .value();
    flux.dispatch('TREEMAP_SET_COLORS', { colors });
  }

  if (!conditions.length) {
    flux.dispatch('TREEMAP_SET_HELP_KEY', { helpKey: 'addCondition' });
    return Promise.resolve();
  }

  if (!allUseSameAsset(conditions)) {
    flux.dispatch('TREEMAP_SET_HELP_KEY', { helpKey: 'mustUseSameAsset' });
    return Promise.resolve();
  }

  if (!_.every(conditions, hasPriorityColor)) {
    flux.dispatch('TREEMAP_SET_HELP_KEY', { helpKey: 'selectPriorities' });
    return Promise.resolve();
  }

  flux.dispatch('TREEMAP_SET_SWAP', _.pick(conditions[0].assets[0], ['id', 'name']));

  if (_.isEmpty(sqTreemapStore.parent)) {
    return sqTreesApi
      .getTree({ id: sqTreemapStore.swap.id, offset: 0, limit: 100 })
      .then(({ data: { item } }) => setParent(_.last(item?.ancestors)!, PUSH_IGNORE));
  }

  if (_.isEmpty(sqTreemapStore.breadcrumbs)) {
    sqTreesApi.getTree({ id: sqTreemapStore.parent.id!, offset: 0, limit: 100 }).then(({ data: { item } }) =>
      flux.dispatch('TREEMAP_SET_BREADCRUMBS', {
        breadcrumbs: item?.ancestors?.concat([item]).map(({ id, name }) => ({ id, name })),
      }),
    );
  }

  options.start = sqDurationStore.displayRange.start.toISOString();
  options.end = sqDurationStore.displayRange.end.toISOString();
  options.swapId = sqTreemapStore.swap.id;
  options.parentId = sqTreemapStore.parent.id;
  options.conditionIds = _.chain(conditions)
    .sortBy((item) => _.indexOf(sqTreemapStore.priorityColors, item.color))
    .map('id')
    .value();
  options.parameters = {};
  options.formulas = _.map(sqTreemapStore.validStatistics, function (statistic: any, i) {
    const identifier = getShortIdentifier(i);
    // for now we do not support providing a unit from within the treemap UI so we filter out the parameter to ensure
    // the formulas using a $unit will still compile as expected (the Backend code provides seconds as the default
    // unit)
    const stat = _.find(signalValueMethods, ['key', statistic.key])!['stat'].replace('$unit', '');
    const capsule = getCapsuleFormula(sqDurationStore.displayRange);
    options.parameters[identifier] = statistic.id;

    return `$${identifier}.aggregate(${stat}, ${capsule})`;
  });

  options.parameters = encodeParameters(options.parameters);
  const cancellationGroup = 'treemap';

  _.forEach(options.conditionIds, (id) => flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }));
  cancelGroup(cancellationGroup);

  return sqTreesApi
    .treemap(options, { cancellationGroup })
    .then(({ data, headers }) => {
      const tree: TreemapItem[] = _.map(data.tree, (node: TreemapItemOutputV1) => {
        const color =
          node.priority === -1
            ? sqTreemapStore.neutralColor
            : _.find(conditions, ['id', options.conditionIds[node.priority as number]]).color;
        const displayScalars = _.chain(node.displayScalars)
          .map((scalar, i) =>
            _.assign(scalar, {
              statistic: sqTreemapStore.validStatistics[i],
            }),
          )
          .filter('statistic') // in case it has been removed since starting fetch
          .map((scalar) => {
            return _.assign(_.omit(scalar, ['statistic']), {
              title: _.find(signalValueMethods, ['key', scalar.statistic.key])!['title'],
              signal: findItemIn([sqTrendSeriesStore, sqTrendScalarStore], scalar.statistic.id).name,
            });
          })
          .value();

        return _.assign(node, { color, displayScalars, asset: _.pick(node.asset, ['id', 'name']) });
      });

      flux.dispatch('TREEMAP_SET_TREE', { tree });
      _.forEach(options.conditionIds, (id) => {
        flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', {
          id,
          warningCount: data.warningCount,
          warningLogs: data.warningLogs,
          timingInformation: headers['server-timing'],
          meterInformation: headers['server-meters'],
          isSharedRequest: true,
        });
      });
    })
    .catch((response) => {
      errorToast({ httpResponseOrError: response });
      _.forEach(options.conditionIds, (id) => catchItemDataFailure(id, cancellationGroup, response));
    });
}

/**
 * Determines if a condition is using a priority color.
 *
 * @param {Object} item - The item to check.
 *
 * @return {boolean} True if it is using a priority color, false otherwise
 */
export function hasPriorityColor(item: { color: string }): boolean {
  return _.includes(sqTreemapStore.priorityColors, item.color);
}

/**
 * Determines if all items are asset-based and from the same asset.
 *
 * @param {any[]} items - The array of items to check.
 *
 * @return {boolean} True if all items are asset-based and from the same asset, false otherwise
 */
export function allUseSameAsset(items: any[]): boolean {
  return (
    _.every(items, 'assets.length') && _.chain(items).flatMap('assets').compact().uniqBy('id').value().length === 1
  );
}
