import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import _ from 'lodash';
import tinycolor from 'tinycolor2';
import { ButtonWithPopover, Icon } from '@seeqdev/qomponents';
import { useStateWithRef } from '@/core/hooks/useStateWithRef.hook';
import { addRecentColor as addWorkbenchRecentColor } from '@/workbench/workbench.actions';
import { sqWorkbenchStore } from '@/core/core.stores';
import { HueSaturationPicker } from '@/core/HueSaturationPicker.molecule';
import { SwatchGroup } from '@/core/SwatchGroup.molecule';
import { PREVIEW_ID, STANDARD_COLORS, TREND_COLORS } from '@/trendData/trendData.constants';
import { doTrack } from '@/track/track.service';
import { setTrendItemColor } from '@/trendData/trend.actions';

interface ColorPickerIF {
  color?: string;
  itemId?: string;
  colors?: string[];
  heading?: string;
  notifyOnSelect?: (itemId: string | number, color: string) => void;
  limitToSwatches?: boolean;
  linkText?: string;
  linkFunction?: () => void;
  placement: TooltipPosition;
  dataTestId?: string;
  t?: (key: string) => string;
  isWorkbookLocked?: boolean;
}

export const ColorPicker: React.FunctionComponent<ColorPickerIF> = (props) => {
  const defaultTranslate = useTranslation().t;
  const {
    color,
    itemId,
    colors,
    heading,
    notifyOnSelect,
    limitToSwatches,
    linkText,
    linkFunction,
    placement,
    dataTestId = 'colorPickerButton',
    t = defaultTranslate,
    isWorkbookLocked,
  } = props;
  const [originalColor, setOriginalColor] = useStateWithRef(color);
  const [currentColor, setCurrentColor] = useStateWithRef(color);
  const [rgbObj, setRGBObj] = useState(tinycolor(color).toRgb());
  const [displayColor, setDisplayColor] = useState(tinycolor(color).toHexString());
  const [pickerColor, setPickerColor] = useState(tinycolor(color).toHexString());
  const [showPickerPopover, setShowPickerPopover] = useState(false);
  const [target, setTarget] = useState<EventTarget | null>(null);
  const swatchColors = colors ? colors : TREND_COLORS;
  const recentColors = _.reverse(_.clone(sqWorkbenchStore.recentColors as any));

  useEffect(() => {
    setCurrentColor(color);
    // exhaustive deps does not work well with setters from custom hooks
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const openColorPicker = () => {
    setOriginalColor(color);
    setCurrentColor(color);
    if (currentColor.current) {
      updateColor(currentColor.current, false, false, false);
    }
    setShowPickerPopover(true);
  };

  /**
   * Closes the color picker popup and adds color to recent list
   *
   * @param {boolean} addToRecent - whether or not to add the current color to the recent list on close
   */
  const closeColorPicker = (addToRecent = true) => {
    setShowPickerPopover(false);
    if (addToRecent && currentColor.current) {
      addRecentColor(currentColor.current);
    }
  };

  /**
   * Adds the most recent color to the workbenchStore recent colors array
   */
  const addRecentColor = (color: string) => {
    if (!tinycolor.equals(color, originalColor.current)) {
      addWorkbenchRecentColor(color);
    }
  };

  /**
   * Returns the HexCode for white or black based on the selected color. If the color is a dark color it returns white
   * and otherwise black (used by the cog overlay)
   *
   * @returns {String} hexcode for white or black
   */
  const getGearColor = () => {
    return tinycolor(currentColor.current).isLight() ? '#000' : '#fff';
  };

  /**
   * This function updates the item color and sets it to the provided color.
   * Based on which color input triggers the color selection we ensure that the origin does not get modified via this
   * function as there seem to be some "autocorrection" of color that results in undesired overwrites of user input
   * otherwise.
   *
   * @param color - the color to set for the item represented as a Hex String e.g. #445544
   * @param rgbChange - true if the color change is triggered by changing one of the RGB input fields
   * @param hexChange - true if the color change is triggered by changing the hex input field
   * @param pickerChange - true if the color change is triggered by selecting a color via the color picker
   */
  const updateColor = (
    color: string | { r: number; g: number; b: number },
    rgbChange: boolean,
    hexChange: boolean,
    pickerChange: boolean,
  ) => {
    const colorCandidate = tinycolor(color);
    if (colorCandidate.isValid()) {
      if (!rgbChange) {
        setRGBObj(colorCandidate.toRgb());
      }

      if (!hexChange) {
        setDisplayColor(colorCandidate.toHexString());
      }

      if (!pickerChange) {
        setPickerColor(colorCandidate.toHexString());
      }

      if (color !== currentColor.current) {
        setColor(colorCandidate.toHexString());
      }
    }
  };

  /**
   * This function is triggered when the color picker color changes.
   * Calls updateColor to ensure the color is set on the item.
   */
  const setHueSaturationPickerColor = (color: { hex: string }) => {
    doTrack('Trend', 'Signal color changed', 'Hue Saturation Picker');
    const newColor = tinycolor(color.hex).toHexString();
    setPickerColor(newColor);
    updateColor(newColor, false, false, true);
  };

  /**
   * This function is triggered when the RGB entry fields are changed.
   * Calls updateColor to ensure the color is set on the item.
   */
  const setRGBColor = (value: number, rgbComponent: 'r' | 'g' | 'b') => {
    doTrack('Trend', 'Signal color changed', `RGB (${rgbComponent})`);
    const rgbTemp = _.clone(rgbObj);
    rgbTemp[rgbComponent] = value;
    setRGBObj(rgbTemp);
    updateColor(rgbTemp, true, false, false);
  };

  /**
   * This function is triggered when the hex color field is changed.
   * Calls updateColor to ensure the color is set on the item.
   */
  const setHexColor = (color: string) => {
    doTrack('Trend', 'Signal color changed', 'input hex');
    setDisplayColor(color);
    updateColor(color, false, true, false);
  };

  /**
   * Sets the item's color to the specified value. If an $event is provided the popup is closed.
   * Note: Setting this to the preview by default is fine, even for a tool that has not created a preview item,
   * as that will be checked prior to setting it in the store.
   *
   * If a function is passed in the notify-on-select attribute then that function is called. If no function is
   * provided, then sqTrendActions.setItemColor is called.
   * Note: all functions passed in via notify-on-select need to have uniform parameters of an itemId and a color.
   *
   * @param color - Color hex code (e.g. #CCCCCC)
   * @param [event] - The click event
   */
  const setColor = (color: string, event?: React.MouseEvent<HTMLDivElement>) => {
    setCurrentColor(color);
    if (event) {
      closeColorPicker();
    }

    // PREVIEW_ID is used to set the color of items that have not yet been created
    const id = _.isUndefined(itemId) ? PREVIEW_ID : itemId;
    notifyOnSelect ? notifyOnSelect(id, color) : setTrendItemColor(id, color);
  };

  const callLinkFunction = () => {
    linkFunction?.();
    closeColorPicker(false);
  };

  return (
    <ButtonWithPopover
      trigger={
        <div
          className="colorPickerSwatch"
          onClick={openColorPicker}
          data-testid={dataTestId}
          data-itemid={itemId}
          style={{ backgroundColor: currentColor.current }}
          id="specColorPickerButton"
        />
      }
      hasArrow
      isPortal
      isOpen={showPickerPopover}
      onOpenChange={(isOpen) => setShowPickerPopover(isOpen)}
      onPointerDownOutside={() => setShowPickerPopover(false)}
      onInteractOutside={() => setShowPickerPopover(false)}
      isCloseOnContentClick={false}
      placement={placement}>
      {!isWorkbookLocked && (
        <div id="colorPickerPopover" data-testid="colorPickerPopover">
          <div id="colorPickerContainer" className="p10">
            {/*Swatches only*/}
            {limitToSwatches && (
              <SwatchGroup
                colors={colors ?? []}
                onSelectColor={setColor}
                heading={heading ?? ''}
                limitToSwatches={limitToSwatches}
                testId="swatches"
              />
            )}
            {/*Full color picker*/}
            {!limitToSwatches && (
              <div>
                <div
                  className="picker-close cursorPointer"
                  onClick={() => closeColorPicker()}
                  data-testid="colorPickerCloseButton">
                  <Icon icon="fa-close" />
                </div>
                {/*Picker, Hex and RGB Inputs*/}
                <div className="flexRowContainer">
                  <span className="mb3">{t('COLOR_PICKER.CURRENT_COLOR')}</span>
                  <div className="flexColumnContainer flexAlignCenter">
                    {/*Hue Saturation Picker*/}
                    <div className="flexRowContainer cursorPointer large-color-swatch">
                      <div className="colorswatch-overlay">
                        <Icon icon="fa-cog" large={true} type="color" color={getGearColor()} testId="gear" />
                      </div>
                      <HueSaturationPicker onChange={setHueSaturationPickerColor} color={pickerColor} />
                    </div>
                    {/*Hex and RGB Inputs*/}
                    <div className="flexRowContainer pl8">
                      <div className="flexColumnContainer flexAlignCenter">
                        Hex
                        <input
                          id="hexInput"
                          autoComplete="off"
                          type="text"
                          className="form-control input-xs mb5 ml5 mr8"
                          value={displayColor}
                          onChange={(e) => setHexColor(e.target.value)}
                          data-testid="hexInput"
                        />
                      </div>
                      <div className="flexColumnContainer flexAlignCenter">
                        R:
                        <input
                          autoComplete="off"
                          type="number"
                          className="form-control color-rgb-input input-xs ml5 mr8"
                          value={rgbObj.r}
                          onChange={(e) => setRGBColor(_.toNumber(e.target.value), 'r')}
                          data-testid="rInput"
                        />
                        G:
                        <input
                          autoComplete="off"
                          type="number"
                          className="form-control color-rgb-input input-xs ml5 mr8"
                          value={rgbObj.g}
                          onChange={(e) => setRGBColor(_.toNumber(e.target.value), 'g')}
                          data-testid="gInput"
                        />
                        B:
                        <input
                          autoComplete="off"
                          type="number"
                          className="form-control color-rgb-input input-xs ml5 mr8"
                          value={rgbObj.b}
                          onChange={(e) => setRGBColor(_.toNumber(e.target.value), 'b')}
                          data-testid="bInput"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/*Swatches*/}
                <SwatchGroup
                  colors={swatchColors}
                  onSelectColor={setColor}
                  heading="COLOR_PICKER.SEEQ_COLORS"
                  limitToSwatches={!!limitToSwatches}
                  testId="seeqColors"
                />
                <SwatchGroup
                  colors={STANDARD_COLORS}
                  onSelectColor={setColor}
                  heading="COLOR_PICKER.STANDARD_COLORS"
                  limitToSwatches={!!limitToSwatches}
                  testId="standardColors"
                />
                <SwatchGroup
                  colors={recentColors}
                  onSelectColor={setColor}
                  heading="COLOR_PICKER.RECENT_COLORS"
                  limitToSwatches={!!limitToSwatches}
                  testId="recentColors"
                />
                {linkText && (
                  <div className="pt5">
                    <span
                      className="cursorPointer text-underline"
                      onClick={callLinkFunction}
                      data-testid="linkFunction">
                      {t(linkText)}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </ButtonWithPopover>
  );
};
