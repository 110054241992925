import { flux } from '@/core/flux.module';
import { sqWorkbenchStore } from '@/core/core.stores';
import _ from 'lodash';
import { sqSystemApi } from '@/sdk/api/SystemApi';
import { AddOnToolOutputV1 } from '@/sdk/model/AddOnToolOutputV1';

export function loadHomeScreenAddOnTools() {
  return sqSystemApi.getAddOnTools().then(({ data: { addOnTools } }) => {
    const myUserId = sqWorkbenchStore.currentUser.id;
    // We don't want admins to see all addOns on the system, just relevant ones
    const filteredAddOnTools = _.filter(addOnTools, (addOnTool) => {
      return !addOnTool.inDevelopment || addOnTool?.installer?.id === myUserId;
    });

    setHomeScreenAddOnTools(filteredAddOnTools);
  });
}

export function setHomeScreenAddOnTools(addOnTools: AddOnToolOutputV1[] = []) {
  flux.dispatch('ADD_ON_TOOLS_SET', { addOnTools });
}
