import { LoadingFallback } from '@/main/LoadingFallback.atom';
import React, { Suspense } from 'react';
import { FormulaEditorProps } from '@/formula/formula.types';

const FormulaEditor = React.lazy(() => import('@/defaultExportShims/FormulaEditor'));

export const FormulaEditorAsync: React.FunctionComponent<FormulaEditorProps> = (props) => {
  return (
    <Suspense fallback={<LoadingFallback size="sm" />}>
      <FormulaEditor {...props}></FormulaEditor>
    </Suspense>
  );
};
