import { getFolderName, getFolderRoot } from '@/utilities/homescreen.utilities';
import { HOME_SCREEN_TABS } from '@/main/app.constants';
import i18next from 'i18next';
import { sqFoldersApi } from '@/sdk/api/FoldersApi';
import { sqHomeScreenStore } from '@/core/core.stores';
import { flux } from '@/core/flux.module';
import { isAdmin } from '@/services/authorization.service';
import _ from 'lodash';

export function getTabFolder(folderId: string | undefined) {
  if (!_.isUndefined(folderId) && (folderId !== HOME_SCREEN_TABS.USERS || isAdmin())) {
    if (sqHomeScreenStore.tabFolders[folderId]) {
      return Promise.resolve(sqHomeScreenStore.tabFolders[folderId]) as any;
    } else {
      return sqFoldersApi
        .getFolder({ folderId: getFolderRoot(folderId) as string })
        .then((response: any) => {
          // response.data returns the string "null" when the folder doesn't exist
          if (response.data !== 'null') {
            const name = getFolderName(folderId) as string;
            const folder = _.assign({}, response.data, {
              name: `${i18next.t(name)}`,
            });
            flux.dispatch('SET_TAB_FOLDER', { key: folderId, folder });
            return folder;
          }
        })
        .finally(() => sqHomeScreenStore.tabFolders[folderId]);
    }
  } else {
    return Promise.resolve(undefined);
  }
}
