// @ts-strict-ignore
import React from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FormControl } from 'react-bootstrap';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FORM_ERROR, ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormError } from '@/core/FormError.atom';
import { ErrorMessageFormComponent } from '@/formbuilder/ErrorMessageFormComponent.atom';
import { validPassword } from '@/utilities/password.utilities';

export interface PasswordCheckIF extends Omit<ValidatingFormComponent<string>, 'label'> {
  component: 'PasswordCheckFormComponent';
  size: string;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  max?: number;
  min?: number;
  as?: string;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  disabled?: boolean;
  fixedWidth?: boolean;
  skipMemo?: boolean;
  onIconClick?: () => any;
  key?: string;
}

export const PasswordCheckFormComponent: React.FunctionComponent<PasswordCheckIF> = (props) => {
  const {
    name,
    extendValidation,
    validation,
    placeholder,
    onChange,
    onBlur,
    customErrorText,
    customErrorParams = {},
    type = 'text',
    fixedWidth = true,
    extraClassNames,
    tooltip,
    onIconClick,
  } = props;

  const { t } = useTranslation();
  const formState = useForm().getState();
  const formValues = formState.values;
  const valueMatch = formValues.newPassword === formValues.confirmPassword;
  const defaultValidation = (value: string) => !validPassword(value);

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <Field name={name} validate={appliedValidation} key={name}>
      {({ input, meta }) => {
        const properProps = _.assign({}, getFormFieldProps(formState, input, meta, props), {
          placeholder: t(placeholder),
          value: input.value,
          onChange: (e) => {
            input.onChange(e.target.value);
            onChange(e.target.value);
          },
          onBlur: (e) => _.isFunction(onBlur) && onBlur(e.target.value),
          onFocus: (e) => e.target.select(),
        });

        return (
          <div
            className={classNames('flexRowContainer', extraClassNames, {
              'width-100percent': fixedWidth,
            })}>
            <div className="flexColumnContainer flexAlignCenter">
              <PasswordCheckMemo
                {..._.pick(properProps, [
                  'value',
                  'onChange',
                  'placeholder',
                  'onBlur',
                  'onFocus',
                  'data-testid',
                  'className',
                  'size',
                ])}
                type={type}
              />
              {tooltip && (
                <Icon
                  icon="fa-question-circle"
                  large={true}
                  tooltip={t(tooltip)}
                  extraClassNames="pl5"
                  onClick={onIconClick}
                />
              )}
            </div>
            {properProps.showError && customErrorText && (
              <FormError errorText={customErrorText} errorParameters={customErrorParams} />
            )}
            {!valueMatch && (
              <ErrorMessageFormComponent
                component="ErrorMessageFormComponent"
                failForm={true}
                type={FORM_ERROR}
                name="mismatchedPasswords"
                value="USER.PASSWORD_MISMATCH"
              />
            )}
          </div>
        );
      }}
    </Field>
  );
};

export const PasswordCheckMemo = React.memo(
  FormControl,
  (prev, next) =>
    !(
      !_.isEqual(prev.value, next.value) ||
      prev.disabled !== next.disabled ||
      prev.placeholder !== next.placeholder ||
      prev.className !== next.className ||
      prev.skipMemo
    ),
);
