import _ from 'lodash';
import { AnyProperty } from '@/utilities.types';
import { getStorageSafely } from '@/utilities/storage.utilities';
import { flux } from '@/core/flux.module';
import { warnToast } from '@/utilities/toast.utilities';

export let reduxDevtoolsConnection: ReturnType<ReduxDevtoolsExtension['connect']> | undefined;

export let isDebugStoresEnabled: boolean | undefined;

let unsupportedActionToast: number | string | null = null;

export const stateSanitizer = (stores: AnyProperty) =>
  _.reduce(stores, (allStoresState, store, storeName) => ({ ...allStoresState, [storeName]: store.state }), {});

export const initializeReduxDevtools = () => {
  const debugStoresSearchParam = _.lowerCase(new URLSearchParams(window.location.search).get('debug-stores') ?? '');

  if (debugStoresSearchParam && debugStoresSearchParam !== 'true') {
    return getStorageSafely().removeItem('debug-stores');
  }

  const debugStoresLocalStorage = Boolean(getStorageSafely().getItem('debug-stores'));

  if (debugStoresSearchParam === 'true' || debugStoresLocalStorage) {
    reduxDevtoolsConnection = window.__REDUX_DEVTOOLS_EXTENSION__?.connect({
      name: 'All stores',
      stateSanitizer,
    });
    reduxDevtoolsConnection?.init({});

    reduxDevtoolsConnection?.subscribe((data) => {
      if (data?.type !== 'DISPATCH' || data.payload?.type === 'PAUSE_RECORDING') {
        return;
      }

      switch (data.payload?.type) {
        case 'COMMIT':
          reduxDevtoolsConnection?.init(stateSanitizer(flux.dispatcher.storeInstances));
          break;

        // No break to allow the toast message to display
        case 'RESET':
          reduxDevtoolsConnection?.init({});

        // eslint-disable-next-line no-fallthrough
        default:
          if (!unsupportedActionToast) {
            unsupportedActionToast = warnToast(
              { messageKey: 'Action not fully supported' },
              { onClose: () => (unsupportedActionToast = null) },
            );
          }
      }
    });

    isDebugStoresEnabled = true;
    if (!debugStoresLocalStorage) {
      getStorageSafely().setItem('debug-stores', 'true');
    }
  }
};
