import { SeeqNames } from '@/main/app.constants.seeqnames';

/**
 * Checks if search types are invalid.
 * An invalid result is defined as combining {@link SeeqNames.Types.TableDefinition} with other search types.
 */
export const hasInValidSearchTypes = (types: string[]) =>
  types.length > 1 && types.includes(SeeqNames.Types.TableDefinition);

/*
 * Limit of items shown in the overview mode of the Search Widget
 */
export const LIMIT_IN_OVERVIEW = 8;
