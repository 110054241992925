import { REPORT_CONTENT, ReportContentSummary } from '@/reportEditor/report.constants';
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';
import { FrontendDuration } from '@/services/systemConfiguration.types';

/**
 * Parses the given summary out into it's backend representation
 *
 * @param summary - The summary being parsed
 * @return - an object containing the type and value of the given summary
 */
export const parseSummaryToTypeAndValue = (
  summary?: ReportContentSummary,
): {
  summaryType: SummaryTypeEnum | undefined;
  summaryValue: string | undefined;
} => {
  const undefinedSummary = {
    summaryType: undefined,
    summaryValue: undefined,
  };
  if (summary === undefined) {
    return undefinedSummary;
  } else if (summary.key === REPORT_CONTENT.SUMMARY.NONE.key) {
    return { summaryType: SummaryTypeEnum.NONE, summaryValue: '0' };
  } else if (summary.key === REPORT_CONTENT.SUMMARY.DISCRETE.key) {
    const discreteSummary = summary.value as FrontendDuration;
    return {
      summaryType: SummaryTypeEnum.DISCRETE,
      summaryValue: `${discreteSummary.value}${discreteSummary.units}`,
    };
  } else if (summary.key === REPORT_CONTENT.SUMMARY.AUTO.key) {
    return {
      summaryType: SummaryTypeEnum.AUTO,
      summaryValue: summary.value as any,
    };
  } else {
    return undefinedSummary;
  }
};
