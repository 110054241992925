import { sqUsersApi } from '@/sdk/api/UsersApi';
import _ from 'lodash';
import { useState } from 'react';
import { UpdateDomainFn, useLogin } from '@/main/auth/hooks/useLogin.hook';
import { DatasourceOutputV1, sqAuthApi } from '@/sdk';

interface FormData {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  passwordCheck: string;
  [key: string]: string | undefined;
}

export const useAuth = ({
  seeqDomain,
  domain,
  updateDomain,
}: {
  seeqDomain: DatasourceOutputV1;
  domain: DatasourceOutputV1;
  updateDomain: UpdateDomainFn;
}) => {
  const { doLogin } = useLogin({ domain, updateDomain });

  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    passwordCheck: '',
  });
  const [serverMessage, setServerMessage] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');

  const { firstName, lastName, username, password } = formData;

  const updateFormData = (data: Partial<typeof formData>) =>
    setFormData((formData) => ({
      ...formData,
      ...data,
    }));

  /**
   * Submits a request to generate a new user and executes a consecutive login.
   *
   * @returns {Promise} that resolves after the user was created and logged in
   */
  const register = () => {
    setServerMessage('');

    return sqUsersApi
      .createUser({
        firstName,
        lastName,
        username,
        email: username,
        password,
      })
      .then(() => doLogin({ username, password }, seeqDomain.datasourceClass!, seeqDomain.datasourceId!))
      .catch((e) => {
        const statusMessage = _.get(e, 'response.data.statusMessage');
        if (statusMessage) {
          setServerMessage(`(${statusMessage})`);
        }
      }) as Promise<any>;
  };

  const requestPasswordReset = () => {
    setServerMessage('');

    return sqAuthApi
      .requestPasswordReset({
        email: username,
      })
      .catch((e) => {
        const statusMessage = e?.response?.data?.statusMessage;
        if (statusMessage) {
          setForgotPasswordError(statusMessage);
        }
      }) as Promise<any>;
  };

  return { updateFormData, formData, register, serverMessage, requestPasswordReset, forgotPasswordError };
};
