import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { KEY_CODES } from '@/main/app.constants';
import { useElementHasMoved } from '@/core/hooks/useElementHasMoved.hook';
import { ButtonWithPopover as QButtonWithPopover } from '@seeqdev/qomponents';

export type PopoverConfig = {
  id: string;
  placement: TooltipPosition;
  trigger?: OverlayTriggerType;
  wrapperClassNames?: string;
  contentClassNames?: string;
};

interface ButtonWithPopoverProps {
  hasArrow?: boolean;
  /** used for the button that triggers the dropdown/popover */
  label: React.ReactNode;
  /** content of dropdown/popover */
  children: React.ReactNode;
  /** config props for popover */
  popoverConfig: PopoverConfig;
  /** true if the button should be disabled */
  disabled?: boolean;
  /** true if tooltip should show even if button is disabled */
  alwaysShowTooltip?: boolean;
  /** extra class names to be placed on the label wrapper */
  extraClassNames?: string;
  /** function to call when dropdown/popover is toggled */
  onToggle?: (nextShow?: boolean) => void;
  tabIndex?: number;
  /** true to call e.stopPropagation() when clicking on the trigger */
  stopPropagation?: boolean;
  /** false to prevent the dropdown from closing if the button is moved (e.g. scrolled into new location) */
  closeWhenMoved?: boolean;
  /** true to close the popup when the content is clicked */
  isCloseOnContentClick?: boolean;
  dropdownItems?: {
    icon?: string;
    iconExtraClassNames?: string;
    labelExtraClassNames?: string;
    containerExtraClassNames?: string;
    labelTestId?: string;
    iconTestId?: string;
    onClick: (e: Event) => void;
    label: string;
  }[];
  shouldTriggerFullWidth?: boolean;
  align?: 'start' | 'center' | 'end';
}

/**
 * A button with a dropdown. Content for the dropdown menu should be provided as children to the component.
 * This component combines the best of `ButtonWithPopoverDropdown` and `ButtonWithDropdown` which have both been removed
 */
export const ButtonWithPopover: React.FunctionComponent<ButtonWithPopoverProps> = ({
  label,
  children,
  popoverConfig,
  disabled,
  alwaysShowTooltip,
  extraClassNames,
  hasArrow,
  shouldTriggerFullWidth,
  onToggle,
  isCloseOnContentClick = false,
  closeWhenMoved = true,
  stopPropagation = false,
  align = 'center',
}) => {
  const [show, setShow] = useState<boolean>();
  const ref = useRef(null);

  useEffect(() => {
    if (disabled) {
      setShow(false);
    }
  }, [disabled]);

  const onClickPopover = (e: React.MouseEvent) => {
    if (isCloseOnContentClick) {
      setShow(false);
    }
    if (stopPropagation) {
      e.stopPropagation();
    }
  };

  useElementHasMoved(
    ref,
    () => {
      setShow(false);
    },
    closeWhenMoved && !!show,
  );

  return (
    <QButtonWithPopover
      disabled={disabled}
      trigger={
        <div
          ref={ref}
          onKeyDown={(e) => {
            if (e.keyCode === KEY_CODES.UP_ARROW || e.keyCode === KEY_CODES.DOWN_ARROW) {
              e.currentTarget.click();
            }
          }}
          onClick={(e) => {
            setShow(!show);
            stopPropagation && e.stopPropagation();
          }}
          className={classNames('cursorPointer', extraClassNames, {
            disabled,
            disabledBehavior: disabled && !alwaysShowTooltip,
          })}>
          {label}
        </div>
      }
      hasArrow={hasArrow}
      isHoverEnabled={popoverConfig.trigger === 'hover'}
      placement={popoverConfig.placement}
      align={align}
      isOpen={show}
      shouldTriggerFullWidth={shouldTriggerFullWidth}
      onOpenChange={(show) => {
        onToggle?.(show);
        setShow(show);
      }}>
      <div
        id={popoverConfig.id}
        className={classNames(popoverConfig.wrapperClassNames, 'noArrow pt5 pb5')}
        onClick={onClickPopover}>
        <div
          className={classNames('forceZeroPadding', popoverConfig.contentClassNames)}
          data-testid={`${popoverConfig.id}Content`}>
          {children}
        </div>
      </div>
    </QButtonWithPopover>
  );
};
