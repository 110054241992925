import { PluginOutputV1 } from '@/sdk/model/PluginOutputV1';
import { sqPluginsApi } from '@/sdk/api/PluginsApi';
import { flux } from '@/core/flux.module';
import { PluginState } from '@/plugin/pluginApiDefinition';
import { PluginCapsuleRegion, PluginIdentifier } from '@/plugin/pluginHost.constants';
import { setPluginTools } from '@/toolSelection/investigate.actions';
import { sqWorkbenchStore } from '@/core/core.stores';
import _ from 'lodash';
import { PUSH_IGNORE } from '@/core/flux.service';

export function load() {
  return sqPluginsApi.getPlugins({ offset: 0, limit: 5000 }).then(({ data: { plugins } }) => {
    const myUserId = sqWorkbenchStore.currentUser.id;
    // We don't want admins to see all plugins on the system, just relevant ones
    const filteredPlugins = _.filter(plugins, (plugin) => !plugin.inDevelopment || plugin?.installer?.id === myUserId);
    setPlugins(filteredPlugins);
    setPluginTools(filteredPlugins);
  });
}

export function setPlugins(plugins: PluginOutputV1[] = []) {
  flux.dispatch('PLUGINS_SET', { plugins });
}

export function setPluginState(pluginIdentifier: PluginIdentifier, pluginState: PluginState) {
  flux.dispatch('PLUGINS_SET_STATE', { pluginIdentifier, pluginState });
}

export function setDisplayPaneRenderComplete(complete: boolean) {
  flux.dispatch('PLUGINS_SET_DISPLAY_PANE_RENDER_COMPLETE', { complete });
}

export function setQueryParam(queryParam: string) {
  flux.dispatch('PLUGINS_SET_QUERY_PARAM', { queryParam });
}

export function updateContentPluginData(contentId: string, data?: any) {
  flux.dispatch('PLUGINS_UPDATE_CONTENT_PLUGIN_DATA', { contentId, data }, PUSH_IGNORE);
}

export function setContentPluginRenderComplete(contentId: string, complete: boolean) {
  flux.dispatch('PLUGINS_SET_CONTENT_PLUGIN_RENDER_COMPLETE', { contentId, complete }, PUSH_IGNORE);
}

export function setSelectedRegionOption(selectedOption: string, selectedRegion: PluginCapsuleRegion) {
  flux.dispatch('PLUGINS_SET_SELECTED_REGION_TARGET', { selectedOption, selectedRegion });
}

export function setPluginCapsuleRegion(capsuleRegion: PluginCapsuleRegion) {
  flux.dispatch('PLUGINS_SET_CAPSULE_REGION', { capsuleRegion });
}
