// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Modal } from '@seeqdev/qomponents';
import { getNextFormulaIdentifier } from '@/formula/formula.utilities';
import { FormulaEditorParam } from '@/formula/FormulaParametersTable.molecule';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { ToolPanelFormBuilder } from '@/formbuilder/ToolPanelFormBuilder.page';

interface AddFormulaParameterModalProps {
  onClose: () => void;
  testId: string;
  parameter?: FormulaEditorParam;
  parameters: FormulaEditorParam[];
  onSave: (item: FormulaEditorParam, original: FormulaEditorParam) => void;
}

export const AddFormulaParameterModal: React.FunctionComponent<AddFormulaParameterModalProps> = ({
  testId,
  onClose,
  onSave,
  parameters,
  parameter,
}) => {
  const { t } = useTranslation();
  const SEARCH_TYPES = ['Asset', 'Condition', 'Signal', 'CalculatedScalar'];
  const emptyItem = { name: '', id: '' };
  const [parameterIdentifier, setParameterIdentifier] = useState(parameter?.identifier ?? '');
  const [item, setItem] = useState(parameter?.item ?? emptyItem);

  useEffect(() => {
    if (!parameterIdentifier) {
      setParameterIdentifier(getNextFormulaIdentifier(_.map(parameters, 'identifier')));
    }
  }, []);

  const onSaveCallback = () => {
    if (item.id) {
      onSave(
        {
          ...parameter,
          name: parameterIdentifier,
          item,
          identifier: parameterIdentifier,
        },
        { ...parameter, name: parameter?.name ?? parameter?.identifier },
      );
      onClose();
    }
  };

  const formDataSetup: FormElement[] = [
    {
      component: 'FormControlFormComponent',
      label: 'FORMULA.VARIABLE_IDENTIFIER',
      name: 'parameterIdentifier',
      id: 'parameterIdentifier',
      value: parameterIdentifier,
      onChange: (value: string) => setParameterIdentifier(value),
      size: 'lg',
      extraClassNames: 'mb15',
    },
    {
      component: 'SearchWidgetFormComponent',
      name: 'parameterIdentifierItem',
      searchTypes: SEARCH_TYPES,
      pane: 'modal',
      allowAssetReplacement: false,
      onSearchItemClick: setItem,
      onCloseClick: () => setItem(emptyItem),
      customErrorText: 'FORMULA.NO_ITEM',
      value: item.name,
      onChange: _.noop,
      insideModal: true,
      searchResultsClassNames: 'max-height-425',
    },
  ];

  return (
    <Modal
      size="sm"
      open={true}
      onClose={onClose}
      testId={testId}
      title={t('FORMULA.VARIABLE_DEFINE')}
      hideFooterButtons={true}>
      <div className="flexRowContainer mt5">
        <ToolPanelFormBuilder
          formDefinition={formDataSetup}
          submitFn={onSaveCallback}
          closeFn={onClose}
          toolId="add-variable"
          submitBtnId="addVariableButton"
          wrapInPanel={false}
          submitBtnLabel="ADD"
        />
      </div>
    </Modal>
  );
};
