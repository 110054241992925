import { sqAxiosStore } from '@/core/core.stores';

export const addRequest = () =>
  sqAxiosStore.setPendingRequests({
    nRequests: sqAxiosStore.pendingRequests + 1,
  });

export const removeRequest = () =>
  sqAxiosStore.setPendingRequests({
    nRequests: sqAxiosStore.pendingRequests - 1,
  });
