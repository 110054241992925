import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { DurationSelector } from '@/core/DurationSelector.atom';
import { DurationTimeUnit } from '@/main/app.constants';

export type DurationTimeUnitValue = { unit: DurationTimeUnit; value: number };

export interface DurationFormComponentProps extends ValidatingFormComponent<any> {
  component: 'DurationFormComponent';
  customErrorText?: string;
  onChange: (value: any) => void;
  value: DurationTimeUnitValue;
}

export const DurationFormComponent: React.FunctionComponent<DurationFormComponentProps> = (props) => {
  const {
    name,
    value,
    testId = 'duration',
    validation,
    extendValidation,
    customErrorText,
    required = false,
    onChange,
  } = props;

  const { t } = useTranslation();

  const formState = useForm().getState();
  const showError = _.has(formState.errors, name) && formState.submitFailed && formState.hasValidationErrors;

  const defaultValidation = (value: any) => required && !value;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <FormFieldWrapper
      wrapperClassNames="flexFill mb20"
      testId={testId}
      showError={showError}
      errorClassNames="ml20"
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => (
          <DurationSelector
            unit={value.unit}
            value={value.value}
            onChange={(value, unit) => onChange({ value, unit })}
            onInvalidInput={_.noop}
            durationValuesByUnit={{ 'DURATION_UNITS.DAYS': [1, 2, 3, 4, 5, 6, 7, 14, 21, 28] }}
          />
        )}
      </Field>
    </FormFieldWrapper>
  );
};
