import { useCallback, useEffect, useState } from 'react';

// Exposed for testing
export const editorIdHandlers: Record<string, (() => void)[]> = {};

/**
 * Signals to all components listening to the editor with the given id that they should resize themselves
 *
 * @param editorId - the id of the editor who's content to signal
 */
export function triggerEditorContentResize(editorId: string): void {
  if (editorIdHandlers[editorId]) {
    editorIdHandlers[editorId].forEach((handler) => handler());
  }
}

/**
 * This hooks allows a component inside an editor that is removed from the standard dom to listen to resize events
 * published by it's parent editor and force a rerender to adjust to the new size. This should primarily be used by
 * Ag-Grid components inside CKEditor.
 *
 * Implementation details: Each component needing to resize will use this hook, which will register a set state
 * callback in the above `editorIdHandlers` object. When the parent editor resizes, it will call the
 * `triggerEditorContentResize` function to signal a resize event, which will call all the registered set state
 * functions. This will cause this hook to return an incremented number, allowing the component using this hook to
 * either immediately force a rerender or pass that state value downstream and use it later.
 *
 * @param editorId - the id of the editor who's content to signal
 */
export function useEditorResize(editorId: string): number {
  const [nextNumber, setNextNumber] = useState(0);
  const forceUpdate = useCallback(() => setNextNumber((num) => num + 1), []);

  useEffect(() => {
    if (!editorIdHandlers[editorId]) {
      editorIdHandlers[editorId] = [];
    }
    editorIdHandlers[editorId].push(forceUpdate);
    return () => {
      editorIdHandlers[editorId] = editorIdHandlers[editorId].filter((handler) => handler !== forceUpdate);
      if (editorIdHandlers[editorId].length === 0) {
        delete editorIdHandlers[editorId];
      }
    };
  });

  return nextNumber;
}
