import { ColumnDefinitionInputV1, ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { ColumnDefinitionOutputV1 } from '@/sdk/model/ColumnDefinitionOutputV1';
import { ColumnRuleInputV1 } from '@/sdk/model/ColumnRuleInputV1';
import { TableDefinitionInputV1 } from '@/sdk/model/TableDefinitionInputV1';
import { TableDefinitionOutputV1 } from '@/sdk/model/TableDefinitionOutputV1';
import { RecomputeColumnTypeEnum1, RecomputeColumnTypeEnum2 } from '@/sdk/api/TableDefinitionsApi';
import {
  ColumnRuleFormulaCreatorInputV1,
  DatasourceOutputV1,
  GraphQLInputV1,
  GraphQLOutputV1,
  ItemSearchPreviewV1,
  sqDatasourcesApi,
  sqGraphQLApi,
  sqItemsApi,
  sqTableDefinitionsApi,
  sqTableOverridesApi,
  TableComputeOutputV1,
} from '@/sdk';
import { addTableDefinition } from '@/workbook/workbook.actions';
import {
  AgGridScalingColumnDefinition,
  BooleanTableCell,
  FormulaCompileResult,
  ItemTableCell,
  MaterializedTableHeader,
  MaterializedTableOutput,
  MaterializedTablePropertyColumnInput,
  ProcessedMaterializedTable,
  ProcessedTableCell,
  PROPERTY_COLUMN_MATCH_SEGMENT,
  ScalingTableColumnDefinition,
  TableDefinitionAccessSettings,
  UOM_COLUMN_MATCH_SUFFIX,
} from '@/tableDefinitionEditor/tableDefinition.types';
import { runFormula as compileFormulaOrThrowError } from '@/formula/formula.utilities';
import { t } from 'i18next';
import {
  ColumnRule,
  ColumnRuleInput,
  ColumnTypeOptions,
  CombinedColumnRuleInputParameters,
} from '@/tableDefinitionEditor/columnRules/columnRule.constants';
import {
  columnRuleOutputToColumnRuleInput,
  getRuleTypeFromRuleInput,
} from '@/tableDefinitionEditor/columnRules/columnRule.utilities';
import { errorToast } from '@/utilities/toast.utilities';
import { setDisplayTableDefinitionEditor } from '@/worksheet/worksheet.actions';
import { resetTableDefinition, setTableDefinition } from '@/tableDefinitionEditor/tableDefinition.actions';
import _ from 'lodash';
import { toNumber } from '@/utilities/numberHelper.utilities';
import {
  ColumnRulesWithMetaData,
  RULES_WITH_PERMISSIONS,
} from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.constants';
import { ColumnRuleWithMetadata } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.types';
import { fullyArchiveDatasource } from '@/utilities/datasources.utilities';
import { sqTableDefinitionStore, sqWorkbenchStore } from '@/core/core.stores';
import { FormulaErrorInterface } from '@/formula/formula.types';
import { MAX_TABLE_ROWS } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { HIDDEN_COLUMNS } from '@/tableDefinitionEditor/tableDefinition.constants';
import { NumericWithUomEditor, TextEditor, UUIDEditor } from '@/tableDefinitionEditor/TableDefinitionCellEditor.atom';
import { TableDefinitionCellRenderer } from '@/tableDefinitionEditor/TableDefinitionCellRenderer.atom';
import { ColDef, Column, ITooltipParams } from '@ag-grid-community/core';
import { setDatasources } from '@/search/search.actions';

export const itemIdColumnHasItemSearchRule = (columns: ScalingTableColumnDefinition[]): boolean =>
  columns
    .find((column) => column.columnName === SeeqNames.MaterializedTables.ItemIdColumn)
    ?.rules.some((rule) => rule.rule === SeeqNames.MaterializedTables.Rules.ItemSearch) ?? false;

export const getColumnsHiddenFromUser = (columns: ScalingTableColumnDefinition[]): string[] => {
  if (sqTableDefinitionStore.subscriberId) {
    return [SeeqNames.MaterializedTables.DatumIdColumn];
  }

  if (itemIdColumnHasItemSearchRule(columns)) {
    return [];
  }

  return [SeeqNames.MaterializedTables.ItemIdColumn];
};

export const getRuleTypeAndParameters = (
  columnRuleInput: ColumnRuleInput,
): {
  ruleType: ColumnRule;
  parameters: Partial<CombinedColumnRuleInputParameters>;
} => {
  const ruleType = getRuleTypeFromRuleInput(columnRuleInput);
  const parameters = columnRuleInput[ruleType]!;
  return { ruleType, parameters };
};

export const getColumnTypeFromText = (text: string): ColumnTypeEnum | undefined => {
  const columnType = ColumnTypeOptions.find((option) => t(option.label) === text);
  return columnType?.value;
};

export const getColumnRuleWithMetaDataFromLabel = (label: string): ColumnRuleWithMetadata => {
  const columnRule = ColumnRulesWithMetaData.find((rule) => rule.label === label);
  if (!columnRule) {
    throw new Error(`Column rule ${label} not found`);
  }
  return columnRule;
};

export const getColumnRuleWithMetaDataFromRule = (rule: string): ColumnRuleWithMetadata => {
  // In the case of Constant rules, the rule comes back like "stringConstant" whereas the ruleWithLabel is just
  // "constant", causing the exception to be thrown. Instead, we match on whether the rule contains "constant" as a
  // substring
  const columnRule = ColumnRulesWithMetaData.find(
    (ruleWithLabel) =>
      ruleWithLabel.rule === rule ||
      (ruleWithLabel.rule === 'constant' && rule.toLowerCase().includes(ruleWithLabel.rule.toLowerCase())),
  );
  if (!columnRule) {
    throw new Error(`Column rule ${rule} not found`);
  }
  return columnRule;
};

export const buildColumnDefsForAgGrid = (
  currentColumns: ScalingTableColumnDefinition[],
  tableDefinitionId: string,
): AgGridScalingColumnDefinition[] => {
  const columnsHiddenFromUser = getColumnsHiddenFromUser(currentColumns);
  return currentColumns
    .filter((column) => !columnsHiddenFromUser.includes(column.columnName))
    .map((tableDefinitionColumnDef, index) => {
      const sortIndex = tableDefinitionColumnDef.sortIndex ?? null;
      const potentialSort = tableDefinitionColumnDef.sortAscending ? 'asc' : 'desc';
      const sort = sortIndex !== null ? potentialSort : null;
      const hide = tableDefinitionColumnDef.isHidden;
      const columnType = tableDefinitionColumnDef.columnType;
      const agGridCellDataType = {
        [ColumnTypeEnum.BOOLEAN]: 'boolean',
        [ColumnTypeEnum.NUMERIC]: 'numericWithUom',
        [ColumnTypeEnum.TEXT]: 'text',
        [ColumnTypeEnum.UUID]: 'uuid',
        [ColumnTypeEnum.TIMESTAMPTZ]: 'text', // We may want to change this to date, it will be easier to see after
        // we fix the timestamp constant rule https://seeq.atlassian.net/browse/CRAB-42009
      }[columnType];
      const agGridCellEditor = {
        [ColumnTypeEnum.BOOLEAN]: TextEditor,
        [ColumnTypeEnum.NUMERIC]: NumericWithUomEditor,
        [ColumnTypeEnum.TEXT]: TextEditor,
        [ColumnTypeEnum.UUID]: UUIDEditor,
        [ColumnTypeEnum.TIMESTAMPTZ]: TextEditor, // We may want to change this to date, it will be easier to see after
        // we fix the timestamp constant rule https://seeq.atlassian.net/browse/CRAB-42009
      }[columnType];
      return {
        field: tableDefinitionColumnDef.id,
        headerName: tableDefinitionColumnDef.columnName,
        headerValueGetter: () => {
          return tableDefinitionColumnDef.displayName;
        },
        cellDataType: agGridCellDataType,
        initialHide: hide,
        suppressColumnsToolPanel: getColumnsHiddenFromUser(currentColumns).includes(
          tableDefinitionColumnDef.columnName,
        ),
        propertyToDisplay: columnType === ColumnTypeEnum.UUID && tableDefinitionColumnDef.propertyToDisplay,
        editable:
          tableDefinitionColumnDef?.columnName !== SeeqNames.MaterializedTables.DatumIdColumn &&
          tableDefinitionColumnDef?.columnName !== SeeqNames.MaterializedTables.ItemIdColumn,
        cellEditor: agGridCellEditor,
        cellEditorPopup: columnType === ColumnTypeEnum.NUMERIC && !tableDefinitionColumnDef.columnUom,
        cellEditorParams: columnType === ColumnTypeEnum.NUMERIC && { columnUom: tableDefinitionColumnDef.columnUom },
        cellRenderer: TableDefinitionCellRenderer,
        cellRendererParams: {
          tableDefinitionId,
          // This makes it easier to handle hidden columns in system tests
          columnIndex: index,
        },
        ...(columnType === ColumnTypeEnum.NUMERIC && { comparator: (valueA, valueB) => valueA.value - valueB.value }),
        ...(tableDefinitionColumnDef?.columnName !== SeeqNames.MaterializedTables.DatumIdColumn &&
          tableDefinitionColumnDef?.columnName !== SeeqNames.MaterializedTables.ItemIdColumn && {
            tooltipValueGetter: (p: ITooltipParams) => {
              if (p.value?.override) {
                return t('SCALING.OVERRIDE.EDIT_OVERRIDE_TOOLTIP');
              } else {
                return t('SCALING.OVERRIDE.ADD_OVERRIDE_TOOLTIP');
              }
            },
          }),
        initialSortIndex: sortIndex,
        initialSort: sort,
      };
    }) as AgGridScalingColumnDefinition[];
};

export const buildRowDataForAgGrid = (
  agGridColumnDefinitions: AgGridScalingColumnDefinition[],
  materializedTable: ProcessedMaterializedTable | undefined,
) => {
  if (!materializedTable) {
    return null;
  }

  const materializedTableHeaders = materializedTable.headers;
  const rows = materializedTable.rows;
  return rows.map((row) => {
    const rowData: Record<string, any> = {};
    materializedTableHeaders.forEach((header: any, index: number) => {
      const agColumn = agGridColumnDefinitions.find((columnDef) => columnDef.headerName === header.name);
      const field = agColumn?.field;
      if (field) {
        rowData[field] = row[index];
      }
    });
    return rowData;
  });
};

export const tableDefinitionOutputToTableDefinitionInput = (
  tableDefinition: TableDefinitionOutputV1,
): TableDefinitionInputV1 => {
  return {
    name: tableDefinition.name,
    description: tableDefinition.description,
    dataId: tableDefinition.dataId,
    datasourceClass: tableDefinition.datasourceClass,
    datasourceId: tableDefinition.datasourceId,
    scopedTo: tableDefinition.scopedTo,
    subscriptionId: tableDefinition.subscription?.id,
    batchAction: tableDefinition.batchAction,
    columnDefinitions: tableDefinition.columnDefinitions.map((columnDef) =>
      columnDefinitionOutputToColumnDefinitionInput(columnDef, tableDefinition.columnDefinitions, {
        scopedTo: sqTableDefinitionStore.scopedTo,
      }),
    ),
  };
};

export const columnDefinitionOutputToColumnDefinitionInput = (
  columnDefinition: ColumnDefinitionOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
  accessSettings: TableDefinitionAccessSettings,
): ColumnDefinitionInputV1 => {
  return {
    columnType: columnDefinition.columnType,
    columnUom: columnDefinition.columnUom,
    columnName: columnDefinition.columnName,
    columnRules: columnDefinition.rules
      .filter((rule) => !rule.rule.includes(SeeqNames.MaterializedTables.Rules.Overrides.Override))
      .map((rule) => columnRuleOutputToColumnRuleInput(rule, otherColumns, accessSettings)),
    isIndexed: columnDefinition.isIndexed,
    isHidden: columnDefinition.isHidden,
    sortAscending: columnDefinition.sortAscending,
    sortIndex: columnDefinition.sortIndex,
    isGenerated: columnDefinition.isGenerated,
  };
};

export const removeColumnDefinition = async (tableDefinitionId: string, columnDefinitionId: string) => {
  const { data: tableDefinitionOutput } = await sqTableDefinitionsApi.deleteColumnFromTableDefinition({
    id: tableDefinitionId,
    columnId: columnDefinitionId,
  });
  return tableDefinitionOutput;
};

export const removeColumnOverridesIfExisting = async (
  columns: ScalingTableColumnDefinition[],
  columnToDelete: ColDef<any, any>,
  tableDefinitionId: string,
) => {
  const mtColumnDefinition = columns.find((columnDefinition) => columnDefinition.id === columnToDelete?.field);
  if (
    mtColumnDefinition?.rules.some((rule) => rule.rule.includes(SeeqNames.MaterializedTables.Rules.Overrides.Override))
  ) {
    const removeOverrideOutput = await removeColumnOverrides(tableDefinitionId, columnToDelete.field!);
    setTableDefinition(removeOverrideOutput);
  }
};

export const removeColumnOverrides = async (tableDefinitionId: string, columnDefinitionId: string) => {
  const { data: tableDefinitionOutput } = await sqTableOverridesApi.deleteOverridesFromColumn({
    id: tableDefinitionId,
    columnId: columnDefinitionId,
  });
  return tableDefinitionOutput;
};

export const addOrUpdateColumnDefinition = async (
  tableDefinitionId: string,
  columnDefinition: ColumnDefinitionInputV1,
  columnDefinitionId?: string,
  tableHasUniqueDatumIdColumn?: boolean,
): Promise<TableComputeOutputV1 | undefined> => {
  const hasUniqueDatumIdColumn = (tableHasUniqueDatumIdColumn ?? !sqTableDefinitionStore.subscriberId) || undefined;

  const addOrUpdatePromise = columnDefinitionId
    ? sqTableDefinitionsApi.modifyColumnInTableDefinition(columnDefinition, {
        columnId: columnDefinitionId,
        id: tableDefinitionId,
        recomputeColumnType: RecomputeColumnTypeEnum1.PROVIDEDWITHDEPENDENTS,
        hasUniqueDatumIdColumn,
      })
    : sqTableDefinitionsApi.addColumnsToTableDefinition(
        { columnDefinitions: [columnDefinition] },
        {
          id: tableDefinitionId,
          recomputeColumnType: RecomputeColumnTypeEnum2.PROVIDEDWITHDEPENDENTS,
          hasUniqueDatumIdColumn,
        },
      );

  try {
    const { data: tableComputeOutput } = await addOrUpdatePromise;

    if (columnDefinition.columnRules.some((rule) => rule.treePathCreator)) {
      setDatasources('main', sqWorkbenchStore.stateParams.workbookId, true);
    }
    return tableComputeOutput;
  } catch (e) {
    errorToast({ httpResponseOrError: e });
  }
};

/**
 * When updating, this function will update properties of the table definition except for columns. For that, use
 * {@link addOrUpdateColumnDefinition}
 */
export const createOrUpdateTableDefinition = async (
  tableDefinition: TableDefinitionInputV1,
  tableDefinitionId?: string,
): Promise<TableDefinitionOutputV1> => {
  const { data: tableDefinitionOutput } = await (tableDefinitionId
    ? sqTableDefinitionsApi.updateTableDefinition(tableDefinition, { id: tableDefinitionId })
    : sqTableDefinitionsApi.createTableDefinition(tableDefinition));
  addTableDefinition(tableDefinitionOutput);

  return tableDefinitionOutput;
};

export const updateTableDefinitionName = async ({
  tableDefinitionId,
  name,
}: {
  tableDefinitionId: string;
  name: string;
}) => await sqItemsApi.setProperty({ value: name }, { id: tableDefinitionId, propertyName: SeeqNames.Properties.Name });

export const deleteTableRows = async (tableDefinitionId: string, rowIds: object[]) => {
  const REMOVE_MATERIALIZED_TABLE_ROWS =
    'mutation DeleteRows($tableId: String!, $rowIds: [RowIdInput!]!) {' +
    ' deleteRows(tableId: $tableId, rowIds: $rowIds) }';
  const inputObject: GraphQLInputV1 = {
    query: REMOVE_MATERIALIZED_TABLE_ROWS,
    variables: {
      tableId: tableDefinitionId,
      rowIds,
    },
  };
  try {
    const response = await sqGraphQLApi.graphql(inputObject);
    if (response.data.errors) {
      errorToast({ httpResponseOrError: response });
    }
    return response.data;
  } catch (e) {
    errorToast({ httpResponseOrError: e });
    return {};
  }
};

export const getMaterializedTable = async (
  tableDefinitionId: string,
  columnsToInclude?: string[],
  propertiesToInclude?: MaterializedTablePropertyColumnInput[],
): Promise<GraphQLOutputV1> => {
  const GET_MATERIALIZED_TABLE_QUERY =
    'query GetTable($id: String!, $filter: FilterInput, $limit: Int!, $columnsToInclude: [String!],' +
    ' $propertiesToInclude: [PropertiesForItemUUIDColumnInput!], $includeOverrides: Boolean!) {' +
    ' table(id: $id, filter: $filter, limit: $limit, columnsToInclude: $columnsToInclude, propertiesToInclude:' +
    ' $propertiesToInclude, includeOverrides: $includeOverrides) { rows headers { name' +
    ' type }' +
    ' hasMore } }';
  const inputObject: GraphQLInputV1 = {
    query: GET_MATERIALIZED_TABLE_QUERY,
    variables: {
      id: tableDefinitionId,
      limit: MAX_TABLE_ROWS,
      columnsToInclude,
      propertiesToInclude,
      includeOverrides: true,
    },
  };
  try {
    const response = await sqGraphQLApi.graphql(inputObject);
    if (response.data.errors) {
      errorToast({ httpResponseOrError: response });
    }
    return response.data;
  } catch (e) {
    errorToast({ httpResponseOrError: e });
    return {};
  }
};

/**
 * Validates a formula for an instance of a formulaCreatorRule. In order to compile the formula, this function will
 * find the first viable item in each column that the formula references and use those as parameters. If no viable
 * items are found, the function will return an error. If viable parameters are found but there is a compilation
 * error, the compilation error will be returned.
 */
export const validateFormulaForCreator = async (
  formulaRule: ColumnRuleFormulaCreatorInputV1,
  materializedTable?: ProcessedMaterializedTable,
  columnDefinitions?: ColumnDefinitionOutputV1[],
): Promise<FormulaCompileResult> => {
  if (!materializedTable || !columnDefinitions || columnDefinitions.length === 0) {
    return {
      success: false,
      errors: [{ message: 'No items in the table to run the formula on', column: -1, line: -1 }],
    };
  }
  const errors: FormulaErrorInterface[] = [];
  const materializedTableHeaders = materializedTable.headers;
  const parameters = formulaRule.parameters || [];
  const columnIndexes = formulaRule.columnIndexes || [];

  const parametersForCompile = parameters
    .map((parameter, index) => {
      const indexOfColumnInTableDefinition = columnIndexes[index] - 1;
      const columnName = columnDefinitions[indexOfColumnInTableDefinition].columnName;
      const indexOfColumnInMaterializedTable = materializedTableHeaders.findIndex(
        (header) => header.name === columnName,
      );
      const rowWithValidItem = materializedTable.rows.find((row) =>
        isItemTableCell(row[indexOfColumnInMaterializedTable]),
      );
      const idOfFirstValidItemInGivenColumn = rowWithValidItem
        ? (rowWithValidItem[indexOfColumnInMaterializedTable] as ItemTableCell).uuid
        : undefined;

      if (idOfFirstValidItemInGivenColumn === undefined && formulaRule.formula.includes(parameter)) {
        errors.push({
          message: `No valid items in column ${columnName} which is referred to by parameter: ${parameter}`,
          column: -1,
          line: -1,
        });
      }
      return `${parameter}=${idOfFirstValidItemInGivenColumn}`;
    })
    .filter((param) => param.includes('undefined') === false);

  if (errors.length > 0) {
    return { success: false, errors };
  }

  let formulaResult = formulaRule.formula;
  formulaRule.variableParameters?.forEach((parameter, index) => {
    const variableParams = formulaRule.variableParameterStrings
      ?.at(index)
      ?.split(',')
      ?.map((param) => param.trim())
      .map((param) => param.replace('$', ''))
      .filter((param) => parametersForCompile.some((paramForCompile) => paramForCompile.includes(`${param}=`)))
      .map((param) => `$${param}`);
    formulaResult = `$${parameter}=parameters(${variableParams?.join(',')})\n${formulaResult}`;
  });
  try {
    await compileFormulaOrThrowError(formulaResult, parametersForCompile);
    return { success: true, errors: [] };
  } catch (e: any) {
    return { success: false, errors: e };
  }
};

export async function editTableDefinition(tableDefinition: ItemSearchPreviewV1) {
  resetTableDefinition();
  try {
    const { data: tableDefinitionData } = await sqTableDefinitionsApi.getTableDefinition({ id: tableDefinition.id });
    setTableDefinition(tableDefinitionData);
    setDisplayTableDefinitionEditor(true);
  } catch (e) {
    errorToast({ httpResponseOrError: e });
  }
}

export const processMaterializedTable = (
  rawMaterializedTable: MaterializedTableOutput | undefined,
  columns: ScalingTableColumnDefinition[],
): ProcessedMaterializedTable | undefined => {
  if (!rawMaterializedTable) {
    return undefined;
  }
  const headers = rawMaterializedTable.headers;
  const rows = rawMaterializedTable.rows;

  const headerMap = headers.reduce((map, header) => {
    map.set(header.name, header);
    return map;
  }, new Map<string, MaterializedTableHeader>());

  const processedRows: ProcessedTableCell[][] = rows.map((row) => {
    const newRow: ProcessedTableCell[] = row.map((cell, columnIndex) => {
      const header = headers[columnIndex];
      const potentialValue: string | number | boolean | undefined = cell;
      const potentialNumericValue = toNumber(potentialValue);

      const column = columns.find((col) => col.columnName === header.name);
      const overrideColumnIndex = headers.findIndex((header) =>
        header.name.endsWith(`."${column?.id?.toLowerCase()}_overrides"`),
      );
      const overrideValue = overrideColumnIndex > -1 ? row[overrideColumnIndex] : undefined;
      const overrideIndicator = overrideValue !== undefined && overrideValue !== null;

      const itemIdIndex = headers.findIndex((header) => header.name === SeeqNames.MaterializedTables.ItemIdColumn);
      const datumIdIndex = headers.findIndex((header) => header.name === SeeqNames.MaterializedTables.DatumIdColumn);

      const evaluationDetails = column?.evaluationDetails?.find(
        (evaluationDetail) =>
          evaluationDetail.itemId.toUpperCase() === row[itemIdIndex]?.toString().toUpperCase() &&
          (!row[datumIdIndex] ||
            evaluationDetail.datumId?.toUpperCase() === row[datumIdIndex]?.toString().toUpperCase()),
      );

      if (header.type === ColumnTypeEnum.NUMERIC && !_.isNil(potentialNumericValue)) {
        const potentialUomColumn = headerMap.get(`${header.name}${UOM_COLUMN_MATCH_SUFFIX}`);
        const potentialUomColumnIndex = headers.findIndex((header) => header.name === potentialUomColumn?.name);
        const potentialUomColumnValue = potentialUomColumnIndex > -1 ? row[potentialUomColumnIndex] : undefined;
        // If there was not a uom column, then check the column definition for a uom override
        const uomColumnValue = potentialUomColumnValue ?? column?.columnUom;
        return {
          value: potentialNumericValue,
          uom: uomColumnValue as string | undefined,
          override: overrideIndicator,
          evaluationDetails: evaluationDetails?.ruleResults,
        };
      }
      if (header.type === ColumnTypeEnum.UUID && !_.isNil(potentialValue)) {
        const potentialItemColumn = columns.find((col) => col.columnName === header.name);
        const potentialPropertyColumns = potentialItemColumn?.additionalProperties
          ? [...potentialItemColumn.additionalProperties]
          : [];

        const potentialPropertyToDisplay = potentialItemColumn?.propertyToDisplay;
        if (potentialPropertyToDisplay) {
          potentialPropertyColumns.push(potentialPropertyToDisplay);
        }
        let fetchedProperties: Record<string, any> = {};
        potentialPropertyColumns.forEach((property) => {
          const propertyToDisplayHeaderIndex = headers.findIndex(
            (propertyHeader) => propertyHeader.name === `${header.name}${PROPERTY_COLUMN_MATCH_SEGMENT}${property}`,
          );
          fetchedProperties[property] =
            propertyToDisplayHeaderIndex > -1 ? (row[propertyToDisplayHeaderIndex] as string) : undefined;
        });

        return {
          uuid: potentialValue as string,
          fetchedProperties,
          override: overrideIndicator,
          evaluationDetails: evaluationDetails?.ruleResults,
        } as ItemTableCell;
      }
      if (header.type === ColumnTypeEnum.BOOLEAN && !_.isNil(potentialValue)) {
        return {
          value: !!potentialValue,
          override: overrideIndicator,
          evaluationDetails: evaluationDetails?.ruleResults,
        } as BooleanTableCell;
      }

      // make it a default text cell
      return {
        value: potentialValue ? potentialValue.toString() : undefined,
        override: overrideIndicator,
        evaluationDetails: evaluationDetails?.ruleResults,
      };
    });
    return newRow;
  });

  // Filter out property columns and uom columns from headers and rows so we do not put extra data in the store
  const propertyAndUomColumnIndices = headers
    .map((header, index) => ({ name: header.name, index }))
    .filter(({ name }) => name.includes(PROPERTY_COLUMN_MATCH_SEGMENT) || name.includes(UOM_COLUMN_MATCH_SUFFIX))
    .map(({ index }) => index);
  const filteredHeaders = headers.filter((_, index) => !propertyAndUomColumnIndices.includes(index));
  const filteredRows = processedRows.map((row) =>
    row.filter((_, index) => !propertyAndUomColumnIndices.includes(index)),
  );

  return {
    headers: filteredHeaders,
    rows: filteredRows,
    hasMore: rawMaterializedTable.hasMore,
  };
};

export const isItemTableCell = (cell: any): cell is ItemTableCell => {
  return cell && cell.uuid && typeof cell.uuid === 'string';
};

/**
 * Retrieves the data source for a table definition.
 */
export const getTableDefinitionDataSource = async (
  tableDefinitionId: string,
): Promise<DatasourceOutputV1 | undefined> => {
  const {
    data: { datasources },
  } = await sqDatasourcesApi.getDatasources({
    datasourceId: tableDefinitionId.toLocaleLowerCase(),
  });

  return datasources?.at(0);
};

/**
 * Deletes datasource associated with a table definition which
 * will delete all created items associated with the table definition.
 */
export async function deleteTableItems(tableDefinitionId: string): Promise<void> {
  const tableDefinitionDatasource = await getTableDefinitionDataSource(tableDefinitionId);
  if (tableDefinitionDatasource) {
    await fullyArchiveDatasource(tableDefinitionDatasource.id);
  }
}

export const getColumnsContainingRulesWithPermissions = (
  columns: ColumnDefinitionOutputV1[],
): ColumnDefinitionOutputV1[] =>
  columns.filter((column) => column.rules.some((rule) => RULES_WITH_PERMISSIONS.includes(rule.rule)));

/**
 * Updates the column definitions of a table.
 *
 * @param columnsToUpdate - The array of column definitions to update.
 * @param tableDefinitionId - The ID of the table definition.
 * @param allColumns - The array of all column definitions for the table.
 * @param accessSettings - The scope for the table and its formulas
 *
 * @returns A promise that resolves to the updated table definition, or undefined if there are no columns to update.
 */
export const updateColumnDefinitions = async (
  columnsToUpdate: ColumnDefinitionOutputV1[],
  tableDefinitionId: string,
  allColumns: ScalingTableColumnDefinition[],
  accessSettings: TableDefinitionAccessSettings,
): Promise<TableComputeOutputV1 | undefined> => {
  let tableComputeOutputV1: TableComputeOutputV1 | undefined;

  // Do not update in parallel with Promise.all as columns may depend on each other
  for (const column of columnsToUpdate) {
    tableComputeOutputV1 = await addOrUpdateColumnDefinition(
      tableDefinitionId,
      columnDefinitionOutputToColumnDefinitionInput(column, allColumns, accessSettings),
      column.id,
    );
  }

  return tableComputeOutputV1;
};

export const updateColumnDefinitionsUsingInputs = async (
  columnsToUpdate: ColumnDefinitionInputV1[],
  allColumns: ScalingTableColumnDefinition[],
  tableDefinitionId: string,
): Promise<TableComputeOutputV1 | undefined> => {
  let tableComputeOutput: TableComputeOutputV1 | undefined;

  // Do not update in parallel with Promise.all as columns may depend on each other
  for (const column of columnsToUpdate) {
    const id = allColumns.find((col) => col.columnName === column.columnName)?.id;
    tableComputeOutput = await addOrUpdateColumnDefinition(tableDefinitionId, column, id);
  }

  return tableComputeOutput;
};

/**
 * Updates the columns containing rules with permissions with the new
 * access settings
 */
export const updateColumnsContainingRulesWithPermissions = async (accessSettings: TableDefinitionAccessSettings) =>
  await updateColumnDefinitions(
    getColumnsContainingRulesWithPermissions(sqTableDefinitionStore.columns),
    sqTableDefinitionStore.id,
    sqTableDefinitionStore.columns,
    accessSettings,
  );

export const getColumnOptions = (
  allColumnDefinitions: ScalingTableColumnDefinition[],
  hiddenColumns = HIDDEN_COLUMNS,
) =>
  allColumnDefinitions
    .filter((column) => !hiddenColumns.includes(column.columnName))
    .map((column) => ({
      text: sqTableDefinitionStore.getColumnDisplayName(column.columnName),
      value: column.columnName,
    }));

/**
 * Updates the visibility of columns in a table based on user actions.
 *
 * @param tableDefinitionId
 * @param existingColumnsAsOutputs
 * @param existingColumnsAsInputs
 * @param agColumns - The AgGrid columns pulled from the api at time of execution
 * @returns Promise<void>
 */
export const onColumnHideTriggeredByUser = async (
  tableDefinitionId: string,
  existingColumnsAsOutputs: ScalingTableColumnDefinition[],
  existingColumnsAsInputs: ColumnDefinitionInputV1[],
  agColumns: Column[],
) => {
  const columnIdToColumnInputMap: Record<string, ColumnDefinitionInputV1> = existingColumnsAsOutputs.reduce(
    (acc, column, index) => {
      acc[column.id] = existingColumnsAsInputs[index];
      return acc;
    },
    {} as Record<string, ColumnDefinitionInputV1>,
  );

  const tableColumnDefinitionsWithUpdatedVisibility: ColumnDefinitionInputV1[] = (
    agColumns
      .map((agColumn) => {
        const columnId = agColumn.getColDef().field;
        const columnInput = columnId !== undefined ? columnIdToColumnInputMap[columnId] : undefined;
        const visibilityChanged = columnInput && !columnInput?.isHidden !== agColumn.isVisible();
        return visibilityChanged
          ? {
              isVisibleNow: agColumn.isVisible(),
              columnInput,
            }
          : undefined;
      })
      .filter((agColumn) => !!agColumn) as { isVisibleNow: boolean; columnInput: ColumnDefinitionInputV1 }[]
  ).map((agColumn) => {
    const columnInput = agColumn.columnInput;
    return {
      ...columnInput,
      isHidden: !agColumn.isVisibleNow,
    } as ColumnDefinitionInputV1;
  });

  const updatedTableDefinition = await updateColumnDefinitionsUsingInputs(
    tableColumnDefinitionsWithUpdatedVisibility,
    existingColumnsAsOutputs,
    tableDefinitionId,
  );
  if (updatedTableDefinition) {
    setTableDefinition(updatedTableDefinition.tableDefinition);
  }
};

/**
 * Updates the column definitions based on the user's sorting changes in the table.
 *
 * @param tableDefinitionId
 * @param existingColumnsAsOutputs
 * @param existingColumnsAsInputs
 * @param agColumns The AgGrid columns pulled from the api at time of execution
 */
export const onSortChangedByUser = async (
  tableDefinitionId: string,
  existingColumnsAsOutputs: ScalingTableColumnDefinition[],
  existingColumnsAsInputs: ColumnDefinitionInputV1[],
  agColumns: Column[],
) => {
  const columnIdToColumnInputMap: Record<string, ColumnDefinitionInputV1> = existingColumnsAsOutputs.reduce(
    (acc, column, index) => {
      acc[column.id] = existingColumnsAsInputs[index];
      return acc;
    },
    {} as Record<string, ColumnDefinitionInputV1>,
  );
  const tableDefColumnsWithUpdatedSort = agColumns
    .map((agColumn) => {
      const columnId = agColumn.getColDef().field;
      const columnInput = columnId !== undefined ? columnIdToColumnInputMap[columnId] : undefined;
      const existingSortIndex = columnInput?.sortIndex;
      const existingPotentialSort = columnInput?.sortAscending ? 'asc' : 'desc';
      const existingSort = existingSortIndex !== undefined ? existingPotentialSort : undefined;
      const agSort = agColumn.getSort();
      const agSortIndex = agColumn.getSortIndex() ?? undefined;
      if (existingSort !== agSort || existingSortIndex !== agSortIndex) {
        return {
          ...columnInput,
          sortAscending: agSort === 'asc',
          sortIndex: agSortIndex,
        } as ColumnDefinitionInputV1;
      }
      return undefined;
    })
    .filter((column) => !!column) as ColumnDefinitionInputV1[]; // This will remove all undefined values
  const updatedTableDefinition = await updateColumnDefinitionsUsingInputs(
    tableDefColumnsWithUpdatedSort,
    existingColumnsAsOutputs,
    tableDefinitionId,
  );
  if (updatedTableDefinition) {
    setTableDefinition(updatedTableDefinition.tableDefinition);
  }
};
