// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {
  AdvancedFormGroup,
  CollapsibleFormGroup,
  FormElement,
  FormGroup,
  FormGroupWithTitle,
  FormRow,
  RemovableFormGroup,
  RemovableFormRow,
  supportedComponents,
  ValidatingFormComponent,
} from '@/formbuilder/formBuilder.constants';
import {
  AdvancedFormBlock,
  CollapsibleFormBlock,
  ConditionalFormBlock,
  FormBlockWithCheckmarkAndNumber,
  FormGroupBlock,
  FormGroupWithTitleBlock,
  FormRowBlock,
  RemovableFormBlock,
  RemovableFormRowBlock,
} from '@/formbuilder/formBuildingBlocks';
import { FormFieldWrapperProps } from './FormFieldWrapper';

export function getFormFieldProps(formState, input, meta, props) {
  const { extraClassNames, testId, value, skipStore = false, defaultProvided = false } = props;
  const showError = formState.submitFailed ? meta.error : meta.error && (meta.modified || defaultProvided);

  return _.assign({}, props, {
    'onChange': (value) => {
      props.onChange?.(value);
      input.onChange(value);
    },
    'value': skipStore ? input.value : value,
    'data-testid': testId,
    // temporary until all Forms are converted to FormBuilder (https://seeq.atlassian.net/browse/CRAB-21727)
    'fromFormBuilder': true,
    'className': classNames({ 'error-border': showError }),
    'extraClassNames': classNames(extraClassNames, {
      'error-border': showError,
    }),
    showError,
  });
}

export function getFormFieldWrapperProps<T = any>(
  props: ValidatingFormComponent<T>,
  propsToOmit?: (keyof FormFieldWrapperProps)[],
): Omit<FormFieldWrapperProps, 'children'> {
  const propsToInclude = [
    'testId',
    'label',
    'wrapperClassNames',
    'customErrorText',
    'customErrorParams',
    'tooltip',
    'tooltipPlacement',
    'onTooltipClick',
    'popoverContent',
    'id',
  ];
  const selectedProps = propsToOmit?.length > 0 ? _.xor(propsToInclude, propsToOmit) : propsToInclude;

  return _.pick<ValidatingFormComponent<T>, any>(props, selectedProps);
}

export function getValidationFunction(defaultValidation, extendValidation, customValidation) {
  if (extendValidation && _.isFunction(customValidation)) {
    return (value) => defaultValidation(value) || customValidation(value);
  }
  return _.isFunction(customValidation) ? customValidation : defaultValidation;
}

/**
 * Recursively "flattens" out all form elements into an array of form field names.
 **/
export function flattenFormDefinition(formDefinition: FormElement[]): FormElement[] {
  const flattenFormDefinition = (formDefinition) =>
    _.map(formDefinition, (def) =>
      (def.component === 'FormGroup' ||
        def.component === 'FormGroupWithTitle' ||
        def.component === 'AdvancedFormGroup' ||
        def.component === 'FormRow' ||
        def.component === 'RemovableFormRow' ||
        def.component === 'RemovableFormGroup' ||
        def.component === 'CollapsibleFormGroup') &&
      def.components
        ? flattenFormDefinition(def.components)
        : def,
    );
  return _.flattenDeep(flattenFormDefinition(formDefinition));
}

export function decorateWithFieldNames(formDefinition: FormElement[]) {
  const addFieldNames = (formDefinition: FormElement[]) =>
    _.map(formDefinition, (def) => {
      const fieldNames = _.map(flattenFormDefinition([def]), 'name');
      if ('components' in def && !_.isEmpty(def.components)) {
        def.components = addFieldNames(def.components);
      }

      return _.assign({}, def, { fieldNames });
    });

  return addFieldNames(formDefinition);
}

// walks the formConfig and assigns the appropriate numbers for checkmarkAndNumber display
export function decorateWithNumbers(formDefinition: FormElement[]) {
  let number = 0;

  const hasDisplayNumber = (def) => def.displayNumber && (!_.has(def, 'includeIf') || def.includeIf);

  const getDefWithNumber = (def) => {
    number++;
    return _.assign({}, def, { number });
  };

  const addDisplayNumber = (formDefinition) =>
    _.map(formDefinition, (def) => {
      if (hasDisplayNumber(def)) {
        const decoratedDef = getDefWithNumber(def);
        if (!_.isEmpty(def.components)) {
          _.assign(decoratedDef, {
            components: addDisplayNumber(def.components),
          });
        }
        return decoratedDef;
      } else {
        if (!_.isEmpty(def.components) && (!_.has(def, 'includeIf') || def.includeIf)) {
          return _.assign({}, def, {
            components: addDisplayNumber(def.components),
          });
        }
        return def;
      }
    });
  return addDisplayNumber(formDefinition);
}

export function renderIt(def: FormElement) {
  if (!def.component) {
    throw new Error('Every FormElement must provide a valid component or it can not be rendered.');
  }
  switch (def.component) {
    case 'FormGroup':
      return renderFormGroup(def as FormGroup);
    case 'FormGroupWithTitle':
      return renderFormGroupWithTitle(def as FormGroupWithTitle);
    case 'FormRow':
      return renderFormRow(def as FormRow);
    case 'RemovableFormRow':
      return renderRemovableFormRow(def as RemovableFormRow);
    case 'AdvancedFormGroup':
      return renderAdvancedFormGroup(def as AdvancedFormGroup);
    case 'RemovableFormGroup':
      return renderRemovableFormGroup(def as RemovableFormGroup);
    case 'CollapsibleFormGroup':
      return renderCollapsibleFormGroup(def as CollapsibleFormGroup);
    default:
      return renderFormComponent(def);
  }
}

function renderFormGroup(def: FormGroup) {
  const { key, name, components, testId, extraClassNames, showBracket, showScroll = false } = def;
  const children = components.map(renderIt);
  const content = (
    <FormGroupBlock
      key={key}
      name={name}
      testId={testId}
      extraClassNames={extraClassNames}
      showBracket={showBracket}
      showScroll={showScroll}>
      {children}
    </FormGroupBlock>
  );

  return finalizeForRender(def, content);
}

function renderFormGroupWithTitle(def: FormGroupWithTitle) {
  const {
    key,
    name,
    components,
    testId,
    extraClassNames,
    showBracket,
    showScroll = false,
    showTitle = false,
    title,
    titleLink,
    titleLinkAction,
    additionalTitleLink,
    additionalTitleLinkAction,
  } = def;
  const children = components.map(renderIt);
  const content = (
    <FormGroupWithTitleBlock
      key={key}
      name={name}
      testId={testId}
      extraClassNames={extraClassNames}
      showBracket={showBracket}
      showTitle={showTitle}
      title={title}
      titleLink={titleLink}
      titleLinkAction={titleLinkAction}
      additionalTitleLink={additionalTitleLink}
      additionalTitleLinkAction={additionalTitleLinkAction}
      showScroll={showScroll}>
      {children}
    </FormGroupWithTitleBlock>
  );

  return finalizeForRender(def, content);
}

function renderAdvancedFormGroup(def: AdvancedFormGroup) {
  const { key, name, components, testId, toolName, toolId, toolStore, alternateName, extraClassNames } = def;
  const children = components.map(renderIt);
  const content = (
    <AdvancedFormBlock
      key={key || name}
      name={name}
      testId={testId}
      toolName={toolName}
      toolId={toolId}
      toolStore={toolStore}
      alternateName={alternateName}
      extraClassNames={extraClassNames}>
      {children}
    </AdvancedFormBlock>
  );
  return finalizeForRender(def, content);
}

function renderCollapsibleFormGroup(def: CollapsibleFormGroup) {
  const {
    components,
    testId,
    deleteIconAction,
    hideDeleteIcon = false,
    extraClassNames,
    isCollapsed = false,
    toggleCollapse,
    label,
    isLast = false,
  } = def;
  const children = components.map(renderIt);
  const content = (
    <CollapsibleFormBlock
      hideDeleteIcon={hideDeleteIcon}
      testId={testId}
      deleteIconAction={deleteIconAction}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      extraClassNames={extraClassNames}
      label={label}
      isLast={isLast}>
      {children}
    </CollapsibleFormBlock>
  );
  return finalizeForRender(def, content);
}

function renderRemovableFormGroup(def: RemovableFormGroup) {
  const { components, testId, iconAction, hideIcon = false, extraClassNames, iconHasMargin = false } = def;
  const children = components.map(renderIt);
  const content = (
    <RemovableFormBlock
      hideIcon={hideIcon}
      testId={testId}
      iconAction={iconAction}
      iconHasMargin={iconHasMargin}
      extraClassNames={extraClassNames}>
      {children}
    </RemovableFormBlock>
  );
  return finalizeForRender(def, content);
}

function renderFormRow(def: FormRow) {
  const { name, components, testId, extraClassNames } = def;
  const children = components.map(renderIt);
  const content = (
    <FormRowBlock name={name} testId={testId} extraClassNames={classNames('formRow', extraClassNames)}>
      {children}
    </FormRowBlock>
  );

  return finalizeForRender(def, content);
}

function renderRemovableFormRow(def: RemovableFormRow) {
  const { name, components, testId, removeAction, hideRemoveIcon = false, extraClassNames, wrapperClassNames } = def;
  const children = components.map(renderIt);
  const content = (
    <RemovableFormRowBlock
      hideRemoveIcon={hideRemoveIcon}
      name={name}
      testId={testId}
      removeAction={removeAction}
      wrapperClassNames={wrapperClassNames}
      extraClassNames={classNames('formRow', extraClassNames)}>
      {children}
    </RemovableFormRowBlock>
  );
  return finalizeForRender(def, content);
}

function renderFormComponent(def: FormElement) {
  if (!supportedComponents[def.component]) {
    throw new Error(`Please supply an existing FormComponent or add ${def.component} as a supported component.`);
  }

  return renderWrapped(supportedComponents[def.component], def);
}

function renderWrapped(What, props: FormElement) {
  const { name } = props;
  return finalizeForRender(props, <What {...props} key={name} />);
}

function finalizeForRender(def: FormElement, content: JSX.Element) {
  const { number, includeIf, name, fieldNames, key, extraClassNames } = def;
  const finalContent: JSX.Element = number ? (
    <FormBlockWithCheckmarkAndNumber
      name={name}
      extraClassNames={extraClassNames}
      fieldNames={fieldNames}
      number={number}>
      {content}
    </FormBlockWithCheckmarkAndNumber>
  ) : (
    content
  );

  return (
    <ConditionalFormBlock key={key || name} isConditional={_.has(def, 'includeIf')} show={includeIf} name={name}>
      {finalContent}
    </ConditionalFormBlock>
  );
}

export function canUseMemo(prev, next) {
  // return false if render is necessary
  return !(
    !_.isEqual(prev.value, next.value) ||
    prev.disabled !== next.disabled ||
    prev.extendValidation ||
    prev.extraClassNames !== next.extraClassNames ||
    prev.options !== next.options
  );
}
