import _ from 'lodash';
import { DURATION_TIME_UNITS, STRING_UOM } from '@/main/app.constants';
import { base64guid } from '@/utilities/utilities';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { INTERPOLATION_TYPES, ManualSample } from '@/tools/manualSignal/manualSignal.constants';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';
import { InputTableSort } from '@/formbuilder/InputTableFormComponent.molecule';

export class ManualSignalStore extends BaseToolStore {
  static readonly storeName = 'sqManualSignalStore';
  type = TREND_TOOLS.MANUAL_SIGNAL;

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, {
        interpolationMethod: INTERPOLATION_TYPES.LINEAR,
        maximumInterpolation: {
          value: '40',
          units: DURATION_TIME_UNITS[2].unit[0],
        },
        uom: undefined,
        samples: [],
        editingSampleId: undefined,
        sort: { asc: false, property: 'dateTime' },
        color: '',
      }),
    );
  }

  get color(): string {
    return this.state.get('color');
  }

  get interpolationMethod(): string {
    return this.state.get('interpolationMethod');
  }

  get maximumInterpolation(): ValueWithUnitsItem {
    return this.state.get('maximumInterpolation');
  }

  get uom(): string {
    return this.state.get('uom');
  }

  get samples(): ManualSample[] {
    return this.state.get('samples');
  }

  get editingSampleId(): string | undefined {
    return this.state.get('editingSampleId');
  }

  get sort(): InputTableSort {
    return this.state.get('sort');
  }

  dehydrate() {
    return _.omit(this.state.serialize(), 'editingSampleId');
  }

  rehydrate(dehydratedState: any) {
    this.state.merge(dehydratedState);
  }

  setUom({ uom }: { uom: string }) {
    this.state.set('uom', uom);
  }

  protected readonly handlers = {
    ...this.baseHandlers,

    MANUAL_SIGNAL_SET_INTERPOLATION_METHOD: ({ interpolationMethod }: { interpolationMethod: string }) => {
      this.state.set('interpolationMethod', interpolationMethod);
    },

    MANUAL_SIGNAL_SET_MAXIMUM_INTERPOLATION: ({
      maximumInterpolation,
    }: {
      maximumInterpolation: {
        value: string;
        units: string;
      };
    }) => {
      this.state.set('maximumInterpolation', maximumInterpolation);
    },

    MANUAL_SIGNAL_SET_UOM: this.setUom,

    MANUAL_SIGNAL_ADD_SAMPLE: ({ sample }: { sample: ManualSample }) => {
      // Help the user out by setting the Unit of Measure if the first value they enter is a string
      if (
        this.state.get('samples').length === 0 &&
        _.isUndefined(this.state.get('uom')) &&
        _.isNaN(_.toNumber(sample.value))
      ) {
        this.setUom({ uom: STRING_UOM });
      }

      const newSample = _.assign(sample, { id: base64guid() });
      this.state.set('samples', [...this.state.get('samples'), newSample]);
    },

    MANUAL_SIGNAL_REMOVE_SAMPLE: ({ id }: { id: string }) => {
      const index = _.findIndex(this.state.get('samples'), ['id', id]);
      if (index > -1) {
        this.state.unset(['samples', index]);
      }
    },

    MANUAL_SIGNAL_UPDATE_SAMPLE: ({ sample }: { sample: ManualSample }) => {
      const index = _.findIndex(this.state.get('samples'), ['id', sample.id]);
      const existingSample = this.state.select('samples', index);

      if (existingSample.exists()) {
        existingSample.merge(sample);
      }
    },

    MANUAL_SIGNAL_SET_EDITING_SAMPLE_ID: ({ editingSampleId }: { editingSampleId: string | undefined }) => {
      this.state.set('editingSampleId', editingSampleId);
    },

    MANUAL_SIGNAL_SET_SORT: ({ sort }: { sort: string }) => {
      this.state.set('sort', sort);
    },

    MANUAL_SIGNAL_SET_COLOR: ({ color }: { color: string }) => {
      this.state.set('color', color);
    },
  };
}
