import _ from 'lodash';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { AddOnToolOutputV1 } from '@/sdk/model/AddOnToolOutputV1';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export class AddOnToolStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqAddOnToolStore';

  initialize() {
    this.state = this.immutable({
      addOnTools: this.state?.get?.('addOnTools') || [], // preserve any existing addOnTools state
    });
  }

  get homeScreenAddOnTools(): AddOnToolOutputV1[] {
    return this.state.get('addOnTools');
  }

  /**
   * Gets an addOn tool by its id from the store
   *
   * @param {string} id - the tool id
   */
  getAddOnTool(id: string): AddOnToolOutputV1 {
    return _.chain(this.state.get('addOnTools')).filter({ id }).first().value() as AddOnToolOutputV1;
  }

  protected readonly handlers = {
    ADD_ON_TOOLS_SET: (payload: { addOnTools: AddOnToolOutputV1[] }) => {
      const homescreenAddOnTools = _.filter(
        payload.addOnTools,
        (addOnTool) => addOnTool.launchLocation === SeeqNames.API.AddOnToolLaunchLocation.Homescreen,
      );
      this.state.set('addOnTools', homescreenAddOnTools);
    },
  };
}
