import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';
import { LockErrorOutputV1, LockErrorPathOutputV1 } from '@/sdk';
import { LockFailureReasons } from '@/accessControl/LockFailureReasons.atom';

interface LockErrorsProps {
  lockErrors: LockErrorOutputV1[];
}

/**
  Display a list of global items that are preventing a user locking an Analysis. Underneath each item, display a
  reason why the lock failed and a link to where the item is used in the user's Analysis.
 */
export const GlobalItems: React.FunctionComponent<LockErrorsProps> = ({ lockErrors }) => {
  const { t } = useTranslation();
  /**
   * Prevent the same item, analysis, etc. from appearing multiple times in the global items list by
   * merging all entries for the same id together
   */
  const globalItemsWithMergedItemPaths: LockErrorOutputV1[] = _.chain(lockErrors)
    .groupBy('id')
    .map((group) => ({
      id: group[0].id,
      item: group[0].item,
      itemPath: group.flatMap((g) => g.itemPath).filter((path): path is LockErrorPathOutputV1 => path !== undefined),
    }))
    .value();

  const HelpIcon = (
    <a
      href="https://telemetry.seeq.com/support-link/kb/latest/cloud/setting-permissions-access-control/locking-a-workbench-analysis"
      target="_blank"
      rel="noopener">
      <Icon
        icon="fa-question-circle"
        large={true}
        tooltip={t('ACCESS_CONTROL.LOCKING.GLOBAL_ITEMS_TOOLTIP')}
        extraClassNames="text-interactive"
      />
    </a>
  );

  return (
    <>
      {lockErrors.length > 0 && (
        <>
          <div className="pb10" data-testid="cannotLockTitle">
            <strong>{t('ACCESS_CONTROL.LOCKING.CANNOT_LOCK')}</strong> {HelpIcon}
          </div>
          <div className="bg-gray p10">
            {globalItemsWithMergedItemPaths.length > 0 &&
              globalItemsWithMergedItemPaths.map((globalItem) => (
                <div key={`error-container-${globalItem.item.id}`} className="mb20">
                  <LockFailureReasons itemName={globalItem.item.name} globalItem={globalItem} />
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};
