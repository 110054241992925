// @ts-strict-ignore
import React, { useState } from 'react';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { KEY_CODES } from '@/main/app.constants';

export interface IconWithManagedSpinnerProps {
  icon?: string;
  action: (e?: any) => Promise<any>;
  tooltip: string;
  tooltipDelay?: number;
  actionOnEnter?: boolean;
  large?: boolean;
  white?: boolean;
  extraClassNames?: string;
  disabled?: boolean;
  testId?: string;
  correctSpin?: boolean;
}

/** Icon that shows spinner when click action is in progress */
export const IconWithManagedSpinner: React.FunctionComponent<IconWithManagedSpinnerProps> = (props) => {
  const { action, tooltip, tooltipDelay = 0, disabled = false } = props;
  const [spinning, setSpinning] = useState(false);

  const clickFunction = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    setSpinning(true);
    return action().finally(() => setSpinning(false));
  };

  return (
    <HoverTooltip text={tooltip} delay={tooltipDelay}>
      <div onClick={clickFunction} onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && clickFunction(e)}>
        <IconWithSpinner {...props} spinning={spinning} />
      </div>
    </HoverTooltip>
  );
};
