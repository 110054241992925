/**
 * Invokes a callback if the enter key is pressed from an input element. Expected to be called as an onKeyUp
 * handler.
 *
 * @param callback - The action to invoke if the enter key is pressed
 * @return A callback to be invoked whenever a key is pressed
 */
export const onEnterKeypress =
  (callback: (value: string) => void) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      callback((event.target as HTMLInputElement).value);
      event.stopPropagation();
    }
  };

/**
 * Stops propagation of the event and executes the callback
 *
 * @param callback - The action to be invoked.
 * @return A callback that stops propagation and then invokes the callback
 */
export const onEventPreventPropagation =
  (callback: () => void) => (event: React.SyntheticEvent | React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    event?.stopPropagation();
    callback();
  };

/**
 * Should be used in conjunction with the onEnterKeypress handler to also ensure the parent form element does not
 * submit when enter key is pressed.
 *
 * @param event - The form event
 */
export const onSubmitPreventDefault = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
};
