import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';

export interface PluginHostIF {
  entryPoint: string;
  createApi: (messagePort: MessagePort) => {
    destroy: () => void;
  };
}

export const PluginHost: React.FunctionComponent<PluginHostIF> = ({ entryPoint, createApi }) => {
  const api = useRef<ReturnType<typeof createApi>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return () => api?.current?.destroy();
  }, [api]);

  useEffect(() => {
    setLoading(true);
  }, [entryPoint]);

  const onLoad = useCallback(
    (event: SyntheticEvent<HTMLIFrameElement, Event>) => {
      const iframe = event.target as HTMLIFrameElement;
      const { port1, port2 } = new MessageChannel();
      api.current = createApi(port1);
      iframe.contentWindow?.postMessage('init', '*', [port2]);
      setLoading(false);
    },
    [createApi],
  );

  const renderSpinner = () => (
    <div className="pluginLoadingSpinnerWrapper">
      <i
        className="pluginLoadingSpinner fa fa-lg fa-solid fa-spinner fa-spin-pulse"
        data-testid="pluginLoadingSpinner"
      />
    </div>
  );

  return (
    <>
      <iframe
        data-testid="pluginHost"
        src={entryPoint}
        sandbox="allow-scripts allow-popups allow-popups-to-escape-sandbox"
        className="pluginContainer"
        onLoad={onLoad}
      />
      {loading ? renderSpinner() : null}
    </>
  );
};
