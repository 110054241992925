import React from 'react';
import { useTranslation } from 'react-i18next';
import { doTrack } from '@/track/track.service';
import { Modal } from '@seeqdev/qomponents';

interface CloseWhileSavingModalProps {
  onClose: () => void;
}

export const ClosedWhileSavingModal: React.FunctionComponent<CloseWhileSavingModalProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={true}
      title={t('ITEM_ACL.CLOSE_DURING_SAVE.HEADER')}
      hideSubmitButton={true}
      cancelButtonLabel={t('ITEM_ACL.CLOSE_DURING_SAVE.CLOSE')}
      onClose={() => {
        doTrack('Access Control', 'close during save', 'close');
        onClose();
      }}
      onSubmit={() => {}}>
      <div>{t('ITEM_ACL.CLOSE_DURING_SAVE.MESSAGE')}</div>
    </Modal>
  );
};
