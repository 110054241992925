import _ from 'lodash';
import { PREDICTION } from '@/tools/prediction/prediction.constants';
import { getShortIdentifier } from '@/utilities/utilities';
import { getCapsuleFormula } from '@/datetime/dateTime.utilities';
import { ParametersMap } from '@/utilities/formula.constants';
import { PREDICTION_OPERATION } from '@/services/calculationRunner.constants';
import { FrontendDuration } from '@/services/systemConfiguration.types';
import { Signal } from '@/utilities/items.types';

/**
 * Service that encapsulates logic related to construction of the prediction formula and transformation
 * of the backend table response (which has a single row) into a data structure that is more easily
 * consumed by the frontend UI.
 */

/**
 * Creates and returns a prediction or predictionModel formula along with the formula parameters and some related
 * information that is used when transforming the formula response.
 *
 * @param operator - the Seeq Formula operator that should be used (one of PREDICTION_OPERATION)
 * @param targetSignalId - The id of the dependent signal for the prediction
 * @param inputSignals - An array of the independent signals for the prediction
 * @param [conditionId] - The id of the condition that limits the prediction training window
 * @param principalComponents - The number of principal components to keep if mode is PCA
 * @param config - Object containing all the necessary information to re-populate the panel for edit
 * @param config.windowStart - a UTC unix time stamp for the start of the prediction training window
 * @param config.windowEnd - a UTC unix time stamp for the end of the prediction training window
 * @param config.option - One of PREDICTION.SCALE (e.g. LINEAR, POLYNOMIAL, LOG, etc.)
 * @param [config.polynomialValue] - The polynomial value. Only required when option is POLYNOMIAL.
 * @param config.mustGoThroughZero - Whether the prediction must be forced to go through zero.
 * @param config.lambda - The lambda value (must be positive).
 * @param config.regressionMethod - The regression method to use (one of REGRESSION_METHODS).
 * @param config.variableSelection - The variable selection (range of [0, 1]).
 * @param config.variableSelectionEnabled - True if variable selection is enabled, false otherwise.
 * @returns the formula, parameters, inputLegend (used to create the prediction model legend), and
 *   groupArray (used to replace the backend-supplied name such as "value1" with a variable name such as "$a")
 */
export function createFormula(
  operator: string,
  targetSignalId: string,
  inputSignals: any[],
  conditionId: string | undefined,
  principalComponents: number,
  config: {
    lambda: number;
    mustGoThroughZero: boolean;
    option: string;
    polynomialValue?: number;
    windowStart: number;
    windowEnd: number;
    regressionMethod: string;
    variableSelection: number;
    variableSelectionEnabled: boolean;
    refreshRate: FrontendDuration;
    isRefreshRateEnabled: boolean;
  },
) {
  // Exclude the target signal from the inputs
  inputSignals = _.reject(inputSignals, (signal: any) => signal.id === targetSignalId);
  inputSignals = _.uniqBy(inputSignals, (signal: any) => signal.id);
  const inputParams = _.transform(
    inputSignals,
    (result, item, index) => {
      result[getShortIdentifier(index)] = item.id;
    },
    {},
  );
  const inputLegend = _.transform(
    inputSignals,
    (result, item, index) => {
      result[getShortIdentifier(index)] = item.name;
    },
    {},
  );

  if (_.isNil(config.regressionMethod)) {
    throw new Error('regressionMethod should be defined');
  }

  const condition = conditionId ? { condition: conditionId } : undefined;
  const parameters = _.merge({}, { target: targetSignalId }, inputParams, condition) as ParametersMap;
  const signalArray = createInputsGroupArray(inputParams, config.option, config.polynomialValue);
  const signalList = signalArray.join(', ');
  const capsuleGroup = createCapsule(config.windowStart, config.windowEnd, condition);
  const algorithm = config.regressionMethod;
  const refreshRate = config.refreshRate;
  const isRefreshRateEnabled = config.isRefreshRateEnabled;
  let modelSpecificArgument = config.mustGoThroughZero ? ', true' : ', false';
  if (algorithm === PREDICTION.REGRESSION_METHODS.RIDGE) {
    modelSpecificArgument = `, ${config.lambda}`;
  } else if (algorithm === PREDICTION.REGRESSION_METHODS.PRINCIPAL_COMPONENT_REGRESSION) {
    modelSpecificArgument = `, ${principalComponents}`;
  }

  let formula = `$target.regressionModel${algorithm}(${capsuleGroup}${modelSpecificArgument}, ${signalList})`;

  if (config.variableSelectionEnabled && _.isFinite(config.variableSelection) && config.variableSelection !== 1) {
    formula += `.variableSelection(${config.variableSelection})`;
  }

  if (isRefreshRateEnabled && !!refreshRate) {
    formula += `.setRefreshRate(${refreshRate.value}${refreshRate.units})`;
  }

  if (operator !== PREDICTION_OPERATION.PREDICTION_MODEL) {
    formula += `.predict(${signalList})`;
  }

  return {
    formula,
    parameters,
    inputLegend,
    groupArray: signalArray,
  };
}

/**
 * Helper method to extract the regression model formula string from a prediction tool signal.
 *
 * @param formula The formula of the calculated signal
 * @return The formula for a prediction signal or undefined if the formula is not one
 * that produces a regression model formula.
 */
export function getRegressionModelFormula(formula: string): string | undefined {
  return _.get(formula.match(/(\$target\.regressionModel\w+\(.*?\))\.predict/), '[1]');
}

/**
 * Adjusts the passed 'principal components to keep' value if necessary (not a number or
 * outside [1, totalInputCount] range).
 *
 * @param inputSignals - An array of the independent signals for the prediction
 * @param option - One of PREDICTION.SCALE (e.g. LINEAR, POLYNOMIAL, LOG, etc.)
 * @param polynomialValue - The polynomial value. Only required when option is POLYNOMIAL.
 * @param principalComponentsToKeep - The number of principal components to keep
 */
export function adjustPrincipalComponentsToKeep(
  inputSignals: Signal[],
  option: string,
  polynomialValue: number,
  principalComponentsToKeep: string,
) {
  const parsedComponentsToKeep = Number.parseInt(principalComponentsToKeep, 10);
  const totalInputs = createInputsGroupArray(inputSignals, option, polynomialValue).length;

  if (!Number.isNaN(parsedComponentsToKeep)) {
    // Make sure variable selection is limited to the [1, totalInputCount] range
    return Math.round(Math.max(Math.min(parsedComponentsToKeep, totalInputs), 1));
  }

  return totalInputs;
}

/**
 * Transforms the one-row table returned by the PREDICTION_OPERATION.PREDICTION_MODEL operation into a structure
 * more suitable for use by frontend UI code. Generates a legend that maps variable names to series names. Replaces
 * the generic * "value1" and "standardError1" coefficient labels with an actual variable name from the legend
 * (e.g. "$a")
 *
 * @param table - the prediction table returned from the backend
 * @param regressionOutput - the prediction output returned from the backend
 * @param inputSignals - an object that maps variable names to signal names
 * @param groupArray - an array containing the variable names
 * @returns {Object} - an object containing legend, coefficient, and stats information
 */
export function transformTableResponse(
  { table, regressionOutput }: { table: any; regressionOutput: any },
  inputSignals: Record<string, any>,
  groupArray: string[],
) {
  const legend = _.map(inputSignals, (name, variable) => ({
    variable: `$${variable}`,
    name,
  }));

  const stats = _.map(regressionOutput, (value, name) => ({ name, value }));

  const coefficients = _.map(groupArray, (variable, index) => ({
    name: variable,
    coefficient: table.data[index][0],
    error: table.data[index][1],
    pValue: table.data[index][2],
  }));

  return {
    legend,
    stats,
    coefficients,
  };
}

/**
 * Helper function that creates the input signal group used in the prediction or prediction model formula
 */
export function createInputsGroupArray(inputSignals: Record<string, any>, option: string, polynomialValue?: number) {
  const keys = _.keys(inputSignals).sort();
  let inputs: string[] = [];
  const cross = [];

  // Linear inputs are always applied
  _.forEach(keys, function (input) {
    inputs.push(`$${input}`);
  });

  if (option === PREDICTION.SCALE.LOG) {
    _.forEach(keys, function (input) {
      inputs.push(`ln($${input}).validValues()`);
    });
  } else if (option === PREDICTION.SCALE.POLYNOMIAL) {
    _.forEach(_.range(2, (polynomialValue ?? 1) + 1), function (p) {
      _.forEach(keys, function (input) {
        inputs.push(`$${input}^${p}`);
      });
    });
  } else if (option === PREDICTION.SCALE.EXPANDED_BASIS) {
    // Polynomial order 3 params
    _.forEach(_.range(2, 3 + 1), function (p) {
      _.forEach(keys, function (input) {
        inputs.push(`$${input}^${p}`);
      });
    });

    // Only apply cross products if there are at least 2 inputs
    if (keys.length >= 2) {
      // Order 2 cross products (e.g. for three inputs: AB, AC, BC)
      _.forEach(keys, function (inputA, i) {
        _.forEach(_.slice(keys, i + 1), function (inputB) {
          cross.push(`$${inputA}*$${inputB}`);
        });
      });

      // Only apply order three cross products if there are 2 or 3 inputs
      if (keys.length <= 3) {
        // Order 3 cross products (e.g. for three inputs: ABC, A2B, A2C, B2A, B2C, C2A, C2B)
        cross.push(
          _.map(keys, function (key) {
            return `$${key}`;
          }).join('*'),
        );
        _.forEach(keys, function (inputA) {
          _.forEach(_.filter(keys, _.partial(_.negate(_.isEqual), inputA)), function (inputB) {
            cross.push(`$${inputA}^2*$${inputB}`);
          });
        });
      }

      inputs = inputs.concat(_.uniq(cross));
    }
  }

  return inputs;
}

/**
 * Helper function that creates the capsule or group used in the prediction or prediction model formula
 */
export function createCapsule(start: number, end: number, condition?: { condition: string }) {
  if (_.isNil(start) || _.isNil(end)) {
    throw new Error('windowStart and windowEnd should be defined');
  }

  const capsuleRange = getCapsuleFormula({ start, end });
  return condition ? `$condition.toGroup(${capsuleRange}, CapsuleBoundary.Intersect)` : `group(${capsuleRange})`;
}
