import React, { useState } from 'react';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import _ from 'lodash';
import { FORM_ERROR, FormElement } from '@/formbuilder/formBuilder.constants';
import { SelectOption } from '@/formbuilder/SelectFormComponent';
import { useTranslation } from 'react-i18next';
import { PostAuthError, PostAuthErrorHandlerFn, UpdateDomainFn, useLogin } from '@/main/auth/hooks/useLogin.hook';
import { isCompatibleWithUsernameAndPassword } from '@/utilities/auth.utilities';
import { SimpleSaveFormBuilder } from '@/formbuilder/SimpleSaveFormBuilder.page';
import { FakeLink } from '@/core/FakeLink';

export type LoginFormProps = {
  availableDomains: any[];
  domain: any;
  updateDomain: UpdateDomainFn;
  postAuthErrorFn: PostAuthErrorHandlerFn;
};

export const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  availableDomains,
  domain,
  updateDomain,
  postAuthErrorFn,
}) => {
  const { t } = useTranslation();

  const [userRevealedUsernameAndPassword, setUserRevealedUsernameAndPassword] = useState(false);
  const {
    username,
    password,
    loginError,
    updateFormData,
    login,
    sharedProps: { processing },
  } = useLogin({ domain, updateDomain });

  const domainOptions = availableDomains.map<SelectOption<string>>(({ name }) => ({
    label: name,
    value: name,
  }));

  /**
   * Returns whether Windows auth is enabled.
   */
  const isWindowsAuthEnabled = () =>
    _.get(_.find(domain.additionalProperties, ['name', SeeqNames.Properties.WindowsAuthMode]), 'value') === 'Enabled';

  /**
   * Returns whether the selected domain requires username and password authentication.
   */
  const requiresUsernameAndPassword = () => !isWindowsAuthEnabled() && isCompatibleWithUsernameAndPassword(domain);

  /**
   * Returns whether the username and password inputs should be displayed.
   */
  const shouldShowUsernameAndPassword = () =>
    requiresUsernameAndPassword() || (userRevealedUsernameAndPassword && isCompatibleWithUsernameAndPassword(domain));

  /**
   * Forces display of the username and password inputs.
   */
  const showEmailAndPassword = () => setUserRevealedUsernameAndPassword(true);

  const loginWithPostAuthErrorHandling = async () => {
    try {
      await login();
    } catch (e) {
      if (e instanceof PostAuthError) {
        postAuthErrorFn(e);
      } else {
        throw e;
      }
    }
  };

  const formDefinition: FormElement[] = [
    {
      component: 'SelectFormComponent',
      name: 'domains',
      testId: 'domains',
      label: 'LOGIN_PANEL.DOMAIN',
      options: domainOptions,
      value: domain.name,
      onChange: (domainName) => updateDomain(domainName as string, 'name'),
    },
    {
      component: 'DisplayOnlyFormElementWrapper',
      name: 'noFields',
      includeIf: !shouldShowUsernameAndPassword(),
      children: (
        <div>
          {isWindowsAuthEnabled() && <span>{t('LOGIN_PANEL.WINDOWS_AUTH_ENABLED')}</span>}
          {isCompatibleWithUsernameAndPassword(domain) && (
            <FakeLink onClick={showEmailAndPassword} extraClassNames="ng-scope">
              {t('LOGIN_PANEL.USE_PASSWORD_ANYWAY')}
            </FakeLink>
          )}
        </div>
      ),
    },
    {
      component: 'FormGroup',
      name: 'loginFormGroup',
      includeIf: shouldShowUsernameAndPassword(),
      components: [
        {
          component: 'FormControlFormComponent',
          name: 'username',
          testId: 'username',
          label: 'LOGIN_PANEL.EMAIL_LABEL',
          placeholder: 'LOGIN_PANEL.USERNAME_EMAIL',
          value: username,
          onChange: (username) => updateFormData({ username: username as string }),
          size: 'lg',
          autoComplete: 'username',
          required: true,
          autoFocus: true,
        },
        {
          component: 'FormControlFormComponent',
          name: 'password',
          testId: 'password',
          type: 'password',
          label: 'USER.PASSWORD',
          placeholder: 'USER.PASSWORD_PROMPT',
          value: password,
          onChange: (password) => updateFormData({ password: password as string }),
          size: 'lg',
          autoComplete: 'current-password',
          required: true,
        },
      ],
    },
    {
      component: 'ErrorMessageFormComponent',
      name: 'loginError',
      testId: 'specLoginError',
      type: FORM_ERROR,
      value: loginError,
      failForm: false,
      includeIf: !!loginError,
    },
  ];

  return (
    <SimpleSaveFormBuilder
      testId="login"
      formDefinition={formDefinition}
      submitFn={loginWithPostAuthErrorHandling}
      closeFn={() => {}}
      submitBtnLabel="LOGIN_PANEL.LOGIN"
      buttonExtraClassNames="mt25"
      hideCancel={true}
      continueProcessing={processing}
    />
  );
};
