// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Select } from '@seeqdev/qomponents';
import { COMPARISON_OPERATORS_SYMBOLS } from '@/toolSelection/investigate.constants';

interface OperatorSelectIF {
  operators: any[];
  value: any;
  onChange: (entryCondition: any) => void;
  extraClassNames?: string;
  disabled?: boolean;
}

const OperatorsSelectUnwrapped: React.FunctionComponent<OperatorSelectIF> = (props) => {
  const { operators, onChange, value, extraClassNames, disabled } = props;
  const { t } = useTranslation();

  const operatorKeys = _.invert(COMPARISON_OPERATORS_SYMBOLS);
  const formattedOperators = _.map(operators, (operator) => ({
    text: operator,
    value: operator,
    label: (
      <>
        <span className="inlineBlock width-25 text-center text-bolder text-monospace" id={operator}>
          {operator}
        </span>
        <span className="pl5">{t(`VALUE_SEARCH.OPERATORS.${operatorKeys[operator]}`)}</span>
      </>
    ),
  }));

  const selectedValue = _.find(formattedOperators, { value });
  return (
    <Select
      extraClassNames={`${extraClassNames} width-60`}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      placeholder=""
      value={_.isUndefined(selectedValue) ? null : selectedValue}
      options={formattedOperators}
      isSearchable={false}
      isDisabled={disabled}
      getSelectedValueLabel={(value: { text: string }) => {
        return value.text;
      }}
      onChange={onChange}
      inputGroup="left"
    />
  );
};

export const OperatorsSelect = React.memo(
  OperatorsSelectUnwrapped,
  (prev, next) =>
    !(
      !_.isEqual(prev.value, next.value) ||
      !_.isEqual(prev.operators, next.operators) ||
      prev.disabled !== next.disabled ||
      prev.extraClassNames !== next.extraClassNames
    ),
);
