import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface EmptyContent {
  onContentLoad: () => void;
}

/**
 * This component is for rendering empty content blobs in organizer.
 */
export const EmptyContent: React.FunctionComponent<EmptyContent> = ({ onContentLoad }) => {
  const { t } = useTranslation();
  useEffect(() => onContentLoad(), []);

  return (
    <div className="alert alert-info fs16 p25 m0 flexRowContainer flexFill flexCenter">
      <div className="flexSelfCenter">{t('TREND_HELP.CONTENT')}</div>
    </div>
  );
};
