import React from 'react';
import { Alert } from 'react-bootstrap';
import { FORM_ERROR, FORM_WARNING } from '@/formbuilder/formBuilder.constants';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface ErrorWithBackgroundProps {
  errorText: string;
  errorHeading?: string;
  onClose?: () => void;
  dismissible?: boolean;
  type?: typeof FORM_ERROR | typeof FORM_WARNING;
  errorParameters?: Record<string, string | number>;
}

/**
 * Generic error message display
 */
export const ErrorWithBackground: React.FunctionComponent<ErrorWithBackgroundProps> = (props) => {
  const { errorText, errorHeading, dismissible = false, onClose, type = FORM_ERROR, errorParameters = {} } = props;
  const { t } = useTranslation();

  return (
    <Alert
      className={classNames(type === FORM_ERROR ? 'sq-alert-danger' : 'sq-alert-warning', 'mb0')}
      variant={undefined}
      onClose={onClose}
      dismissible={dismissible}>
      {errorHeading && <div className="fs14 text-bolder mt0">{t(errorHeading)}</div>}
      <p className="max-height-120 overflowAuto text-pre-wrap breakWord">{t(errorText, errorParameters)}</p>
    </Alert>
  );
};
