import React, { useState } from 'react';
import { FORM_ERROR, FormElement } from '@/formbuilder/formBuilder.constants';
import { useAuth } from '@/main/auth/hooks/useAuth.hook';
import { SimpleSaveFormBuilder } from '@/formbuilder/SimpleSaveFormBuilder.page';
import { UpdateDomainFn } from '@/main/auth/hooks/useLogin.hook';
import { useTranslation } from 'react-i18next';

export type ForgotPasswordFormProps = {
  seeqDomain: any;
  domain: any;
  updateDomain: UpdateDomainFn;
  closeFn: () => void;
  confirmationFn: () => void;
};

export const ForgotPasswordForm: React.FunctionComponent<ForgotPasswordFormProps> = ({
  seeqDomain,
  domain,
  updateDomain,
  closeFn,
  confirmationFn,
}) => {
  const { t } = useTranslation();
  const {
    updateFormData,
    formData: { username },
    forgotPasswordError,
    requestPasswordReset,
  } = useAuth({ seeqDomain, domain, updateDomain });

  const requestResetAndShowConfirmation = () => {
    return requestPasswordReset().then(() => confirmationFn());
  };

  const [forgotEmailFocused, setForgotEmailFocused] = useState(false);

  const formDefinition: FormElement[] = [
    {
      component: 'FormControlFormComponent',
      name: 'forgotEmail',
      testId: 'forgotEmail',
      label: 'USER.EMAIL',
      placeholder: 'USER.EMAIL_PROMPT',
      type: 'email',
      value: username,
      onChange: (username) => updateFormData({ username: username as string }),
      size: 'lg',
      autoComplete: 'email',
      onFocus: () => setForgotEmailFocused(true),
      onBlur: () => setForgotEmailFocused(false),
      required: true,
      autoFocus: true,
      customErrorText: forgotEmailFocused ? 'USER.EMAIL_INVALID' : '',
    },
    {
      component: 'ErrorMessageFormComponent',
      name: 'forgotPasswordError',
      testId: 'specForgotPasswordError',
      type: FORM_ERROR,
      value: forgotPasswordError,
      failForm: false,
      includeIf: !!forgotPasswordError,
    },
  ];

  return (
    <>
      <p>{t('LOGIN_PANEL.REQUEST_RESET_DESCRIPTION')}</p>
      <SimpleSaveFormBuilder
        testId="forgotPassword"
        formDefinition={formDefinition}
        submitFn={requestResetAndShowConfirmation}
        closeFn={closeFn}
        buttonExtraClassNames="mt25"
        submitBtnLabel="LOGIN_PANEL.REQUEST_RESET"
      />
    </>
  );
};
