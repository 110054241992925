// @ts-strict-ignore TODO: CRAB-44309 Remove this and strongly type the store
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { sqDurationStore } from '@/core/core.stores';
import _ from 'lodash';

export class SelfOrganizingMapsStore extends BaseToolStore {
  static readonly storeName = 'sqSelfOrganizingMapsStore';
  type = TREND_TOOLS.SELF_ORGANIZING_MAPS;
  parameterDefinitions = {
    condition: {
      predicate: ['name', 'condition'],
    },
    inputSignals: {
      predicate: (parameter) => parameter.name !== 'condition',
      multiple: true,
    },
  };

  initialize() {
    this.state = this.immutable({
      ...BASE_TOOL_COMMON_PROPS,
      windowStart: sqDurationStore.displayRange.start.valueOf(),
      windowEnd: sqDurationStore.displayRange.end.valueOf(),
      window: this.monkey(['windowStart'], ['windowEnd'], (startTime, endTime) => ({ startTime, endTime })),
      modelPropertiesExpanded: false,
      color: '',
      anomalyThreshold: 95,
      sigma: 1.0,
      learningRate: 0.5,
      gridDimension: '',
      numIterations: '',
      randomOrder: false,
    });
  }

  get inputSignals() {
    return this.state.get('inputSignals');
  }

  get condition() {
    return this.state.get('condition');
  }

  get window() {
    return this.state.get('window');
  }

  get color(): string {
    return this.state.get('color');
  }

  get modelPropertiesExpanded(): boolean {
    return this.state.get('modelPropertiesExpanded');
  }

  get anomalyThreshold(): number {
    return this.state.get('anomalyThreshold');
  }

  get sigma(): number {
    return this.state.get('sigma');
  }

  get learningRate(): number {
    return this.state.get('learningRate');
  }

  get gridDimension(): number {
    return this.state.get('gridDimension');
  }

  get numIterations(): number {
    return this.state.get('numIterations');
  }

  get randomOrder(): boolean {
    return this.state.get('randomOrder');
  }

  dehydrate() {
    return this.state.serialize();
  }

  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  setTrainingWindow(payload) {
    this.state.set('windowStart', _.get(payload.window, 'startTime'));
    this.state.set('windowEnd', _.get(payload.window, 'endTime'));
  }

  setModelPropertiesExpanded(payload) {
    this.state.set('modelPropertiesExpanded', payload.modelPropertiesExpanded);
  }

  setAnomalyThreshold(payload) {
    this.state.set('anomalyThreshold', payload.anomalyThreshold);
  }

  setSigma(payload) {
    this.state.set('sigma', payload.sigma);
  }

  setLearningRate(payload) {
    this.state.set('learningRate', payload.learningRate);
  }

  setGridDimension(payload) {
    this.state.set('gridDimension', payload.gridDimension);
  }

  setNumIterations(payload) {
    this.state.set('numIterations', payload.numIterations);
  }

  setRandomOrder(payload) {
    this.state.set('randomOrder', payload.randomOrder);
  }

  modifyConfigParams(config: any) {
    return _.omit(config, ['modelPropertiesExpanded']);
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    SOM_SET_TRAINING_WINDOW: this.setTrainingWindow,
    SOM_SET_MODEL_PROPERTIES_EXPANDED: this.setModelPropertiesExpanded,
    SOM_SET_ANOMALY_THRESHOLD: this.setAnomalyThreshold,
    SOM_SET_SIGMA: this.setSigma,
    SOM_SET_LEARNING_RATE: this.setLearningRate,
    SOM_SET_GRID_DIMENSION: this.setGridDimension,
    SOM_SET_NUM_ITERATIONS: this.setNumIterations,
    SOM_SET_RANDOM_ORDER: this.setRandomOrder,
  };
}
