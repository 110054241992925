import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@seeqdev/qomponents';
import { exitSandboxMode } from '@/reportEditor/report.actions';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { renameWorksheet, setWorkbookName } from '@/workbook/workbook.actions';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { successToast } from '@/utilities/toast.utilities';
import { TEMP_TOPIC_NAME } from '@/reportEditor/report.constants';
import { sqReportStore, sqWorkbookStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import { getWorksheetLink } from '@/main/routing.utilities';

export const SandboxModeBanner: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sandboxMode = useFluxPath(sqReportStore, () => sqReportStore.sandboxMode);

  const [originalWorksheetName, setOriginalWorksheetName] = useState<string | undefined | null>(null);
  const [saveTooltip, setSaveTooltip] = useState<string | undefined>(undefined);
  useEffect(() => {
    const getSaveTooltip = () => {
      return t('SANDBOX_MODE.SAVE_COPY_TOOLTIP', {
        workbook: buildNewWorkbookName(),
      });
    };

    const loadOldWorksheetName = () => {
      return sqItemsApi
        .getProperty({
          id: sandboxMode.originalWorksheetId!,
          propertyName: 'Name',
        })
        .then((results) => setOriginalWorksheetName(results.data.value));
    };

    if (sandboxMode.enabled) {
      loadOldWorksheetName().then(() => setSaveTooltip(getSaveTooltip()));
    }
  });

  const buildNewWorkbookName = () => {
    const oldWorkbookName = sqWorkbookStore.name;
    return t('SANDBOX_MODE.NEW_WORKBOOK_NAME', { workbook: oldWorkbookName });
  };

  const buildNewWorksheetName = () => {
    return t('SANDBOX_MODE.NEW_WORKSHEET_NAME', {
      worksheet: originalWorksheetName,
    });
  };

  const onClickSave = () => {
    const newWorkbookName = buildNewWorkbookName();
    sqItemsApi
      .setProperty(
        { value: false },
        {
          id: sandboxMode.sandboxedWorkbookId!,
          propertyName: SeeqNames.Properties.Archived,
        },
      )
      .then(() => setWorkbookName(sandboxMode.sandboxedWorkbookId!, newWorkbookName, TEMP_TOPIC_NAME))
      .then(() => {
        navigate(getWorksheetLink(sandboxMode.sandboxedWorkbookId!, sandboxMode.sandboxedWorksheetId!));
        renameWorksheet(sandboxMode.sandboxedWorksheetId!, buildNewWorksheetName()).then(() => {
          successToast({
            messageKey: 'SANDBOX_MODE.SUCCESS_MESSAGE',
            messageParams: { newWorkbook: newWorkbookName },
          });
        });
      });
    doTrack('Sandbox Mode', 'Save a Copy');
  };

  return (
    <>
      {sandboxMode.enabled && (
        <div className="flexColumnContainer flexCenter">
          <span className="p3">
            <Icon
              icon="fc-eraser"
              tooltip={t('SANDBOX_MODE.REVERT_TOOLTIP')}
              tooltipPlacement="bottom"
              onClick={exitSandboxMode}
              type="theme-light"
              large={true}
              testId="sandboxExit"
            />
          </span>
          <span className="p3">
            <Icon
              icon="fa-copy"
              tooltip={saveTooltip}
              tooltipPlacement="bottom"
              onClick={onClickSave}
              type="theme-light"
              large={true}
              testId="sandboxSave"
            />
          </span>
        </div>
      )}
    </>
  );
};
