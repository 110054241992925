import _ from 'lodash';
import { InitializeMode, PersistenceLevel, Store } from '@/core/flux.service';
import {
  CHILD_CLONED_PROPERTIES,
  ITEM_CUSTOMIZATIONS,
  ITEM_DATA_STATUS,
  ITEM_TYPES,
  PREVIEW_ID,
  TREND_COLORS,
  TREND_NO_COLOR,
} from '@/trendData/trendData.constants';
import { COMMON_PROPS, iconClasses, PROPS_TO_DEHYDRATE, translateKeys } from '@/trendData/baseItem.constants';

/**
 * A base store that adds standard functionality for all stores that represent an item that can show up on the
 * worksheet.
 */

export type Item = Record<string, any> | any;

let colors: {
  useCount: Record<string, number>;
  lastRemoved: string | null;
} = {
  useCount: _.zipObject(TREND_COLORS, _.map(TREND_COLORS, _.constant(0))),
  lastRemoved: null,
};
const types = _.values(ITEM_TYPES);

export abstract class BaseItemStore<T = Item> extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';

  /**
   * TODO CRAB-29756: Should check if child class has an initialize function, then call it and check for required
   * properties.
   * @param initializeMode
   */
  initialize(initializeMode?: InitializeMode) {
    if (this.state) {
      _.forEach(this.state.get('items'), (item) => this.removeColor(item.color));
      colors.lastRemoved = null;
    }
    this.state = this.immutable(COMMON_PROPS);
  }

  get baseHandlers() {
    return this.handlers;
  }

  get items(): T[] {
    return this.state.get('items');
  }

  get dataStatus() {
    return this.state.get('dataStatus');
  }

  get warningCount() {
    return this.state.get('warningCount');
  }

  get warningLogs() {
    return this.state.get('warningLogs');
  }

  get timingInformation() {
    return this.state.get('timingInformation');
  }

  get meterInformation() {
    return this.state.get('meterInformation');
  }

  get isSharedRequest() {
    return this.state.get('isSharedRequest');
  }

  /**
   * Find an item by ID
   *
   * @param {String} id The ID of the item to find
   * @returns {Object} The item; or undefined if the item could not be found
   */
  findItem = (id: string | number): Item => {
    return this.findItemByProp('id', id);
  };

  /**
   * Find an item by the value of a property on the item
   *
   * @param {string} prop The property name
   * @param {string | number} val The property value
   * @returns {Object} The item; or undefined if the item could not be found
   */
  findItemByProp(prop: string, val: string | number): Item {
    const filter = { [prop]: val };
    const item = this.state.get('items', filter);

    if (_.isUndefined(item) && !_.isEmpty(this.state.get('previewChartItem'))) {
      if (_.isArray(this.state.get('previewChartItem'))) {
        return _.find(this.state.get('previewChartItem'), (item) => item[prop] === val);
      } else {
        return this.state.get('previewChartItem')[prop] === val ? this.state.get('previewChartItem') : item;
      }
    }
    return item;
  }

  /**
   * Get an item's cursor by ID. NOTE: the dynamic cursor method "select('items', { id: id })" is specifically
   * not used because it creates listeners that are extra overhead and can lead to memory leaks if not explicitly
   * released.
   *
   * @param {String} id - The ID of the item to find
   * @returns {Object} The cursor to the item
   */
  getItemCursor(id: string) {
    return this.isPreviewItem(id)
      ? this.state.select('previewChartItem')
      : this.state.select('items', this.findItemIndex(id));
  }

  findItemIndex(id: string) {
    return _.findIndex(this.state.get('items'), ['id', id]);
  }

  findChildren(id: string | number): Item[] {
    if (!id) {
      return [];
    }

    return _.filter(this.state.get('items'), (item: Item) => {
      return item.isChildOf === id || (item.otherChildrenOf && _.includes(item.otherChildrenOf as string[], id));
    });
  }

  /**
   * Determines if there is a preview series and id is preview or this is the object being updated
   *
   * @param {String} id - The ID of the item to test
   * @returns {boolean} - True if the item is the preview item or the object being updated
   */
  isPreviewItem(id: string) {
    return (
      !_.isEmpty(this.state.get('previewChartItem')) &&
      (_.startsWith(id, PREVIEW_ID) || _.endsWith(this.state.get('previewChartItem').id, id))
    );
  }

  /**
   * Creates an item object that can be stored in `state.items`. It adds properties shared by all items.
   *
   * @param {String} id - The guid
   * @param {String} name - The name
   * @param {String} itemType - The type of object. One of ITEM_TYPES
   * @param {Object} props - Any additional properties to add to the object
   *
   * @returns {Object} An object with the shared properties
   */
  createItem(id: string, name: string, itemType: keyof typeof iconClasses, props: Record<string, any>) {
    if (_.includes(types, itemType)) {
      return _.merge(
        {},
        {
          id,
          name,
          itemType,
          iconClass: iconClasses[itemType],
          translateKey: translateKeys[itemType],
          selected: false,
          autoDisabled: false,
          isArchived: false,
          color: this.getItemColor(_.get(props, 'color')),
          lastFetchRequest: '',
        },
        props,
      );
    } else {
      throw new TypeError(`Not a valid itemType.${itemType}`);
    }
  }

  /**
   * Sets the status of the item specified by the payload id to the value specified by the status parameter and
   * sets the statusMessage.
   *
   * @param {String} status - one of ITEM_DATA_STATUS, intended to be partially applied
   * @param {Object} payload - object container for arguments
   * @param {String} payload.id - guid of item
   * @param {String} [payload.message] - an optional error message to set, if none is specified the statusMessage
   *                                     is set to ''
   * @param {Number} [payload.warningCount] - an optional count of warnings returned by the backend
   * @param {Object[]} [payload.warningLogs] - an optional array of warning details returned by the backend. Note
   *                                           that warningLogs.length does not always equal warningCount
   * @param {String} [payload.timingInformation] - String providing information for timing display in the
   * requests details panel
   * @param {String} [payload.meterInformation] - String providing information for Data Read display in the
   * requests details panel
   * @param {Boolean} [payload.isSharedRequest] - Boolean flag indicating whether the item's timing and meter
   * information counts are totaled across multiple items
   */
  setDataStatusTo(
    status: string,
    payload: {
      id: string;
      message?: string;
      warningCount?: number;
      warningLogs?: Record<string, any>[];
      timingInformation?: string;
      meterInformation?: string;
      errorType?: string;
      errorCategory?: string;
      isSharedRequest?: boolean;
    },
  ) {
    const index = this.findItemIndex(payload.id);
    if (index > -1) {
      this.state.merge(['items', index], {
        dataStatus: status,
        statusMessage: _.escape(payload.message),
        warningCount: _.isNumber(payload.warningCount) ? payload.warningCount : 0,
        warningLogs: _.isArray(payload.warningLogs) ? payload.warningLogs : [],
        errorType: payload.errorType,
        errorCategory: payload.errorCategory,
        timingInformation: payload.timingInformation,
        meterInformation: payload.meterInformation,
        isSharedRequest: payload.isSharedRequest,
      });

      if (
        _.includes(
          [
            ITEM_DATA_STATUS.FAILURE,
            ITEM_DATA_STATUS.NOT_REQUIRED,
            ITEM_DATA_STATUS.REDACTED,
            ITEM_DATA_STATUS.HIDDEN_FROM_TREND,
            ITEM_DATA_STATUS.ABORTED,
          ],
          status,
        ) &&
        _.has(this.state.get(['items', index]), 'data')
      ) {
        this.state.set(['items', index, 'data'], []);
      }
    }
  }

  /**
   * This function should be overridden if the store needs to change the behavior of TREND_SET_CUSTOMIZATIONS
   *
   * Called when the customizations for an item change, should set the customizations on the item as necessary
   *
   * @param {Object} item - serialized item object
   * @param {Object} customizations - customize properties being set
   * @param {string} [parentId] - what was the id of the parent that triggered the change
   */
  protected onSetCustomizations(item: Record<string, any>, customizations: Record<string, any>) {
    this.getItemCursor(item.id).merge(customizations);
  }

  /**
   * Sets a property on an item if it finds it, but only if the property actually changed.
   *
   * @param {String} id The id of the item.
   * @param {String} property The property to set.
   * @param {*} value The value to set.
   */
  setProperty(id: string, property: string, value: any) {
    const cursor = this.getItemCursor(id);
    if (cursor.exists() && cursor.get(property) !== value) {
      cursor.set(property, value);
    }
  }

  /**
   * This function should be overridden if the store needs to change the behavior of TREND_SET_COLOR
   *
   * Called when the color for an item change, should set the color on the item as necessary
   *
   * @param {Object} item - serialized item object
   * @param {Object} color - color that should be set
   * @param {string} [parentId] - what was the id of the parent that triggered the change
   */
  protected onSetColor(item: Record<string, any>, color: string, parentId?: string) {
    this.getItemCursor(item.id).set('color', color);
  }

  /**
   * This function should be overridden if the store needs to change the behavior of TREND_SET_SELECTED
   *
   * Called when the selection for an item change, should set the color on the item as necessary
   *
   * @param {Object} item - serialized item object
   * @param {boolean} selected - selection status
   * @param {string} [parentId] - what was the id of the parent that triggered the change
   */
  protected onSetSelected(item: Record<string, any>, selected: boolean) {
    this.getItemCursor(item.id).set('selected', selected);
  }

  /**
   * Gets the next available color. We prefer reassigning the last used color because it prevents the color from
   * changing unexpectedly when the user removes and then re-adds an item such as a capsuleSeries. Otherwise we
   * find the next color that is the least-used.
   *
   * @returns {String} The next color
   */
  findNextColor() {
    let leastUsedColor = TREND_NO_COLOR;
    let minUseCount = Number.MAX_VALUE;
    if (colors.lastRemoved && !colors.useCount[colors.lastRemoved]) {
      return colors.lastRemoved;
    }

    _.forOwn(colors.useCount, function (count, color) {
      if (count < minUseCount) {
        leastUsedColor = color;
        minUseCount = count;
      }
    });

    return leastUsedColor;
  }

  /**
   * Finds a color for an item using either the specified color or the last color that was removed or the least
   * used color if the last color that was removed has already been reassigned.
   *
   * @param {String} [color] - The existing color, if not provided then the next available is found.
   *
   * @returns {String} A color
   */
  getItemColor(color?: string) {
    color = color || this.findNextColor();
    colors.lastRemoved = null;
    this.updateColorUsage(color);
    return color;
  }

  /**
   * Updates the map of which colors are in use with the specified color. Custom colors are not updated since they
   * will have been specifically chosen by the user and should not be included by findNextColor().
   *
   * @param {String} color - The color.
   */
  updateColorUsage(color: string) {
    if (_.includes(TREND_COLORS, color)) {
      colors.useCount[color] += 1;
    }
  }

  /**
   * Decrements the color usage count and sets the last removed color.
   *
   * @param {String} color - The color.
   */
  removeColor(color: string) {
    if (_.includes(TREND_COLORS, color)) {
      const colorUseCount = colors.useCount[color];
      colors.lastRemoved = color;

      if (colorUseCount) {
        colors.useCount[color] = colorUseCount - 1;
      }
    }
  }

  /**
   * Resets colors back to original state. It is called only in tests
   */
  resetColors() {
    colors = {
      useCount: _.zipObject(TREND_COLORS, _.map(TREND_COLORS, _.constant(0))),
      lastRemoved: null,
    };
  }

  setSelected(payload: { item: Record<string, any>; selected: boolean }) {
    const item = this.findItem(payload.item.id);
    if (item) {
      this.onSetSelected(item, payload.selected);
    }

    // Handle propagating selection to children
    _.forEach(this.findChildren(payload.item.id), (item) => {
      if (_.includes(CHILD_CLONED_PROPERTIES[item.childType as keyof typeof CHILD_CLONED_PROPERTIES], 'selected')) {
        this.onSetSelected(item, payload.selected);
      }
    });
  }

  /**
   * Child stores should call this function to decide if individual items should be dehydrated.
   *
   * @param {Object} item - an item to test.
   * @returns {Boolean}
   */
  shouldDehydrateItem(item: Item): boolean {
    return _.isNil(_.get(item, 'childType'));
  }

  /**
   *  Returns a dehydrated item with unneeded properties removed.
   *
   * @param {Object} item - the dehydrated item to prune.
   * @param {Array} extraPropsToDehydrate - props to dehydrate in addition to service.PROPS_TO_DEHYDRATE
   * @returns {Object} a dehydrated item without unneeded properties.
   */
  pruneDehydratedItemProps(item: Item, extraPropsToDehydrate: string[] = []) {
    const propsToDehydrate = _.concat(PROPS_TO_DEHYDRATE, extraPropsToDehydrate);
    if (item.axisAutoScale) {
      return _.pick(item, _.without(propsToDehydrate, 'yAxisConfig', 'yAxisMin', 'yAxisMax') as any);
    } else {
      return _.pick(item, propsToDehydrate as any);
    }
  }

  /**
   * Removes items.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Item[]} payload.items - An array of items to remove
   */
  removeItems(payload: { items: Item[] }) {
    let mutableItems: Item[];
    const items = _.chain(payload.items)
      .map('id')
      .map((id) => this.findItem(id))
      .compact()
      .value();

    if (items.length) {
      // For performance reasons we acquire a writable items array, mutate it, and then set it back
      mutableItems = this.state.deepClone('items');
      _.forEach(items, (item) => {
        const index = _.findIndex(mutableItems, ['id', item.id]);
        if (index >= 0) {
          mutableItems.splice(index, 1);
          this.removeColor(item.color);
        }
      });

      this.state.set('items', mutableItems);
    }
  }

  setDataStatusPresent(payload: any) {
    // return _.partial(this.setDataStatusTo, ITEM_DATA_STATUS.PRESENT);
    this.setDataStatusTo(ITEM_DATA_STATUS.PRESENT, payload);
  }

  setProperties(payload: { id: string; includeChildren?: boolean }) {
    const cursor = this.getItemCursor(payload.id);
    if (cursor.exists()) {
      _.forEach(_.omit(payload, ['id', 'includeChildren']), (value, key) => {
        if (_.isNil(value)) {
          cursor.unset(key);
        } else {
          cursor.set(key, value);
        }
      });
    }
  }

  protected readonly handlers = {
    TREND_REMOVE_ITEMS: this.removeItems,

    /**
     * Sets property values of an existing item
     *
     * @param payload {Object} Object that has an id property for the item to be updated
     *   along with any other properties that should be updated.
     * @param payload.includeChildren? - If true the property on all children of the item will also be updated
     */
    TREND_SET_PROPERTIES: (payload: { id: string; includeChildren?: boolean }) => {
      this.setProperties(payload);
      if (payload.includeChildren) {
        // Set properties for all children (i.e. for a metric)
        _.forEach(this.findChildren(payload.id), (item) => {
          this.setProperties({ ...payload, id: item.id });
        });
      }
    },

    /**
     * Sets customizations as property values of an existing item
     *
     * @param itemsPayload.items {Object[]} Objects that have an id property for the item to be updated
     *   along with any other properties that should be updated.
     */
    TREND_SET_CUSTOMIZATIONS: (itemsPayload: {
      items: {
        id: string;
        lane: string;
        axisAlign?: string;
        yAxisType?: string;
        itemType?: ITEM_TYPES;
      }[];
    }) => {
      _.forEach(itemsPayload.items, (payload) => {
        const item = this.findItem(payload.id);
        if (item) {
          const customizations = _.pick(
            payload,
            ITEM_CUSTOMIZATIONS[item.itemType as keyof typeof ITEM_CUSTOMIZATIONS],
          );
          this.onSetCustomizations(item, customizations);
        }

        let childPayload = payload;
        let omitProps = ['itemType'];
        if (payload.itemType === ITEM_TYPES.CONDITION) {
          omitProps = _.concat(omitProps, ['lane', 'axisAlign']);
        }

        childPayload = _.omit(childPayload, omitProps);

        if (_.size(childPayload) === 1) {
          return;
        }

        // Handle propagating cloned customizations to children
        // NOTE: children and parents can exist in different stores; the parent may not exist in the child's store
        _.forEach(this.findChildren(payload.id), (item) => {
          const customizations = _.pick(
            childPayload,
            _.intersection(
              ITEM_CUSTOMIZATIONS[item.itemType as keyof typeof ITEM_CUSTOMIZATIONS] as string[],
              CHILD_CLONED_PROPERTIES[item.childType as keyof typeof CHILD_CLONED_PROPERTIES] as string[],
            ),
          );

          if (!_.isEmpty(customizations)) {
            this.onSetCustomizations(item, customizations);
          }
        });
      });
    },

    /**
     * Sets the statistics for an item.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.id - Id of the item
     * @param {Object} payload.statistics - key: value for each stat
     */
    TREND_SET_STATISTICS: (payload: { id: string; statistics: Record<string, unknown> }) => {
      this.setProperty(payload.id, 'statistics', payload.statistics);
    },

    /**
     * Sets the progress for an item.
     * Only set the meterInformation and timingInformation if the payload provides them to avoid
     * unintended clearing on cancellation!
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.id - Id of the item
     * @param {Object} payload.progress - the progress percentage for item requests
     * @param {String} [payload.timingInformation] - String providing information for timing display in the
     * requests details panel
     * @param {String} [payload.meterInformation] - String providing information for Data Read display in the
     * requests details panel
     */
    TREND_SET_PROGRESS: (payload: {
      id: string;
      progress: Record<string, unknown>;
      timingInformation?: Record<string, unknown>;
      meterInformation?: Record<string, unknown>;
    }) => {
      this.setProperty(payload.id, 'progress', payload.progress);
      if (_.has(payload, 'timingInformation')) {
        this.setProperty(payload.id, 'timingInformation', payload.timingInformation);
      }
      if (_.has(payload, 'meterInformation')) {
        this.setProperty(payload.id, 'meterInformation', payload.meterInformation);
      }
    },

    /**
     * Set the color on an item.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - The id of the item to change
     * @param {String} payload.color - The color
     */
    TREND_SET_COLOR: (payload: { id: string; color: string }) => {
      const item = this.findItem(payload.id);
      if (item) {
        this.removeColor(item.color);
        this.onSetColor(item, payload.color);
        this.updateColorUsage(payload.color);
      }

      // Handle propagating colors to children
      _.forEach(this.findChildren(payload.id), (item) => {
        if (_.includes(CHILD_CLONED_PROPERTIES[item.childType as keyof typeof CHILD_CLONED_PROPERTIES], 'color')) {
          this.onSetColor(item, payload.color, payload.id);
        }
      });
    },

    /**
     * Set the selected property for multiple capsules
     * @param payload - Object container for arguments
     * @param payload.capsules - an array of capsules.
     */
    TREND_SET_SELECTED_CAPSULES: (payload: { capsules: Item[] }) => {
      const capsules = payload.capsules;
      _.forEach(capsules, (capsule) => {
        this.setSelected({ item: capsule, selected: true });
      });
    },

    /**
     * Set the selected property on an item.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.item - The item to select
     * @param {boolean} payload.selected - Selection status
     */
    TREND_SET_SELECTED: this.setSelected,

    /**
     * Swaps out the specified items from one asset for the variants based off another asset.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.swaps - The items that were swapped where the keys are the swapped out ids and the
     *   values are the corresponding swapped in ids.
     * @param {Object} payload.outAsset - Asset that was swapped out
     * @param {String} payload.outAsset.id - The ID of the asset to swapped out
     * @param {String} payload.inAsset.name - The name of the asset that was swapped out
     * @param {Object} payload.inAsset - Asset that was swapped in
     * @param {String} payload.inAsset.id - The ID of the asset that was swapped in
     * @param {String} payload.inAsset.name - The name of the asset that was swapped in
     */
    TREND_SWAP_ITEMS: (payload: { swaps: Record<string, string> }) => {
      _.forEach(payload.swaps, (swappedInId, swappedOutId) => {
        const cursor = this.getItemCursor(swappedOutId);
        if (cursor.exists()) {
          cursor.set('id', swappedInId);
        }
      });
    },

    /**
     * Updates lastFetchRequest with the current time as an ISO string
     */
    TREND_UPDATE_LAST_FETCH_REQUEST: (payload: { id: string }) => {
      this.setProperty(payload.id, 'lastFetchRequest', new Date().toISOString());
    },

    TREND_SET_DATA_STATUS_PRESENT: this.setDataStatusPresent,
    TREND_SET_DATA_STATUS_LOADING: _.partial(this.setDataStatusTo, ITEM_DATA_STATUS.LOADING),
    TREND_SET_DATA_STATUS_FAILURE: _.partial(this.setDataStatusTo, ITEM_DATA_STATUS.FAILURE),
    TREND_SET_DATA_STATUS_REDACTED: _.partial(this.setDataStatusTo, ITEM_DATA_STATUS.REDACTED),
    TREND_SET_DATA_STATUS_CANCELED: _.partial(this.setDataStatusTo, ITEM_DATA_STATUS.CANCELED),
    TREND_SET_DATA_STATUS_CANCELED_RETRIES: _.partial(this.setDataStatusTo, ITEM_DATA_STATUS.CANCELED_RETRIES),
    TREND_SET_DATA_STATUS_HIDDEN_FROM_TREND: _.partial(this.setDataStatusTo, ITEM_DATA_STATUS.HIDDEN_FROM_TREND),
    TREND_SET_DATA_STATUS_NOT_REQUIRED: _.partial(this.setDataStatusTo, ITEM_DATA_STATUS.NOT_REQUIRED),
    TREND_SET_DATA_STATUS_ABORTED: _.partial(this.setDataStatusTo, ITEM_DATA_STATUS.ABORTED),
  };
}
