// @ts-strict-ignore
import _ from 'lodash';
import { sqSystemApi } from '@/sdk/api/SystemApi';
import { ValidityEnum } from '@/sdk/model/LicenseStatusOutputV1';
import { flux } from '@/core/flux.module';
import { LICENSE_STATUS, WARNING_FLAGS } from '@/licenseManagement/licenseManagement.constants.ts';
import { sqLicenseManagementStore } from '@/core/core.stores';
import { getLicenseLink, goTo } from '@/main/routing.utilities';

export function fetchLicense() {
  return sqSystemApi.getLicense().then(({ data: license }) => {
    const cutOffs = license?.level === 'Trial' ? WARNING_FLAGS.TRIAL : WARNING_FLAGS.STANDARD;
    const isValid = _.matches({ validity: ValidityEnum.Valid });
    const needsNoWarning = _.conforms({
      daysToExpiration: (d) => d > cutOffs.SHOW_ADMIN_WARNING_DAYS_COUNT,
    });
    const needsAdminWarning = _.conforms({
      daysToExpiration: (d) => d > cutOffs.SHOW_WARNING_DAYS_COUNT,
    });
    const status = _.cond([
      [(input) => isValid(input) && needsNoWarning(input), _.constant(LICENSE_STATUS.VALID)],
      [(input) => isValid(input) && needsAdminWarning(input), _.constant(LICENSE_STATUS.SHOW_ADMIN_LICENSE_WARNING)],
      [isValid, _.constant(LICENSE_STATUS.SHOW_LICENSE_WARNING)],
      [_.matches({ validity: ValidityEnum.Expired }), _.constant(LICENSE_STATUS.EXPIRED)],
      [_.matches({ validity: ValidityEnum.NoLicense }), _.constant(LICENSE_STATUS.NONE)],
      [_.stubTrue, _.constant(LICENSE_STATUS.UNKNOWN)],
    ])(license);

    flux.dispatch('LICENSE_SET_LICENSE', license);
    flux.dispatch('LICENSE_SET_STATUS', { status });
  });
}

export function uploadLicense(file: File) {
  return sqSystemApi.importLicense({ file });
}

export function checkLicenseStatus(currentPath: string) {
  return Promise.resolve(sqLicenseManagementStore.licenseStatus ? undefined : fetchLicense()).then(() => {
    const INVALID_STATUSES = [LICENSE_STATUS.NONE, LICENSE_STATUS.UNKNOWN, LICENSE_STATUS.EXPIRED];
    if (!_.includes(currentPath, 'license') && _.includes(INVALID_STATUSES, sqLicenseManagementStore.licenseStatus)) {
      goTo(getLicenseLink());
    }
  });
}
