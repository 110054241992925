import { browserIsEdgeBeforeChromium } from '@/utilities/browserId';

/**
 * Provides access to a form of browser storage.  localStorage if available, otherwise sessionStorage.  Allows support
 * for browsers with localStorage disabled and in a form that is easily mocked.
 */

/**
 * IE throws an error if you try and access localStorage and its disabled. #@$#%!!
 */
export function getStorageSafely() {
  try {
    if (window.localStorage) {
      // Edge before chromium doesn't seem to keep localStorage synchronized
      // across tabs unless an `onstorage` handler is assigned #@$#%!!
      // REF: https://stackoverflow.com/a/42377129
      if (browserIsEdgeBeforeChromium) {
        window.onstorage = function () {};
      }
      return window.localStorage;
    } else {
      return window.sessionStorage;
    }
  } catch (e) {
    return window.sessionStorage;
  }
}
