// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { SearchTitle } from '@/workbook/SearchTitle.organism';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';

export interface SearchTitleIF extends ValidatingFormComponent<string> {
  component: 'SearchTitleFormComponent';
  id: string;
  onChange: (name: string) => void;
  searchIconClass: string;
  defaultName: string;
  onColorChange?: (color: string) => void;
  extraClassNames?: any;
}

export const SearchTitleFormComponent: React.FunctionComponent<SearchTitleIF> = (props) => {
  const { name, validation, value, testId, onChange, extendValidation, extraClassNames } = props;
  const defaultValidation = (value) => _.isEmpty(_.trim(value));
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  return (
    <div className={classNames('flexFill', extraClassNames)} data-testid={testId}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => (
          <SearchTitle
            {...getFormFieldProps(formState, input, meta, props)}
            name={value}
            setSearchName={(value) => {
              input.onChange(value);
              onChange(value);
            }}
          />
        )}
      </Field>
    </div>
  );
};
