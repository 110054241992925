import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { useTogglingSelectAll } from '@/search/hooks/useTogglingSelectAll.hook';
import { ToggleSelectAll } from '@/search/multiSelect/ToggleSelectAll.molecule';
import { SelectAndAdd } from '@/search/multiSelect/SelectAndAdd.molecule';
import { useTranslation } from 'react-i18next';
import { isAsset, isDisplay, isTableDefinition } from '@/utilities/utilities';
import { doTrack } from '@/track/track.service';
import { SearchPanes } from '@/search/search.constants';
import { getStoreForPane, toggleSelectAllItems } from '@/search/search.actions';
import { batchAddItems } from '@/trendData/trend.actions';
import { sqAssetGroupStore, sqLayoutStore } from '@/core/core.stores';
import { batchAddAssets } from '@/assetGroupEditor/assetGroup.actions';

interface TrendMultiSelectProps {
  pane: SearchPanes;
  items: any[];
}

/**
 * Manages trend multi-select
 */
export const TrendMultiSelect: React.FunctionComponent<TrendMultiSelectProps> = ({ pane, items }) => {
  const { t } = useTranslation();
  const assetGroupEditorVisible = useFluxPath(sqLayoutStore, () => sqLayoutStore.assetGroupEditorVisible);
  const store = getStoreForPane(pane);
  const selectAllChecked = useFluxPath(store, () => store.selectAllChecked);
  const selectedItemIds = useFluxPath(store, () => store.selectedItemIds);
  const assets = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.assets);

  const options = [
    { value: 'separate', label: t('MULTI_SELECT.TREND.SEPARATE') },
    { value: 'same', label: t('MULTI_SELECT.TREND.SAME') },
  ];

  const selectableItems = useMemo(
    () =>
      items?.filter(
        (item) =>
          (!isAsset(item) || assetGroupEditorVisible) &&
          !isDisplay(item) &&
          !isTableDefinition(item) &&
          !item.hasChildren,
      ),
    [items, assetGroupEditorVisible],
  );

  const [isAssetAndItemSelected, isOnlyAssetsSelected] = useMemo(() => {
    if (!items.length || !selectedItemIds.length) return [false, null];

    const firstSelectedItem = items?.find((item) => item.id === selectedItemIds[0]);
    const curr = isAsset(firstSelectedItem) || firstSelectedItem.hasChildren ? 1 : 0;
    for (let i = 0; i < selectedItemIds.length; i++) {
      const item = items?.find((item) => item.id === selectedItemIds[i]);
      const newCurr = isAsset(item) || item?.hasChildren ? 1 : 0;
      if (curr !== newCurr) {
        return [true, null];
      }
    }

    return [false, curr === 1];
  }, [selectedItemIds, items]);

  const [selectActionOptions, setSelectActionOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (!assetGroupEditorVisible) return;
    switch (isOnlyAssetsSelected) {
      case true: {
        const options = [{ value: 'addAll', label: t('MULTI_SELECT.ASSET_GROUP.ADD_ALL') }];
        if (assets?.length > 0) {
          options.push({
            value: 'addMatching',
            label: t('MULTI_SELECT.ASSET_GROUP.ADD_MATCHING'),
          });
        }
        setSelectActionOptions(options);
        break;
      }

      case false:
        setSelectActionOptions([
          {
            value: 'addAll',
            label: t('MULTI_SELECT.ASSET_GROUP.ADD_ALL_ITEMS'),
          },
        ]);
        break;

      default:
        setSelectActionOptions([]);
        break;
    }
  }, [assets?.length, isOnlyAssetsSelected]);

  const { togglingSelectAll, setTogglingSelectAll } = useTogglingSelectAll({
    items: assetGroupEditorVisible ? items : selectableItems,
  });

  const showOnlyAssetGroupActions = selectableItems.length === 0;
  // eslint-disable-next-line no-nested-ternary
  const displayOptions = showOnlyAssetGroupActions
    ? selectActionOptions
    : assetGroupEditorVisible
    ? [
        {
          label: t('MULTI_SELECT.TREND.HEADER'),
          options,
        },
        {
          label: t('MULTI_SELECT.ASSET_GROUP.HEADER'),
          options: selectActionOptions,
        },
      ]
    : options;

  const isSelectAllDisabled = selectableItems.length === 0;
  const [selectedValue, setSelectedValue] = useState(options[0]);

  useEffect(() => {
    // eslint-disable-next-line no-nested-ternary
    const selected = !assetGroupEditorVisible
      ? options[0]
      : showOnlyAssetGroupActions
      ? selectActionOptions[0]
      : options[0];
    setSelectedValue(selected);
  }, [selectActionOptions]);

  return (
    <div className="flexColumnContainer flexAlignCenter" data-testid="multiSelectActions">
      <ToggleSelectAll
        togglingSelectAll={togglingSelectAll}
        isChecked={selectAllChecked}
        onClick={() => {
          setTogglingSelectAll(true);
          toggleSelectAllItems(assetGroupEditorVisible ? items : selectableItems);
        }}
        isDisabled={isSelectAllDisabled}
      />
      <SelectAndAdd
        selectedItemIds={selectedItemIds}
        options={displayOptions}
        onChange={setSelectedValue}
        selectedValue={selectedValue}
        onAddClick={() => {
          const selectedItems = selectedItemIds
            .map((id: string) => _.find(selectableItems, { id }))
            .filter((item: string) => !!item);
          if (_.get(selectedValue, 'value', '') === 'separate') {
            batchAddItems(selectedItems);
            doTrack('Multi-Select', 'Add items to separate lanes');
          } else if (_.get(selectedValue, 'value', '') === 'addAll') {
            batchAddAssets({ items, assetIds: selectedItemIds });

            doTrack('Multi-Select', 'Add all items or all items under assets');
          } else if (_.get(selectedValue, 'value', '') === 'addMatching') {
            batchAddAssets({
              items,
              assetIds: selectedItemIds,
              matchingOnly: true,
            });
            doTrack('Multi-Select', 'Add only matching items');
          } else {
            batchAddItems(selectedItems, false);
            doTrack('Multi-Select', 'Add items to same lane and axis');
          }
        }}
        isSelectAllDisabled={isSelectAllDisabled}
        isAssetAndItemSelected={isAssetAndItemSelected && assetGroupEditorVisible}
      />
    </div>
  );
};
