// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntervalWhen } from 'rooks';
import _ from 'lodash';
import { ButtonWithPopover, Icon } from '@seeqdev/qomponents';
import {
  datasourceDisplayName,
  DatasourceStatus,
  DatasourceStatusIcons,
  fetchDatasourcesImmediately,
  filterAndSortDatasources,
  FilterParameters,
  getDatasourceItemsCount,
  getDatasourceStatus,
} from '@/utilities/datasources.utilities';
import { DatasourcesStatusOutputV1 } from '@/sdk/model/DatasourcesStatusOutputV1';
import { DatasourceSummaryStatusOutputV1 } from '@/sdk/model/DatasourceSummaryStatusOutputV1';
import { isAdmin } from '@/services/authorization.service';

export const DatasourcesStatus: React.FunctionComponent = () => {
  const [popupShown, setPopupShown] = useState(false);
  const { t } = useTranslation();
  const [datasourcesContainer, setDatasourcesContainer] = useState(null);
  const [fetchErrors, setFetchErrors] = useState(false);

  const fetchDatasources = () => {
    fetchDatasourcesImmediately()
      .then((datasourcesStatus) => {
        setDatasourcesContainer(datasourcesStatus);
        setFetchErrors(false);
      })
      .catch(() => setFetchErrors(true));
  };

  useEffect(() => {
    if (popupShown) {
      fetchDatasources();
    }
  }, [popupShown]);

  useIntervalWhen(fetchDatasources, 2000, popupShown);

  const datasourcesStatusMessages = new Map([
    [DatasourceStatus.Unknown, 'DATASOURCES.DISCONNECTED'],
    [DatasourceStatus.New, 'DATASOURCES.PENDING_DATASOURCE'],
    [DatasourceStatus.Error, 'DATASOURCES.DISCONNECTED'],
    [DatasourceStatus.Warning, 'DATASOURCES.INDEX_SUMMARY'],
    [DatasourceStatus.Indexing, 'DATASOURCES.INDEX_PROGRESS'],
    [DatasourceStatus.Happy, 'DATASOURCES.INDEX_SUMMARY'],
    [DatasourceStatus.NotConnectable, 'DATASOURCES.DISABLED'],
  ]);

  const emptyFilters: FilterParameters = {
    name: '',
    datasourceClass: '',
    datasourceId: '',
    agentName: '',
    status: '',
    metricsTimeRange: '',
    datasourceLabels: [],
  };

  const emptyDatasourceContainer: DatasourcesStatusOutputV1 = {
    connectedConnectionsCount: 0,
    connectedDatasourcesCount: 0,
    totalConnectionsCount: 0,
    totalDatasourcesCount: 0,
    datasources: [],
    agents: [],
  };

  const renderAdministrationLink = () => {
    if (isAdmin()) {
      return (
        <div className="text-center pt7">
          <a href="/administration" target="_blank">
            <span>{t('DATASOURCES.MANAGEMENT')}</span>
          </a>
        </div>
      );
    }
  };

  const renderDatasourcesTray = (dsContainer: DatasourcesStatusOutputV1) => {
    const datasourcesStatuses = new Map();
    _.forEach(dsContainer.datasources, (ds) => datasourcesStatuses.set(ds, getDatasourceStatus(ds)));

    const getDatasourceStatusMessages = (ds: DatasourceSummaryStatusOutputV1) => {
      const status = datasourcesStatuses.get(ds);
      const messagePlaceholder = datasourcesStatusMessages.get(status);
      const args = {};
      if (status === DatasourceStatus.Happy || status === DatasourceStatus.Warning) {
        args['count'] = getDatasourceItemsCount(ds);
      }
      return t(messagePlaceholder, args);
    };

    const renderDatasource = (ds: DatasourceSummaryStatusOutputV1) => {
      const status = datasourcesStatuses.get(ds);
      const icon = DatasourceStatusIcons.get(status);
      const message = getDatasourceStatusMessages(ds);
      return (
        <div className="fakeTableRow fakeTableStriped flexColumnContainer flexNoGrowNoShrink" key={ds.id}>
          <div className="width-300 p7 breakWord">{datasourceDisplayName(ds)}</div>
          <div className="p7">
            <Icon icon={icon} type="inherit" />
            <span className="ml5">{message}</span>
          </div>
        </div>
      );
    };

    const renderDatasources = () => {
      const enabledDatasources = _.filter(
        dsContainer.datasources,
        (ds) => getDatasourceStatus(ds) !== DatasourceStatus.NotConnectable,
      );
      const filteredDatasources = filterAndSortDatasources(enabledDatasources, emptyFilters);
      return (
        <div className="flexRowContainer fakeTableWrapper" data-testid="datasourcesList">
          {_.map(filteredDatasources, renderDatasource)}
        </div>
      );
    };

    const renderHeader = () => {
      return (
        <div className="flexColumnContainer fakeTableHeader">
          <div className="width-300 pt7 pr7 pb2 pl7">{t('NAME')}</div>
          <div className="nowrap pt7 pr7 pb2 pl7">{t('STATUS')}</div>
        </div>
      );
    };

    return (
      <ButtonWithPopover
        isOpen={popupShown}
        trigger={
          <div className="ml3" id="toggleDatasources" data-testid="datasourcesStatus">
            <span className="d-none d-sm-inline-block list-group-item-text ">{t('DATASOURCES.TITLE')}</span>
          </div>
        }
        hasArrow
        tooltip={t('DATASOURCES.TOOLTIP')}
        onOpenChange={(isOpen) => setPopupShown(isOpen)}
        placement="top">
        <div id="dataSourcesPopup" className=" min-width-500">
          <div className="flexRowContainer p10">
            {!fetchErrors && dsContainer.totalConnectionsCount !== 0 && renderHeader()}
            {!fetchErrors && dsContainer.totalConnectionsCount !== 0 && renderDatasources()}
            {fetchErrors && (
              <div className="flexColumnContainer sq-text-danger cursorPointer pt3 pb6">
                <Icon icon="fa-exclamation-triangle" type="danger" extraClassNames="flexCenter pl5 pr10 pt2" />
                <div className="flexFill pl5">{t('DATASOURCES.FETCH_ERROR')}</div>
              </div>
            )}
            {renderAdministrationLink()}
          </div>
        </div>
      </ButtonWithPopover>
    );
  };

  return datasourcesContainer
    ? renderDatasourcesTray(datasourcesContainer)
    : renderDatasourcesTray(emptyDatasourceContainer);
};
