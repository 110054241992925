import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import type { ChartToolPanelsDef, ColDef, GridApi, GridOptions } from '@ag-grid-community/core';
import _ from 'lodash';
import { setChartViewConditionSettings } from '@/tableBuilder/tableBuilder.actions';
import { formatNumber } from '@/utilities/numberHelper.utilities';
import { TREND_COLORS } from '@/trendData/trendData.constants';
import type { AgChartTheme } from '@ag-grid-enterprise/charts';

export const persistChart = (isInteractiveContent: boolean, api: GridApi<any> | undefined) => {
  if (!isInteractiveContent && !headlessRenderMode()) {
    setChartViewConditionSettings(_.first(api?.getChartModels()));
  }
};

export const regenerateChart = (containerId: string, api: GridApi<any> | undefined, columnDefs: ColDef<any, any>[]) => {
  api?.createRangeChart({
    chartContainer: document.querySelector(containerId) as HTMLElement,
    cellRange: {
      columns: columnDefs.map((column) => column.colId) as string[],
    },
    chartType: 'stackedColumn',
  });
};

const formatChartValue = (value: number | string) => (typeof value === 'number' ? formatNumber(value) : value);

const createSeriesConfig = () => ({
  tooltip: {
    renderer: (params: any) => {
      const formattedContent = `${params.datum[params.xKey]}: ${formatChartValue(params.datum[params.yKey])}`;
      return {
        title: params.datum.title,
        content: formattedContent,
      };
    },
  },
  label: {
    formatter: (params: any) => formatChartValue(params.value),
  },
});

export const getSeeqAgChartTheme = (darkMode: boolean, isInteractiveContent: boolean) =>
  ({
    palette: {
      fills: TREND_COLORS,
      strokes: TREND_COLORS,
    },
    overrides: {
      common: {
        axes: {
          category: {
            label: {
              color: darkMode ? '#C2BCB0' : '#242525',
              fontFamily: 'Source Sans Pro, Helvetica Nueu, Arial sans-serif',
            },
          },
          number: {
            label: {
              color: darkMode ? '#C2BCB0' : '#242525',
              fontFamily: 'Source Sans Pro, Helvetica Nueu, Arial sans-serif',
            },
          },
        },
        padding: { right: 40 },
        background: {
          fill: darkMode ? '#242525' : 'white',
        },
        title: {
          color: darkMode ? '#C2BCB0' : 'black',
          enabled: true,
          fontSize: 16,
          fontFamily: 'Source Sans Pro, Helvetica Nueu, Arial sans-serif',
          text: 'Condition Table Chart',
        },
        series: {
          label: {
            enabled: !!(!headlessRenderMode() && !isInteractiveContent),
            fontSize: 12,
            fontFamily: 'Source Sans Pro, Helvetica Nueu, Arial sans-serif',
            color: darkMode ? '#C2BCB0' : 'black',
          },
        },
        legend: {
          enabled: true,
          spacing: 30,
          item: {
            label: {
              fontSize: 12,
              fontFamily: 'Source Sans Pro, Helvetica Nueu, Arial sans-serif',
              color: darkMode ? '#C2BCB0' : 'black',
            },
          },
        },
      },
    },
  } as AgChartTheme);

export const gridOptions: GridOptions = {
  chartThemeOverrides: {
    bar: {
      series: createSeriesConfig(),
    },
    line: {
      series: createSeriesConfig(),
    },
    area: {
      series: createSeriesConfig(),
    },
    pie: {
      series: {
        tooltip: {
          renderer: (params) => ({
            title: params.datum.title,
            content: formatChartValue(params.datum[params.sectorLabelKey!]),
          }),
        },
        sectorLabel: {
          formatter: (params) => formatChartValue(params.value),
        },
      },
    },
    donut: {
      series: {
        tooltip: {
          renderer: (params) => {
            const formattedContent = `${params.datum[params.calloutLabelKey!]}: ${formatChartValue(
              params.datum[params.sectorLabelKey!],
            )}`;

            return {
              title: params.datum.title,
              content: formattedContent,
            };
          },
        },
        sectorLabel: {
          formatter: (params) => formatChartValue(params.value),
        },
      },
    },
    histogram: {
      series: {
        tooltip: {
          renderer: (params) => {
            const formattedTitle = `${params.xName}: ${params.datum.domain[0]} - ${params.datum.domain[1]}`;
            return {
              title: formattedTitle,
            };
          },
        },
        label: {
          formatter: (params) => formatChartValue(params.value),
        },
      },
    },
  },
};

export const chartToolPanelsDef: ChartToolPanelsDef = {
  settingsPanel: {
    chartGroupsDef: {
      columnGroup: ['column', 'stackedColumn', 'normalizedColumn'],
      barGroup: ['bar', 'stackedBar', 'normalizedBar'],
      pieGroup: ['donut', 'pie'],
      scatterGroup: ['scatter', 'bubble'],
      hierarchicalGroup: ['treemap', 'sunburst'],
      lineGroup: ['line'],
      specializedGroup: ['waterfall', 'heatmap'],
      areaGroup: ['area', 'stackedArea', 'normalizedArea'],
      polarGroup: ['radarLine', 'radarArea', 'nightingale', 'radialColumn', 'radialBar'],
      statisticalGroup: ['histogram', 'boxPlot', 'rangeBar', 'rangeArea'],
    },
  },
  dataPanel: {
    groups: [
      { type: 'seriesChartType', isOpen: true },
      { type: 'categories', isOpen: true },
      { type: 'series', isOpen: true },
    ],
  },
  formatPanel: {
    groups: [
      { type: 'chart', isOpen: false },
      { type: 'titles', isOpen: false },
      { type: 'legend', isOpen: false },
      { type: 'horizontalAxis', isOpen: false },
      { type: 'verticalAxis', isOpen: false },
      { type: 'series', isOpen: false },
    ],
  },
};
