import { TOGGLE_CK_SAVING } from '@/annotation/annotation.constants';
import { DecoupledEditor } from '@ckeditor/ckeditor5-editor-decoupled';

export type DashboardEditorId = `dashboardEditor-${string}`;
export type CkEditorInstanceId =
  | 'journalEditor'
  | 'reportEditor'
  | 'reportTemplateEditor'
  | 'notificationEditor'
  | DashboardEditorId;

const ckEditorInstanceMap: Map<CkEditorInstanceId, DecoupledEditor | undefined> = new Map();

/*** Set the ckEditor instance for a given CkEditorInstanceId, so it can be referenced elsewhere. ***/
export function setCKEditorInstance(
  instance: DecoupledEditor | undefined,
  editorId: CkEditorInstanceId,
): DecoupledEditor | undefined {
  exposedForTesting.ckEditorInstanceMap.set(editorId, instance);
  return instance;
}

/*** Get the ckEditor instance for a given CkEditorInstanceId ***/
export function getCKEditorInstance(id: CkEditorInstanceId): DecoupledEditor | undefined {
  return exposedForTesting.ckEditorInstanceMap.get(id);
}

/**
 * Replaces all the html with a new html string
 *
 * @param html - the html to set
 * @param editorId - the DecoupledEditor instance to set the html on
 * @param forceUpdate? - force CK to trigger an update on setting the HTML
 */
export function setHtmlCKEditor(html: string, editorId: CkEditorInstanceId, forceUpdate?: boolean) {
  const ckeditorInstance = getCKEditorInstance(editorId);
  if (ckeditorInstance) {
    // CRAB-25207: CK triggers an update event on `setData`, and we don't want fast followers to trigger updates
    // on receiving the latest document
    !forceUpdate && ckeditorInstance.fire && ckeditorInstance.fire(TOGGLE_CK_SAVING, false);
    ckeditorInstance.setData(html ?? '');
    !forceUpdate && ckeditorInstance.fire && ckeditorInstance.fire(TOGGLE_CK_SAVING, true);
  }
}

export const exposedForTesting = {
  ckEditorInstanceMap,
};
