// @ts-strict-ignore
import _ from 'lodash';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.constants.ts';

/*
 * This list is for convenience within Webserver to reference known licensed features. It is not strictly
 * necessary to add to this list, you can refer to the feature by string (instead of this enum).
 * The master list is the "salesforce_to_seeq_mapping" variable in licensing.py.
 */
export enum Feature {
  ODATA_Realtime_Export,
  Data_Lab,
  WITSML_Connector,
  AF_Data_Reference,
  Audit_Trail,
  Seeq_Connector_Plus_PI_EF,
  Asset_Group,
  Write_To_PI,
  Marketplace_6001,
  Marketplace_6002,
  Marketplace_6003,
  Marketplace_6004,
  Marketplace_6005,
  Marketplace_6006,
  Marketplace_6007,
  Marketplace_6008,
  Marketplace_6009,
  Marketplace_6010,
  Seeq_Preview,
  ThirdParty_DataSharing,
  Vantage,
  Locking,
}

export class LicenseManagementStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqLicenseManagementStore';

  initialize() {
    this.state = this.immutable({
      licenseStatus: undefined,
      license: {},
    });
  }

  get licenseStatus() {
    return this.state.get('licenseStatus');
  }

  get license() {
    return this.state.get('license');
  }

  get isTrial() {
    return _.get(this.state.get('license'), 'level') === 'Trial';
  }

  hasValidFeature(feature: Feature) {
    return _.some(
      this.state.get(['license', 'additionalFeatures']),
      (licensedFeature) => licensedFeature.name === Feature[feature] && licensedFeature.validity === 'Valid',
    );
  }

  getFeature(name: string) {
    return _.chain(this.state.get(['license', 'additionalFeatures']))
      .filter((licensedFeature) => licensedFeature.name.toLowerCase() === name.toLowerCase())
      .first()
      .value();
  }

  get displayLicenseWarning() {
    const warningLevels = [LICENSE_STATUS.SHOW_ADMIN_LICENSE_WARNING, LICENSE_STATUS.SHOW_LICENSE_WARNING];
    return _.includes(warningLevels, this.state.get('licenseStatus'));
  }

  canAddUsers() {
    const license = this.state.get('license');
    return !license.userLimitRestrict || license.userLimitRestrict > license.userCount;
  }

  hasAssetGroups() {
    return _.some(
      this.state.get(['license', 'additionalFeatures']),
      (licensedFeature) =>
        licensedFeature.name === Feature[Feature.Asset_Group] && licensedFeature.validity === 'Valid',
    );
  }

  protected readonly handlers = {
    LICENSE_SET_LICENSE: (license) => this.state.set('license', license),
    LICENSE_SET_STATUS: ({ status }) => this.state.set('licenseStatus', status),
  };
}
