import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { sqWorkbenchStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { isCsrfSet } from '@/utilities/auth.utilities';
import { setCurrentUser } from '@/workbench/workbench.actions';
import { UnsubscribeDone } from '@/notifications/UnsubscribeDone.molecule';
import { ConfirmUnsubscribeLoggedIn } from '@/notifications/ConfirmUnsubscribeLoggedIn.molecule';
import { ConfirmUnsubscribeNotLoggedIn } from '@/notifications/ConfirmUnsubscribeNotLoggedIn.molecule';
import _ from 'lodash';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { UnsubscribeOutputV1 } from 'sdk/model/UnsubscribeOutputV1';
import { sqNotificationConfigurationsApi } from '@/sdk/api/NotificationConfigurationsApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { errorToast } from '@/utilities/toast.utilities';
import seeqLogoDarkBlueSm from 'resources/img/Seeq_logo_darkBlue_sm.png';
import { useTranslation } from 'react-i18next';

export const Unsubscribe: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const userEmail = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser.email);
  const { notificationId } = useParams();
  const [searchParams] = useSearchParams();
  const name = searchParams.get(SeeqNames.API.QueryParams.Name) ?? '';
  const creator = searchParams.get(SeeqNames.API.QueryParams.Creator) ?? '';
  const admin = searchParams.get(SeeqNames.API.QueryParams.Admin) ?? '';

  const [isLoggedIn, setLoggedIn] = useState<boolean>();
  const [unsubscribedSuccessful, setUnsubscribedSuccessful] = useState(false);
  const [removedEntries, setRemovedEntries] = useState(0);

  const handleUnsubscribeClick = async () => {
    if (!_.isNil(notificationId)) {
      try {
        const response = await sqNotificationConfigurationsApi.unsubscribeFromItem({ id: notificationId });
        const unsubscribeResponse = response.data as UnsubscribeOutputV1;
        setRemovedEntries(unsubscribeResponse.removedEntries);
        setUnsubscribedSuccessful(true);
      } catch (error) {
        errorToast({ httpResponseOrError: error, displayForbidden: true });
      }
    }
  };

  useEffect(() => {
    document.body.classList.add('color_topic');

    if (isCsrfSet()) {
      if (userEmail) {
        return setLoggedIn(true);
      }

      // To get the authenticated user email (since this page exists outside 'WorkbenchWrapperPage')
      setCurrentUser()
        .then(() => setLoggedIn(true))
        .catch(() => setLoggedIn(false));
    } else {
      setLoggedIn(false);
    }
  }, []);

  const renderMainContent = () => {
    if (_.isNil(isLoggedIn)) {
      return <IconWithSpinner testId="loading" spinning={true} />;
    }

    if (!isLoggedIn) {
      return <ConfirmUnsubscribeNotLoggedIn creator={creator} admin={admin} />;
    }

    if (!unsubscribedSuccessful) {
      return (
        <ConfirmUnsubscribeLoggedIn
          creator={creator}
          admin={admin}
          notificationName={name}
          userEmail={userEmail}
          handleUnsubscribeClick={handleUnsubscribeClick}
        />
      );
    }

    return (
      <UnsubscribeDone
        message={
          removedEntries > 0
            ? t('UNSUBSCRIBE.SUCCESSFUL_MESSAGE')
            : t('UNSUBSCRIBE.UNSUCCESSFUL_MESSAGE', { creator, admin })
        }
        title={t(removedEntries > 0 ? 'UNSUBSCRIBE.SUCCESSFUL_TITLE' : 'UNSUBSCRIBE.UNSUCCESSFUL_TITLE')}
      />
    );
  };

  return (
    <div data-testid="unsubscribePage" className="flexColumnContainer flexCenter splashScreen fullViewport">
      <div className="flexCenter jumbotron max-width-500">
        <div data-testid="mainContent" className="text-center">
          <img src={seeqLogoDarkBlueSm} />
          <hr className=" width-100percent" />
          {renderMainContent()}
        </div>
      </div>
    </div>
  );
};
