export const INTERPOLATION_TYPES = {
  LINEAR: 'Linear',
  STEP: 'Step',
  PILINEAR: 'PILinear',
  DISCRETE: 'Discrete',
};

export const INTERPOLATION_METHODS = [
  {
    text: 'IMPORTS.INTERPOLATION.LINEAR',
    value: INTERPOLATION_TYPES.LINEAR,
  },
  {
    text: 'IMPORTS.INTERPOLATION.STEP',
    value: INTERPOLATION_TYPES.STEP,
  },
  {
    text: 'IMPORTS.INTERPOLATION.PILINEAR',
    value: INTERPOLATION_TYPES.PILINEAR,
  },
  {
    text: 'IMPORTS.INTERPOLATION.DISCRETE',
    value: INTERPOLATION_TYPES.DISCRETE,
  },
];

export const SAMPLE_VALUE_ERROR = {
  EMPTY: 'empty',
  INVALID_TYPE: 'invalidType',
};

export interface ManualSample {
  id?: string;
  value: string;
  dateTime: number;
}
