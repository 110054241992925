// @ts-strict-ignore
import _ from 'lodash';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { BaseToolStore, ParameterDefinitions } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';

const VERSION_TWO = 'V2';
const VERSION_THREE = 'V3';

interface Config {
  limitsParams?: {
    entryCondition?: {
      durationUnits: string;
      operator: string;
      value: string;
      durationValue: number;
      duration: string | Record<string, unknown>;
      value2?: string;
    };
    exitCondition?: {
      durationUnits: string;
      operator: string;
      value: string;
      durationValue: number;
      duration: string | Record<string, unknown>;
    };
  };
  type?: string;
  version?: string;
  isSimple?: boolean;
  simpleOperator?: string;
  simpleValue?: string;
  simpleLowerValue?: string;
  minDuration?: string | Record<string, unknown>;
  mergeDuration?: string | Record<string, unknown>;
  advancedEntryOperator?: string;
  advancedEntryValue?: string;
  advancedEntryLowerValue?: string;
  advancedEntryDuration?: string | Record<string, unknown>;
  advancedExitOperator?: string;
  advancedExitValue?: string;
  advancedExitDuration?: string | Record<string, unknown>;
}

export const DEFAULT_DURATION = { value: 0, units: 'min' };

export class ValueSearchStore extends BaseToolStore {
  static readonly storeName = 'sqValueSearchStore';
  parameterDefinitions: ParameterDefinitions = {
    inputSignal: { predicate: ['name', 'a'] },
  };
  limitsParams: Record<string, unknown>;
  type = TREND_TOOLS.VALUE_SEARCH;

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, {
        ...this.parameterDefinitions,
        isSimple: true,
        useValidValues: false,
        isCleansing: false,
        simpleOperator: undefined,
        simpleValue: null,
        simpleUpperValueInclusivity: undefined,
        simpleLowerValue: null,
        simpleLowerValueInclusivity: undefined,
        minDuration: DEFAULT_DURATION,
        mergeDuration: DEFAULT_DURATION,
        advancedEntryOperator: undefined,
        advancedEntryValue: null,
        advancedEntryLowerValue: null,
        advancedEntryDuration: DEFAULT_DURATION,
        advancedExitOperator: undefined,
        advancedExitValue: null,
        advancedExitDuration: DEFAULT_DURATION,
        isMigratedDeviationSearch: false,
        version: VERSION_THREE,
      }),
    );
  }

  get isSimple() {
    return this.state.get('isSimple');
  }

  get inputSignal() {
    return this.state.get('inputSignal');
  }

  get isCleansing() {
    return this.state.get('isCleansing');
  }

  get useValidValues() {
    return this.state.get('useValidValues');
  }

  get simpleOperator() {
    return this.state.get('simpleOperator');
  }

  get simpleValue() {
    return this.state.get('simpleValue');
  }

  get simpleUpperValueInclusivity() {
    return this.state.get('simpleUpperValueInclusivity');
  }

  get simpleLowerValue() {
    return this.state.get('simpleLowerValue');
  }

  get simpleLowerValueInclusivity() {
    return this.state.get('simpleLowerValueInclusivity');
  }

  get minDuration() {
    return this.state.get('minDuration');
  }

  get mergeDuration() {
    return this.state.get('mergeDuration');
  }

  get advancedEntryOperator() {
    return this.state.get('advancedEntryOperator');
  }

  get advancedEntryValue() {
    return this.state.get('advancedEntryValue');
  }

  get advancedEntryLowerValue() {
    return this.state.get('advancedEntryLowerValue');
  }

  get advancedEntryDuration() {
    return this.state.get('advancedEntryDuration');
  }

  get advancedExitOperator() {
    return this.state.get('advancedExitOperator');
  }

  get advancedExitValue() {
    return this.state.get('advancedExitValue');
  }

  get advancedExitDuration() {
    return this.state.get('advancedExitDuration');
  }

  get isMigratedDeviationSearch() {
    return this.state.get('isMigratedDeviationSearch');
  }

  /**
   * Exports the state that is not ephemeral (such as progress indicators).
   *
   * @returns {Object} The dehydrated parameters.
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Re-creates the value search store.
   *
   * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  /**
   * Update UI config property when its loaded
   * @param  {Object} oldConfig - containing the conditions to remap
   * @return {Object} result of the remapping (if it was necessary)
   */
  migrateSavedConfig(oldConfig: Config) {
    const newConfig = this.migrateSavedConfig1(oldConfig);

    // using switch statement so future upgrades can be added here
    switch (oldConfig.version) {
      case undefined: {
        const v2Config = this.updateToSaveConfigV2(newConfig);
        return this.updateToSaveConfigV3(v2Config);
      }
      case VERSION_TWO:
        return this.updateToSaveConfigV3(newConfig);
      default:
        return newConfig;
    }
  }

  /**
   * Remaps the entry and exit conditions from the store in the format { durationValue, durationUnits, duration }
   * to { duration: { value, units } } to maintain compatibility with older versions. This function is called by the
   * baseToolStore as it rehydrates the payload
   * @param  {Object} oldConfig - containing the conditions to remap
   * @return {Object} result of the remapping (if it was necessary)
   */
  migrateSavedConfig1(oldConfig: Config) {
    // Keeping this old config just to be safe, but bypassing it if we have a V3 config already
    if (oldConfig.version === VERSION_THREE) {
      return oldConfig;
    }

    _.forEach([oldConfig?.limitsParams?.entryCondition, oldConfig?.limitsParams?.exitCondition], function (condition) {
      if (!_.isUndefined(condition?.durationValue) && !_.isUndefined(condition?.durationUnits)) {
        condition.duration = {
          units: condition?.durationUnits,
          value: condition?.durationValue,
        };
        delete condition?.durationUnits;
        delete condition?.durationValue;
      }
    });

    return oldConfig;
  }

  /**
   * Remaps config version to version 2
   *
   * @param  {Object} oldConfig - containing the conditions to remap
   * @return {Object} result of the remapping (if it was necessary)
   */

  updateToSaveConfigV2(oldConfig: Config) {
    oldConfig.version = VERSION_TWO;

    if (oldConfig?.limitsParams?.entryCondition?.operator === '=') {
      oldConfig.limitsParams.entryCondition.operator = '~=';
    } else if (oldConfig?.limitsParams?.entryCondition?.operator === '!=') {
      oldConfig.limitsParams.entryCondition.operator = '!~';
    }

    if (oldConfig?.limitsParams?.exitCondition?.operator === '=') {
      oldConfig.limitsParams.exitCondition.operator = '~=';
    } else if (oldConfig?.limitsParams?.exitCondition?.operator === '!=') {
      oldConfig.limitsParams.exitCondition.operator = '!~';
    }

    // If we have an existing value search, we want it to display in advanced mode
    if (_.isUndefined(oldConfig.isSimple)) {
      oldConfig.isSimple = false;
    }

    return oldConfig;
  }

  updateToSaveConfigV3(v2Config: Config) {
    v2Config.version = VERSION_THREE;

    if (v2Config.isSimple) {
      v2Config.simpleOperator = v2Config?.limitsParams?.entryCondition?.operator;
      v2Config.simpleValue = v2Config?.limitsParams?.entryCondition?.value;
      v2Config.simpleLowerValue = v2Config?.limitsParams?.entryCondition?.value2;
      v2Config.minDuration = v2Config?.limitsParams?.entryCondition?.duration;
      v2Config.mergeDuration = v2Config?.limitsParams?.exitCondition?.duration;
    } else {
      v2Config.advancedEntryOperator = v2Config?.limitsParams?.entryCondition?.operator;
      v2Config.advancedEntryValue = v2Config?.limitsParams?.entryCondition?.value;
      v2Config.advancedEntryLowerValue = v2Config?.limitsParams?.entryCondition?.value2;
      v2Config.advancedEntryDuration = v2Config?.limitsParams?.entryCondition?.duration;
      v2Config.advancedExitOperator = v2Config?.limitsParams?.exitCondition?.operator;
      v2Config.advancedExitValue = v2Config?.limitsParams?.exitCondition?.value;
      v2Config.advancedExitDuration = v2Config?.limitsParams?.exitCondition?.duration;
    }

    delete v2Config.limitsParams;
    return v2Config;
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    VALUE_SEARCH_SET_SIMPLE_OPERATOR: (payload: { simpleOperator: string }) => {
      this.state.set('simpleOperator', payload.simpleOperator);
    },

    VALUE_SEARCH_SET_SIMPLE_VALUE: (payload: { simpleValue: string }) => {
      this.state.set('simpleValue', payload.simpleValue);
    },

    VALUE_SEARCH_SET_SIMPLE_UPPER_VALUE_INCLUSIVITY: (payload: { simpleUpperValueInclusivity: number }) => {
      this.state.set('simpleUpperValueInclusivity', payload.simpleUpperValueInclusivity);
    },

    VALUE_SEARCH_SET_SIMPLE_LOWER_VALUE: (payload: { simpleLowerValue: number }) => {
      this.state.set('simpleLowerValue', payload.simpleLowerValue);
    },

    VALUE_SEARCH_SET_SIMPLE_LOWER_VALUE_INCLUSIVITY: (payload: { simpleLowerValueInclusivity: string }) => {
      this.state.set('simpleLowerValueInclusivity', payload.simpleLowerValueInclusivity);
    },

    VALUE_SEARCH_SET_ADVANCED_ENTRY_OPERATOR: (payload: { advancedEntryOperator: string }) => {
      this.state.set('advancedEntryOperator', payload.advancedEntryOperator);
    },

    VALUE_SEARCH_SET_ADVANCED_ENTRY_VALUE: (payload: { advancedEntryValue: number }) => {
      this.state.set('advancedEntryValue', payload.advancedEntryValue);
    },

    VALUE_SEARCH_SET_ADVANCED_ENTRY_LOWER_VALUE: (payload: { advancedEntryLowerValue: number }) => {
      this.state.set('advancedEntryLowerValue', payload.advancedEntryLowerValue);
    },

    VALUE_SEARCH_SET_ADVANCED_ENTRY_DURATION: (payload: { advancedEntryDuration: number }) => {
      this.state.set('advancedEntryDuration', payload.advancedEntryDuration);
    },

    VALUE_SEARCH_SET_ADVANCED_EXIT_OPERATOR: (payload: { advancedExitOperator: string }) => {
      this.state.set('advancedExitOperator', payload.advancedExitOperator);
    },

    VALUE_SEARCH_SET_ADVANCED_EXIT_VALUE: (payload: { advancedExitValue: number }) => {
      this.state.set('advancedExitValue', payload.advancedExitValue);
    },

    VALUE_SEARCH_SET_ADVANCED_EXIT_DURATION: (payload: { advancedExitDuration }) => {
      this.state.set('advancedExitDuration', payload.advancedExitDuration);
    },

    VALUE_SEARCH_SET_MIN_DURATION: (payload: { minDuration: number }) => {
      this.state.set('minDuration', payload.minDuration);
    },

    VALUE_SEARCH_SET_MERGE_DURATION: (payload: { mergeDuration: number }) => {
      this.state.set('mergeDuration', payload.mergeDuration);
    },

    VALUE_SEARCH_SET_IS_MIGRATED_DEVIATION_SEARCH: (payload: { isMigratedDeviationSearch: boolean }) => {
      this.state.set('isMigratedDeviationSearch', payload.isMigratedDeviationSearch);
    },

    /**
     * Sets the isSimple property (true if simple search, false if advanced)
     *
     * @param {Object} payload - state information
     * @param {boolean} payload.isSimple - whether we are setting it to simple or advanced
     */
    VALUE_SEARCH_SET_SEARCH_TYPE: (payload: { isSimple: any }) => {
      this.state.set('isSimple', payload.isSimple);
    },

    /**
     * Toggles the isCleansing property
     */
    VALUE_SEARCH_TOGGLE_CLEANSING: () => {
      this.state.set('isCleansing', !this.state.get('isCleansing'));
    },

    /**
     * Toggles the isCleansing property
     */
    VALUE_SEARCH_TOGGLE_VALID_VALUES: () => {
      this.state.set('useValidValues', !this.state.get('useValidValues'));
    },

    TOOL_REHYDRATE_FOR_EDIT: (payload: { parameters: Record<string, unknown>; type: string }) => {
      if (payload.type !== TREND_TOOLS.VALUE_SEARCH) {
        return;
      }

      this.rehydrateForEdit(payload);

      const simpleValueParam = _.find(payload.parameters, { name: 'b' }) as any;
      if (simpleValueParam) {
        this.state.set('simpleValue', simpleValueParam.item.id);
      }

      const simpleLowerValueParam = _.find(payload.parameters, {
        name: 'c',
      }) as any;
      if (simpleLowerValueParam) {
        this.state.set('simpleLowerValue', simpleLowerValueParam.item.id);
      }
    },
  };
}
