export enum BulkEditMode {
  DO_NOT_SHOW,
  ASSET_SELECTION,
  DATE_RANGE,
  ADVANCED_ONLY,
}

export enum InteractiveOptions {
  YES = 'yes',
  NO = 'no',
  NOT_APPLICABLE = 'na',
}
