export const DATASOURCES_TAB_INDEX = 0;
export const AGENTS_TAB_INDEX = 1;
export const USERS_TAB_INDEX = 2;
export const GROUPS_TAB_INDEX = 3;
export const REQUESTS_TAB_INDEX = 4;
export const SUBSCRIPTIONS_TAB_INDEX = 5;
export const JOBS_TAB_INDEX = 6;
export const CONFIGURATION_TAB_INDEX = 7;
export const EXPORTS_TAB_INDEX = 8;
export const ACCESS_KEY_TAB_INDEX = 9;
export const PLUGINS_TAB_INDEX = 10;
export const REPORT_TAB_INDEX = 11;
export const USAGE_TAB_INDEX = 12;
export const SYSTEM_USERNAME = 'system';
export const API_KEY_SUFFIX = '_api_key';
export const EVERYONE_USERGROUP = 'Everyone';
export const AGENTS_USERGROUP = 'Agents';

export const SCHEDULABLE_TABLE_CANCELLATION_GROUP = 'schedulableAdminTable';
export const SCHEDULABLE_CHARTS_CANCELLATION_GROUP = 'schedulableAdminChart';

/**
 * Needs to be high enough to get all on busy servers, but not so high it causes OOM or frontend performance. 10k is a
 * good balance that has been tested.
 */
export const ADMIN_LIMIT = 10000;
