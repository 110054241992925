import { PersistenceLevel, Store } from '@/core/flux.service';
import { AnyProperty } from '@/utilities.types';

/**
 * This store manages the pieces of the layout data that apply WORKBENCH-level wide.
 */
export class WorkbenchLayoutStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKBENCH';
  static readonly storeName = 'sqWorkbenchLayoutStore';

  initialize() {
    this.state = this.immutable({
      layoutLocked: false,
    });
  }

  get layoutLocked(): boolean {
    return this.state.get('layoutLocked');
  }

  dehydrate() {
    return this.state.serialize();
  }

  rehydrate(dehydratedState: AnyProperty) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    LAYOUT_SET_LAYOUT_LOCKED: ({ locked }: { locked: boolean }) => {
      this.state.set('layoutLocked', locked);
    },
  };
}
