// @ts-strict-ignore
import _ from 'lodash';
import { sqWorkbenchStore } from '@/core/core.stores';
import { DURATION_TIME_UNITS } from '@/main/app.constants';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';

export class PeriodicConditionStore extends BaseToolStore {
  type = TREND_TOOLS.PERIODIC_CONDITION;
  static readonly storeName = 'sqPeriodicConditionStore';

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, {
        timezone: sqWorkbenchStore.userTimeZone?.name,
        duration: undefined,
        frequencies: [],
        weekStart: 'Day.Sunday',
        quarterStart: { month: 'Month.January', day: 1 },
        shift: { offset: 8, duration: 8 },
        offset: { value: 0, units: DURATION_TIME_UNITS[1].unit[0] },
        customDuration: { value: 0, units: DURATION_TIME_UNITS[2].unit[0] },
        customPeriod: { value: 0, units: DURATION_TIME_UNITS[2].unit[0] },
        customPeriodDisable: true,
      }),
    );
  }

  get duration() {
    return this.state.get('duration');
  }

  get timezone() {
    return this.state.get('timezone');
  }

  get frequencies() {
    return this.state.get('frequencies');
  }

  get shift() {
    return this.state.get('shift');
  }

  get weekStart() {
    return this.state.get('weekStart');
  }

  get quarterStart() {
    return this.state.get('quarterStart');
  }

  get offset() {
    return this.state.get('offset');
  }

  get customDuration() {
    return this.state.get('customDuration');
  }

  get customPeriod() {
    return this.state.get('customPeriod');
  }

  get customPeriodDisable() {
    return this.state.get('customPeriodDisable');
  }

  /**
   * Compute the formula that this tool represents.
   *
   * @returns {Object} Object with properties of "formula" which is the string for the formula and
   *   "parameters" which are all parameters used in the formula (none)
   */
  getFormula() {
    const duration = this.state.get('duration');
    const frequencies = this.state.get('frequencies');
    let operatorName = duration;
    let move = '';
    let args = [];
    const fb = this.formulaBuilder;
    if (duration === 'shifts') {
      args.push(this.state.get('shift').offset);
      args.push(this.state.get('shift').duration);
    } else if (duration === 'weeks') {
      args.push(this.state.get('weekStart'));
    } else if (duration === 'quarters') {
      args.push(this.state.get('quarterStart').month);
      args.push(this.state.get('quarterStart').day);
    } else if (duration === 'periods') {
      args.push(fb.duration(this.state.get('customDuration')));
      if (!this.state.get('customPeriodDisable')) {
        args.push(fb.duration(this.state.get('customPeriod')));
      }
    }

    const tz = this.state.get('timezone');
    if (tz && duration !== 'periods') {
      args.push(`"${tz}"`);
    }

    if (frequencies.length === 1) {
      // put the specific enum at the first argument
      args.unshift(_.head(frequencies));
    } else if (frequencies.length > 1) {
      // make a list of conditions, one per frequency
      args = _.map(frequencies, function (frequency) {
        return fb.operator(operatorName, [frequency].concat(args));
      });
      // now switch to the operator that combines all these
      operatorName = 'combineWith';
    }

    if (_.isFinite(this.state.get('offset')?.value) && this.state.get('offset').value !== 0) {
      move = `.${fb.operator('move', [fb.duration(this.state.get('offset'))])}`;
    }

    return {
      formula: fb.operator(operatorName, args) + move,
      parameters: {},
    };
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the references panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  /** Applies conventions for copying a unit value
   *
   * @param {String} key - The state field being set
   * @param {Object} payload - Object container
   * @param {Number} payload.value - The number that indicates how long the value is
   * @param {String} payload.units - The units that the value represents   */
  setValueUnit(key, payload: { value?: number; units: string }) {
    if (_.isNil(payload.value) || _.isNaN(payload.value) || _.isNil(payload.units)) {
      this.state.set(key, {});
    } else {
      this.state.set(key, {
        value: payload.value,
        units: payload.units,
      });
    }
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    /**
     * Set the period of the condition. Resets the frequency choices if the duration changes.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.duration - One of the keys from STANDARD_DURATIONS
     */
    PERIODIC_CONDITION_DURATION: (payload: { duration: string }) => {
      if (this.state.get('duration') !== payload.duration) {
        this.state.set('frequencies', []);
      }
      this.state.set('duration', payload.duration);
    },

    /**
     * Set the timezone of the condition.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.timezone - Name of the timezone
     */
    PERIODIC_CONDITION_TIMEZONE: (payload: { timezone: string }) => {
      this.state.set('timezone', payload.timezone);
    },

    /**
     * Set the day that the week starts on. Only useful for duration of weeks()
     *
     * @param {Object} payload - Object container
     * @param {String} payload.weekStart - The day enum of the week start
     */
    PERIODIC_CONDITION_WEEK_START: (payload: { weekStart: string }) => {
      this.state.set('weekStart', payload.weekStart);
    },

    /**
     * Set the day that the quarter starts on. Only useful for duration of quarters()
     *
     * @param {Object} payload - Object container
     * @param {String} payload.month - The month enum of the quarter start
     * @param {String} payload.day - The number of the first day in the month of the quarter
     */
    PERIODIC_CONDITION_QUARTER_START: (payload: { month: string; day: string }) => {
      this.state.set('quarterStart', {
        month: payload.month,
        day: payload.day,
      });
    },

    /**
     * Set the parameters of the shift operator
     *
     * @param {Object} payload - Object container
     * @param {String} payload.offset - The number of hours after midnight to start
     * @param {String} payload.duration - The number of the hours in the shift. Must be an integer
     */
    PERIODIC_CONDITION_SHIFT: (payload: { offset: string; duration: string }) => {
      this.state.set('shift', {
        offset: payload.offset,
        duration: payload.duration,
      });
    },

    /**
     * Adds or removes the specified frequency from the frequencies list.
     *
     * @param {Object} payload - Object container
     * @param {String} [payload.frequency] - The frequency to toggle. If not provided the list will be cleared.
     */
    PERIODIC_CONDITION_TOGGLE_FREQUENCY: (payload: { frequency: string }) => {
      const index = _.indexOf(this.state.get('frequencies'), payload.frequency);
      if (!payload.frequency) {
        this.state.set('frequencies', []);
      } else if (index >= 0) {
        this.state.splice('frequencies', [index, 1]);
      } else {
        this.state.push('frequencies', payload.frequency);
      }
    },

    /**
     * Set an offset that can be used to shift the start of the capsules.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.value - The number that indicates how long the offset is
     * @param {String} payload.units - The units that the value represents
     */
    PERIODIC_CONDITION_OFFSET: (payload: { value: number; units: string }) => {
      this.setValueUnit('offset', payload);
    },
    /**
     * Set the custom period.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.value - The number that indicates how long the period is
     * @param {String} payload.units - The units that the value represents
     */
    PERIODIC_CONDITION_CUSTOM_PERIOD: (payload: { value: number; units: string }) => {
      this.setValueUnit('customPeriod', payload);
    },
    /**
     * Set the custom duration. If the custom period is disabled, it also keeps the period in lockstep so that
     * it's ready when the user enables the option.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.value - The number that indicates how long the duration of each capsule is
     * @param {String} payload.units - The units that the value represents
     */
    PERIODIC_CONDITION_CUSTOM_DURATION: (payload: { value: number; units: string }) => {
      if (
        this.state.get('customPeriodDisable') &&
        _.isEqual(this.state.get('customDuration'), this.state.get('customPeriod'))
      ) {
        this.setValueUnit('customPeriod', payload);
      }
      this.setValueUnit('customDuration', payload);
    },
    /**
     * Toggles the custom period
     */
    PERIODIC_CONDITION_CUSTOM_PERIOD_TOGGLE: () => {
      this.state.set('customPeriodDisable', !this.state.get('customPeriodDisable'));
    },
  };
}
