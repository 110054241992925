import _ from 'lodash';
import { DirectionEnum, sqWorkbooksApi } from '@/sdk/api/WorkbooksApi';
import { WorkstepOutputV1 } from '@/sdk/model/WorkstepOutputV1';
import {
  isInWorkbookRouteAndWorkbookLoaded,
  isPresentationWorkbookMode,
  isViewOnlyWorkbookMode,
} from '@/utilities/utilities';
import { apply } from '@/worksteps/workstepUpgrader.utilities';
import {
  WORKSHEET_WITH_NO_WORKSTEPS,
  WORKSTEP_PUSH_DISABLED,
  WORKSTEP_SCHEMA_VERSION,
} from '@/worksteps/worksteps.constant';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { AnyProperty } from '@/utilities.types';

// ensure the response doesn't leak over to the new worksheet.
const cancellationOptions = {
  cancellationGroup: 'worksteps',
  cancelOnServer: false, // Still want the workstep request to finish on the appserver side
};

export type WorkstepOutput = {
  previous?: string;
  current: { id: string; state: { stores: AnyProperty }; createdAt: number };
  next?: string;
  last?: string;
};

/**
 * Get workstep state and set it as the current workstep on the server, if specified.
 *
 * @param {string} workbookId - The workbook ID
 * @param {string} worksheetId - The worksheet ID
 * @param {string} [workstepId] - The workstep ID. If undefined then the current workstep will be found.
 * @param {boolean} [setAsCurrent] - True if it should be set as the current workstep on the server. Only
 *   applicable if `workstepId` is set.
 *
 * @returns {Promise<WorkstepOutput>} A promise that will resolve with state for the workstep or reject if workstep
 *   state is not available Remove first parameter once react code avaible
 */
export async function getWorkstep(
  workbookId: string,
  worksheetId: string,
  workstepId?: string,
  setAsCurrent = false,
): Promise<WorkstepOutput> {
  let promise;
  if (workstepId) {
    if (setAsCurrent) {
      promise = setCurrentWorkstep(workbookId, worksheetId, workstepId);
    } else {
      promise = Promise.resolve(workstepId);
    }
  } else {
    promise = getCurrentWorkstepId(workbookId, worksheetId);
  }

  const currentWorkstepId = await promise;
  const response = await sqWorkbooksApi.getWorkstep(
    { workbookId, worksheetId, workstepId: currentWorkstepId },
    cancellationOptions,
  );

  return workstepFromResponse(response.data);
}

/**
 * Push workstep state and make it the current workstep. If state is pushed when the current
 * workstep is not the last workstep, then the current workstep is first pushed, followed
 * by the workstep state.
 *
 * @param {String} workbookId - The workbook ID
 * @param {String} worksheetId - The worksheet ID
 * @param {AnyProperty} worksheetState - The worksheet state object
 *
 * @returns {Promise} A promise that will resolve with state for the pushed workstep or reject if workstep state
 *   could not be pushed
 */
export async function pushWorkstep(
  workbookId: string,
  worksheetId: string,
  worksheetState: AnyProperty,
): Promise<WorkstepOutput> {
  if (
    headlessRenderMode() ||
    !isInWorkbookRouteAndWorkbookLoaded() ||
    isViewOnlyWorkbookMode() ||
    isPresentationWorkbookMode()
  ) {
    return Promise.reject(WORKSTEP_PUSH_DISABLED);
  }

  const response = await sqWorkbooksApi.createWorkstep(
    {
      data: JSON.stringify({
        version: WORKSTEP_SCHEMA_VERSION,
        state: worksheetState,
      }),
    },
    { workbookId, worksheetId },
    cancellationOptions,
  );

  return workstepFromResponse(response.data);
}

/**
 * Gets the ID of a worksheet's current workstep.
 *
 * @param {String} workbookId - a workbook ID
 * @param {String} worksheetId - a worksheet ID
 *
 * @returns {Promise} a promise that resolves with the workstep ID or WORKSHEET_WITH_NO_WORKSTEPS if the worksheet
 *   has no worksteps.
 */
export async function getCurrentWorkstepId(workbookId: string, worksheetId: string) {
  const response = await sqWorkbooksApi.getWorksheet({ workbookId, worksheetId }, cancellationOptions);
  if (response.data.workstep) {
    return response.data.workstep;
  }

  return Promise.reject(WORKSHEET_WITH_NO_WORKSTEPS);
}

/**
 * Gets the ID of a worksheet's current workstep.
 *
 * @param {String} workbookId - a workbook ID
 * @param {String} worksheetId - a worksheet ID
 * @param {String} fromWorkstepId - a workstep ID
 * @param {DirectionEnum} direction - search direction
 *
 * @returns {Promise} a promise that resolves with the workstep ID or WORKSHEET_WITH_NO_WORKSTEPS if the worksheet
 *   has no worksteps.
 */
export async function getWorksteps(
  workbookId: string,
  worksheetId: string,
  fromWorkstepId: string,
  direction: DirectionEnum,
) {
  const response = await sqWorkbooksApi.getWorksteps(
    { workbookId, worksheetId, fromWorkstepId, direction },
    cancellationOptions,
  );
  return response.data;
}

/**
 * Sets the current workstep.
 *
 * @param {String} workbookId - The workbook ID
 * @param {String} worksheetId - The worksheet ID
 * @param {String} workstepId - The workstep ID
 *
 * @returns {Promise<string>} A promise that resolves with the workstepId
 */
export async function setCurrentWorkstep(workbookId: string, worksheetId: string, workstepId: string): Promise<string> {
  if (
    headlessRenderMode() ||
    !isInWorkbookRouteAndWorkbookLoaded() ||
    isViewOnlyWorkbookMode() ||
    isPresentationWorkbookMode()
  ) {
    return Promise.resolve(workstepId);
  }

  const { data } = await sqWorkbooksApi.setCurrentWorkstep(
    { workbookId, worksheetId, workstepId },
    cancellationOptions,
  );

  return data.id;
}

/**
 * Extracts the state, next, and previous steps from a workstep response. Rejects with an error if the JSON state
 * cannot be parsed.
 *
 * @param {Object} workstep - Response from an API call that returns workstep information
 *
 * @returns {Object} The parsed workstep information or a rejected promise if state cannot be parsed.
 */
export async function workstepFromResponse(workstep: WorkstepOutputV1): Promise<WorkstepOutput> {
  const parsedData = _.attempt(JSON.parse, workstep.data);
  if (!_.isError(parsedData)) {
    const newState = await apply(parsedData.state, parsedData.version);

    return {
      previous: workstep.previous,
      current: {
        id: workstep.id,
        state: newState,
        createdAt: workstep.createdAt ? Date.parse(workstep.createdAt) : 0,
      },
      next: workstep.next,
      last: workstep.last,
    };
  }

  return Promise.reject(parsedData.message);
}
