import _ from 'lodash';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { sqWorkbookStore } from '@/core/core.stores';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { ODataExportItemProps } from './ODataExportItem.organism';
import { PersistenceLevel } from '@/core/flux.service';

export class ViewODataExportsStore extends BaseToolStore {
  static readonly storeName = 'sqViewODataExportsStore';
  type = TREND_TOOLS.VIEW_ODATA_EXPORTS;
  persistenceLevel: PersistenceLevel = 'NONE';

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, {
        name: sqWorkbookStore.name,
        items: [],
        loading: false,
        hasNextPage: false,
      }),
    );
  }

  get isLoading() {
    return this.state.get('loading');
  }

  get items() {
    return this.state.get('items');
  }

  get hasNextPage() {
    return this.state.get('hasNextPage');
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    /**
     * Initializes the items to their default values. Done here so that this store doesn't dehydrate a bunch of
     * items when it is not in use.
     *
     * @param {Object} payload - Object containing export items
     */
    SET_VIEW_ODATA_EXPORTS: (payload: { items: ODataExportItemProps[]; hasNextPage: boolean }) => {
      this.state.set('items', _.uniqBy([...this.state.get('items'), ...payload.items], 'id'));
      this.state.set('hasNextPage', payload.hasNextPage);
    },

    SET_LOADING_ODATA_EXPORTS: (payload: boolean) => {
      this.state.set('loading', payload);
    },

    DELETE_ODATA_EXPORT: (id: string) => {
      const items = this.state.get('items');
      const filteredItems = _.filter(items, (item) => item.id !== id);
      this.state.set('items', filteredItems);
    },

    SET_VIEW_ODATA_EXPORT_ELEMENT: (payload: ODataExportItemProps) => {
      const items = this.state.get('items');
      this.state.set('items', _.sortBy([...items, { ...payload }], 'exportName'));
    },
  };
}
