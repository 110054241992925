import _ from 'lodash';
import 'other_components/piwik/piwik';
import { sqStateSynchronizer } from '@/core/core.stores';
import { findItemIn, getFormulaTooStores } from '@/trend/trendDataHelper.utilities';
import { logTrackEvent } from '@/utilities/logger';
import { getUserTrackingData, randomInt } from '@/utilities/utilities';
import {
  adminContactEmail,
  isMixpanelEnabled,
  isTelemetryAnonymized,
  isTelemetryEnabled,
} from '@/services/systemConfiguration.utilities';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { isSystemTest, throwError } from '@/core/utilities';
import { TrackerService } from '@/track/tracker.service';
import { AnyProperty } from '@/utilities.types';
import { TrackInformation } from '@/track/track.types';
import PiwikTrackingService from '@/track/piwik.service';
import MixpanelTrackingService from '@/track/mixpanel.service';
import { LicenseStatusOutputV1 } from '@/sdk';

let trackers: TrackerService[] = [];

let eventsToBeTracked: { category: string; action: string; information: string | AnyProperty }[] = [];

/**
 * This function calls the function that sends tracking events to Piwik. If we don't know if tracking is enabled or
 * not we make a call to initialize, otherwise we call the executeDoTrack function directly.
 *
 * @param category    - displayed as the 'Category' in the Piwik Console
 * @param action      - displayed as the 'Action' in the Piwik Console
 * @param [information] - displayed as the 'Event' in the Piwik Console
 * @param [dateRange] - optional, indicator whether or not to include the display and investigate
 *   range in the information field.
 */
export function doTrack(
  category: string,
  action: string,
  information?: TrackInformation,
  dateRange?: { displayRange: any; investigateRange: any },
) {
  if (!_.isEmpty(dateRange)) {
    const presentDateRange = dateRange as NonNullable<typeof dateRange>;
    information = `${information} ` ?? '';
    const datePattern = 'MM-DD-YYYY HH:mm:ss.SSS';

    information += `Investigation Range: ${presentDateRange.investigateRange.start.format(
      datePattern,
    )}-${presentDateRange.investigateRange.end.format(datePattern)}`;

    information += `Display Range: ${presentDateRange.displayRange.start.format(
      datePattern,
    )}-${presentDateRange.displayRange.end.format(datePattern)}`;
  }

  if (_.isEmpty(trackers)) {
    // add event to queue to track once trackers have been initialized:
    eventsToBeTracked.push({ category, action, information: information as string | AnyProperty });
  } else {
    executeDoTrack(category, action, information as string | AnyProperty);
  }
}

export function identifyUser(email: string) {
  _.forEach(trackers, (tracker) => {
    if (!sqStateSynchronizer.isRehydrating && !isSystemTest() && !headlessRenderMode() && tracker.shouldTrack()) {
      tracker.identifyUser(email);
    }
  });
}

export function initializeTrackers(
  currentUser: { username: string; email: string; role: string; id: string },
  darkMode: boolean,
  userLanguage: string,
  license: LicenseStatusOutputV1,
) {
  // ensure we destroy existing singleton trackers:
  resetTrackers();

  const { serverEmail, userName, userEmail } = getUserTrackingData({
    anonymizeTelemetry: isTelemetryAnonymized(),
    adminEmail: adminContactEmail(),
    username: currentUser?.username,
    userEmail: currentUser?.email,
  });

  const contractNumber = license.contractNumber as string;
  const companyName = license?.companyName as string;
  // we want to always send tracking events to Piwik (at least for the time being)
  trackers = [
    new PiwikTrackingService({
      companyName,
      serverEmail,
      userName,
      userEmail,
      contractNumber,
      processPiwikRequest,
    }),
  ];

  if (isMixpanelEnabled() && isTelemetryEnabled()) {
    trackers.push(
      new MixpanelTrackingService({
        companyName,
        serverEmail,
        userName,
        userEmail,
        contractNumber,
        language: userLanguage,
        darkMode,
        role: currentUser?.role,
        id: currentUser?.id,
      }),
    );

    if (userEmail) {
      identifyUser(userEmail);
    }
  }
  if (eventsToBeTracked) {
    eventsToBeTracked.forEach((event) => {
      doTrack(event.category, event.action, event.information);
    });
    eventsToBeTracked = [];
  }
}

// destroys the singleton instances of the trackers and resets the tracker array.
export function resetTrackers() {
  _.forEach(trackers, (tracker) => tracker.reset());
  trackers = [];
}

/**
 * This function sends tracking events to Piwik.
 *
 * @param category    - displayed as the 'Category' in the Piwik Console
 * @param action      - displayed as the 'Action' in the Piwik Console
 * @param information - displayed as the 'Event' in the Piwik Console
 */
export function executeDoTrack(category: string, action: string, information: string | AnyProperty) {
  // Since the event is logged both locally and directly to piwik a unique event id is stored so they can be de-duped
  const uniqueEventId = randomInt();
  _.forEach(trackers, (tracker) => {
    if (!sqStateSynchronizer.isRehydrating && !isSystemTest() && !headlessRenderMode() && tracker.shouldTrack()) {
      tracker.trackEvent(category, action, information, uniqueEventId);
    }
  });
}

/**
 * A Piwik callback that is called whenever a request is about to be made. Always logs the event to a local log
 * with the timestamp so that it can be imported later in case telemetry is disabled or something like an
 * ad-blocker is blocking the Piwik URL.
 *
 * @param request - The request params for recording a Piwik event
 * @return If remote telemetry is disabled then an empty string which will cause Piwik to not issue any
 * request. Otherwise the original request params.
 */
export function processPiwikRequest(request: string) {
  logTrackEvent(`?${request}&cdt=${getUnixTimestamp()}`);
  if (!isTelemetryEnabled()) {
    return '';
  }

  return request;
}

/**
 * Helper function for generating a UNIX timestamp that can be passed to Piwik, such as for overriding the
 * datetime of the request.
 *
 * @return {number} A UNIX timestamp
 */
function getUnixTimestamp() {
  return Math.round(Date.now() / 1000);
}

/**
 * Gets a JSON blob of data to be used as the 'information' field for tracking formula results
 *
 * @param payload - container for arguments
 * @param payload.id - id of the item (maybe undefined if the formula is new and fails)
 * @param payload.success - true if this was a successful search
 * @param payload.name - name of the formula
 * @param payload.formula - text of the formula
 * @param payload.parameters - arguments to the formula
 * @param payload.parameters[].identifier - name of the parameter in the formula
 * @param payload.parameters[].item - information about the item
 * @param payload.parameters[].item.assets - the list of assets
 * @param payload.parameters[].item.id - item's id
 * @param payload.parameters[].item.name - the name property of the item
 * @param payload.isNew - true if this is a new item (otherwise an update)
 * @param [payload.errorMessage] - if success is false contains the rejection message
 * @return JSON encoded string containing info for tracking
 */
export function trackPowerSearchCompletedInfo(payload: {
  id: string;
  success: boolean;
  name: string;
  formula: string;
  parameters: {
    identifier: string;
    item: {
      assets: any[];
      id: string;
      name: string;
    };
  }[];
}) {
  const parameters = _.map(payload.parameters, function (parameter: any) {
    const item = parameter.item;
    const type = _.get(findItemIn(getFormulaTooStores(), item.id), 'itemType');
    return [
      '$',
      parameter.identifier,
      ' = ',
      _.isEmpty(item.assets) ? item.name : `${_.get(item.assets, '[0].formattedName')} > ${item.name}`,
      // If the item is on the chart we know its type otherwise we wont show this info
      type ? ` (type:${type})` : '',
    ].join('');
  });

  doTrack('Workbench_Tool', 'Formula', JSON.stringify(_.assign(_.omit(payload, ['success']), { parameters })));
}
