import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface FormErrorProps {
  errorText: string;
  errorParameters?: Record<string, string | number>;
  extraClassNames?: string;
  dataTestId?: string;
}

export const FormError: React.FunctionComponent<FormErrorProps> = (props) => {
  const { errorText, errorParameters, extraClassNames, dataTestId } = props;
  const { t } = useTranslation();

  return (
    <p className={classNames('sq-text-danger', extraClassNames)} data-testid={dataTestId}>
      {errorParameters ? t(errorText, errorParameters) : t(errorText)}
    </p>
  );
};
