// @ts-strict-ignore
import _ from 'lodash';
import { STRING_UOM } from '@/main/app.constants';
import { formatNumber, NO_FORMAT } from '@/utilities/numberHelper.utilities';
import i18next from 'i18next';
import { ENUM_REGEX } from '@/trendData/trendData.constants';
import { AnyProperty } from '@/utilities.types';
import { formatString } from '@/utilities/stringHelper.utilities';
import { defaultNumberFormat } from '@/services/systemConfiguration.utilities';

/**
 * Gets a custom property from an item using said propertys column key.
 *
 * @param item - The item object containing data displayed in the column
 * @param columnKey - key to match to object property, column.key or column.uomKey
 *
 * @return The property of `item` that the key matched to
 */
const getUsingKey = (item: AnyProperty, columnKey: string | undefined): string | number => {
  if (!columnKey) {
    return;
  }

  const [parentPropName, ...rest] = _.split(columnKey, '.');

  return _.get(item, _.chain([parentPropName]).concat(rest.join('.')).compact().value());
};

/**
 * Get the value and UOM of a column for an item
 *
 * @param {Object} column - One of the columns from COLUMNS
 * @param {Object} item - Item for which to get a value
 *
 * @returns {String} Formatted value
 */
export function getColumnValueAndUOM(column, item) {
  const isStartTime = column.key === 'startTime';
  const isEndTime = column.key === 'endTime';

  if (isStartTime && _.isNil(item.startTime)) {
    return i18next.t('CAPSULES_PANEL.STARTS_OFF_SCREEN');
  }

  if (isEndTime && _.isNil(item.endTime)) {
    return i18next.t('CAPSULES_PANEL.ENDS_OFF_SCREEN');
  }

  const rawFormatOptions = column.formatOptions ?? item.formatOptions ?? column.series?.formatOptions ?? {};
  const formatOptions = {
    ...rawFormatOptions,
    format: column.format || rawFormatOptions.format,
    isPercent: column.style === 'percent',
  };

  const toString = (stringValue: string | undefined): string => {
    if (_.isString(stringValue)) {
      const match = stringValue.match(ENUM_REGEX);
      if (match) {
        return match[2];
      } else if (column.key.includes('properties')) {
        return formatString(rawValue, formatOptions, _.noop, getDefaultFormat(true));
      } else {
        return rawValue;
      }
    }
    return '';
  };

  const rawValue = _.get(item, column.accessor) ?? getUsingKey(item, column.key);
  const value =
    column.style === STRING_UOM || (column.isStringCompatible && _.isString(rawValue))
      ? toString(rawValue)
      : formatNumber(getUsingKey(item, column.key), formatOptions, _.noop);

  const uom = getUsingKey(item, column.uomKey);

  return `${_.isNil(value) ? '' : value}${_.isNil(uom) || uom === STRING_UOM ? '' : uom}`;
}

export function getDefaultFormat(isString: boolean): string {
  return isString ? NO_FORMAT : defaultNumberFormat();
}

export function isValueColumn(column) {
  return (
    column.style !== 'icon' &&
    column.style !== 'select' &&
    column.style !== 'input' &&
    column.style !== 'iconSelect' &&
    column.style !== 'assets' &&
    column.style !== 'datasourceName' &&
    column.key !== 'valueUnitOfMeasure'
  );
}
