import React from 'react';

export interface UnsubscribeDoneProps {
  message: string;
  title: string;
}

export const UnsubscribeDone: React.FunctionComponent<UnsubscribeDoneProps> = ({ message, title }) => {
  return (
    <div data-testid="unsubscribeDone" className="text-center">
      <h2>{title}</h2>
      <h4>{message}</h4>
    </div>
  );
};
