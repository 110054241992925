// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { Checkbox } from '@/core/Checkbox.atom';
import { canUseMemo, getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { Field, useForm } from 'react-final-form';
import { ALL } from '@/tools/periodicCondition/periodicCondition.constants';

export interface CheckboxTableIF extends ValidatingFormComponent<any> {
  component: 'CheckboxTableFormComponent';
  options: { key: string; display: string }[];
  includeAll?: boolean;
  type?: 'radio' | 'checkbox';
}

export const CheckboxTableFormComponentUnwrapped: React.FunctionComponent<CheckboxTableIF> = (props) => {
  const {
    name,
    extraClassNames,
    onChange,
    options,
    includeAll = false,
    validation,
    extendValidation,
    value, // value is the array of the keys of the selected options
    type = 'checkbox',
    required,
  } = props;

  const tableOptions = includeAll ? _.concat(ALL, options) : options;

  const isOptionSelected = (key) =>
    type === 'radio' ? key === value : (key === 'all' && includeAll && _.isEmpty(value)) || _.includes(value, key);

  const defaultValidation = (value) =>
    type === 'radio' ? _.isEmpty(value) : _.isEmpty(value) && !isOptionSelected('all') && (includeAll || required);

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  return (
    <Field name={name} validate={appliedValidation}>
      {({ input, meta }) => (
        <table
          className={classNames('table', 'table-striped', 'table-condensed', 'mb0', extraClassNames)}
          id={name}
          data-testid={name}
          tabIndex={0}>
          <tbody>
            {_.map(tableOptions, (option) => (
              <tr key={option.key} className="cursorPointer">
                <td
                  className="forceZeroPadding"
                  onClick={(e) => {
                    e.preventDefault();
                    onChange(option.key);
                  }}>
                  <Checkbox
                    {..._.omit(getFormFieldProps(formState, input, meta, props), 'onChange')}
                    isChecked={isOptionSelected(option.key)}
                    id={`${option.key}Checkbox`}
                    label={option.display}
                    type={type}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Field>
  );
};

export const CheckboxTableFormComponent = React.memo(CheckboxTableFormComponentUnwrapped, canUseMemo);
