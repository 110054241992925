import React from 'react';
import { useTranslation } from 'react-i18next';

interface ClearAllFiltersButtonProps {
  onClear: () => void;
}

export const ClearAllFiltersButton: React.FunctionComponent<ClearAllFiltersButtonProps> = ({ onClear }) => {
  const { t } = useTranslation();

  return (
    <span
      data-testid="clearAllFiltersButton"
      className="sq-text-primary cursorPointer text-interactive text-underline-onhover ml10 text-italic"
      onClick={onClear}>
      <span>{t('HOME_SCREEN.CLEAR_ALL')} &times;</span>
    </span>
  );
};
