import { useEffect, useRef } from 'react';

/**
 * A hook used to check if a component is mounted.
 *
 * It is useful for preventing a resolved/rejected asynchronous operation from updating
 * the state of an unmounted component
 */
export const useIsMounted = () => {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};
