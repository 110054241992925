// @ts-strict-ignore
import React from 'react';
import { Icon } from '@seeqdev/qomponents';
import { LegendWrapper } from '@/trend/LegendWrapper.atom';

interface RainbowLegendItemsIF {
  getCapsuleName: (any) => string;
  capsules: any[];
  title: string;
}

export const RainbowLegendItems: React.FunctionComponent<RainbowLegendItemsIF> = (props) => {
  const { getCapsuleName, capsules, title } = props;

  return (
    <LegendWrapper title={title}>
      {capsules.map((capsule) => (
        <div className="mr10 text-nowrap" key={capsule.id}>
          <Icon icon="fc-series" extraClassNames="pr5" type="color" color={capsule.childColor} />
          {getCapsuleName(capsule)}
        </div>
      ))}
    </LegendWrapper>
  );
};
