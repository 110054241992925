import { useResizeWatcher } from '@/core/hooks/useResizeWatcher.hook';
import { AnyProperty } from '@/utilities.types';

interface UseResizeGroupingBarParams {
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  autoGroupColumn: AnyProperty | undefined;
}

/**
 * Resize the grouping bar to always match the width of the table header row
 */
export const useResizeGroupingBar = ({ wrapperRef, autoGroupColumn }: UseResizeGroupingBarParams) => {
  useResizeWatcher(
    {
      element: document.querySelector<HTMLElement>('.ag-header-row'),
      callback: ({ newWidth }) => {
        if (wrapperRef.current && autoGroupColumn) {
          wrapperRef.current
            .querySelector<HTMLDivElement>('.ag-column-drop-wrapper')
            ?.style.setProperty('width', `${newWidth}px`);
        }
      },
      callOnDependencyChange: true,
    },
    [autoGroupColumn],
  );
};
