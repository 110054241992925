import { SeeqNames } from '@/main/app.constants.seeqnames';
import { BatchActionEnum } from '@/sdk/model/TableDefinitionInputV1';

export const HIDDEN_COLUMNS: string[] = [
  SeeqNames.MaterializedTables.ItemIdColumn,
  SeeqNames.MaterializedTables.DatumIdColumn,
];

export const batchActionOptions = [
  {
    value: BatchActionEnum.UPDATEEXISTINGINSERTNEWCLEANUP,
    label: 'SCALING.BATCH_ACTION.OPTIONS.UPDATE_EXISTING_INSERT_NEW_CLEANUP',
  },
  { value: BatchActionEnum.UPDATEEXISTINGINSERTNEW, label: 'SCALING.BATCH_ACTION.OPTIONS.UPDATE_EXISTING_INSERT_NEW' },
  { value: BatchActionEnum.ONLYUPDATEEXISTING, label: 'SCALING.BATCH_ACTION.OPTIONS.ONLY_UPDATE_EXISTING' },
  { value: BatchActionEnum.ONLYINSERTNEW, label: 'SCALING.BATCH_ACTION.OPTIONS.ONLY_INSERT_NEW' },
];

export const MAX_ROW_COUNT = 500000;
