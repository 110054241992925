import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Outlet, useNavigate } from 'react-router-dom';
import { getCurrentPath, getFolderLink, setNavigate } from '@/main/routing.utilities';
import {
  ACL_MODAL_CHANGE_CHANNEL,
  cleanupBroadcastChannels,
  subscribeToBroadcastChannel,
} from '@/services/broadcastChannel.utilities';
import { sqHomeScreenStore } from '@/core/core.stores';
import { cancelRequestsOnUnload } from '@/requests/pendingRequests.utilities';
import { cleanupOnUnload } from '@/main/cleanup.utilities';
import { addEventListeners, onSystemMessage } from '@/services/notifier.service';
import { setSystemMessage } from '@/systemWarning/systemWarning.actions';
import { subscribeToAuthChangeChannel } from '@/homescreen/homescreen.actions';
import { fetchConfiguration } from '@/services/systemConfiguration.utilities';
import { Tracker } from '@/track/Tracker.atom';
import { LoadingFallback } from './LoadingFallback.atom';
import { init18n } from '@/utilities/i18n.utilities';
import { setAIAssistantDisplayed } from '@/aiAssistant/aiAssistant.actions';
import { getDefaultDisplayed } from '@/aiAssistant/aiAssistant.utilities';

/**
 * Wrapper component that wraps all the Route components.
 * Everything that has to happen once per application load and applies to every single component should happen here.
 */
export const Main: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    fetchConfiguration().then(() => {
      setConfigLoaded(true);
      init18n();
      setAIAssistantDisplayed(getDefaultDisplayed(), true);
    });
    subscribeToBroadcastChannel({
      channelId: ACL_MODAL_CHANGE_CHANNEL,
      onMessage() {
        if (_.indexOf(getCurrentPath(), '/folder') > -1 || _.indexOf(getCurrentPath(), '/workbooks') > -1) {
          const currentFolderId = sqHomeScreenStore.currentFolderId;
          navigate(getFolderLink(currentFolderId));
        }
      },
    });
    subscribeToAuthChangeChannel();
    onSystemMessage(setSystemMessage);
    addEventListeners();

    // we need to expose the navigate function to call it from services
    setNavigate(navigate);
    const beforeUnloadListener = () => cancelRequestsOnUnload();
    window.addEventListener('beforeunload', beforeUnloadListener);
    return () => {
      cleanupBroadcastChannels();
      cancelRequestsOnUnload();
      cleanupOnUnload();
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, []);

  if (!configLoaded) {
    return <LoadingFallback />;
  }

  return (
    <>
      <Tracker />
      <Outlet />
    </>
  );
};
