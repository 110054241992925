import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';

/**
 * This component renders a "Link" that doesn't have an actual href value. React-Router doesn't like links like <a
 * href='#" ... so instead we can use this component to render a "look a like"
 **/
export interface FakeLinkIF {
  onClick?: (e?: any) => any;
  id?: string;
  testId?: string;
  children: React.ReactNode;
  extraClassNames?: string;
  target?: string;
  destination?: string;
  tooltipText?: string;
  tooltipPlacement?: TooltipPosition;
}

export const FakeLink: React.FunctionComponent<FakeLinkIF> = ({
  testId,
  onClick = _.noop,
  id,
  children,
  extraClassNames,
  target,
  destination,
  tooltipText,
  tooltipPlacement = 'top',
}) => {
  // this is needed for one special case :(
  const openWindow = () => {
    window.open(destination, '_blank');
  };

  return (
    <HoverTooltip text={tooltipText} placement={tooltipPlacement}>
      <span
        onClick={target ? openWindow : onClick}
        className={classnames('link', 'cursorPointer', extraClassNames ? extraClassNames : 'fakeLink')}
        id={id}
        data-testid={testId}>
        {children}
      </span>
    </HoverTooltip>
  );
};
