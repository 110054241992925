import _ from 'lodash';
import { InitializeMode, PersistenceLevel, Store } from '@/core/flux.service';
import { getUniqueId } from '@/trendData/trend.utilities';
import {
  Annotation,
  AnnotationCapsuleSet,
  AnnotationInterest,
  AnnotationStoreState,
} from '@/annotation/annotation.types';

/**
 * A store for containing annotations which are listed in the panel. An annotation is composed of a description
 * and a collection of interests that it annotates.
 */

export class AnnotationStore extends Store {
  static readonly storeName = 'sqAnnotationStore';
  persistenceLevel: PersistenceLevel = 'WORKBOOK';

  initialize(initializeMode: InitializeMode) {
    const saveState = this.state && initializeMode !== 'FORCE';
    this.state = this.immutable({
      id: undefined,
      document: '',
      isDiscoverable: false,
      isExpanded: false,
      width: 0,
      height: 0,
      isCommentsExpanded: false,
      isAnnotatesExpanded: false,
      annotations: saveState ? this.state.get('annotations') : ([] as Annotation[]),
      annotatedItemIds: this.monkey(['annotations'], function (annotations) {
        return _.transform(
          annotations,
          function (map, annotation: Annotation) {
            _.forEach(annotation.interests, function (interest: AnnotationInterest) {
              map[interest.item.id] = annotation.id;
              if (interest.capsule) {
                const key = getUniqueId(interest.item.id, interest.capsule.id);
                map[key] = annotation.id;
              }
            });
          },
          {},
        );
      }),
      annotationCapsuleSet: undefined,
      highlightId: '',
    });
  }

  get id(): string {
    return this.state.get('id');
  }

  get document(): string {
    return this.state.get('document');
  }

  get isDiscoverable(): boolean {
    return this.state.get('isDiscoverable');
  }

  get isExpanded(): boolean {
    return this.state.get('isExpanded');
  }

  get width(): number {
    return this.state.get('width');
  }

  get height(): number {
    return this.state.get('height');
  }

  get isCommentsExpanded(): boolean {
    return this.state.get('isCommentsExpanded');
  }

  get isAnnotatesExpanded(): boolean {
    return this.state.get('isAnnotatesExpanded');
  }

  get annotations(): Annotation[] {
    return this.state.get('annotations');
  }

  get annotatedItemIds(): Record<string, string> {
    return this.state.get('annotatedItemIds');
  }

  get annotationCapsuleSet(): AnnotationCapsuleSet | undefined {
    return this.state.get('annotationCapsuleSet');
  }

  get highlightId(): string {
    return this.state.get('highlightId');
  }

  get isCkEnabled(): boolean {
    return this.state.get('isCkEnabled');
  }

  /**
   * Filters a collection of annotations to only those that are a journal entry for the specified worksheet.
   *
   * @param {Annotation[]} annotations - The collection to filter
   * @param {String} worksheetId - The worksheet
   * @returns The filtered collection of annotations
   */
  findJournalEntries(annotations: Annotation[], worksheetId: string) {
    return _.filter(
      annotations,
      (annotation: Annotation) => !annotation.discoverable && _.some(annotation.interests, ['item.id', worksheetId]),
    );
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @returns {Object} The dehydrated items.
   */
  dehydrate() {
    return _.omit(this.state.serialize(), [
      'annotations',
      'annotationCapsuleSet',
      'document',
      'highlightId',
      'isDiscoverable',
    ]);
  }

  /**
   * Re-creates the annotations. All necessary data needed to rehydrate is persisted so no actions have to be
   * called.
   *
   * @param {AnnotationStoreState} dehydratedState Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState: AnnotationStoreState) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    /**
     * Sets the id of the loaded annotation.
     *
     * @param payload - Object container for arguments
     * @param payload.id - The id
     */
    ANNOTATION_SET_ID: (payload: { id?: string }) => {
      this.state.set('id', payload.id);
    },

    /**
     * Sets the document of the loaded annotation.
     *
     * @param payload - Object container for arguments
     * @param payload.document - The document
     */
    ANNOTATION_SET_DOCUMENT: (payload: { document?: string }) => {
      this.state.set('document', payload.document);
    },

    /**
     * Sets the isDiscoverable mode flag
     *
     * @param payload - Object container for arguments
     * @param payload.isDiscoverable - The isDiscoverable mode flag
     */
    ANNOTATION_SET_IS_DISCOVERABLE: (payload: { isDiscoverable: boolean }) => {
      this.state.set('isDiscoverable', payload.isDiscoverable);
    },

    /**
     * Sets the isExpanded state
     *
     * @param payload - Object container for arguments
     * @param payload.expand - The isExpanded state
     */
    ANNOTATION_SET_IS_EXPANDED: (payload: { expand: boolean }) => {
      this.state.set('isExpanded', payload.expand);
    },

    /**
     * Sets the width state
     *
     * @param payload - Object container for arguments
     * @param payload.width - The width state
     */
    ANNOTATION_SET_WIDTH: (payload: { width: number }) => {
      this.state.set('width', payload.width);
      this.state.commit();
    },

    /**
     * Sets the height state
     *
     * @param payload - Object container for arguments
     * @param payload.height - The height state
     */
    ANNOTATION_SET_HEIGHT: (payload: { height: number }) => {
      this.state.set('height', payload.height);
      this.state.commit();
    },

    /**
     * Sets the isCommentsExpanded state
     *
     * @param payload - Object container for arguments
     * @param payload.expand - The isCommentsExpanded state
     */
    ANNOTATION_SET_IS_COMMENTS_EXPANDED: (payload: { expand: boolean }) => {
      this.state.set('isCommentsExpanded', payload.expand);
    },

    /**
     * Sets the isAnnotatesExpanded state
     *
     * @param payload - Object container for arguments
     * @param payload.expand - The isAnnotatesExpanded state
     */
    ANNOTATION_SET_IS_ANNOTATES_EXPANDED: (payload: { expand: boolean }) => {
      this.state.set('isAnnotatesExpanded', payload.expand);
    },

    /**
     * Sets the list of annotations.
     *
     * @param payload - Object container for arguments
     * @param payload.annotations - The annotations
     */
    ANNOTATION_SET_ANNOTATIONS: (payload: { annotations: Annotation[] }) => {
      this.state.set('annotations', payload.annotations);
    },

    /**
     * Replaces an annotation in this list of annotations, or adds it to the list if not already present.
     *
     * @param payload - Object container for arguments
     * @param payload.id - The ID of the annotation to set
     * @param payload.annotation - The annotation
     */
    ANNOTATION_SET_ANNOTATION: (payload: { id: string; annotation: Annotation }) => {
      const cursor = this.state.select('annotations');
      const index = _.findIndex(cursor.get(), ['id', payload.id]);
      if (index >= 0) {
        cursor.splice([index, 1, payload.annotation]);
      } else {
        cursor.push(payload.annotation);
      }
    },

    /**
     * Sets the capsule set that is used to store annotations in this worksheet
     *
     * @param payload - Object container for arguments
     * @param payload.id - ID of the capsule set
     * @param payload.maximumDuration - Duration of the largest annotation that has been stored in
     *   this capsule set, in milliseconds
     */
    ANNOTATION_SET_CAPSULE_SET: (payload: { id: string; maximumDuration: number }) => {
      this.state.set('annotationCapsuleSet', {
        id: payload.id,
        maximumDuration: payload.maximumDuration,
      });
    },

    /**
     * Sets the id of the annotation that should be highlighted
     *
     * @param payload - Object container for arguments
     * @param payload.id - ID of the annotation to be highlighted
     */
    ANNOTATION_SET_HIGHLIGHT_ID: (payload: { id: string }) => {
      this.state.set('highlightId', payload.id);
    },

    ANNOTATION_SET_IS_CK_ENABLED: (payload: { isCkEnabled: boolean }) => {
      this.state.set('isCkEnabled', payload.isCkEnabled);
    },
  };
}
