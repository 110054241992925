import { base64guid } from '@/utilities/utilities';

let sessionId: string | null = null;

/**
 * Returns a new interactive session ID, which is just a GUID in base64
 *
 * @returns interactive session ID
 */
export function generateInteractiveSessionId() {
  sessionId = base64guid();
  return sessionId;
}

/**
 * Returns a new interactive session ID, which is just a GUID in base64
 * If regenerate is set to true then a new sessionId will be generated.
 *
 * @returns interactive session ID
 */
export function getSessionId(regenerate = false): string {
  if (!sessionId || regenerate) {
    sessionId = generateInteractiveSessionId();
  }
  return sessionId;
}
