// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { DISPLAY_MODE } from '@/main/app.constants';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { sqDurationStore, sqTrendStore, sqWorkbenchStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import { BaseToolStore, ParameterDefinitions } from '@/toolSelection/baseTool.store';
import { isItemRedacted } from '@/utilities/redaction.utilities';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { RangeExport } from '@/trendData/duration.store';
import { SAMPLES_TABLE_MODE } from '@/tools/exportOData/odata.constants';

export const GRID_OPTION = {
  AUTOMATIC: 'automatic',
  CUSTOM: 'custom',
  ORIGINAL: 'original',
};

export class ExportExcelPanelStore extends BaseToolStore {
  static readonly storeName = 'sqExportExcelPanelStore';
  parameterDefinitions: ParameterDefinitions = {
    exportSignals: {
      predicate: ['name', 'a'],
      multiple: true,
    },
    exportConditions: {
      predicate: ['name', 'a'],
      multiple: true,
    },
  };
  type = TREND_TOOLS.EXPORT_EXCEL;

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, {
        name: `${sqWorkbookStore.name}_${sqWorkbookStore.getWorksheetName(sqWorkbenchStore.stateParams.worksheetId)}`,
        exportStatistics: true,
        exportSamples: true,
        exportCapsules: true,
        timeRange: {
          start: sqDurationStore.displayRange.start.valueOf(),
          end: sqDurationStore.displayRange.end.valueOf(),
        },
        exportTimeZone: sqWorksheetStore.timezone,
        gridOption: GRID_OPTION.CUSTOM,
        gridSize: ExportExcelPanelStore.generateDefaultGridSize(
          moment.duration(sqDurationStore.displayRange.end.valueOf() - sqDurationStore.displayRange.start.valueOf()),
        ),
        gridOrigin: moment().tz(sqWorksheetStore.timezone.name).startOf('year').valueOf(),
        gridOriginEnabled: false,
        chainView: sqTrendStore.isTrendViewChainView(),
        capsuleTime: sqTrendStore.isTrendViewCapsuleTime(),
        samplesMode: this.getSamplesMode(sqTrendStore.isTrendViewChainView(), sqTrendStore.isTrendViewCapsuleTime()),
      }),
    );
  }

  get exportStatistics() {
    return this.state.get('exportStatistics');
  }

  get exportSamples() {
    return this.state.get('exportSamples');
  }

  get exportCapsules() {
    return this.state.get('exportCapsules');
  }

  get exportSignals() {
    return this.state.get('exportSignals');
  }

  get exportConditions() {
    return this.state.get('exportConditions');
  }

  get timeRange(): RangeExport {
    const state = this.state;
    const start = state.get('timeRange', 'start');
    const end = state.get('timeRange', 'end');

    return {
      duration: moment.duration(end - start),
      end: moment.utc(end),
      start: moment.utc(start),
    };
  }

  get exportTimeZone() {
    return this.state.get('exportTimeZone');
  }

  get gridOption() {
    return this.state.get('gridOption');
  }

  get gridSize() {
    return this.state.get('gridSize');
  }

  get gridOrigin() {
    return moment(this.state.get('gridOrigin')).tz(sqWorksheetStore.timezone.name);
  }

  get gridOriginEnabled() {
    return this.state.get('gridOriginEnabled');
  }

  get capsuleTime() {
    return this.state.get('capsuleTime');
  }

  get chainView() {
    return this.state.get('chainView');
  }

  get samplesMode() {
    return this.getSamplesMode(this.chainView, this.capsuleTime);
  }

  static generateDefaultGridSize(duration) {
    // Same as the values in ExportUtilities.kt#getAutomaticResamplePeriod
    const gridLookup = [
      [moment.duration(15, 'minutes'), { value: 1, units: 'second' }], // 0-900 rows
      [moment.duration(3, 'hours'), { value: 15, units: 'second' }], // 60-720 rows
      [moment.duration(12, 'hours'), { value: 30, units: 'second' }], // 360-1440 rows
      [moment.duration(3, 'days'), { value: 1, units: 'min' }], // 720-4320 rows
      [moment.duration(14, 'days'), { value: 5, units: 'min' }], // 864-4032 rows
      [moment.duration(62, 'days'), { value: 30, units: 'min' }], // 672-2976 rows
      [moment.duration(185, 'days'), { value: 1, units: 'hour' }], // 1488-4440 rows
      [moment.duration(5, 'years'), { value: 1, units: 'day' }], // 185-1827 rows
      [moment.duration(9999, 'years'), { value: 7, units: 'day' }], // 260-N rows
    ];
    return _.find(gridLookup, function ([key, value]) {
      return duration <= key;
    })[1];
  }

  /**
   * Exports state, so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the prediction panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  setChainViewAndCapsuleTime(chainView: boolean, capsuleTime: boolean) {
    this.state.set('chainView', chainView);
    this.state.set('capsuleTime', capsuleTime);
  }

  getSamplesMode(chainView, capsuleTime) {
    if (chainView) {
      return SAMPLES_TABLE_MODE.CHAIN;
    } else if (capsuleTime) {
      return SAMPLES_TABLE_MODE.CAPSULE;
    } else {
      return SAMPLES_TABLE_MODE.CALENDAR;
    }
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    /**
     * Initializes the items to their default values. Done here so that this store doesn't dehydrate a bunch of
     * items when it is not in use.
     *
     * @param {Object} payload - Object containing state information
     * @param {String} payload.mode - The display mode being set, one of DISPLAY_MODE
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     */
    INVESTIGATE_SET_DISPLAY_MODE: (payload: { mode: string; type: string }) => {
      this.reset(payload);

      const getExportItems = (itemType) => {
        return _.chain(
          getAllItems({
            workingSelection: true,
            itemTypes: [itemType],
          }),
        )
          .reject(isItemRedacted)
          .map((item) => _.pick(item, this.TOOL_ITEM_PROPS))
          .value();
      };

      if (payload.mode === DISPLAY_MODE.NEW && payload.type === TREND_TOOLS.EXPORT_EXCEL) {
        this.state.set('exportSignals', getExportItems(ITEM_TYPES.SERIES));
        this.state.set('exportConditions', getExportItems(ITEM_TYPES.CONDITION));
      }
    },

    /**
     * Sets the Samples export mode
     *
     * @param {Object} payload - Object container
     * @param {SAMPLES_TABLE_MODE} payload.samplesMode - Value for samples table mode
     */
    EXPORT_EXCEL_SAMPLES_MODE: (payload: { samplesMode: string }) => {
      const mode = payload.samplesMode;
      if (mode === SAMPLES_TABLE_MODE.CHAIN) {
        this.setChainViewAndCapsuleTime(true, false);
      } else if (mode === SAMPLES_TABLE_MODE.CAPSULE) {
        this.setChainViewAndCapsuleTime(false, true);
      } else {
        this.setChainViewAndCapsuleTime(false, false);
      }
    },

    /**
     * Sets whether statistics should be exported
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.exportStatistics - True to export statistics
     */
    EXPORT_EXCEL_STATISTICS: (payload: { exportStatistics: boolean }) => {
      this.state.set('exportStatistics', payload.exportStatistics);
    },

    /**
     * Sets whether signal samples should be exported
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.exportSamples - True to export signal samples
     */
    EXPORT_EXCEL_SAMPLE_DATA: (payload: { exportSamples: boolean }) => {
      this.state.set('exportSamples', payload.exportSamples);
    },

    /**
     * Sets whether condition capsules should be exported
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.exportCapsules - True to export condition capsules
     */
    EXPORT_EXCEL_CAPSULE_DATA: (payload: { exportCapsules: boolean }) => {
      this.state.set('exportCapsules', payload.exportCapsules);
    },

    /**
     * Set the time range end value that is used in the form. Ensures that start is not before end.
     *
     * @param {Object} payload - Object container
     * @param {moment} payload.start - The start time
     */
    EXPORT_EXCEL_TIME_RANGE_START: (payload: { start: Date }) => {
      const newStart = payload.start.valueOf();
      const end = this.state.get(['timeRange', 'end']);
      this.state.set(['timeRange', 'start'], newStart);

      if (newStart > end) {
        this.state.set(['timeRange', 'end'], newStart);
      }
    },

    /**
     * Set the time range end value that is used in the form. Ensures that end is not after start.
     *
     * @param {Object} payload - Object container
     * @param {moment} payload.end - The start time
     */
    EXPORT_EXCEL_TIME_RANGE_END: (payload: { end: Date }) => {
      const newEnd = payload.end.valueOf();
      const start = this.state.get(['timeRange', 'start']);
      this.state.set(['timeRange', 'end'], newEnd);

      if (newEnd < start) {
        this.state.set(['timeRange', 'start'], newEnd);
      }
    },

    /**
     * Set the time zone that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.exportTimeZone - The time zone object from Moment.JS
     * @param {String} payload.exportTimeZone.name - The time zone name to be used by the backend
     */
    EXPORT_EXCEL_EXPORT_TIME_ZONE: (payload: { exportTimeZone: { name: string } }) => {
      this.state.set(['exportTimeZone'], payload.exportTimeZone);
    },

    /**
     * Updates the duration of the time range by shifting the start date.
     *
     * @param {Object} payload - Object container
     * @param {moment.duration} payload.duration - The duration
     */
    EXPORT_EXCEL_DURATION: (payload: { duration: moment.Duration }) => {
      this.state.set(['timeRange', 'start'], this.state.get('timeRange', 'end') - payload.duration.asMilliseconds());
    },

    /**
     * Set the selected option for gridding.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.gridOption - The grid option
     */
    EXPORT_EXCEL_GRID_OPTION: (payload: { gridOption: string }) => {
      this.state.set('gridOption', payload.gridOption);
    },

    /**
     * If GRID_OPTION.CUSTOM is chosen, the duration between samples to be used for gridding.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.gridSize - The grid size, value and unit
     */
    EXPORT_EXCEL_GRID_SIZE: (payload: { gridSize: string }) => {
      this.state.set('gridSize', payload.gridSize);
    },

    /**
     * If GRID_OPTION.CUSTOM is chosen, the timestamp to originate the gridding through.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.gridOrigin - The origin of the grid, in the form of a Moment
     */
    EXPORT_EXCEL_GRID_ORIGIN: (payload: { gridOrigin: { value: string } }) => {
      this.state.set('gridOrigin', payload.gridOrigin.valueOf());
    },

    /**
     * The boolean to indicate whether the gridOrigin will be used.
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.gridOriginEnabled - True if the gridOrigin should be used
     */
    EXPORT_EXCEL_GRID_ORIGIN_ENABLED: (payload: { gridOriginEnabled: boolean }) => {
      this.state.set('gridOriginEnabled', payload.gridOriginEnabled);
    },
  };
}
