import {
  AutoRate,
  AutoUpdateTimeScheduleEntry,
  DailyScheduleType,
  MonthlyScheduleData,
  MonthlyScheduleTypeName,
  ScheduleType,
  ScheduleTypeName,
  UpdatedSchedule,
  WeeklyScheduleData,
} from '@/schedule/schedule.types';
import _ from 'lodash';
import { quartzCronExpressionHelper } from '@/annotation/reportContent.utilities';
import { MAX_DAY, MAX_MONTH, MIN_DAY, MIN_MONTH } from '@/schedule/schedule.constants';

export const defaultScheduleType: () => ScheduleType = () => ({
  selectedType: ScheduleTypeName.DAILY,
  data: {
    [ScheduleTypeName.DAILY]: DailyScheduleType.EVERY_DAY,
    [ScheduleTypeName.WEEKLY]: new WeeklyScheduleData(),
    [ScheduleTypeName.MONTHLY]: new MonthlyScheduleData(),
  },
});

/**
 * Helper function to assert that all of a given array of values are numbers.
 *
 * @param values - The values to check
 *
 * @returns - true if all the values are finite and numeric; false otherwise
 */
export const areAllElementsANumber = (values: string[]): boolean => _.every(values, (el) => _.isFinite(_.toNumber(el)));

/**
 * Determine the times at which updates based on this schedule will be run.
 *
 * @param minutes - An array of the minutes specified by the cron schedule
 * @param hours - An array of the hours specified by the cron schedule
 *
 * @returns - corresponding to the source cron schedule
 * @throws {Error} if there's an error parsing the schedule
 */
export function determineTimeEntries(minutes: string[], hours: string[]): AutoUpdateTimeScheduleEntry[] {
  const results: string[] = [];
  if (!areAllElementsANumber(minutes) || !areAllElementsANumber(hours)) {
    throw new Error('Unable to parse the schedule');
  }

  _.forEach(hours, (hour) => {
    const paddedHour = hour.padStart(2, '0');
    _.forEach(minutes, (minute) => {
      const paddedMinute = minute.padStart(2, '0');
      results.push(`${paddedHour}:${paddedMinute}`);
    });
  });

  // If we support more strategies in the future, we will have to modify this
  return _.map(results, (time) => ({ time }));
}

/**
 * Return a Quartz cron schedule for a {@link ScheduleType} object describing the auto update schedule.
 *
 * @param schedule - The auto update schedule for which a cron expression is desired
 * @param timeEntry - The time to schedule
 * @returns - The cron schedule
 */
export function entriesToQuartzCronExpression(
  schedule: ScheduleType,
  timeEntry: AutoUpdateTimeScheduleEntry,
): string | undefined {
  if (_.isEmpty(schedule)) {
    return;
  }

  const quartzCronExpressionHelperInstance = quartzCronExpressionHelper();
  const DAYS_OF_THE_WEEK_TO_CRON = {
    sunday: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.SUN,
    monday: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.MON,
    tuesday: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.TUE,
    wednesday: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.WED,
    thursday: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.THU,
    friday: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.FRI,
    saturday: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.SAT,
    SUNDAY: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.SUN,
    MONDAY: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.MON,
    TUESDAY: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.TUE,
    WEDNESDAY: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.WED,
    THURSDAY: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.THU,
    FRIDAY: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.FRI,
    SATURDAY: quartzCronExpressionHelperInstance.CRON_DAYS_OF_WEEK.SAT,
  };

  const { selectedType, data } = schedule;
  const times = [timeEntry.time];

  if (selectedType === ScheduleTypeName.DAILY) {
    return data[ScheduleTypeName.DAILY] === DailyScheduleType.EVERY_DAY
      ? quartzCronExpressionHelperInstance.createDailySchedule(times)
      : quartzCronExpressionHelperInstance.createWeekdaySchedule(times);
  } else if (selectedType === ScheduleTypeName.WEEKLY) {
    const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = data[ScheduleTypeName.WEEKLY];
    const daysOfWeek = [];

    if (sunday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.sunday);
    if (monday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.monday);
    if (tuesday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.tuesday);
    if (wednesday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.wednesday);
    if (thursday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.thursday);
    if (friday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.friday);
    if (saturday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.saturday);

    return quartzCronExpressionHelperInstance.createWeeklySchedule(daysOfWeek, times);
  } else if (selectedType === ScheduleTypeName.MONTHLY) {
    const typeOfMonthly = data[ScheduleTypeName.MONTHLY].selectedType;
    if (typeOfMonthly === MonthlyScheduleTypeName.BY_DAY_OF_MONTH) {
      const subData = data[ScheduleTypeName.MONTHLY].data[MonthlyScheduleTypeName.BY_DAY_OF_MONTH];
      return quartzCronExpressionHelperInstance.createMonthlyScheduleByDayOfMonth(
        subData.day,
        subData.numberOfMonths,
        times,
      );
    } else if (typeOfMonthly === MonthlyScheduleTypeName.BY_DAY_OF_WEEK) {
      const subData = data[ScheduleTypeName.MONTHLY].data[MonthlyScheduleTypeName.BY_DAY_OF_WEEK];
      return quartzCronExpressionHelperInstance.createMonthlyScheduleByDayOfWeek(
        subData.nth,
        DAYS_OF_THE_WEEK_TO_CRON[subData.dayOfWeek],
        subData.numberOfMonths,
        times,
      );
    } else {
      throw new Error('Cannot map cron expression due to unknown monthly schedule');
    }
  } else if (selectedType === ScheduleTypeName.LIVE) {
    // do nothing
  } else {
    throw new Error('Cannot map cron expression due to unknown schedule');
  }
}

export const isDailyScheduleValid = (dailyScheduleType?: DailyScheduleType) =>
  _.includes(Object.values(DailyScheduleType), dailyScheduleType);

export const isWeeklyScheduleValid = ({
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
}: WeeklyScheduleData) => monday || tuesday || wednesday || thursday || friday || saturday || sunday;

export const isMonthlyScheduleValid = ({ selectedType, data }: MonthlyScheduleData) => {
  if (_.isNil(selectedType)) {
    return false;
  }

  if (selectedType === MonthlyScheduleTypeName.BY_DAY_OF_MONTH) {
    const { numberOfMonths, day } = data[MonthlyScheduleTypeName.BY_DAY_OF_MONTH];

    return (
      _.isFinite(numberOfMonths) &&
      numberOfMonths >= MIN_MONTH &&
      numberOfMonths <= MAX_MONTH &&
      _.isFinite(day) &&
      day >= MIN_DAY &&
      day <= MAX_DAY
    );
  }

  const { numberOfMonths } = data[MonthlyScheduleTypeName.BY_DAY_OF_WEEK];

  return _.isFinite(numberOfMonths) && numberOfMonths >= MIN_MONTH && numberOfMonths <= MAX_MONTH;
};

export const determineInvalidEntries = (entries: AutoUpdateTimeScheduleEntry[]): boolean[] => {
  // In future, account for different strategies too.
  const times = entries.map((entry) => entry.time);

  // find duplicates
  return times.map((value, index) => {
    const firstFindIndex = times.indexOf(value);
    const lastFindIndex = times.lastIndexOf(value);

    return firstFindIndex === lastFindIndex ? false : index !== firstFindIndex;
  });
};

export const areTimeEntriesValid = (entries: AutoUpdateTimeScheduleEntry[]) => {
  if (_.isEmpty(entries)) {
    return false;
  }

  return _.every(determineInvalidEntries(entries), (isInvalid) => !isInvalid);
};

/**
 * Determine whether the current schedule is valid
 *
 * @param updatedSchedule - The current schedule
 *
 * @returns true if the schedule is valid; false otherwise
 */
export const isScheduleValid = (updatedSchedule: UpdatedSchedule | undefined): boolean => {
  if (!updatedSchedule) {
    return false;
  }

  const { irregularSchedule, selectedScheduleType, timeEntries } = updatedSchedule;
  if (_.isNil(selectedScheduleType) || !_.isNil(irregularSchedule)) {
    return false;
  }

  const { selectedType, data } = selectedScheduleType;
  switch (selectedType) {
    case ScheduleTypeName.DAILY:
      return isDailyScheduleValid(data[ScheduleTypeName.DAILY]) && areTimeEntriesValid(timeEntries);
    case ScheduleTypeName.WEEKLY:
      return isWeeklyScheduleValid(data[ScheduleTypeName.WEEKLY]) && areTimeEntriesValid(timeEntries);
    case ScheduleTypeName.MONTHLY:
      return isMonthlyScheduleValid(data[ScheduleTypeName.MONTHLY]) && areTimeEntriesValid(timeEntries);
    case ScheduleTypeName.LIVE:
      // The live update rate should always be valid, since unit and value choices are constrained by the UI.
      return true;
    default:
      return false;
  }
};

export const getNewCronSchedule = ({ liveRate, selectedScheduleType, timeEntries }: UpdatedSchedule): string[] => {
  const { selectedType } = selectedScheduleType;
  if (selectedType !== ScheduleTypeName.LIVE) {
    return _.chain(timeEntries)
      .map((timeEntry) => entriesToQuartzCronExpression(selectedScheduleType, timeEntry))
      .compact()
      .value();
  }

  const rate: AutoRate = {
    value: liveRate.value,
    units: liveRate.unit.unit[0],
  };

  return [quartzCronExpressionHelper().rateToCronSchedule(rate)];
};
