import _ from 'lodash';

let unsubscribeFromWorkbook: (() => void)[] = [];
let unsubscribeFromWorkstepChannel = _.noop;
let unsubscribeFromReportUpdatesChannel = _.noop;

export function cleanupOnUnload() {
  unsubscribeFromWorkbook.forEach((fn) => fn());
  unsubscribeFromWorkstepChannel();
  unsubscribeFromReportUpdatesChannel();
}

export function setUnsubscribeFromWorkbook(functionDefs: (() => void)[]) {
  unsubscribeFromWorkbook = functionDefs;
}

export function setUnsubscribeFromWorkstepChannel(functionDef: () => void) {
  unsubscribeFromWorkstepChannel = functionDef;
}

export function setUnsubscribeFromReportUpdatesChannel(functionDef: () => void) {
  unsubscribeFromWorkstepChannel = functionDef;
}
