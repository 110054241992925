import _ from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@seeqdev/qomponents';
import { SearchWidget } from '@/search/SearchWidget.organism';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { isAsset } from '@/utilities/utilities';
import { onEventPreventPropagation } from '@/core/onEnterKeypress.util';
import { AnyProperty } from '@/utilities.types';
import { ItemPreviewV1 } from '@/sdk/model/ItemPreviewV1';

export interface SelectItemSearchWidgetProps {
  onSelect: (item: SelectedItem) => void;
  iconPartialTooltipKey: string;
  itemId?: string | string[];
  validateItem: (item: SelectedItem) => Promise<boolean>;
  scopeIds: string[];
  excludeGloballyScoped?: boolean;
  modalId?: string;
  searchTypes: string[];
  isMultiple?: boolean;
  selectAllCallback?: (itemIds: string[]) => void;
  areAllSelected?: (itemIds: string[]) => boolean;
  showPinnedAssets?: boolean;
  showOnlyScopedAssets?: boolean;
  showOnlyConditions?: boolean;
  showSwitchToLocallyScoped?: boolean;
  onSwitchToLocallyScoped?: () => void;
}

export type SelectedItem = {
  id: string;
  name: string;
  ancestors?: ItemPreviewV1[];
};

export const SelectItemSearchWidget: React.FunctionComponent<SelectItemSearchWidgetProps> = ({
  onSelect,
  iconPartialTooltipKey,
  itemId,
  validateItem,
  scopeIds,
  excludeGloballyScoped = false,
  modalId,
  searchTypes,
  isMultiple = false,
  selectAllCallback = undefined,
  areAllSelected = () => false,
  showPinnedAssets = false,
  showOnlyScopedAssets = false,
  showOnlyConditions = false,
  showSwitchToLocallyScoped = false,
  onSwitchToLocallyScoped = _.noop,
}) => {
  const { t } = useTranslation();

  const isSelectingAssets = searchTypes.includes(SeeqNames.Types.Asset);

  const searchResultIcons = useCallback(
    (item: AnyProperty): Promise<React.ReactNode> => {
      const onlySearchingAssets = isSelectingAssets && searchTypes.length === 1;
      if (!isAsset(item) && onlySearchingAssets) {
        return Promise.resolve(null);
      }

      return validateItem(item as SelectedItem).then((isValid) => {
        let isSelected: boolean;
        if (isMultiple) {
          isSelected = _.includes(itemId, item.id);
        } else {
          isSelected = itemId ? item.id === itemId : false;
        }

        return isValid ? (
          <Checkbox
            type={isMultiple ? 'checkbox' : 'radio'}
            testId="itemSelect"
            tooltip={t(`${iconPartialTooltipKey}.ASSET_TOOLTIP`)}
            tooltipPlacement="top"
            checked={isSelected}
            onClick={onEventPreventPropagation(() => onSelect(item as SelectedItem))}
          />
        ) : (
          <Checkbox
            type={isMultiple ? 'checkbox' : 'radio'}
            testId="itemSelectInvalid"
            extraClassNames="disabled mr3 p3"
            tooltip={t(`${iconPartialTooltipKey}.ASSET_TOOLTIP_INVALID`)}
            onClick={onEventPreventPropagation(_.noop)}
            disabled={true}
          />
        );
      });
    },
    [itemId, validateItem, iconPartialTooltipKey, onSelect],
  );

  return (
    <SearchWidget
      isSelectingAsset={isSelectingAssets}
      iconClasses="sq-fairly-dark-gray"
      pane="modal"
      searchTypes={searchTypes}
      searchResultIcons={searchResultIcons}
      allowAssetReplacement={false}
      showOnlyResults={!(searchTypes.includes(SeeqNames.Types.Asset) && searchTypes.length > 2)}
      showPinnedAssets={showPinnedAssets}
      scopeIds={scopeIds}
      excludeGloballyScoped={excludeGloballyScoped}
      modalId={modalId}
      predefinedSearchTypes={searchTypes}
      onSelectItem={onSelect}
      selectAllCallback={selectAllCallback}
      areAllSelected={areAllSelected}
      showOnlyScopedAssets={showOnlyScopedAssets}
      showOnlyConditions={showOnlyConditions}
      showSwitchToLocallyScoped={showSwitchToLocallyScoped}
      onSwitchToLocallyScoped={onSwitchToLocallyScoped}
    />
  );
};
