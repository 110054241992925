import { CustomCellRendererProps } from '@ag-grid-community/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Component that displays a message indicating the number of additional rows in a simple/condition table.
 */
export const MoreRowsMessage = ({ node }: CustomCellRendererProps<{ remainingRows: number }>) => {
  const { t } = useTranslation();

  const message = t('TABLE_BUILDER.TABLE_HAS_MORE_ROWS', { remainingRows: node.data?.remainingRows });

  return (
    <div
      className="alert-warning ptb3 plr15 inheritHeight"
      style={{ width: document.querySelector<HTMLElement>('.ag-header-row')?.clientWidth }}>
      {message}
    </div>
  );
};
