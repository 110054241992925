import _ from 'lodash';
import { ColumnRuleOutputV1 } from 'sdk/model/ColumnRuleOutputV1';
import { ColumnDefinitionOutputV1, ColumnRuleInputV1 } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { AllColumnEnumOptions, ColumnRule } from '@/tableDefinitionEditor/columnRules/columnRule.constants';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { ColumnRulesWithMetaData } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.constants';
import { getColumnRuleWithMetaData } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.utilities';
import { ColumnRuleWithMetadata } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.types';
import {
  DecoratedScalingTableColumnDefinition,
  PotentialColumnDefinition,
  TableDefinitionAccessSettings,
} from '@/tableDefinitionEditor/tableDefinition.types';
import { t } from 'i18next';
import { sqTableDefinitionStore } from '@/core/core.stores';

export const getAllowedColumnTypes = (columnRule: ColumnRule): ColumnTypeEnum[] => {
  return getColumnRuleWithMetaData(columnRule)?.allowedOnColumnTypes ?? [];
};

export const getRulesAllowedOnColumnType = (columnType: ColumnTypeEnum): ColumnRuleWithMetadata[] => {
  return ColumnRulesWithMetaData.filter((columnRule) => columnRule.allowedOnColumnTypes.includes(columnType));
};

export const getAllowedColumnTypesGivenCurrentColumnAndSetOfRules = (
  ruleInputs: ColumnRuleInputV1[],
  currentColumnTypeIfConfigured?: ColumnTypeEnum,
): ColumnTypeEnum[] => {
  return ruleInputs.reduce((commonTypes, ruleInput) => {
    const rule: ColumnRule = getRuleTypeFromRuleInput(ruleInput);
    let currentList: ColumnTypeEnum[];
    if (
      rule === SeeqNames.MaterializedTables.Rules.Constant.BaseConstant &&
      currentColumnTypeIfConfigured !== undefined
    ) {
      // A constant rule that has been configured on a given column is by necessity of the same type as the
      // column, so we don't want to allow changing the column type if you have a configured constant rule
      currentList = [currentColumnTypeIfConfigured];
    } else {
      currentList = getAllowedColumnTypes(rule);
    }
    return _.compact(commonTypes.filter((type) => currentList.includes(type)));
  }, AllColumnEnumOptions);
};

export const getRuleTypeFromRuleInput = (ruleInput: ColumnRuleInputV1): ColumnRule => {
  // Each rule input should have only one field filled out, so we can just get the first one as the rule type
  return Object.keys(ruleInput)[0] as ColumnRule;
};

export const columnRuleOutputToColumnRuleInput = (
  ruleOutput: ColumnRuleOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
  accessSettings: TableDefinitionAccessSettings,
): ColumnRuleInputV1 | any => {
  // The constant rule is unique in that there is only 1 api input type but multiple api output types
  const isConstantRule = /constant/i.test(ruleOutput.rule);
  const isScalarCreatorRule = /scalarCreator/i.test(ruleOutput.rule);
  let ruleType;
  if (isConstantRule) {
    ruleType = SeeqNames.MaterializedTables.Rules.Constant.BaseConstant;
  } else if (isScalarCreatorRule) {
    ruleType = SeeqNames.MaterializedTables.Rules.ScalarCreator.BaseScalarCreator;
  } else {
    ruleType = ruleOutput.rule;
  }
  if (!isValidColumnRule(ruleType)) {
    throw new Error(`Invalid rule type: ${ruleType}`);
  }

  const columnRuleWithMetadata = getColumnRuleWithMetaData(ruleType);
  if (!columnRuleWithMetadata) {
    throw new Error(`Could not find metadata for rule: ${ruleType}`);
  }

  const result = {
    [ruleType]: columnRuleWithMetadata.columnRuleOutputToColumnRuleInput(ruleOutput, otherColumns, accessSettings),
  };

  if (ruleType === 'formulaCreator') {
    return { ...result, inputs: ruleOutput.inputs };
  }

  return result;
};

const isValidColumnRule = (rule: string): rule is ColumnRule => {
  return ColumnRulesWithMetaData.some((columnRule) => columnRule.rule === rule);
};

const getDefaultColumnNameBase = () => t('SCALING.NEW_COLUMN_NAME_BASE');
export const generateColumnName = (baseName?: string): string => {
  const columns = sqTableDefinitionStore.columns;
  const existingColumnNames = columns
    .map((col) => col.displayName)
    // The following are reserved names and should not be used
    .concat([SeeqNames.MaterializedTables.DatumIdColumn, SeeqNames.MaterializedTables.ItemIdColumn]);
  const base = baseName ?? getDefaultColumnNameBase();
  const existingNames = new Set(existingColumnNames);
  let newName = base;
  let i = 1;
  while (existingNames.has(newName)) {
    newName = `${base} ${i}`;
    i++;
  }
  return newName;
};

const potentialAssetCreatorColumnsBasedOnExistingTextColumns = (
  columnDefinitionsToCreate: PotentialColumnDefinition[],
  allowedExistingColumnDefinitionsWithColumnIndex: (DecoratedScalingTableColumnDefinition & { columnIndex: number })[],
  startingIndexForNewColumns: number,
  scopedTo?: string,
): PotentialColumnDefinition[] => {
  const alreadyChosenPotentialColumns = columnDefinitionsToCreate.filter((col) => col.isOptionForDropdown);
  const textColumnsNotAlreadyUsedAsAssetInputs = allowedExistingColumnDefinitionsWithColumnIndex
    .filter((column) => column.columnType === ColumnTypeEnum.TEXT)
    .filter(
      (column) =>
        !allowedExistingColumnDefinitionsWithColumnIndex.some(
          (col) => col.rules[0]?.rule === 'assetCreator' && col.rules[0]?.inputs?.[0] === column.id,
        ),
    );

  return textColumnsNotAlreadyUsedAsAssetInputs
    .filter(
      (textColumn) =>
        !columnDefinitionsToCreate.some(
          (col) => col.columnRules[0]?.assetCreator?.columnIndex === textColumn.columnIndex,
        ),
    )
    .map<PotentialColumnDefinition>((col, index) => ({
      columnName: generateColumnName(
        t('SCALING.GENERATED_COLUMN_NAMES.ASSET_COLUMN_BASED_ON_TEXT_COLUMN', {
          existingColumn: col.displayName,
        }),
      ),
      columnType: ColumnTypeEnum.UUID,
      columnIndex: startingIndexForNewColumns + index + 1,
      isOptionForDropdown: true,
      sourceColumnDisplayName: col.displayName,
      dropdownDescription: col.dropdownDescription ?? t('SCALING.POTENTIAL_COLUMN_LABELS.ASSETS_FROM_TEXT'),
      isAllowedForFirstInput: col.isAllowedForFirstInput,
      columnRules: [
        {
          [SeeqNames.MaterializedTables.Rules.AssetCreator]: {
            columnIndex: col.columnIndex,
            isRoot: false,
            scopedTo,
          },
        },
      ],
    }))
    .filter((col) => !alreadyChosenPotentialColumns.some((chosenCol) => chosenCol.columnName === col.columnName));
};

const potentialFormulaPassThroughItemsBasedOnExistingUUIDColumns = (
  columnDefinitionsToCreate: PotentialColumnDefinition[],
  uuidColumnsWithAssetOrFormulaTag: (DecoratedScalingTableColumnDefinition & {
    columnIndex: number;
  })[],
  startingIndexForNewColumns: number,
  scopedTo?: string,
): PotentialColumnDefinition[] => {
  const alreadyChosenPotentialColumns = columnDefinitionsToCreate.filter((col) => col.isOptionForDropdown);
  return uuidColumnsWithAssetOrFormulaTag
    .filter((col) => col.isFormulaColumn)
    .filter(
      (uuidColumn) =>
        !columnDefinitionsToCreate.some((colDef) =>
          colDef.columnRules[0]?.formulaCreator?.columnIndexes.includes(uuidColumn.columnIndex),
        ),
    )
    .map<PotentialColumnDefinition>((col, index) => {
      return {
        columnName: generateColumnName(
          t('SCALING.GENERATED_COLUMN_NAMES.COPY', {
            existingColumn: col.displayName,
          }),
        ),
        columnType: ColumnTypeEnum.UUID,
        columnIndex: startingIndexForNewColumns + index + 1,
        sourceColumnDisplayName: col.displayName,
        dropdownDescription: t('SCALING.POTENTIAL_COLUMN_LABELS.FORMULA_JUMP_TAGS'),
        isOptionForDropdown: true,
        columnRules: [
          {
            [SeeqNames.MaterializedTables.Rules.FormulaCreator]: {
              columnIndexes: [col.columnIndex],
              scopedTo,
              formula: '$a',
              parameters: ['a'],
              name: col.displayName,
            },
          },
        ],
      };
    })
    .filter((col) => !alreadyChosenPotentialColumns.some((chosenCol) => chosenCol.columnName === col.columnName));
};

const potentialTextColumnsBasedOnExistingAssetColumns = (
  columnDefinitionsToCreate: PotentialColumnDefinition[],
  uuidColumnsWithAssetOrFormulaTag: (DecoratedScalingTableColumnDefinition & {
    columnIndex: number;
    isMadeWithCreatorRule: boolean;
  })[],
  startingIndexForNewColumns: number,
): PotentialColumnDefinition[] => {
  const alreadyChosenPotentialColumns = columnDefinitionsToCreate.filter((col) => col.isOptionForDropdown);
  const uuidColumnsWithOnlyAssetsNotCreatedInTable = uuidColumnsWithAssetOrFormulaTag.filter(
    (col) => col.isAssetColumn && !col.isMadeWithCreatorRule,
  );
  return uuidColumnsWithOnlyAssetsNotCreatedInTable
    .filter((col) => {
      return !columnDefinitionsToCreate.some(
        (colDef) => colDef.columnRules?.[0]?.getItemProperty?.columnIndex === col.columnIndex,
      );
    })
    .map<PotentialColumnDefinition>((col, index) => ({
      columnName: generateColumnName(
        t('SCALING.GENERATED_COLUMN_NAMES.NAME_TEXT_COLUMN_BASED_ON_ITEM_COLUMN', {
          existingColumn: col.displayName,
        }),
      ),
      columnType: ColumnTypeEnum.TEXT,
      columnIndex: startingIndexForNewColumns + index + 1,
      isOptionForDropdown: false,
      sourceColumnDisplayName: col.displayName,
      columnRules: [
        {
          [SeeqNames.MaterializedTables.Rules.GetItemProperty]: {
            propertyName: 'name',
            columnIndex: col.columnIndex,
          },
        },
      ],
    }))
    .filter((col) => !alreadyChosenPotentialColumns.some((chosenCol) => chosenCol.columnName === col.columnName));
};

const potentialAssetColumnsBasedOnExistingAssetColumns = (
  columnDefinitionsToCreate: PotentialColumnDefinition[],
  textColumnsBasedOnExistingAssetColumns: PotentialColumnDefinition[],
  startingIndexForNewColumns: number,
  scopedTo?: string,
): PotentialColumnDefinition[] => {
  const alreadyChosenPotentialColumns = columnDefinitionsToCreate.filter((col) => col.isOptionForDropdown);
  return textColumnsBasedOnExistingAssetColumns
    .filter(
      (col) =>
        !columnDefinitionsToCreate.some(
          (colDef) => colDef.columnRules?.[0]?.assetCreator?.columnIndex === col.columnIndex,
        ),
    )
    .map<PotentialColumnDefinition>((col, index) => ({
      columnName: generateColumnName(
        t('SCALING.GENERATED_COLUMN_NAMES.COPY', {
          existingColumn: col.sourceColumnDisplayName,
        }),
      ),
      columnType: ColumnTypeEnum.UUID,
      columnIndex: startingIndexForNewColumns + index + 1,
      isOptionForDropdown: true,
      dropdownDescription: 'SCALING.POTENTIAL_COLUMN_LABELS.ASSET_JUMP_TAGS',
      sourceColumnDisplayName: col.sourceColumnDisplayName,
      sourceColumnDisplayNameIfSourceIsNotYetCreated: col.columnName,
      columnRules: [
        {
          [SeeqNames.MaterializedTables.Rules.AssetCreator]: {
            columnIndex: col.columnIndex,
            isRoot: false,
            scopedTo,
          },
        },
      ],
    }))
    .filter((col) => !alreadyChosenPotentialColumns.some((chosenCol) => chosenCol.columnName === col.columnName));
};

export const getPotentialColumnsForTreePathRule = async (
  columnDefinitionsToCreate: PotentialColumnDefinition[],
  existingColumnDefinitions: DecoratedScalingTableColumnDefinition[],
  potentialRootColumnPairIfNotAlreadySelectedForCreation?: {
    rootText: PotentialColumnDefinition;
    rootAsset: PotentialColumnDefinition;
  },
  scopedTo?: string,
): Promise<PotentialColumnDefinition[]> => {
  const allowedExistingColumnDefinitionsWithColumnIndex = existingColumnDefinitions
    .map((column, index) => ({
      ...column,
      columnIndex: index + 1,
    }))
    .filter((column) => column.columnName !== SeeqNames.MaterializedTables.DatumIdColumn);

  const UUIDColumns = allowedExistingColumnDefinitionsWithColumnIndex
    .filter((column) => column.columnType === ColumnTypeEnum.UUID)
    .map((column, index) => {
      const isMadeWithCreatorRule = column.rules.some((rule) => rule.rule.includes('Creator'));
      const hasOnlyOneRule = column.rules.length === 1;
      return { ...column, isMadeWithCreatorRule: isMadeWithCreatorRule && hasOnlyOneRule };
    });

  const startingIndex = existingColumnDefinitions.length + columnDefinitionsToCreate.length;
  const potentialAssetColumnsBasedOnTextColumns = potentialAssetCreatorColumnsBasedOnExistingTextColumns(
    columnDefinitionsToCreate,
    allowedExistingColumnDefinitionsWithColumnIndex,
    startingIndex,
    scopedTo,
  );

  const uuidColumnsNotCreatedInTable = UUIDColumns.filter((col) => !col.isMadeWithCreatorRule);

  const potentialFormulaPassThroughItems = potentialFormulaPassThroughItemsBasedOnExistingUUIDColumns(
    columnDefinitionsToCreate,
    uuidColumnsNotCreatedInTable,
    startingIndex + potentialAssetColumnsBasedOnTextColumns.length,
    scopedTo,
  );

  const textColumnsBasedOnExistingAssetColumns = potentialTextColumnsBasedOnExistingAssetColumns(
    columnDefinitionsToCreate,
    uuidColumnsNotCreatedInTable,
    startingIndex + potentialAssetColumnsBasedOnTextColumns.length + potentialFormulaPassThroughItems.length,
  );

  const assetColumnsBasedOnExistingAssetColumns = potentialAssetColumnsBasedOnExistingAssetColumns(
    columnDefinitionsToCreate,
    textColumnsBasedOnExistingAssetColumns,
    startingIndex +
      potentialAssetColumnsBasedOnTextColumns.length +
      potentialFormulaPassThroughItems.length +
      textColumnsBasedOnExistingAssetColumns.length,
    scopedTo,
  );

  const potentialAdditionalColumns: PotentialColumnDefinition[] = columnDefinitionsToCreate.concat(
    potentialAssetColumnsBasedOnTextColumns,
    potentialFormulaPassThroughItems,
    textColumnsBasedOnExistingAssetColumns,
    assetColumnsBasedOnExistingAssetColumns,
  );
  const totalColumnsSoFar = potentialAdditionalColumns.length + existingColumnDefinitions.length;
  const rootColumns = potentialRootColumnPairIfNotAlreadySelectedForCreation
    ? [
        { ...potentialRootColumnPairIfNotAlreadySelectedForCreation.rootText, columnIndex: totalColumnsSoFar + 1 },
        { ...potentialRootColumnPairIfNotAlreadySelectedForCreation.rootAsset, columnIndex: totalColumnsSoFar + 2 },
      ]
    : [];
  return potentialAdditionalColumns.concat(rootColumns);
};
