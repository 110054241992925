import { Timezone } from '@/datetime/timezone.service';
import { DurationTimeUnit } from '@/main/app.constants';

export enum DailyScheduleType {
  EVERY_DAY = 'daily',
  EVERY_WEEKDAY = 'weekdays',
}

export enum MonthlyScheduleTypeName {
  BY_DAY_OF_MONTH = 'by-day-of-month',
  BY_DAY_OF_WEEK = 'by-day-of-week',
}

export enum DayOfTheWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface LiveUpdateRate {
  unit: DurationTimeUnit;
  value: number;
}

export enum ScheduleTypeName {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  LIVE = 'live',
}

export class WeeklyScheduleData {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;

  constructor() {
    this.monday = false;
    this.tuesday = false;
    this.wednesday = false;
    this.thursday = false;
    this.friday = false;
    this.saturday = false;
    this.sunday = false;
  }
}

export type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export class MonthlyScheduleData {
  selectedType: MonthlyScheduleTypeName;
  data: {
    [MonthlyScheduleTypeName.BY_DAY_OF_MONTH]: DayOfMonthSchedule;
    [MonthlyScheduleTypeName.BY_DAY_OF_WEEK]: DayOfWeekSchedule;
  };

  constructor() {
    this.selectedType = MonthlyScheduleTypeName.BY_DAY_OF_MONTH;
    this.data = {
      [MonthlyScheduleTypeName.BY_DAY_OF_MONTH]: {
        day: 1,
        numberOfMonths: 1,
      },
      [MonthlyScheduleTypeName.BY_DAY_OF_WEEK]: {
        nth: 1,
        dayOfWeek: DayOfTheWeek.MONDAY,
        numberOfMonths: 1,
      },
    };
  }
}

export interface DayOfWeekSchedule {
  nth: number;
  dayOfWeek: DayOfTheWeek;
  numberOfMonths: number;
}

export interface DayOfMonthSchedule {
  day: number;
  numberOfMonths: number;
}

export interface ScheduleData {
  [ScheduleTypeName.DAILY]: DailyScheduleType;
  [ScheduleTypeName.WEEKLY]: WeeklyScheduleData;
  [ScheduleTypeName.MONTHLY]: MonthlyScheduleData;
}

export interface ScheduleType {
  selectedType: ScheduleTypeName;
  data: ScheduleData;
}

export interface AutoUpdateTimeScheduleEntry {
  time: string;
}

export interface AutoRate {
  value: number;
  units: string;
}

export interface UpdatedSchedule {
  liveRate: LiveUpdateRate;
  selectedScheduleType: ScheduleType;
  irregularSchedule: string[] | undefined;
  timeEntries: AutoUpdateTimeScheduleEntry[];
  timezone: Timezone | undefined;
}
