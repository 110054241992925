// @ts-strict-ignore
import _ from 'lodash';
import { SearchPanes } from '@/search/search.constants';
import { BaseSearchStore } from '@/search/baseSearch.store';
import { PersistenceLevel } from '@/core/flux.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { hasInValidSearchTypes } from '@/search/search.utilities';

export class SearchStore extends BaseSearchStore {
  pane: SearchPanes = 'main';
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqSearchStore';

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} The state that is serialized
   */
  dehydrate() {
    const state = _.pick(this.state.serialize(), [
      'nameFilter',
      'isExactName',
      'descriptionFilter',
      'typeFilter',
      'datasourceFilter',
      'sortBy',
      'isAdvancedMode',
      'mode',
      'currentAsset',
      'selectAllChecked',
      'selectedItemIds',
      'recentDatasourceFilters',
      'isUsingDatasourcePrefsSearchFilters',
    ]) as any;
    if (state.mode === 'usages') {
      state.breadcrumbs = _.map(this.state.get('breadcrumbs'), (breadcrumb) => _.pick(breadcrumb, ['id']));
    }
    if (hasInValidSearchTypes(state.typeFilter)) {
      state.typeFilter = state.typeFilter?.filter((type: string) => type !== SeeqNames.Types.TableDefinition);
    }

    return state;
  }

  /**
   * Sets store from dehydratedState. If there are filters it fetches the results.
   *
   * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  handlers = {
    ...super.baseHandlers,
    SEARCH_TOGGLE_SELECT_ALL: ({ items }) => {
      const selectAll = this.state.get('selectAllChecked');
      this.state.set('selectAllChecked', !selectAll);
      // add all items to selectedItemIds
      // if all was selected then we need to clear the items as we're toggling to off:
      const allItemIds = _.map(items, 'id');
      this.state.set('selectedItemIds', selectAll ? [] : allItemIds);
    },
    SEARCH_TOGGLE_SELECTED_ITEM: ({ itemId, items }) => {
      const selectedItemIds = [...this.state.get('selectedItemIds')];
      const selectAllChecked = this.state.get('selectAllChecked');
      const itemIdx = _.indexOf(selectedItemIds, itemId);
      if (itemIdx > -1) {
        // item currently selected - unselect it:
        _.pullAt(selectedItemIds, itemIdx);
        this.state.set('selectedItemIds', selectedItemIds);
        // if select all is checked we should unselect it as "all" are no longer selected:
        if (selectAllChecked) {
          this.state.set('selectAllChecked', undefined);
        }
      } else {
        const updatedSelectedItemIds = [...selectedItemIds, itemId];
        this.state.set('selectedItemIds', updatedSelectedItemIds);
        if (updatedSelectedItemIds.length === items?.length && !selectAllChecked) {
          this.state.set('selectAllChecked', true);
        }
      }
    },
    SEARCH_CLEAR_SELECTED_ITEMS: () => {
      this.state.set('selectAllChecked', false);
      this.state.set('selectedItemIds', []);
    },
    SEARCH_SET_SELECT_ALL: ({ isAllSelected }) => {
      this.state.set('selectAllChecked', isAllSelected);
    },
  };
}
