import _ from 'lodash';

export const PREDICTION = {
  SCALE: {
    LINEAR: 'linear',
    LOG: 'log',
    POLYNOMIAL: 'polynomial',
    EXPANDED_BASIS: 'expandedBasis',
  },
  POLYNOMIAL: {
    MIN_VALUE: 2,
    MAX_VALUE: 9,
  },
  REGRESSION_METHODS: {
    ORDINARY_LEAST_SQUARES: 'OLS',
    RIDGE: 'Ridge',
    PRINCIPAL_COMPONENT_REGRESSION: 'PCA',
  },
};

export const POLYNOMIAL_VALUES = _.range(PREDICTION.POLYNOMIAL.MIN_VALUE, PREDICTION.POLYNOMIAL.MAX_VALUE + 1);
