import { Checkbox } from '@/core/Checkbox.atom';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import React from 'react';

export type ToggleSelectAllProps = {
  togglingSelectAll: boolean;
  isChecked: boolean;
  onClick: () => void;
  isDisabled?: boolean;
};

/**
 * Contains select-all/multi-select checkbox.
 *
 * Renders spinner instead of checkbox if `togglingSelectAll` is `true`
 */
export const ToggleSelectAll: React.FunctionComponent<ToggleSelectAllProps> = ({
  togglingSelectAll,
  isChecked = false,
  onClick,
  isDisabled,
}) => {
  if (togglingSelectAll) {
    return <IconWithSpinner spinning={true} extraClassNames="ml3 mr6" />;
  }

  return (
    <Checkbox id="selectAll" skipMemo={true} isChecked={isChecked} label="" disabled={isDisabled} onClick={onClick} />
  );
};
