import { useReducer } from 'react';

/**
 * This hook provides a function similar to React's forceUpdate component method
 *
 * https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
 */
export function useForceUpdate() {
  const [reference, increment] = useReducer((x) => x + 1, 0);
  const forceUpdate = () => increment();
  forceUpdate.reference = reference;
  return forceUpdate as (() => void) & { reference: any };
}
