import { useTranslation } from 'react-i18next';
import { isCompatibleWithUsernameAndPassword } from '@/utilities/auth.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { AuthInputV1 } from '@/sdk/model/AuthInputV1';
import { sqWorkbenchStore } from '@/core/core.stores';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getStorageSafely } from '@/utilities/storage.utilities';
import { login as loginAuthenticationUtilities } from '@/utilities/authentication.utilities';
import { doTrack } from '@/track/track.service';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { authAutoLogin, authDefaultProviderId } from '@/services/systemConfiguration.utilities';
import { fetchDatasourcesImmediately, getTrackableMessage } from '@/utilities/datasources.utilities';
import { goToWithReload, returnToPreviousState } from '@/main/routing.utilities';

export type UpdateDomainFn = (value: string, property?: string) => any;

interface UseLoginParams {
  domain: any;
  updateDomain: UpdateDomainFn;
}

export class PostAuthError extends Error {
  public failureType: string;

  constructor(failureType: string) {
    super();
    this.failureType = failureType;
  }
}

export type PostAuthErrorHandlerFn = (error: PostAuthError) => any;

export const useLogin = ({ domain, updateDomain }: UseLoginParams) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [loginError, setLoginError] = useState<string | undefined>('');
  const [sharedProps, setSharedProps] = useState({
    processing: false,
    showErrorMessage: false,
  });

  const stateParams = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.stateParams);
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);

  const { username, password } = formData;
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
  const { code, state, directoryId, autoLogin } = _.pick(urlParams, ['code', 'state', 'directoryId', 'autoLogin']);

  const updateFormData = (data: Partial<typeof formData>) =>
    setFormData((formData) => ({
      ...formData,
      ...data,
    }));

  const updateSharedProps = (props: Partial<typeof sharedProps>) =>
    setSharedProps((sharedProps) => ({
      ...sharedProps,
      ...props,
    }));

  /**
   * Returns whether the selected domain is compatible with code and state authentication.
   */
  const isCompatibleWithCodeAndState = () => !isCompatibleWithUsernameAndPassword(domain);

  /**
   * Performs the actual login call and redirects to the proper location.
   * Also resets all form fields.
   *
   * @param {AuthInputV1} authCredentials - the auth credentials
   * @param {String} dataSourceClass - the dataSourceClass of the authentication provider selected
   * @param {String} dataSourceId - the dataSourceId of the authentication provider selected
   *
   * @returns {Promise} that resolves when the login attempt is completed, either success or failure.
   * If the login fails this method also sets a member variable to show an error message.
   */
  const doLogin = (authCredentials: AuthInputV1, dataSourceClass: string, dataSourceId: string): Promise<void> => {
    setLoginError(undefined);
    updateSharedProps({
      showErrorMessage: false,
      processing: true,
    });
    let continueProcessing = false;
    getStorageSafely().setItem('sqDomain', domain.datasourceId);

    // In some cases, params such as returnTo may be lost during authentication with an outside identity provider
    const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
    if (_.has(urlParams, 'returnTo')) {
      getStorageSafely().setItem('stateParams', JSON.stringify(urlParams));
    }

    return loginAuthenticationUtilities(authCredentials, dataSourceClass, dataSourceId)
      .then(() => {
        // This information is requested by Sales and we'd like to have a
        // way to get this information "live" rather than from the data consumption logs.
        // We only want to track this Event once, and we don't really care about 100% accuracy on synced items so we
        // decided to send one event, 1 minute after user login.
        setTimeout(() => {
          fetchDatasourcesImmediately().then((datasourcesStatus) => {
            doTrack('Workbench', 'Datasources', JSON.stringify(getTrackableMessage(datasourcesStatus)));
          });
        }, 60000);

        doTrack('System', 'Login', { success: true, domain: `${domain.datasourceClass} - ${domain.datasourceId}` });

        return returnToPreviousState();
      })
      .catch((e) => {
        if (_.get(e, 'response.status') === 401 && _.has(e, 'response.data.providerAuthURL')) {
          // 401 with a providerAuthURL means that we must be logging in with OAuth2 (or similar) and we need to
          // redirect the user in order for them to authenticate with the provider
          goToWithReload(e.response.data.providerAuthURL);
          continueProcessing = true;
        } else if (_.get(e, 'response.status') === 418) {
          // 418 indicates that a post-auth error occurred and the user needs to take corrective steps
          return Promise.reject(new PostAuthError(e.response.data.failureType));
        } else {
          // If the transition results in a redirect then we could get a rejection from the first attempted transition
          // Resolves CRAB-15836 by never displaying the object containing the password
          const errorMessage = e?.response?.data?.statusMessage ?? t('LOGIN_PANEL.FRONTEND_ERROR');
          setLoginError(errorMessage);
        }
      })
      .finally(() => {
        if (!continueProcessing) {
          updateSharedProps({ processing: false });
        }
      });
  };

  /**
   * Submits the required login information to the server.
   */
  const login = (authCredentials: AuthInputV1 = {}) => {
    const credentials = {
      ...authCredentials,
      username: username || undefined,
      password: password || undefined,
    };

    return doLogin(credentials, domain.datasourceClass, domain.datasourceId);
  };

  /**
   * Decides whether to continue with an OAuth 2.0 authentication attempt. If the right properties are there and the
   * right authentication Directory is selected, then continue with the authentication attempt.
   *
   * Returns a promise containing true if the OAuth 2.0 login was attempted, or false if it wasn't.
   */
  const evaluateContinueOAuth2Login = (authCredentials: AuthInputV1): Promise<boolean> => {
    if (authCredentials.code && authCredentials.state && isCompatibleWithCodeAndState()) {
      return (login(authCredentials) as Promise<any>).then(
        (success) => true,
        (failure) => true,
      );
    }

    if (stateParams.error === 'access_denied') {
      setLoginError(t('LOGIN_PANEL.ACCEPT_PERMISSIONS', { domain: domain.name }));
    }

    return Promise.resolve(false);
  };

  /**
   * Attempts auto-login if autoLogin was supplied as a login URL query parameter or from the
   * Authentication/AutoLogin config option. The query parameter takes precedence.
   * Supports auto-login for Windows Auth and OAuth providers.
   */
  const evaluateAutoLogin = (newDomain = domain) => {
    // Determine if the autoLogin query param is explicitly set to true or false
    const autoLoginQueryParam = _.toLower(autoLogin);
    const queryParamAutoLoginTrue = autoLoginQueryParam === 'true';
    const queryParamAutoLoginFalse = autoLoginQueryParam === 'false';

    // Determine if autoLogin is enabled for a Windows Auth  or OAuth datasource via the config system
    const settingsAutoLogin =
      (newDomain.datasourceClass === SeeqNames.RemoteDatasourceClasses.WindowsAuth ||
        newDomain.datasourceClass === SeeqNames.RemoteDatasourceClasses.OAuth2) &&
      authAutoLogin();

    // The query parameter can override the settings file value to disable auto login if desired
    const doAutoLogin = queryParamAutoLoginTrue || (!queryParamAutoLoginFalse && settingsAutoLogin);

    if (doAutoLogin) {
      return login();
    }
  };

  /**
   * Auto-chooses a directory if a directoryId was supplied via a login URL query parameter or from the
   * Authentication/DefaultProviderId config option. The query parameter takes precedence.
   */
  const evaluateDirectorySelect = (): any | undefined => {
    const currentDirectoryId = directoryId || authDefaultProviderId();
    if (currentDirectoryId) {
      return updateDomain(currentDirectoryId);
    }
  };

  useEffect(() => {
    const isAutoLoginConfigSet = authDefaultProviderId() && authAutoLogin();
    const shouldAttemptAutoLogin =
      _.isEmpty(currentUser) && ((code && state) || directoryId || autoLogin || isAutoLoginConfigSet);
    if (shouldAttemptAutoLogin) {
      const authCredentials: AuthInputV1 = { code, state };
      evaluateContinueOAuth2Login(authCredentials).then((usedOAuth) => {
        if (!usedOAuth) {
          const newDomain = evaluateDirectorySelect();

          return evaluateAutoLogin(newDomain);
        }
      });
    }
  }, [code, state, directoryId, autoLogin]);

  return {
    login,
    doLogin,
    loginError,
    username,
    password,
    updateFormData,
    sharedProps,
  };
};
