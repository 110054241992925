import { useTranslation } from 'react-i18next';
import React from 'react';
import { Icon } from '@seeqdev/qomponents';
import classNames from 'classnames';

interface ToggleSwitchProps {
  isOn: boolean;
  onChange: (newValue: boolean) => void;
  label?: string;
  formattedLabel?: React.ReactNode;
  disabled?: boolean;
  testId?: string;
}

/**
 * Generic two-state toggle button (on/off)
 */
export const ToggleSwitch: React.FunctionComponent<ToggleSwitchProps> = (props) => {
  const { isOn, onChange, label, formattedLabel, disabled = false, testId = 'toggleSwitch' } = props;

  const { t } = useTranslation();

  return (
    <div
      data-testid={testId}
      className={classNames('flexColumnContainer', 'flexCenter', {
        disabledBehavior: disabled,
      })}
      onClick={(e) => {
        e.stopPropagation();
        return !disabled && onChange(!isOn);
      }}>
      {formattedLabel ? formattedLabel : t(label ?? '')}
      <Icon
        testId={`${testId}Icon`}
        icon={isOn ? 'fc-toggle-on' : 'fc-toggle-off'}
        large={true}
        extraClassNames={classNames('cursorPointer', {
          ml7: label || formattedLabel,
          disabled,
        })}
      />
    </div>
  );
};
