// @ts-strict-ignore
import _ from 'lodash';
import { getCapsuleFormula } from '@/datetime/dateTime.utilities';
import { REFERENCE_STATS } from '@/toolSelection/investigate.constants';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { sqDurationStore } from '@/core/core.stores';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';

export class ReferencePanelStore extends BaseToolStore {
  static readonly storeName = 'sqReferencePanelStore';
  type = TREND_TOOLS.REFERENCE;
  parameterDefinitions = {
    repeatOverCondition: { predicate: ['name', 'repeatOverCondition'] },
  };

  REFERENCE_OPPOSITES = {
    [REFERENCE_STATS.MIN]: REFERENCE_STATS.MAX,
    [REFERENCE_STATS.MAX]: REFERENCE_STATS.MIN,
  };

  DEFAULT_INPUT_CONFIG = {
    inputSignal: {},
    inputCondition: {},
  };

  initialize() {
    this.state = this.immutable({
      ...BASE_TOOL_COMMON_PROPS,
      windowStart: sqDurationStore.displayRange.start.valueOf(),
      windowEnd: sqDurationStore.displayRange.end.valueOf(),
      window: this.monkey(['windowStart'], ['windowEnd'], (startTime, endTime) => ({ startTime, endTime })),
      griddingPeriod: { value: 5, units: 'min' },
      referenceStat: '',
      multiplier: 1,
      inputGroups: [this.DEFAULT_INPUT_CONFIG],
    });
  }

  get repeatOverCondition() {
    return this.state.get('repeatOverCondition');
  }

  get inputGroups() {
    return this.state.get('inputGroups');
  }

  get window() {
    return this.state.get('window');
  }

  get griddingPeriod() {
    return this.state.get('griddingPeriod');
  }

  get referenceStat() {
    return this.state.get('referenceStat');
  }

  get multiplier() {
    return this.state.get('multiplier');
  }

  /**
   * Returns the formula used to create a reference profile. Creates a signal using using statistics from source
   * data. These signals are useful for boundary investigations, statistical process control (SPC), and other
   * "golden batch" investigations.
   *
   * @param {Object} inputs - The input item ids for the formula
   * @param {String} inputs.inputSignalId - The id of the input signal
   * @param {String} inputs.inputConditionId - The id of the input condition
   * @param {Object} inputs.repeatOverCondition - The condition to repeat over
   * @param {string} inputs.repeatOverCondition.id = the ID of the condition to repeat over
   * @param {FrontendDuration} inputs.repeatOverCondition.maximumDuration = the maximum duration that should be
   * manually set on the repeatOverCondition via .setMaximumDuration() in the formula, or undefined if the
   * condition is bounded and thus already has a maximum capsule duration.
   * @returns {Object} Object with properties of "formula" which is the string for the reference formula and
   *   "parameters" which are all parameters used in the formula
   */
  formulaParams(inputs) {
    const formula = [];
    const formulaTraining = [];
    const formulaRows = [];
    const config = this.state.get();
    const fb = this.formulaBuilder;
    const parameters = {
      repeatOverCondition: inputs.repeatOverCondition.id,
    };
    const mdo = inputs.repeatOverCondition.maximumDuration;
    const setMaximumDurationFragment = mdo ? `.setMaximumDuration(${mdo.value}${mdo.units})` : '';
    // use of fragment() allows for output when there are overlapping capsules
    const repeatOverConditionFragment = `$repeatOverCondition${setMaximumDurationFragment}.fragment()`;
    const repeatOverArgs = [repeatOverConditionFragment, `ReferenceTableStat.${config.referenceStat}`];
    const needsMultiplier = !_.includes(
      [REFERENCE_STATS.AVERAGE, REFERENCE_STATS.MIN, REFERENCE_STATS.MAX],
      config.referenceStat,
    );
    if (needsMultiplier) {
      repeatOverArgs.push(config.multiplier);
    }

    _.forEach(inputs.data, (input, index) => {
      const itemIndexSuffix = index ? `${index + 1}` : '';
      parameters[`inputSignal${itemIndexSuffix}`] = input.inputSignal.id;
      parameters[`inputCondition${itemIndexSuffix}`] = input.inputCondition.id;

      formulaTraining.push(
        `$trainingRange${itemIndexSuffix} = $inputCondition${itemIndexSuffix}.toGroup(${getCapsuleFormula({
          start: config.windowStart,
          end: config.windowEnd,
        })}, CapsuleBoundary.EnclosedBy)`,
      );

      formulaRows.push(`  .addRows($inputSignal${itemIndexSuffix}, $trainingRange${itemIndexSuffix})`);
    });

    formula.push(...formulaTraining);
    formula.push(fb.operator('referenceTable', [fb.duration(config.griddingPeriod)]));
    formula.push(...formulaRows);
    formula.push(`  .${fb.operator('repeatOver', repeatOverArgs)}`);

    return { formula: formula.join('\n'), parameters };
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the references panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  /**
   * Adds the formula and parameters to the config as part of what gets rehydrated when the tool is loaded.
   *
   * @param {Object} payload - An object with the necessary state to populate the edit form.
   * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
   * @param {Object[]} payload.parameters - The parameters used in the formula
   */
  referencePanelRehydrateForEdit(payload) {
    if (payload.type !== TREND_TOOLS.REFERENCE) {
      return;
    }

    this.rehydrateForEdit(payload);

    const inputGroupsRegex = /^(inputSignal|inputCondition)(\d*)$/;
    const inputGroups = _.chain(payload.parameters)
      .filter(({ name }) => inputGroupsRegex.test(name))
      .map((parameter) => {
        const [match, name, number] = parameter.name.match(inputGroupsRegex);
        return {
          id: parameter.item.id,
          name,
          number: number ? _.toNumber(number) : 1,
        };
      })
      .sortBy('number')
      .groupBy('number')
      .map((parameterGroups) => ({
        inputSignal: {
          id: _.find(parameterGroups, { name: 'inputSignal' }).id,
        },
        inputCondition: {
          id: _.find(parameterGroups, { name: 'inputCondition' }).id,
        },
      }))
      .value();

    this.state.set('inputGroups', inputGroups);
  }

  /**
   * Removes properties from config which are stored as part of the formula.
   *
   * @param {Object} config - The state that will be saved to UIConfig
   * @return {Object} The modified config
   */
  modifyConfigParams(config) {
    return _.omit(config, ['inputGroups', 'advancedParametersCollapsed']);
  }

  addSignalCondition() {
    this.state.push('inputGroups', { ...this.DEFAULT_INPUT_CONFIG });
  }

  removeSignalCondition(index) {
    if (index > -1) {
      this.state.splice('inputGroups', [index, 1]);
    }
  }

  /**
   * This function finds the inputGroups entry that is being modified and updates the parameters specified.
   * If no entry for the provided id is found a new one is added.
   * To enable form-validation it also validates the entry and sets the "valid" flag to true if the definition is
   * valid.
   *
   * @param {Object} payload -  Object container
   * @param {Number} payload.index -  input pair index.
   * @param {String} payload.paramName - input name (signal or condition)
   */
  setValueParams(payload) {
    if (payload.index > -1) {
      const originalConfig = this.state.get(['inputGroups', payload.index]);
      const config = {
        ...originalConfig,
        [payload.paramName]: _.omit(payload, 'index'),
      };
      this.state.set(['inputGroups', payload.index], config);
    }
  }

  /**
   * Set the reference window start value that is used in the form.
   *
   * @param {Object} payload - Object container
   * @param {Number} payload.window.startTime - the start of the reference training window
   * @param {Number} payload.window.endTime - the end of the reference training window
   */
  setTrainingWindow(payload) {
    this.state.set('windowStart', _.get(payload.window, 'startTime'));
    this.state.set('windowEnd', _.get(payload.window, 'endTime'));
  }

  /**
   * Sets the gridding period for used for building the reference model.
   *
   * @param {Object} payload - Object container
   * @param {String} payload.value - The number that indicates how long the period is
   * @param {String} payload.units - The units that the value represent
   */
  setGriddingPeriod(payload) {
    this.state.set('griddingPeriod', _.pick(payload, ['units', 'value']));
  }

  /**
   * Sets the reference table statistic that is used for building the model.
   *
   * @param {Object} payload - Object container
   * @param {String} payload.referenceStat - One of the ReferenceTableStat constants used by the API
   */
  setReferenceStat(payload) {
    this.state.set('referenceStat', payload.referenceStat);
  }

  /**
   * Sets the multiplier that is used in conjunction with the reference statistic. It multiplies the difference
   * between the stat and the average and adds it back to the average.
   *
   * @param {Object} payload - Object container
   * @param {Number} payload.multiplier - The multiplier
   */
  setMultiplier(payload) {
    this.state.set('multiplier', payload.multiplier);
  }

  /**
   * Creates a paired variant of the currently loaded reference profile. This means either flipping min/max
   * reference stat or reversing the sign of the multiplier for other stats.
   *
   * @param {Object} payload - Object container
   * @param {String} payload.name - The name of the new signal
   */
  createPairing(payload) {
    const config = this.state.get();
    this.state.merge({
      id: '',
      name: payload.name,
      multiplier: this.REFERENCE_OPPOSITES[config.referenceStat] ? config.multiplier : config.multiplier * -1,
      referenceStat: this.REFERENCE_OPPOSITES[config.referenceStat] || config.referenceStat,
    });
  }

  localHandlers = {
    REFERENCE_SET_TRAINING_WINDOW: this.setTrainingWindow,
    REFERENCE_SET_GRIDDING_PERIOD: this.setGriddingPeriod,
    REFERENCE_SET_REFERENCE_STAT: this.setReferenceStat,
    REFERENCE_SET_MULTIPLIER: this.setMultiplier,
    REFERENCE_CREATE_PAIRING: this.createPairing,
    REFERENCE_SET_INPUT_VALUE_PARAMS: this.setValueParams,
    REFERENCE_ADD_SIGNAL_CONDITION: this.addSignalCondition,
    REFERENCE_REMOVE_SIGNAL_CONDITION: this.removeSignalCondition,
    TOOL_REHYDRATE_FOR_EDIT: this.referencePanelRehydrateForEdit,
  };

  handlers = _.assign({}, super.baseHandlers, this.localHandlers);
}
