import _ from 'lodash';
import { ITEM_CHILDREN_TYPES, ITEM_TYPES, PREVIEW_ID, TREND_VIEWS } from '@/trendData/trendData.constants';
import { findItemIn, getTrendStores } from '@/trend/trendDataHelper.utilities';
import {
  sqTrendCapsuleStore,
  sqTrendConditionStore,
  sqTrendScalarStore,
  sqTrendSeriesStore,
  sqTrendStore,
  sqTrendTableStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { removeUncertaintyIndicatorsFromChartItems } from '@/utilities/chartHelper.utilities';

/**
 * All the items that could be included depending on the view
 */
export function baseChartItems() {
  const commonItems = [
    // CapsuleSet items aren't really 'chartItems' since the capsule store provides the capsule items for the chart
    // However, `chartItems` isn't populated until late in the load of the page, so we test if a condition is
    // displayed accurately early in the loading of the page. They are filtered out before being returned by
    // chartItems()
    sqTrendConditionStore.items,
  ];
  if (sqTrendStore.view === TREND_VIEWS.CAPSULE) {
    return _.flatten([
      sqTrendSeriesStore.capsuleSeries,
      _.filter(sqTrendScalarStore.items, (item) => !item.isChildOf),
      ...commonItems,
    ]);
  }

  return _.flatten([
    sqTrendTableStore.tables,
    groupedNonCapsuleSeries({
      nonCapsuleSeries: sqTrendSeriesStore.nonCapsuleSeriesAndPreview,
    }),
    getSqTrendCapsuleStoreChartItems(),
    sqTrendScalarStore.items,
    ...commonItems,
  ]);
}

/**
 * Helper function that helps hide not selected capsules from trend when we are in Chain View
 */
function getSqTrendCapsuleStoreChartItems() {
  return sqTrendStore.view === TREND_VIEWS.CHAIN &&
    sqWorksheetStore.capsuleGroupMode &&
    _.some(sqTrendCapsuleStore.chartItems, (item) => item.selected)
    ? _.filter(sqTrendCapsuleStore.chartItems, (item) => item.selected)
    : sqTrendCapsuleStore.chartItems;
}

/**
 * Helper function to filter sqTrendSeriesStore.nonCapsuleSeries in case of grouping mode.
 *
 * @param {Array} nonCapsuleSeries - signals that should be filtered if any grouping
 */
export function groupedNonCapsuleSeries({ nonCapsuleSeries }: { nonCapsuleSeries: any }) {
  if (sqTrendStore.view === TREND_VIEWS.CHAIN && sqWorksheetStore.capsuleGroupMode) {
    const selectedCapsules = _.filter(sqTrendCapsuleStore.items, 'selected');
    const conditionsIds = _.chain(selectedCapsules.length ? selectedCapsules : sqTrendCapsuleStore.items)
      .map('isChildOf')
      .uniq()
      .value();
    const seriesIds = _.chain(conditionsIds)
      .flatMap((conditionId) => sqWorksheetStore.conditionToSeriesGrouping[conditionId])
      .compact()
      .uniq()
      .value();

    // in order to display shadedArea signals we are using item['isChildOf'] for filtering nonCapsuleSeries
    return _.filter(
      nonCapsuleSeries,
      (item) => _.includes(seriesIds, item.id) || _.includes(seriesIds, item.isChildOf),
    );
  }

  return nonCapsuleSeries;
}

/**
 * @returns the list of items that will be shown on the chart
 */
export function chartItems() {
  let chartItems = baseChartItems();

  if (sqTrendStore.hideUnselectedItems && _.some(chartItems, (item) => shownAsSelected(item))) {
    chartItems = _.chain(chartItems)
      .filter((item) =>
        shownInHideUnselectedItemsMode({
          item,
        }),
      )
      .value();
  }

  if (sqTrendStore.hideUncertainty) {
    chartItems = removeUncertaintyIndicatorsFromChartItems(chartItems);
  }

  // Conditions are filtered out here because they aren't used to display capsules (see note in baseChartItems)
  return _.reject(chartItems, ['itemType', ITEM_TYPES.CONDITION]);
}

/**
 * Test if an item that would be shown on the trend is currently hidden because of `hideUnselectedItems` mode
 */
export function isHidden({ item }: { item: any }) {
  if (!sqTrendStore.hideUnselectedItems) {
    return false;
  }
  const baseChartItemsArray = baseChartItems();

  return (
    sqTrendStore.hideUnselectedItems &&
    // If it isn't possible to be part of chartItems return false
    _.some(baseChartItemsArray, ['id', item.id]) &&
    // Items are only hidden if at least one of them is selected
    _.some(baseChartItemsArray, (chartItem) => shownAsSelected(chartItem)) &&
    // Finally, check if this item is shown
    !shownInHideUnselectedItemsMode({ item })
  );
}

/**
 * Helper function that determines if an item should be shown in hideUnselectedItems mode. Preview items are always
 * shown in the mode, but they do not cause items to be filtered
 */
function shownInHideUnselectedItemsMode({ item }: { item: any }) {
  return (
    item.id === PREVIEW_ID ||
    item.capsuleSetId === PREVIEW_ID ||
    shownAsSelected(item) ||
    noneOfTypeSelected({ item }) ||
    _.startsWith(item.id, PREVIEW_ID) ||
    (sqTrendCapsuleStore.editingId && item.capsuleSetId === sqTrendCapsuleStore.editingId)
  );
}

/**
 * Helper function for determining if an item should trigger hiding items in hideUnselectedItems mode
 */
export function shownAsSelected(item: any) {
  // Capsule series draw their selection from capsules but in this mode we want to hide them if their parent is
  // selected in the details pane. Scalars will still have "selected" set to true if they are selected.
  if (_.get(item, 'childType') === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
    const parent = findItemIn(getTrendStores(), item.isChildOf);
    return _.get(parent, 'selected', false);
  }

  return _.get(item, 'selected', false);
}

/**
 * Helper function for determining if an item should display in capsule time in hideUnselectedItems mode, where if
 * no items of a given type(conditions vs. all other types) are selected all should be displayed, as in calendar time,
 * non-dimming.  Without this, it can be confusing to the user why nothing is displayed in the trend when
 * conditions are selected since capsules aren't chart items in capsule view.
 */
export function noneOfTypeSelected({ item }: { item: any }) {
  const selectedConditions = _.some(baseChartItems(), { itemType: ITEM_TYPES.CONDITION, selected: true });
  const selectedOthers = _.chain(sqTrendSeriesStore.nonCapsuleSeriesAndPreview)
    .concat(sqTrendScalarStore.items)
    .some({ selected: true })
    .value();

  if (sqTrendStore.view === TREND_VIEWS.CAPSULE) {
    return item.itemType === ITEM_TYPES.CONDITION ? !selectedConditions : !selectedOthers;
  }

  return false;
}
