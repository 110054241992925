// @ts-strict-ignore
import _ from 'lodash';
import { sqTreesApi } from '@/sdk/api/TreesApi';
import { sqDisplaysApi } from '@/sdk/api/DisplaysApi';
import { sqDatasourcesApi } from '@/sdk/api/DatasourcesApi';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { addAssetsProperty } from '@/utilities/httpHelpers.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqModalSearchStore, sqSearchStore, sqWorkbenchStore } from '@/core/core.stores';
import { fetchAssetGroups, fetchPinnedItems } from '@/workbook/workbook.actions';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import { cancelGroup } from '@/requests/pendingRequests.utilities';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import { API_TYPES, CREATED_BY_SEEQ_WORKBENCH, SEARCH_TYPES } from '@/main/app.constants';
import {
  ASSET_TREE_SEARCH_LIMIT,
  HOME_BREADCRUMB,
  MAX_RETURNED_RESULTS,
  SEARCH_BREADCRUMB,
  SEARCH_PER_PAGE,
  SEARCH_RESULT_TYPES,
  SEARCH_USAGES_BREADCRUMB,
  SearchModes,
  SearchPanes,
} from '@/search/search.constants';
import { isPresentationWorkbookMode } from '@/utilities/utilities';
import { sqDisplayTemplatesApi } from '@/sdk/api/DisplayTemplatesApi';
import { Datasource } from '@/search/search.types';
import { SelectOptionIF } from '@/usage/Usage.page';
import { TOptions } from 'i18next';

const cancellationGroup = 'dataSearch';

/**
 * Initializes the specified search pane with assets or results and the breadcrumbs.
 */
export function initializeSearchActions(
  pane: SearchPanes,
  searchTypes: string[],
  restrictExploration: boolean,
  scopeIds: string[],
  excludeGloballyScoped = false,
): Promise<any> {
  if (isPresentationWorkbookMode() && pane === 'main') {
    return Promise.resolve();
  }
  const store = getStoreForPane(pane);
  flux.dispatch('SEARCH_ASYNC_INITIALIZED', {
    pane,
    isAsyncInitialized: true,
  });
  if (store.mode === 'usages' && !_.isEmpty(store.breadcrumbs)) {
    const breadcrumbIds = _.flatMap(store.breadcrumbs.slice(2), 'id');
    return Promise.all(_.map(breadcrumbIds, (id) => sqItemsApi.getItemAndAllProperties({ id })))
      .then((results) =>
        _.chain(results)
          .map('data')
          .map((item) => _.pick(item, ['id', 'name', 'type']))
          .value(),
      )
      .then((breadcrumbs) => {
        flux.dispatch('SEARCH_SET_BREADCRUMBS', {
          breadcrumbs: [HOME_BREADCRUMB, SEARCH_USAGES_BREADCRUMB].concat(breadcrumbs),
          pane,
        });
        return fetchItemUsages(_.last(breadcrumbs), pane, _.compact([sqWorkbenchStore.stateParams.workbookId]), false);
      });
  }

  if (store.mode === 'search' && !_.isEmpty(store.currentAsset)) {
    const crumbProperties = ['name', 'type', 'archived', 'ancestors', 'id'];
    let breadcrumbs = [HOME_BREADCRUMB] as { id?: string }[];
    sqTreesApi.getTree({ id: store.currentAsset }).then(({ data: item }) => {
      const treeNode = item.item;
      if (!_.isEmpty(treeNode.ancestors)) {
        breadcrumbs = _.concat(
          breadcrumbs,
          _.map(treeNode.ancestors, (ancestor) => {
            return _.pick(ancestor, crumbProperties);
          }),
        );
      }
      breadcrumbs = _.concat(breadcrumbs, _.pick(treeNode, crumbProperties), SEARCH_BREADCRUMB);
      flux.dispatch('SEARCH_SET_BREADCRUMBS', { breadcrumbs, pane: 'main' }, PUSH_IGNORE);
    });
  }

  if (pane === 'modal' && _.includes(searchTypes, SeeqNames.Types.AssetSelection)) {
    fetchAssetGroups(scopeIds);
  }
  return searchOrExplore(pane, store.mode, searchTypes, restrictExploration, scopeIds, excludeGloballyScoped);
}

/**
 * Initiate a new search and fetch the first page of results. If no search terms were entered the asset browser is
 * shown instead.
 *
 * @param pane - The name of the search pane, one of SearchPanes
 * @param searchTypes - The array of Item types to search if the typeFilter is empty
 * @param scopeIds - The array of Item Ids to search in, defaults to current workbook Id
 * @param excludeGloballyScoped - true to exclude globally scoped assets from the results, false otherwise.
 * @return {Promise} A promise that resolves with the results
 */
export function searchItems(
  pane: SearchPanes,
  searchTypes: string[],
  scopeIds: string[] = [sqWorkbenchStore.stateParams.workbookId],
  excludeGloballyScoped = false,
) {
  return cancelGroup(cancellationGroup).finally(() => {
    flux.dispatch('SEARCH_INITIATE', { pane });
    return setDatasources(pane, sqWorkbenchStore.stateParams.workbookId).then(() => {
      return fetchItemResults(pane, searchTypes, scopeIds, excludeGloballyScoped);
    });
  });
}

/**
 * Fetches the next batch of results
 *
 * @param pane - The name of the search pane, one of SearchPanes
 * @param searchTypes - The array of Item types to search if the typeFilter is empty
 * @param restrictExploration - Whether exploration should be restricted or not
 * @param scopeIds - The array of Item Ids to search in, defaults to current workbook Id
 * @param excludeGloballyScoped - true to exclude globally scoped assets from the results, false otherwise.
 * @return {Promise} A promise that resolves with the results
 */
export function loadNextPageSearchActions(
  pane: SearchPanes,
  searchTypes: string[],
  restrictExploration?: boolean,
  scopeIds: string[] = [sqWorkbenchStore.stateParams.workbookId],
  excludeGloballyScoped = false,
) {
  flux.dispatch('SEARCH_LOAD_NEXT_PAGE', { pane }, PUSH_IGNORE);
  return _.includes(['overview', 'tree'], getStoreForPane(pane).mode)
    ? fetchAssetResults(pane, searchTypes, restrictExploration, scopeIds, excludeGloballyScoped)
    : fetchItemResults(pane, searchTypes, scopeIds, excludeGloballyScoped);
}

/**
 * If datasources aren't set in the store, get them via the API and set datasource and localDatasources in the store
 *
 * @param {String} pane - The name of the search pane, one of SearchPanes
 * @param {String} scopeId - The scope of the datasources to fetch
 * @param forceSet - if true, we'll always fetch the datasources
 * @return {Promise<void>} Promise that resolves when datasources are fetched and set, or immediately if
 *          datasources are already set
 */
export function setDatasources(pane: SearchPanes, scopeId?: string, forceSet = false): Promise<void> {
  const store = getStoreForPane(pane);
  if (!_.isEmpty(store.datasources) && !forceSet) {
    return Promise.resolve();
  }
  return sqDatasourcesApi
    .getDatasources({ scopedTo: scopeId, limit: MAX_RETURNED_RESULTS })
    .then(({ data: { datasources } }) => {
      // Set local datasources and datasources to show in the datasources dropdown
      flux.dispatch('SEARCH_SET_DATASOURCES', { pane, datasources });
    });
}

/**
 * Initiate explore view at the asset and fetch the results
 *
 * @param pane - The name of the search pane, one of SearchPanes
 * @param asset - The id of the asset to show
 * @param searchTypes - The types to search
 * @param restrictExploration - If exploration should be restricted
 * @param scopeIds
 * @param excludeGloballyScoped - true to exclude globally scoped items from the results, false otherwise.
 * @return {Promise} A promise that resolves with the results
 */
export function exploreAssetSearchActions(
  pane: SearchPanes,
  asset: string,
  searchTypes?: string[],
  restrictExploration?: boolean,
  scopeIds: string[] = [sqWorkbenchStore.stateParams.workbookId],
  excludeGloballyScoped = false,
): Promise<any> {
  clearSelection();

  return cancelGroup(cancellationGroup).then(() => {
    return setDatasources(pane, sqWorkbenchStore.stateParams.workbookId).then(() => {
      flux.dispatch('SEARCH_EXPLORE_ASSET', { pane, asset });
      return fetchAssetResults(pane, searchTypes, restrictExploration, scopeIds, excludeGloballyScoped);
    });
  });
}

/**
 * Clears the search state and re-populates the root nodes.
 *
 * @param pane - The name of the search pane, one of SearchPanes
 * @param searchTypes - The types to search
 * @param restrictExploration - Used when exploring an asset
 * @param scopeIds - The array of Item Ids to search in, defaults to current workbook Id
 * @param excludeGloballyScoped - true to exclude globally scoped assets from the results, false otherwise.
 */
export function clear(
  pane: SearchPanes,
  searchTypes: string[],
  restrictExploration = false,
  scopeIds: string[] = [sqWorkbenchStore.stateParams.workbookId],
  excludeGloballyScoped = false,
) {
  return cancelGroup(cancellationGroup).finally(() => {
    flux.dispatch('SEARCH_CLEAR', { pane });
    initializeSearchActions(pane, searchTypes, restrictExploration, scopeIds, excludeGloballyScoped);
  });
}

/**
 * Sets the name filter
 *
 * @param {String} pane - The name of the search pane, one of SearchPanes
 * @param {String} nameFilter - The text used to filter by name
 */
export function setNameFilter(pane: SearchPanes, nameFilter: string) {
  flux.dispatch('SEARCH_SET_NAME_FILTER', { pane, nameFilter }, PUSH_IGNORE);
}

/**
 * Sets `isExactName`, indicating if search should be done with the exact name (/^<name>$/)
 */
export function setIsExactName(pane: SearchPanes, isExactName: boolean) {
  flux.dispatch('SEARCH_IS_EXACT_NAME', { pane, isExactName }, PUSH_IGNORE);
}

/**
 * Sets the description filter used in advanced mode
 *
 * @param {String} pane - The name of the search pane, one of SearchPanes
 * @param {String} descriptionFilter - The text used to filter by description
 */
export function setDescriptionFilter(pane, descriptionFilter) {
  flux.dispatch('SEARCH_SET_DESCRIPTION_FILTER', { pane, descriptionFilter }, PUSH_IGNORE);
}

/**
 * Make record of which item types the user wants to filter by in advanced mode
 *
 * @param {String} pane - The name of the search pane, one of SearchPanes
 * @param {CheckBoxSelectOption} types - The user wants to filter by these item type(s) (Asset, Condition, etc.)
 */
export function setSelectedTypes(pane: SearchPanes, types: SelectOptionIF<string>[]) {
  flux.dispatch('SEARCH_SET_SELECTED_TYPES', { pane, types });
}

/**
 * Make record of which datasources the user wants to filter by in advanced mode
 *
 * @param pane - The name of the search pane, one of SearchPanes
 * @param datasources - The user wants to filter by these datasource(s)
 */
export function setSelectedDatasources(pane: SearchPanes, datasources: SelectOptionIF<Datasource>[]) {
  flux.dispatch('SEARCH_SET_SELECTED_DATASOURCES', { pane, datasources });
}

/**
 * Sets the Sort By option used in advanced mode
 *
 * @param {String} pane - The name of the search pane, one of SearchPanes
 * @param {String} sortBy - The property to sort the results by
 */
export function setSortBy(pane: SearchPanes, sortBy: string) {
  flux.dispatch('SEARCH_SET_SORT_BY', { pane, sortBy }, PUSH_IGNORE);
}

/**
 * Sets the ID of the item to highlight
 *
 * @param {String} pane - The name of the search pane, one of SearchPanes
 * @param {String} id- The ID of the item to highlight
 */
export function setHighlightItem(pane: SearchPanes, id: string) {
  flux.dispatch('SEARCH_SET_HIGHLIGHT_ITEM', { pane, id }, PUSH_IGNORE);
}

/**
 * Sets the ID of the display that is currently loading
 *
 * @param {String} pane - The name of the search pane, one of SearchPanes
 * @param {String} id- The ID of the display being loaded
 */
export function setDisplayLoading(pane: SearchPanes, id: string) {
  flux.dispatch('SEARCH_SET_DISPLAY_LOADING', { pane, id }, PUSH_IGNORE);
}

/**
 * Sets the search form to advanced or simple mode
 *
 * @param {String} pane - The name of the search pane, one of SearchPanes
 * @param {Boolean} isAdvancedMode - True if form is advanced mode, false for simple mode
 */
export function setAdvancedMode(pane: SearchPanes, isAdvancedMode: boolean) {
  flux.dispatch('SEARCH_SET_ADVANCED_MODE', { pane, isAdvancedMode });
}

/**
 * Sets the mode for search results
 *
 * @param pane - The name of the search pane, one of SearchPanes
 * @param mode - One of SEARCH_MODES
 * @param searchTypes - The types to search
 * @param restrictExploration - Used when exploring an asset
 * @param scopeIds - The array of Item Ids to search in
 * @param excludeGloballyScoped - true to exclude globally scoped items from the results, false otherwise.
 */
export function setMode(
  pane: SearchPanes,
  mode: SearchModes,
  searchTypes: string[],
  restrictExploration: boolean,
  scopeIds: string[],
  excludeGloballyScoped = false,
) {
  flux.dispatch('SEARCH_SET_MODE', { pane, mode });
  if (mode !== 'usages') {
    searchOrExplore(pane, mode, searchTypes, restrictExploration, scopeIds, excludeGloballyScoped);
  }
}

/**
 * Refreshes the specified search pane
 *
 * @param pane - The name of the search pane, one of SearchPanes
 * @param searchTypes - The types to search
 * @param restrictExploration - Used when exploring an asset
 * @param scopeIds - The array of Item Ids to search in
 * @param excludeGloballyScoped - true to exclude globally scoped items from the results, false otherwise.
 */
export function refresh(
  pane: SearchPanes,
  searchTypes: string[],
  restrictExploration: boolean,
  scopeIds: string[],
  excludeGloballyScoped = false,
) {
  const mode = getStoreForPane(pane).mode;
  return searchOrExplore(pane, mode, searchTypes, restrictExploration, scopeIds, excludeGloballyScoped);
}

export function searchOrExplore(
  pane: SearchPanes,
  mode: SearchModes,
  searchTypes: string[],
  restrictExploration: boolean,
  scopeIds: string[],
  excludeGloballyScoped = false,
) {
  switch (mode) {
    case 'search':
      return searchItems(pane, searchTypes, scopeIds).catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
    case 'tree':
    case 'overview':
      return exploreAssetSearchActions(
        pane,
        getStoreForPane(pane).currentAsset,
        searchTypes,
        restrictExploration,
        scopeIds,
        excludeGloballyScoped,
      ).catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
    default:
      return Promise.resolve();
  }
}

/**
 * Search for items usages and dispatch the results. Results are paginated and restricted to only those in the
 * global scope or in the provided workbook scopes
 *
 * @param item - the Item to find usages for
 * @param pane - The name of the search pane, one of SearchPanes
 * @param scopeIds - The array of workbookIds to search in
 * @param shouldClearBreadcrumbs - if the breadcrumbs should be wiped before appending (if calling from properties
 *   panel)
 */
export function fetchItemUsages(
  item: { id: string; name: string; type: string },
  pane: SearchPanes,
  scopeIds: string[],
  shouldClearBreadcrumbs: boolean,
) {
  flux.dispatch('SEARCH_INITIATE_USAGES', { pane });
  if (shouldClearBreadcrumbs) {
    flux.dispatch('SEARCH_SET_BREADCRUMBS', {
      breadcrumbs: [HOME_BREADCRUMB, SEARCH_USAGES_BREADCRUMB],
      pane,
    });
  }
  flux.dispatch('SEARCH_APPEND_OR_REPLACE_BREADCRUMB', {
    breadcrumb: item,
    pane,
  });
  return sqItemsApi
    .getItemUsages({ id: item.id, scope: scopeIds })
    .then(({ data: { items, next } }) => {
      flux.dispatch('SEARCH_RESULTS_SUCCESS', { pane, items, hasNextPage: !_.isUndefined(next) }, PUSH_IGNORE);
    })
    .catch((e) => {
      errorToast({ httpResponseOrError: e });
      return Promise.reject(e);
    })
    .finally(() => {
      flux.dispatch('SEARCH_FINISH', { pane }, PUSH_IGNORE);
    });
}

/**
 * Search for items and dispatch the results. Results are filtered by the different filters provided by the search
 * store. They are further restricted to only those in the global scope or in the provided workbook scopes
 *
 * @param pane - The name of the search pane, one of SearchPanes
 * @param searchTypes - The array of Item types to search if the typeFilter is empty
 * @param scopeIds - The array of Item Ids to search in
 * @param excludeGloballyScoped - true to exclude globally scoped items from the results, false otherwise.
 */
export function fetchItemResults(
  pane: SearchPanes,
  searchTypes: string[],
  scopeIds: string[],
  excludeGloballyScoped = false,
) {
  const store = getStoreForPane(pane);
  const offset = store.isPaginating ? store.items.length : 0;
  let { filters, types } = store.getSearchFilters(searchTypes);
  const resultType = SEARCH_RESULT_TYPES.ITEMS;
  if (excludeGloballyScoped) {
    filters = _.concat(filters, SeeqNames.API.Flags.ExcludeGloballyScoped);
  }

  return sqItemsApi
    .searchItems(
      {
        filters: filters as string[],
        types: types as string[],
        scope: _.compact(scopeIds),
        asset: store.currentAsset,
        orderBy: store.sortBy,
        includeProperties: [SeeqNames.Properties.DisplayUnit],
        offset,
        limit: SEARCH_PER_PAGE,
      },
      { cancellationGroup },
    )
    .then(({ data }) => {
      addAssetsProperty(data);
      flux.dispatch(
        'SEARCH_RESULTS_SUCCESS',
        {
          pane,
          items: data.items,
          hasNextPage: !_.isUndefined(data.next),
        },
        PUSH_IGNORE,
      );
      return data;
    })
    .catch((e) => {
      errorToast({ httpResponseOrError: e });
      return Promise.reject(e);
    })
    .finally(() => {
      flux.dispatch('SEARCH_FINISH', { pane, resultType }, PUSH_IGNORE);
    });
}

/**
 * Gets a list of children using the tree endpoint
 *
 * @param pane - The name of the search pane, one of SearchPanes
 * @param searchTypes - The types to search (default SEARCH_TYPES)
 * @param restrictExploration - If exploration should be restricted
 * @param scopeIds - Ids of scoped assets, default is workbookId
 * @param excludeGloballyScoped - true to exclude globally scoped items from the results, false otherwise.
 * @return {Promise} a promise that will resolve when the results have been fetched
 */
export function fetchAssetResults(
  pane: SearchPanes,
  searchTypes: string[],
  restrictExploration,
  scopeIds: string[],
  excludeGloballyScoped: boolean,
) {
  const store = getStoreForPane(pane);
  const isPaginating = store.isPaginating;
  const offset = isPaginating ? store.items.length : 0;
  const currentAsset = store.currentAsset;
  const resultType = SEARCH_RESULT_TYPES.ASSETS;
  const { types } = store.getSearchFilters(searchTypes);

  const getTreePromise = _.isEmpty(currentAsset)
    ? sqTreesApi.getTreeRootNodes(
        {
          scope: scopeIds,
          excludeGloballyScoped,
          limit: ASSET_TREE_SEARCH_LIMIT,
          offset,
          properties: [`${SeeqNames.Properties.TreeType}!=${CREATED_BY_SEEQ_WORKBENCH}`],
        },
        { cancellationGroup },
      )
    : sqTreesApi.getTree(
        {
          id: currentAsset,
          scope: scopeIds,
          excludeGloballyScoped,
          limit: ASSET_TREE_SEARCH_LIMIT,
          offset,
        },
        { cancellationGroup },
      );

  return Promise.resolve(getTreePromise)
    .then((response: any) => {
      let breadcrumbs = [HOME_BREADCRUMB];
      const filteredItems = restrictExploration
        ? _.filter(response.data.children, (child) => _.includes(types, child.type))
        : response.data.children;
      if (response.data.item) {
        breadcrumbs = breadcrumbs.concat(response.data.item.ancestors).concat([response.data.item]);
      }

      flux.dispatch('SEARCH_SET_BREADCRUMBS', { pane, breadcrumbs }, PUSH_IGNORE);
      const items = _.map(filteredItems, (child) =>
        _.assign(child, {
          ancestors: _.concat(response.data.item?.ancestors ?? [], response.data.item ?? []),
        }),
      );
      flux.dispatch(
        'SEARCH_RESULTS_SUCCESS',
        {
          pane,
          items,
          hasNextPage: !_.isUndefined(response.data.next),
        },
        PUSH_IGNORE,
      );

      return { ...response.data, items };
    })
    .catch((e) => {
      errorToast({ httpResponseOrError: e });
      return Promise.reject(e);
    })
    .finally(() => flux.dispatch('SEARCH_FINISH', { pane, resultType }, PUSH_IGNORE));
}

export function toggleSelectItem(id: string, items?: any[]): void {
  flux.dispatch('SEARCH_TOGGLE_SELECTED_ITEM', { itemId: id, items });
}

export function toggleSelectAllItems(items): void {
  flux.dispatch('SEARCH_TOGGLE_SELECT_ALL', { items });
}

export function setSelectAllItems(isAllSelected: boolean | undefined): void {
  flux.dispatch('SEARCH_SET_SELECT_ALL', { isAllSelected });
}

export function clearSelection(): void {
  flux.dispatch('SEARCH_CLEAR_SELECTED_ITEMS');
}

/**
 * Restore an archived item and refresh the search pane for the current asset
 *
 * @param item The item to restore
 * @param [pane] - The name of the search pane, one of SearchPanes (default SearchPanes.MAIN)
 * @param [searchTypes] - The types to search (default SEARCH_TYPES)
 * @param [isPinned] - Is this a pinned item (default false)
 * @param [restrictExploration] - If exploration should be restricted (default false)
 * @param [scopeIds] - Ids of scoped assets (default is workbookId)
 */
export function restoreItem(
  item: { id: string; name: string; type: string },
  pane: SearchPanes = 'main',
  searchTypes: string[] = SEARCH_TYPES,
  isPinned = false,
  restrictExploration = false,
  scopeIds: string[] = [sqWorkbenchStore.stateParams.workbookId],
) {
  return sqItemsApi
    .setProperty({ value: false }, { id: item.id, propertyName: SeeqNames.Properties.Archived })
    .then(() => {
      const store = getStoreForPane(pane);
      return searchOrExplore(pane, store.mode, searchTypes, restrictExploration, scopeIds).then(() => {
        if (isPinned && store.mode === 'overview') {
          fetchPinnedItems(sqWorkbenchStore.stateParams.workbookId);
        }
        successToast({
          messageKey: 'RESTORE_ITEM.SUCCESS',
          messageParams: { ITEM_NAME: item.name },
        });
      });
    })
    .catch(() => {
      errorToast({
        messageKey: 'RESTORE_ITEM.ERROR',
        messageParams: { ITEM_NAME: item.name },
      });
    });
}

/**
 * Retrieve the archive API promise for an item
 *
 * @param item The item to archive/delete
 * @return {Promise} The archive API
 */
export function getDeleteAPI(item) {
  switch (item.type) {
    case API_TYPES.DISPLAY:
      return sqDisplaysApi.archiveDisplay({ id: item.id });
    case API_TYPES.DISPLAY_TEMPLATE:
      return sqDisplayTemplatesApi.archiveDisplayTemplate({ id: item.id });
    default:
      return sqItemsApi.archiveItem({ id: item.id });
  }
}

/**
 * Delete an item with an option to show restore button. Upon success calls searchOrExplore.
 *
 * @param item The item to delete
 * @param messageDeleted - The messageKey to show when the item is successfully deleted
 * @param messageDeleteError - The messageKey to show when an error occurs deleting the item
 * @param [messageDeletedParams] - Parameters for messageDeleted
 * @param [messageDeletedErrorParams] - Parameters for messageDeletedError
 * @param [isPinned] - Is this a pinned item (default false)
 * @param [restoreButton] - True to show a Toast message with restore button (default false)
 * @param [restrictExploration] - If exploration should be restricted (default false)
 * @param [pane] - The name of the search pane, one of SearchPanes (default SearchPanes.MAIN)
 * @param [searchTypes] - The types to search (default SEARCH_TYPES)
 * @param [scopeIds] - Ids of scoped assets (default is workbookId)
 */
export function deleteItem(
  item: { id: string; name: string; type: string },
  messageDeleted: string,
  messageDeleteError: string,
  messageDeletedParams: TOptions = {},
  messageDeletedErrorParams: TOptions = {},
  isPinned = false,
  restoreButton = false,
  restrictExploration = false,
  pane: SearchPanes = 'main',
  searchTypes: string[] = SEARCH_TYPES,
  scopeIds: string[] = [sqWorkbenchStore.stateParams.workbookId],
) {
  return getDeleteAPI(item)
    .then(() => {
      const store = getStoreForPane(pane);
      return searchOrExplore(pane, store.mode, searchTypes, restrictExploration, scopeIds).then(() => {
        if (isPinned) {
          fetchPinnedItems(sqWorkbenchStore.stateParams.workbookId);
        }

        if (restoreButton) {
          successToast({
            messageKey: messageDeleted,
            messageParams: messageDeletedParams,
            buttonLabelKey: 'RESTORE',
            buttonAction: () => restoreItem(item, pane, searchTypes, isPinned, restrictExploration, scopeIds),
          });
        } else {
          successToast({
            messageKey: messageDeleted,
            messageParams: messageDeletedParams,
          });
        }
      });
    })
    .catch(() => {
      errorToast({
        messageKey: messageDeleteError,
        messageParams: messageDeletedErrorParams,
      });
    });
}

/**
 * Resets the async initialized flag for the given pane and clears the current search params
 * @param pane - the name of the search pane
 */
export function reset(pane: SearchPanes) {
  flux.dispatch('SEARCH_ASYNC_INITIALIZED', {
    pane,
    isAsyncInitialized: false,
  });
  flux.dispatch('SEARCH_CLEAR', { pane });
}

/**
 * Helper function that returns the correct store based on the pane.
 *
 * @param {String} pane - The name of the search pane, one of SearchPanes
 *
 * @return {Object} The search store that is used for the specified pane
 */
export function getStoreForPane(pane: SearchPanes) {
  return pane === 'main' ? sqSearchStore : sqModalSearchStore;
}

/**
 * Override the datasource preference filtering
 *
 * @param {String} pane - The name of the search pane, one of SearchPanes
 * @param {String} value - Toggle value for the datasource preference filtering
 */
export function setIsUsingDatasourcePrefsSearchFilters(pane: SearchPanes, value: boolean) {
  flux.dispatch('SET_IS_USING_DATASOURCE_PREFS_SEARCH_FILTERS', { pane, value }, PUSH_IGNORE);
}
