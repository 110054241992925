import React from 'react';
import { useTranslation } from 'react-i18next';
import { doTrack } from '@/track/track.service';
import { Modal } from '@seeqdev/qomponents';

interface UnsavedChangesModalProps {
  onCancel: () => void;
  onDiscard: () => void;
}

export const UnsavedChangesModal: React.FunctionComponent<UnsavedChangesModalProps> = ({ onCancel, onDiscard }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={true}
      title={t('ITEM_ACL.UNSAVED_CHANGES.HEADER')}
      onClose={() => {
        doTrack('Access Control', 'confirmation dialog', 'cancel');
        onCancel();
      }}
      submitButtonLabel={t('ITEM_ACL.UNSAVED_CHANGES.DISCARD')}
      onSubmit={() => {
        doTrack('Access Control', 'confirmation dialog', 'discard');
        onDiscard();
      }}
      size="lg"
      cancelButtonLabel={t('CANCEL')}>
      <div>{t('ITEM_ACL.UNSAVED_CHANGES.MESSAGE')}</div>
    </Modal>
  );
};
