import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface FormWarningProps {
  warningText: string;
  extraClassNames?: string;
  onClick?: () => void;
}

export const FormWarning: React.FunctionComponent<FormWarningProps> = (props) => {
  const { warningText, onClick, extraClassNames } = props;
  const { t } = useTranslation();

  return (
    <p className={classNames('form-text', 'sq-text-warning', extraClassNames)} onClick={onClick}>
      {t(warningText)}
    </p>
  );
};
