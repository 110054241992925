import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Field, useForm } from 'react-final-form';
import _ from 'lodash';
import { Button } from '@seeqdev/qomponents';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { ScheduleTypeName, UpdatedSchedule } from '@/schedule/schedule.types';
import { Schedule } from '@/schedule/Schedule';
import { CancelAndSave } from '@/core/CancelAndSave.molecule';
import { getNewCronSchedule } from '@/schedule/schedule.utilities';
import { InputGroup } from 'react-bootstrap';

export interface ScheduleFormComponentProps extends ValidatingFormComponent<any> {
  component: 'ScheduleFormComponent';
  customErrorText?: string;
  onChange: (value: any) => void;
  onDelete: () => void;
  schedule: string;
  customScheduleLabel: string;
  noScheduleMessage: string;
  scheduleMessage: string;
  setIsScheduleUnsaved: (isUnsaved: boolean) => void;
  isScheduleUnsaved: boolean;
}

export const ScheduleFormComponent: React.FunctionComponent<ScheduleFormComponentProps> = (props) => {
  const {
    name,
    value,
    testId = 'schedule',
    validation,
    extendValidation,
    customErrorText,
    required = false,
    schedule,
    onChange,
    customScheduleLabel,
    noScheduleMessage,
    scheduleMessage,
    onDelete,
    setIsScheduleUnsaved,
    isScheduleUnsaved,
  } = props;

  const { t } = useTranslation();

  const [updatedSchedule, setUpdatedSchedule] = useState<UpdatedSchedule>();
  const [newCronSchedule, setNewCronSchedule] = useState<string[]>();

  useEffect(() => {
    onChange(newCronSchedule);
  }, [newCronSchedule]);

  const formState = useForm().getState();
  const showError = _.has(formState.errors, name) && formState.submitFailed && formState.hasValidationErrors;

  const defaultValidation = (value: any) => required && !value;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  const scheduleLabel = !_.isEmpty(value) ? t(scheduleMessage, { schedule }) : t(noScheduleMessage);

  return (
    <>
      <FormFieldWrapper
        wrapperClassNames="flexFill mb20"
        testId={testId}
        showError={showError}
        errorClassNames="ml20"
        customErrorText={customErrorText}>
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const formFieldProps = getFormFieldProps(formState, input, meta, props);
            return (
              <div>
                {!isScheduleUnsaved ? (
                  <InputGroup>
                    <div
                      className={classNames(
                        'form-control ptb0 flexColumnContainer flexAlignCenter cursorPointer',
                        formFieldProps.extraClassNames,
                      )}
                      onClick={() => {
                        setIsScheduleUnsaved(true);
                      }}
                      data-testid={`${testId}-value`}>
                      {newCronSchedule ? t(customScheduleLabel) : `${scheduleLabel}`}
                    </div>
                    <InputGroup.Append>
                      {(newCronSchedule || value) && (
                        <Button
                          icon="fa-trash"
                          iconStyle="theme"
                          testId={`${testId}-delete`}
                          onClick={() => {
                            onDelete();
                            setNewCronSchedule(undefined);
                          }}
                        />
                      )}
                      <Button
                        icon="fa-pencil"
                        iconStyle="theme"
                        testId={`${testId}-edit`}
                        onClick={() => setIsScheduleUnsaved(true)}
                      />
                    </InputGroup.Append>
                  </InputGroup>
                ) : (
                  <div className="mb20 ml20 mt10">
                    <Schedule
                      cronSchedule={value}
                      initialScheduleType={ScheduleTypeName.LIVE}
                      updatedSchedule={updatedSchedule}
                      onScheduleChange={setUpdatedSchedule}
                      scheduleTypeTranslations={{ [ScheduleTypeName.LIVE]: 'SCHEDULE_TYPE.LIVE.NAME_ALTERNATE' }}
                    />
                    <CancelAndSave
                      submitFn={() => {
                        if (updatedSchedule) {
                          setNewCronSchedule(getNewCronSchedule(updatedSchedule));
                        }
                        return setIsScheduleUnsaved(false);
                      }}
                      cancelFn={() => {
                        setIsScheduleUnsaved(false);
                      }}
                      values={[]}
                      submitClassNames="btn-sm width-80"
                      cancelClassNames="btn-sm mr10 width-80"
                    />
                  </div>
                )}
              </div>
            );
          }}
        </Field>
      </FormFieldWrapper>
    </>
  );
};
