import { Moment } from 'moment';
import _ from 'lodash';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { sqFormulasApi } from '@/sdk/api/FormulasApi';
import { COMPARISON_OPERATORS_SYMBOLS, PREDICATE_API } from '@/toolSelection/investigate.constants';
import { API_TYPES_TO_ITEM_TYPES, ITEM_TYPES } from '@/trendData/trendData.constants';
import { CalculatedItemOutputV1 } from 'sdk/model/CalculatedItemOutputV1';
import { FormulaItemOutputV1 } from 'sdk/model/FormulaItemOutputV1';
import { PropertyHrefOutputV1 } from 'sdk/model/PropertyHrefOutputV1';
import { ScalarPropertyV1 } from 'sdk/model/ScalarPropertyV1';
import { sqConditionsApi } from '@/sdk/api/ConditionsApi';
import { sqSignalsApi } from '@/sdk/api/SignalsApi';
import { sqScalarsApi } from '@/sdk/api/ScalarsApi';

import { SeeqNames } from '@/main/app.constants.seeqnames';
import { encodeParameters, isStringSeries as isStringSeriesUtil, validateGuid } from '@/utilities/utilities';
import { PUSH_IGNORE } from '@/core/flux.service';
import { ParametersMap } from '@/utilities/formula.constants';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.constants';
import { sqTrendScalarStore, sqTrendSeriesStore } from '@/core/core.stores';
import { StoredStatistic } from '@/tools/StatisticSelector.molecule';
import { AxiosPromise } from 'axios';
import { Optional } from '@/utilities.types';
import { setTrendItemProps } from '@/trendData/trend.actions';
import { Signal } from '@/utilities/items.types';
import { FrontendDuration } from '@/services/systemConfiguration.types';

type FormulaAndParameters = { formula: string; parameters: ParametersMap };

interface ProfileSearchParams {
  /** ID of the series containing the pattern */
  inputSignalId: string;
  /** Time marking the start of the search pattern */
  referenceStart: Moment;
  /** Time marking the end of the search pattern */
  referenceEnd: Moment;
  /** Threshold of matches, in terms of how similar (0-1) they are */
  similarity: string | number;
  /** True if amplitude should be normalized. */
  normalizeAmplitude: boolean;
  /** True if location should be normalized */
  normalizeLocation: boolean;
}

const DEFAULT_AMPLITUDE_FACTOR = 0.3;
const DEFAULT_LOCATION_FACTOR = 0.3;

/**
 * Searches one or more series based on a visual reference pattern and generates capsules from the matches.
 *
 * @returns an object containing the formula and parameters
 */
export function profileSearch({
  inputSignalId,
  referenceStart,
  referenceEnd,
  similarity,
  normalizeAmplitude,
  normalizeLocation,
}: ProfileSearchParams): FormulaAndParameters {
  const parameters = { a: inputSignalId };
  const start = `toTime("${referenceStart.toISOString()}")`;
  const end = `toTime("${referenceEnd.toISOString()}")`;
  const amplitudeFactor = normalizeAmplitude ? DEFAULT_AMPLITUDE_FACTOR : -1;
  const locationFactor = normalizeLocation ? DEFAULT_LOCATION_FACTOR : -1;
  const params = ['$a', start, end, similarity, 0.5, amplitudeFactor, locationFactor];
  const formula = `profileSearch(${params.join(', ')})`;

  return { formula, parameters };
}

export async function simpleValueSearch({
  inputSignal,
  operator,
  value,
  upperInclusivity,
  lowerValue,
  lowerInclusivity,
  isCleansing,
  useValidValues,
  minDuration,
  mergeDuration,
}: {
  operator: keyof typeof PREDICATE_API;
  inputSignal: Signal;
  useValidValues: boolean;
  isCleansing: boolean;
  mergeDuration: FrontendDuration;
  minDuration: FrontendDuration;
  value: string;
  lowerValue: string;
  lowerInclusivity: keyof typeof PREDICATE_API;
  upperInclusivity: keyof typeof PREDICATE_API;
}): Promise<FormulaAndParameters> {
  // Helper function to put the value in the correct format for scalars, signals and string series
  function getValue(value: string, param: string) {
    const invalidGuidValue = isStringSeries ? `"${value}"` : value;

    return validateGuid(value) ? `$${param}` : invalidGuidValue;
  }

  const isStringSeries = isStringSeriesUtil(inputSignal);
  const parameters = { a: inputSignal.id };

  const isValueItem = validateGuid(value);
  const isLowerValueItem = validateGuid(lowerValue);
  if (isValueItem) _.assign(parameters, { b: value });
  if (isLowerValueItem) _.assign(parameters, { c: lowerValue });

  let formula: string;
  if (operator === COMPARISON_OPERATORS_SYMBOLS.IS_NOT_BETWEEN) {
    // $a < b || $a > c
    formula = `$a ${lowerInclusivity} ${getValue(lowerValue, 'c')} || $a ${upperInclusivity} ${getValue(value, 'b')}`;
  } else if (operator === COMPARISON_OPERATORS_SYMBOLS.IS_BETWEEN) {
    // b > $a > c
    formula = `$a ${upperInclusivity} ${getValue(value, 'b')} && $a ${lowerInclusivity} ${getValue(lowerValue, 'c')}`;
  } else if (operator === COMPARISON_OPERATORS_SYMBOLS.IS_EQUAL_TO) {
    formula = `$a == ${getValue(value, 'b')}`;
  } else {
    formula = `$a ${operator} ${getValue(value, 'b')}`;
  }

  if (isCleansing) {
    formula = `(${formula})`;
    formula += `\n.merge(${mergeDuration.value}${mergeDuration.units})`;

    // only call removeShorterThan if we have a positive value (0 can be accepted in the UI)
    if (minDuration.value > 0) {
      formula += `\n.removeShorterThan(${minDuration.value}${minDuration.units})`;
    }
  }

  const promises = [];
  if (useValidValues) {
    async function getParameterType(id: string, name: string) {
      const item = _.find(_.concat(sqTrendScalarStore.items, sqTrendSeriesStore.primarySeries), { id });
      if (_.isUndefined(item)) {
        const item_1 = await sqItemsApi.getItemAndAllProperties({ id });
        return {
          name,
          itemType: API_TYPES_TO_ITEM_TYPES[item_1.data.type],
        };
      }

      return Promise.resolve({ name, itemType: item.itemType });
    }

    if (isValueItem) {
      promises.push(getParameterType(value, 'b'));
    }

    if (isLowerValueItem) {
      promises.push(getParameterType(lowerValue, 'c'));
    }
  }

  const params = await Promise.all(promises);
  if (useValidValues) {
    // add the .validValues() to each parameter throughout the formula (only if it is NOT a calculated scalar type)
    _.chain(params)
      .concat({ name: 'a', itemType: ITEM_TYPES.SERIES })
      .filter(({ itemType }) => itemType === ITEM_TYPES.SERIES)
      .forEach(({ name: name_1 }) => {
        formula = _.replace(formula, new RegExp(`\\$${name_1}`, 'g'), `$${name_1}.validValues()`);
      })
      .value();
  }

  return { formula, parameters };
}

interface ValueSearchCondition {
  operator: keyof typeof PREDICATE_API;
  value: number;
  lowerValue: number;
  duration: FrontendDuration;
}

export function advancedValueSearch({
  inputSignal,
  entry,
  exit,
  maximumDuration,
  useValidValues,
}: {
  inputSignal: Signal;
  entry: ValueSearchCondition;
  exit: ValueSearchCondition;
  maximumDuration: FrontendDuration;
  useValidValues: boolean;
}): FormulaAndParameters {
  const parameters = { a: inputSignal.id };
  let formula = '$a';
  if (useValidValues) {
    formula += '.validValues()';
  }

  formula +=
    `.valueSearch(${maximumDuration.value}${maximumDuration.units}, ` +
    `${PREDICATE_API[entry.operator]}(${getFormulaValue(entry, inputSignal)}), ` +
    `${entry.duration.value}${entry.duration.units}, ` +
    `${PREDICATE_API[exit.operator]}(${getFormulaValue(exit, inputSignal)}), ` +
    `${exit.duration.value}${exit.duration.units})`;

  return { formula, parameters };

  function getFormulaValue(condition: ValueSearchCondition, inputSignal: Signal) {
    const values = _.compact([condition.value, condition.lowerValue]).join(',');

    return isStringSeriesUtil(inputSignal) ? `"${values}"` : values;
  }
}

/**
 * Compute the parameterized stat from the statistic collector
 *
 * @param {StoredStatistic} statistic - The statistic object
 * @param {string} statistic.key - The key into the SAMPLES_FROM_SCALARS.VALUE_METHOD object
 * @param {string} statistic.timeUnits - for stats that are outputInTimeUnits=true, this is the selected time unit
 * @param {number} statistic.percentile - for stats that needsPercentile=true, this is the specified percentile
 *
 * @return The formula fragment that is usable in the aggregate operator.
 *
 * @throws TypeError
 */
export function getStatisticFragment(statistic: StoredStatistic): string {
  const statObject = _.find(SAMPLE_FROM_SCALARS.VALUE_METHODS, ['key', _.get(statistic, 'key')]);
  if (!_.isNil(statObject)) {
    return _.chain(statObject.stat)
      .replace('$unit', `"${_.get(statistic, 'timeUnits')}"`)
      .replace('$percentile', String(_.get(statistic, 'percentile')))
      .replace('$multiplier', String(_.get(statistic, 'multiplier')))
      .value();
  }

  throw new TypeError(`Unknown statistic key ${_.get(statistic, 'key')} `);
}

interface FormulaCreatorParams {
  /** Name for calculation */
  name: string;
  /** ID of a workbook to which the item will be scoped or undefined for global */
  scopedTo: string | undefined;
  /** The formula to pass to the Calculation Engine */
  formula: string;
  /** Map of parameter name to ID that are the top-level parameters used in the formula */
  parameters: ParametersMap;
}

interface CreateCalculatedItemParams extends FormulaCreatorParams {
  additionalProperties: ScalarPropertyV1[];
}

/**
 * Runs a formula that produces an auto-selecting type. Saves or updates that calculation based on compile type.
 *
 * @returns Resolves when the item has been generated. If successful the promise will resolve with the
 * return type in a container object
 */
export async function createCalculatedItem({
  name,
  scopedTo,
  formula,
  parameters,
  additionalProperties = [],
}: CreateCalculatedItemParams): Promise<FormulaItemOutputV1> {
  const params = _.map(parameters, (v: string, k: string | number | any) => {
    return { name: k, id: v };
  }) as unknown as any[];
  const response = await sqFormulasApi.createItem({
    name,
    scopedTo,
    formula,
    parameters: params,
    additionalProperties,
  });

  return response.data;
}

export type FormulaCreator = (params: FormulaCreatorParams) => Promise<CalculatedItemOutputV1>;

/**
 * Creates a function that runs a formula that produces the type that matches the creator function. Saves or updates a
 * calculation
 *
 * @param itemType - the type of item to create. Condition, Signal, or CalculatedScalar
 *
 * @returns the function that can be used to create the item
 */
export function createFormulaItem(itemType: string): FormulaCreator {
  // The APIs are slightly different. /signals uses formulaParameters, while others use parameters
  const parametersKey = itemType === SeeqNames.Types.Signal ? 'formulaParameters' : 'parameters';
  const isSignal = () => SeeqNames.Types.Signal === itemType;
  const isCondition = () => SeeqNames.Types.Condition === itemType;
  const isScalar = () => SeeqNames.Types.CalculatedScalar === itemType;
  const creator = _.cond([
    [isCondition, (body) => sqConditionsApi.createCondition(body as any)],
    [isSignal, (body) => sqSignalsApi.createSignal(body as any)],
    [isScalar, (body) => sqScalarsApi.createCalculatedScalar(body as any)],
  ]);

  /**
   * Runs a formula that produces the type that matches the creator function. Saves or updates a calculation
   *
   * @param name - Name for item
   * @param scopedTo - ID of a workbook to which the item will be scoped or undefined for global
   * @param formula - The formula to pass to the Calculation Engine
   * @param parameters - Map of parameter name to ID that are the top-level parameters used in the formula
   */
  return async ({ name, scopedTo, formula, parameters }) => {
    const body: Record<string, any> = {
      name,
      formula,
      [parametersKey]: encodeParameters(parameters),
      scopedTo,
    };
    if (isCondition()) {
      body.maximumDuration = null;
    }

    const results = await creator(body);

    return results.data;
  };
}

interface UpdateFormulaItemParams {
  /** The id of the existing calculation */
  id: string;
  /** The name of the calculation */
  name: string;
  /** The formula to pass to the Calculation Engine */
  formula: string;
  /** Map of parameter name to ID that are the top-level parameters used in the formula */
  parameters: ParametersMap;
}

/**
 * Update a calculated series or capsule set by setting the name, formula and UIConfig properties. Properties are
 * updated in sequential order to ensure that if the formula is bad the others are not updated. The backend takes
 * care of invalidating the cached data whenever the `formula` property is updated.
 *
 * @returns Resolves when all the properties are updated
 */
export async function updateFormulaItem({
  id,
  name,
  formula,
  parameters,
}: UpdateFormulaItemParams): Promise<AxiosPromise<PropertyHrefOutputV1>> {
  await sqItemsApi.setFormula({ formula, parameters: encodeParameters(parameters) }, { id });
  setTrendItemProps(id, { name }, PUSH_IGNORE);

  return sqItemsApi.setProperty({ value: name }, { id, propertyName: SeeqNames.Properties.Name });
}

/**
 * Computes a statistic from a statistic fragment
 *
 * @param statisticFragment - a statisticFragment (e.g. average() or totalized("min"))
 *
 * @returns {StoredStatistic} - a statistic or undefined if a statistic could not be determined from the fragment
 */
export function getStatisticFromFragment(
  statisticFragment: string,
): Optional<StoredStatistic, 'timeUnits'> | undefined {
  const result = /^(\w*)\(['"]?(\w*)?['"]?\)$/.exec(statisticFragment);
  const fragFuncName = _.get(result, '1');
  const stat = _.find(SAMPLE_FROM_SCALARS.VALUE_METHODS, (valueMethod) => _.startsWith(valueMethod.stat, fragFuncName));
  if (stat) {
    const statData = { key: stat.key };
    if (stat.needsPercentile) {
      const percentile = parseInt(_.get(result, 2) ?? '', 10);
      _.assign(statData, {
        percentile: _.isNaN(percentile) ? null : percentile,
      });
    } else if (stat.outputInTimeUnits) {
      _.assign(statData, { timeUnits: _.get(result, 2) });
    } else if (stat.needsMultiplier) {
      const multiplier = parseFloat(_.get(result, 2) ?? '');
      _.assign(statData, {
        multiplier: _.isNaN(multiplier) ? null : multiplier,
      });
    }

    return statData;
  }
}

export const createCondition = () => createFormulaItem(SeeqNames.Types.Condition);

export const createSignal = () => createFormulaItem(SeeqNames.Types.Signal);

export const createScalar = () => createFormulaItem(SeeqNames.Types.CalculatedScalar);
