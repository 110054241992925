// @ts-strict-ignore
import { useIsMounted } from '@/core/hooks/useIsMounted.hook';
import { useState } from 'react';
import { useDidUpdate } from 'rooks';

/**
 * A hook to manage toggling-select-all state
 */
export const useTogglingSelectAll = ({ items }) => {
  const isMounted = useIsMounted();
  const [togglingSelectAll, setTogglingSelectAll] = useState(false);

  useDidUpdate(() => {
    if (togglingSelectAll) {
      // Estimated time needed to select all items and/or assets
      const waitTime = 1.32 * items.length + 80;
      setTimeout(() => {
        isMounted.current && setTogglingSelectAll(false);
      }, waitTime);
    }
  }, [togglingSelectAll]);

  return { togglingSelectAll, setTogglingSelectAll };
};
