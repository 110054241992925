import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@seeqdev/qomponents';

interface IrregularScheduleProps {
  schedules: string[];
  onConvertIt: () => void;
}

export const IrregularSchedule: React.FunctionComponent<IrregularScheduleProps> = ({ schedules, onConvertIt }) => {
  const { t } = useTranslation();

  return (
    <FormGroup data-testid="live-schedule-config">
      <FormLabel>{t('SCHEDULE_TYPE.IRREGULAR_SCHEDULE')}</FormLabel>
      {_.map(schedules, (schedule) => (
        <FormControl
          key={schedule}
          readOnly={true}
          className="mb15"
          data-testid="irregular-schedule-config__cron-schedule"
          as="input"
          type="string"
          value={schedule}
        />
      ))}

      <div className="flexColumnContainer flexAlignStart">
        <Icon icon="fa-info-circle" large={true} extraClassNames="btn-transparent" />
        <span>{t('SCHEDULE_TYPE.IRREGULAR')}</span>
        <Button
          testId="irregular-schedule-config__convert-it"
          extraClassNames="width-250"
          label={t('SCHEDULE_TYPE.IRREGULAR_CONVERT_IT')}
          onClick={onConvertIt}
        />
      </div>
    </FormGroup>
  );
};
