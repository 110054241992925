import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { COLORS_PER_ROW } from '@/trendData/trendData.constants';

interface SwatchGroupProps {
  colors: string[];
  onSelectColor: (color: string, event: React.MouseEvent<HTMLDivElement>) => void;
  heading: string;
  limitToSwatches: boolean;
  testId: string;
}

export const SwatchGroup: React.FunctionComponent<SwatchGroupProps> = (props) => {
  const { t } = useTranslation();
  const hasLargeSwatches: boolean = props.colors.length <= COLORS_PER_ROW && props.limitToSwatches;
  const groupedColors = _.chunk(props.colors, hasLargeSwatches ? 1 : COLORS_PER_ROW);

  return (
    <div>
      {props.colors.length > 0 && (
        <div>
          {!props.limitToSwatches && <hr />}
          <div className="flexRowContainer" data-testid={props.testId}>
            <span>{t(props.heading)}</span>
            {_.map(groupedColors, (colorGroup, grpIdx) => {
              return (
                <div className="flexColumnContainer" key={grpIdx}>
                  {_.map(colorGroup, (color, idx) => (
                    <div
                      key={idx}
                      className={hasLargeSwatches ? 'colorPickerSwatch large' : 'colorPickerSwatch'}
                      style={{ backgroundColor: color }}
                      onClick={(e) => props.onSelectColor(color, e)}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
