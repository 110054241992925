import { useContext, useEffect } from 'react';
import { useForceUpdate } from '@/core/hooks/useForceUpdate.hook';
import { useCurrentValueRef } from '@/core/hooks/useCurrentValueRef.hook';
import { FluxContext } from '@/core/flux.context';

/**
 * This hook enables using data from a flux store in React such that the component will be re-rendered when
 * the store changes at a certain path. `useFluxPath` only forces a render when the portion of the store at
 * the path changes.  This hook should be used instead of `useFlux` as a performance optimization, when in doubt
 * use `useFlux`.
 *
 * @example
 * const Component = ({ sqTrendStore }) => {
 *   # will rerender only when view, selectedRegion change
 *   const view = useFluxPath(sqTrendStore, () => sqTrendStore.view);
 *   const selectedRegion = useFluxPath(sqTrendStore, () => sqTrendStore.selectedRegion);
 *   return <OtherComponent view={view} selectedRegion={selectedRegion} targetView={TREND_VIEWS.CALENDAR} />
 * }
 *
 * @example
 * const AdvancedComponent = ({ sqTrendConditionStore, sqTrendSeriesStore, isCondition, id }) => {
 *   # Store and path can be dynamically changed; will rerender when resulting item would change
 *   const item = useFluxPath(
 *     isCondition ? sqTrendConditionStore : sqTrendSeriesStore,
 *     store => store.items[id]
 *   );
 *   return <OtherComponent view={view} selectedRegion={selectedRegion} targetView={TREND_VIEWS.CALENDAR} />
 * }
 *
 * @param store - The flux store export object that will be passed to flux.listenTo
 * @param path - A function to return the desired portion of the store. The function is passed the store as a
 * parameter. The path does not need to use the store parameter if the store is constant. The path function should
 * return the same result for a store with the same state - otherwise the performance optimization won't be effective
 * @return the value of invoking the path function
 */
export function useFluxPath<T, R>(store: T, path: (store: T) => R, compareFn = Object.is): R {
  const state = path(store);
  const flux = useContext(FluxContext);
  const forceUpdate = useForceUpdate();

  const stateRef = useCurrentValueRef(state);
  const pathRef = useCurrentValueRef(path);

  useEffect(
    () =>
      flux.listenTo(
        store,
        undefined,
        () => {
          // Object.is is the method react uses to bail out of state updates for useState and useReducer
          // https://reactjs.org/docs/hooks-reference.html#bailing-out-of-a-state-update
          if (!compareFn(stateRef.current, pathRef.current(store))) {
            forceUpdate();
          }
        },
        false,
      ),
    [flux, store],
  );

  return state;
}
