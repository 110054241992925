import _ from 'lodash';
/**
 * A service that manages changes to the schema of store data with persistenceLevel=WORKBENCH.
 *
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * NOTE:
 * workbench.upgrader (this file) only manages changes to stores with the persistenceLevel WORKBENCH. If your
 * change impacts a WORKSTEP please add your upgrade to workstepUpgrader.utilities; if your change impacts a
 * UIConfig please add the necessary upgrades to configUpgrader.utilities;
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *
 * Since state is just the result of dehydrating all stores this means that any store that makes a
 * backwards incompatible change (e.g. changing a property name, changing a data structure, etc.) must do the following:
 *
 * 1. Create a function named `upgradeX` where X is the current WORKBENCH_SCHEMA_VERSION and add it to the upgraders
 * object.
 *
 * 2. Increase WORKBENCH_SCHEMA_VERSION by one
 */
import { TrainingPromptDisplay } from '@/homescreen/homescreen.constants';
import { DehydratedState } from '@/services/stateSynchronizer.service';
import { WORKBENCH_SCHEMA_VERSION } from '@/workbench/workbench.constants';

/**
 * Upgrade the state of a workstep from the specified version to the latest version. Runs the state through a series
 * of transform functions, in order, from the state's version to the specified version.
 *
 * @param {Object} state - The workstep state that is result of dehydrating all the stores.
 * @param {Number} fromVersion - The version number from when the state was created
 * @param {Number} [toVersion=WORKSTEP_SCHEMA_VERSION] - The version number up to which the state will be migrated.
 * Useful only for testing.
 * @returns {Object} The transformed state.
 */
export function applyWorkbenchConfigUpgrade(
  state: DehydratedState,
  fromVersion: number,
  toVersion?: number | null,
): DehydratedState {
  toVersion = toVersion || WORKBENCH_SCHEMA_VERSION;
  const upgradedState = _.reduce(
    _.range(fromVersion, toVersion, 1),
    (newState, newVersion) => upgraders[`upgrade${newVersion}`](newState),
    state,
  );
  _.set(upgradedState as any, 'stores.sqWorkbenchStore.version', WORKBENCH_SCHEMA_VERSION);
  return upgradedState;
}

/**
 * The new Home Screen training prompt no longer supports being collapsed - it's either shown or not.
 * To avoid showing the screen to users that have already collapsed or hidden the previous training screen we ensure
 * that we initialize the store correctly.
 *
 * @param state
 */
function upgrade1(state: DehydratedState) {
  const oldHelpScreenState = _.get(state, 'stores.sqHomeScreenStore.helpDisplay');
  if (oldHelpScreenState) {
    let initialTrainingPromptState = TrainingPromptDisplay.NONE;
    if (oldHelpScreenState === 'EXPANDED') {
      initialTrainingPromptState = TrainingPromptDisplay.HOME_SCREEN;
    } else if (oldHelpScreenState === 'COLLAPSED') {
      initialTrainingPromptState = TrainingPromptDisplay.POPOVER;
    }
    _.unset(state as any, 'stores.sqHomeScreenStore.helpDisplay');
    _.set(state as any, 'stores.sqHomeScreenStore.trainingPromptDisplay', initialTrainingPromptState);
  }
  return state;
}

const upgraders: Record<string, (state: DehydratedState) => DehydratedState> = {
  upgrade1,
};
