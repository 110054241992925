import React from 'react';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import classNames from 'classnames';

interface LoadingFallbackProps {
  size?: 'lg' | 'sm';
}

/**
 * Loading spinner to use as the fallback with React.Suspense. Fills the entire element.
 */
export const LoadingFallback: React.FunctionComponent<LoadingFallbackProps> = ({ size = 'lg' }) => {
  return (
    <div data-testid="loadingFallback" className="flexColumnContainer flexCenter flexFill height-maximum">
      <IconWithSpinner spinning={true} extraClassNames={classNames({ 'fa-5x': size === 'lg' })} />
    </div>
  );
};
