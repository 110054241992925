import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { GetLink } from '@/core/GetLink.atom';
import {
  getFolderLink,
  getPresentWorksheetLink,
  getShortViewWorksheetLink,
  getWorksheetLink,
} from '@/main/routing.utilities';

interface GetLinksProps {
  workbookId: string;
  worksheetId?: string;
  folderId?: string;
  showOnlyFolderLinks?: boolean;
}

export const GetLinks: React.FunctionComponent<GetLinksProps> = ({
  workbookId,
  worksheetId,
  folderId,
  showOnlyFolderLinks,
}) => {
  const { t } = useTranslation();

  const editFullPath = getWorksheetLink(workbookId, worksheetId, folderId, undefined, true);
  const viewFullPath = getShortViewWorksheetLink(worksheetId ?? '', true);
  const presentFullPath = getPresentWorksheetLink(workbookId, worksheetId ?? '', true);
  const folderPath = getFolderLink(folderId ?? '', undefined, true);

  const getLinks = [
    {
      title: 'ITEM_ACL.EDIT_LINK',
      testId: 'editLink',
      value: editFullPath,
      tooltip: 'ITEM_ACL.EDIT_LINK_TOOLTIP',
    },
    {
      title: 'ITEM_ACL.VIEW_LINK',
      testId: 'viewLink',
      value: viewFullPath,
      tooltip: 'ITEM_ACL.VIEW_LINK_TOOLTIP',
    },
    {
      title: 'ITEM_ACL.PRESENTATION_LINK',
      testId: 'presentationLink',
      value: presentFullPath,
      tooltip: 'ITEM_ACL.PRESENTATION_LINK_TOOLTIP',
    },
  ];

  const folderLinks = [
    {
      title: 'ITEM_ACL.FOLDER_LINK',
      testId: 'folderLink',
      value: folderPath,
      tooltip: 'ITEM_ACL.FOLDER_LINK_TOOLTIP',
    },
  ];

  return (
    <>
      <h4>{t('ITEM_ACL.GET_LINK')}</h4>
      {_.map(showOnlyFolderLinks ? folderLinks : getLinks, (link) => (
        <GetLink key={link.testId} testId={link.testId} title={link.title} value={link.value} tooltip={link.tooltip} />
      ))}
    </>
  );
};
