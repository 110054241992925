import _ from 'lodash';

export interface CompositeLogic {
  key: string;
  operator: string;
  formula: (
    a: string,
    b: string,
    matchProperty?: string,
    keepProperties?: string,
    useEarliestStart?: boolean,
    duration?: string,
  ) => void;
  withMaximumDuration?: boolean;
  withKeepProperties?: boolean;
  exclude?: string;
}

const cleanFormula = (formula: string) => formula.replace(/, '?undefined'?/g, '');

export const LOGIC: CompositeLogic[] = [
  {
    key: 'UNION',
    operator: 'union',
    formula: (a, b, matchProperty) => cleanFormula(`${a}.union(${b}, '${matchProperty}')`),
  },
  {
    key: 'INTERSECTION',
    operator: 'intersect',
    formula: (a, b, matchProperty, keepProperties) =>
      cleanFormula(`${a}.intersect(${b}, '${matchProperty}', ${keepProperties})`),
    withKeepProperties: true,
  },
  {
    key: 'JOIN',
    operator: 'join',
    formula: (a, b, matchProperty, keepProperties, useEarliestStart, duration) =>
      cleanFormula(`${a}.join(${b}, ${duration}, ${useEarliestStart}, '${matchProperty}', ${keepProperties})`),
    withMaximumDuration: true,
    withKeepProperties: true,
  },
  {
    // Touches
    key: 'OVERLAPPED_BY',
    operator: 'touches',
    exclude: 'matchesWith',
    formula: (a, b, matchProperty) => cleanFormula(`${a}.touches(${b}, '${matchProperty}')`),
  },
  {
    // Outside
    key: 'NOT_OVERLAPPED_BY',
    operator: 'matchesWith',
    formula: (a, b, matchProperty) =>
      cleanFormula(`${a}.matchesWith(${a}.subtract(${a}.touches(${b}, '${matchProperty}'), '${matchProperty}'), 0)`),
  },
  {
    // Inside
    key: 'ENCLOSES',
    operator: 'inside',
    formula: (a, b, matchProperty) => cleanFormula(`${a}.inside(${b}, '${matchProperty}')`),
  },
  {
    // Minus
    key: 'SUBTRACT',
    operator: 'subtract',
    formula: (a, b, matchProperty) => cleanFormula(`${a}.subtract(${b}, '${matchProperty}')`),
  },
];

export const LOGIC_KEY = _.zipObject(_.map(LOGIC, 'key'), _.map(LOGIC, 'key'));

export const NO_MATCH_PROPERTY = 'n/a';

export const MATCH_PROPERTY_CANCELLATION_GROUP = 'matchProperty';
