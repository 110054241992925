// @ts-strict-ignore
import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { isValidCapsule } from '@/tools/manualCondition/conditionFormula.service';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { Capsule } from '@/utilities/datetime.constants';
import { DateTimeRange, RangeField } from '@/core/DateTimeRange.molecule';
import { sqWorksheetStore } from '@/core/core.stores';

interface CapsuleInputIF {
  setCapsuleInputValidStatus?: (isValid: boolean) => void;
  onChange?: (capsuleWindow: Capsule) => void;
  trackInformation?: string;
  trackAction: string;
  trackCategory: string;
  dateFormat?: string;
  capsuleWindow?: Capsule;
}

export const CapsuleInput: React.FunctionComponent<CapsuleInputIF> = (props) => {
  const {
    setCapsuleInputValidStatus,
    capsuleWindow = {
      startTime: moment().subtract(1, 'hour').valueOf(),
      endTime: moment().valueOf(),
      properties: [],
    },
    onChange,
    trackInformation,
    trackAction,
    trackCategory,
    dateFormat,
  } = props;

  const { timezone } = useFlux(sqWorksheetStore);

  const { t } = useTranslation();

  useEffect(() => {
    setCapsuleWindowStatus(isValidCapsule(capsuleWindow));
  }, []);

  const setCapsuleWindow = (capsuleWindow: RangeField<moment.Moment>) => {
    const capsule = {
      ...capsuleWindow,
      startTime: capsuleWindow.start.valueOf(),
      endTime: capsuleWindow.end.valueOf(),
    };
    _.isFunction(onChange) && onChange(capsule);
    setCapsuleWindowStatus(isValidCapsule(capsule));
  };

  const setCapsuleWindowStatus = (isValid) => {
    _.isFunction(setCapsuleInputValidStatus) && setCapsuleInputValidStatus(isValid);
  };

  return (
    <div className="flexRowContainer">
      <div className="flexColumnContainer flexSpaceAround mb5">
        <span className="text-bold">{t('CAPSULE_INPUT_DATE_TIME_LABEL')}</span>
        <span className="text-bold">{t('CAPSULE_INPUT_DATE_TIME_LABEL')}</span>
      </div>
      <div className="flexColumnContainer text-center flexCenter">
        <DateTimeRange
          startTime={moment(capsuleWindow.startTime)}
          endTime={moment(capsuleWindow.endTime)}
          onChange={setCapsuleWindow}
          dateFormat={dateFormat}
          timezone={timezone}
          hideTimezone={true}
          trackCategory={trackCategory}
          trackAction={trackAction}
          trackInformation={{
            start: `Start. ${trackInformation}`,
            end: `End. ${trackInformation}`,
          }}
          fieldIdentifier={{
            start: 'INVESTIGATE_WINDOW_START',
            end: 'INVESTIGATE_WINDOW_END',
          }}
        />
      </div>
      {capsuleWindow && !isValidCapsule(capsuleWindow) && (
        <p className="sq-text-danger text-center">{t('FORM.START_BEFORE_END')}</p>
      )}
    </div>
  );
};
