import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSchedule } from '@/schedule/useSchedule.hook';
import { LiveUpdateRate, ScheduleTypeName, UpdatedSchedule } from '@/schedule/schedule.types';
import { Timezone } from '@/datetime/timezone.service';
import { IrregularSchedule } from '@/schedule/IrregularSchedule.atom';
import { ScheduleTypeTranslations, SelectScheduleType } from '@/schedule/SelectScheduleType.molecule';
import { AutoUpdateTimeSchedule } from '@/schedule/AutoUpdateTimeSchedule.molecule';
import { LiveSchedule } from '@/schedule/LiveSchedule.atom';
import { DurationValuesByUnit } from '@/core/DurationSelector.atom';

export interface ScheduleProps {
  cronSchedule: string[] | undefined;
  initialScheduleType: ScheduleTypeName;
  timezoneDefault?: Timezone;
  updatedSchedule: UpdatedSchedule | undefined;
  onScheduleChange: (setSchedule: UpdatedSchedule) => void;
  defaultLiveRate?: LiveUpdateRate;
  selectScheduleTypeHelpContent?: React.ReactNode;
  showLiveRateHelp?: boolean;
  scheduleTypeTranslations?: ScheduleTypeTranslations;
  durationValuesByUnit?: DurationValuesByUnit;
}

const emptyDefaultObject = {};
export const Schedule: React.FunctionComponent<ScheduleProps> = ({
  cronSchedule,
  initialScheduleType,
  timezoneDefault,
  defaultLiveRate,
  updatedSchedule,
  onScheduleChange,
  selectScheduleTypeHelpContent,
  showLiveRateHelp = false,
  scheduleTypeTranslations = emptyDefaultObject,
  durationValuesByUnit = emptyDefaultObject,
}) => {
  const {
    selectedScheduleType,
    setSelectedScheduleType,
    isLive,
    liveRate,
    setLiveRate,
    onLiveScheduleInvalidInput,
    timeEntries,
    setTimeEntries,
    timezone,
    setTimezone,
    irregularSchedule,
    setIrregularSchedule,
  } = useSchedule(cronSchedule, updatedSchedule, initialScheduleType, timezoneDefault, defaultLiveRate);

  useEffect(() => {
    onScheduleChange({ liveRate, selectedScheduleType, irregularSchedule, timeEntries, timezone });
  }, [liveRate, selectedScheduleType, irregularSchedule, timeEntries, timezone, onScheduleChange]);

  if (!_.isNil(irregularSchedule)) {
    return <IrregularSchedule schedules={irregularSchedule} onConvertIt={() => setIrregularSchedule(undefined)} />;
  }

  return (
    <div data-testid="schedule">
      <SelectScheduleType
        scheduleType={selectedScheduleType}
        setScheduleType={setSelectedScheduleType}
        helpContent={selectScheduleTypeHelpContent}
        scheduleTypeTranslations={scheduleTypeTranslations}
      />
      {!isLive && (
        <AutoUpdateTimeSchedule
          timezone={timezone}
          setTimezone={setTimezone}
          entries={timeEntries}
          setEntries={setTimeEntries}
        />
      )}
      {isLive && (
        <LiveSchedule
          rate={liveRate}
          onChange={setLiveRate}
          onInvalidInput={onLiveScheduleInvalidInput}
          showHelp={showLiveRateHelp}
          durationValuesByUnit={durationValuesByUnit}
        />
      )}
    </div>
  );
};
