import _ from 'lodash';
import { getPointAsArray, getYValue } from '@/utilities/utilities';

/**
 * This function calls the alignment functions as specified by types
 * @param items The series array.
 * @return An object containing chartMinY, chartMaxY and autoDisabled values
 */
export function performYAlignment(items: any[]) {
  const itemsWithData = _.filter(items, _.flow(_.property('capsuleSegmentData'), _.negate(_.isEmpty)));

  return noAlignment(itemsWithData);
}

/*
 * Finds the absolute lowest and highest Y values for the series.
 * @param items The series array.
 */
function noAlignment(items: any[]) {
  const allCalculations = _.map(items, _.flow(_.property('capsuleSegmentData'), getCalculations));
  const minYEntry = _.minBy(allCalculations, 'yMin');
  const maxYEntry = _.maxBy(allCalculations, 'yMax');

  return _.map(items, function (item: any) {
    return {
      id: item.id,
      autoDisabled: false,
      chartMinY: minYEntry.yMin,
      chartMaxY: maxYEntry.yMax,
    };
  });
}

/**
 * Calculates the min/max/avg/middle Y value in a series
 * @param samples The data array
 * @return The object that contains the default calculations for reference within a series item
 */
function getCalculations(samples: { x: number; y: number }[]): any {
  const yValues = _.map(samples, (sample) => {
    return getPointAsArray(sample)[1];
  });
  const yMin = _.min(yValues) as number;
  const yMax = _.max(yValues) as number;
  const sum = _.sum(yValues) as number;

  // Series may have different number of data points so we must find or interpolate a y-value that matches the middle
  // time
  const middleTime = getPointAsArray(_.last(samples))[0] / 2;
  return {
    start: _.head(yValues),
    end: _.last(yValues),
    sum,
    avg: sum / yValues.length,
    middle: getYValue(samples, middleTime).yValue,
    yMin,
    yMax,
    rangeY: yMax - yMin,
  };
}
