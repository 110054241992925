import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getLoginLink } from '@/main/routing.utilities';

interface ConfirmUnsubscribeNotLoggedInProps {
  creator: string;
  admin: string;
}

export const ConfirmUnsubscribeNotLoggedIn: React.FunctionComponent<ConfirmUnsubscribeNotLoggedInProps> = ({
  creator,
  admin,
}) => {
  const { t } = useTranslation();

  return (
    <div data-testid="unsubscribeNotLoggedIn">
      <div className="text-center">
        <h2>{t('UNSUBSCRIBE.ARE_YOU_SURE_UNSUBSCRIBE')}</h2>
      </div>
      <div className="text-center pt5">
        <h4>{t('UNSUBSCRIBE.CONTACT', { creator, admin })}</h4>
        <div className="text-center flexColumnContainer">
          <h4>{t('UNSUBSCRIBE.ACCOUNT_UNSUBSCRIBE')}</h4>
          <Link className="ml5 mr5 pt12" to={getLoginLink(window.location.href)}>
            {t('UNSUBSCRIBE.LOGGING_SEEQ')}
          </Link>
        </div>
      </div>
    </div>
  );
};
