import type { IHeaderParams } from '@ag-grid-community/core';
import React from 'react';
import { TableBuilderTextHeaderSimple } from '@/tableBuilder/tableComponents/TableBuilderTextHeaderSimple';
import { TableBuilderTextHeaderSimpleProps } from '@/tableBuilder/tableBuilder.types';
import _ from 'lodash';
import { useUpdateHeaderBackground } from '@/tableBuilder/ag/hooks/useUpdateHeaderBackground.hook';
import { getSimpleTableMenuActions } from '@/utilities/tableBuilderHelper.utilities';

export interface TableHeaderSimpleProps extends IHeaderParams, TableBuilderTextHeaderSimpleProps {
  hideAutoSizeActions: boolean;
}

export const TableHeaderSimple: React.FunctionComponent<TableHeaderSimpleProps> = (props) => {
  const {
    eGridHeader,
    simpleColumn,
    resetColumnWidth = _.noop,
    column,
    isPresentationMode,
    isViewOnlyMode,
    hideAutoSizeActions,
    isTransposed,
    isInteractiveContent = false,
    autoGroupColumn,
    menuActions = undefined,
    showMove = true,
    showTextValue = true,
    canEdit,
  } = props;

  useUpdateHeaderBackground(simpleColumn.headerBackgroundColor, eGridHeader);

  return (
    <TableBuilderTextHeaderSimple
      {...props}
      autoSizeColumn={() => resetColumnWidth(column?.getColId())}
      autoSizeAllColumns={() => props.api.getColumnState().forEach((state) => resetColumnWidth(state.colId))}
      menuActions={
        menuActions && menuActions.length > 0
          ? menuActions
          : getSimpleTableMenuActions({
              columnType: simpleColumn.type,
              isPresentationMode,
              isViewOnlyMode,
              hideAutoSize: hideAutoSizeActions,
              isTransposed,
              isInteractiveContent,
              isGroupingEnabled: !!autoGroupColumn,
            })
      }
      showMove={showMove}
      showTextValue={showTextValue}
      canEdit={canEdit}
    />
  );
};
