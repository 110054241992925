/**
 * When loading a display, we want to completely replace the contents of the display pane and the details pane.
 * These contents are dictated by:
 *   - The item stores. These are the stores with name "sqTrend{item-type}Store"
 *   - The stores for each of the four worksheet views: trend, table, scatter plot, and treemap
 *   - The viewKey specifying what view the worksheet is in. This is found in sqWorksheetStore; because we don't
 *     want to rehydrate the rest of sqWorksheetStore, the view key is set in the beforeFetch callback passed to
 *     sqStateSynchronizer.rehydrate()
 *   - The plugin stores, which describe display pane plugins.
 * Use caution before modifying this list.
 */
export const STORES_REHYDRATED_BY_DISPLAY = [
  'sqTrendCapsuleStore',
  'sqTrendConditionStore',
  'sqTrendMetricStore',
  'sqTrendScalarStore',
  'sqTrendSeriesStore',
  'sqTrendTableStore',
  'sqTrendStore',
  'sqTableBuilderStore',
  'sqScatterPlotStore',
  'sqTreemapStore',
  'sqPluginStore',
];

export const DISPLAYS_CATEGORY = 'Displays';

/**
 * A data-customid that is applied to DisplayDropdown elements so clicks on those elements can be filtered
 * to prevent the Display from being loaded.
 * @see onEventExceptDisplayDropdown
 */
export const DISPLAY_DROPDOWN_ACTION_CUSTOM_ID = 'displayDropdownAction';
