import _ from 'lodash';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';

export class ManualConditionStore extends BaseToolStore {
  static readonly storeName = 'sqManualConditionStore';
  type = TREND_TOOLS.MANUAL_CONDITION;

  initialize() {
    this.state = this.immutable({ ...BASE_TOOL_COMMON_PROPS, color: '', isExecuting: false });
  }

  get color(): string {
    return this.state.get('color');
  }

  get isExecuting(): boolean {
    return this.state.get('isExecuting');
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return _.omit(this.state.serialize(), ['isExecuting']);
  }

  /**
   * Sets the references panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState: any) {
    this.state.merge(dehydratedState);
  }

  /**
   * Removes properties from config which are stored as part of the formula.
   *
   * @param config - The state that will be saved to UIConfig
   * @return The modified config
   */
  modifyConfigParams(config: any) {
    return _.omit(config, ['maximumDuration', 'advancedParametersCollapsed']);
  }

  handlers = _.assign(
    {
      MANUAL_CONDITION_SET_COLOR: ({ color }: { color: string }) => {
        this.state.set('color', color);
      },
      MANUAL_CONDITION_SET_IS_EXECUTING: ({ isExecuting }: { isExecuting: string }) => {
        this.state.set('isExecuting', isExecuting);
      },
    },
    super.baseHandlers,
  );
}
