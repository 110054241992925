import _ from 'lodash';
import { Y_AXIS_LABEL_CHARACTER_WIDTH } from '@/utilities/label.utilities';
import i18next from 'i18next';
import { AUTO_FORMAT, NO_FORMAT } from './numberHelper.utilities';

export const STRING_FORMATS = [
  { name: 'PROPERTIES.STRING_FORMAT.AUTO', format: AUTO_FORMAT },
  { name: 'PROPERTIES.STRING_FORMAT.NONE', format: NO_FORMAT },
  { name: 'PROPERTIES.STRING_FORMAT.MAX_LENGTH', format: '10' },
  { name: 'PROPERTIES.STRING_FORMAT.ELLIPSIS', format: '5...3' },
];

export interface FormatOptions {
  format?: string;
}

function shortenIfNecessary(string: string, head: number, tail: number) {
  if (string.length > Math.max(Y_AXIS_LABEL_CHARACTER_WIDTH, head + tail + 1)) {
    return tail === 0 ? `${string.slice(0, head)}...` : `${string.slice(0, head)}...${string.slice(-tail)}`;
  } else {
    return string;
  }
}

export function formatString(
  string: string,
  options?: FormatOptions,
  errorHandler: (err: string) => void = _.noop,
  defaultFormat: string = AUTO_FORMAT,
): string {
  const opts: FormatOptions = _.defaults({}, options, {
    format: defaultFormat,
  });
  if (opts.format === NO_FORMAT) {
    return string;
  } else if (_.isNil(opts.format) || opts.format === AUTO_FORMAT) {
    return shortenIfNecessary(string, 2, 3);
  } else if (/^\d+$/.test(opts.format)) {
    const maxLength = _.parseInt(opts.format);
    return shortenIfNecessary(string, maxLength, 0);
  } else if (/^\d+\.{2,}\d+$/.test(opts.format)) {
    const [_ignored, headRaw, tailRaw] = /(\d+)\.{2,}(\d+)/.exec(opts.format) ?? [];
    const head = _.parseInt(headRaw);
    const tail = _.parseInt(tailRaw);
    return shortenIfNecessary(string, head, tail);
  } else {
    errorHandler(
      i18next.t('PROPERTIES.STRING_FORMAT_UNSUPPORTED', {
        FORMAT: opts.format,
      }),
    );
    return shortenIfNecessary(string, 2, 3);
  }
}

/**
 * Determines if the user-input is a regex. It must be enclosed in slashes. It supports the most common regex
 * mode modifiers: ignore-case (i), dotall (s), and multiline (m).
 */
const REGEX_IS_REGEX = /^\/.*\/[ism]*$/;

export function escapeRegex(regex: string) {
  return regex.replace(/[/\-\\^$+.()|[\]{}]/g, '\\$&');
}

export function isRegexDelimited(input: string): boolean {
  return REGEX_IS_REGEX.test(input);
}

/**
 * We anticipate users will try to make their regex case insensitive by adding a /i, so this is a common place to
 * catch that and extract the modifiers/flags. This works with other modifiers that they may try to use
 * as well such as /m, /s, etc.
 */
export function extractRegexBody(search: string, asSubstring: boolean): RegExp {
  if (!isRegexDelimited(search)) {
    throw new Error('Must specify a regex delineated with slashes');
  }

  const endIndex = search.lastIndexOf('/');
  let regex = search.substring(1, endIndex);

  if (!asSubstring) {
    if (!regex.startsWith('^')) {
      regex = `^${regex}`;
    }
    if (!regex.endsWith('$')) {
      regex = `${regex}$`;
    }
  }

  // Convert the /{modifiers} suffix to regex flags
  const modifiers = search.substring(endIndex + 1);

  return new RegExp(regex, modifiers);
}

/**
 * Create a regex string from our input. Seeq search strings can be either regex or globs, using the following:
 *  * Globs are dos-like wildcards, "*" for 0 or more additional characters, and "?" to match a single character.
 *  * Regexes must begin and end with "/".
 *
 * @param search
 * an input string in glob format or regex surrounded by /
 * @param asSubstring
 * true if the resulting regex should include wildcards at the beginning and end, such that a full match
 * behavior can be asserted. If the input is a regex, this option is ignored. If the input includes any
 * wildcards, then don't add more wildcards, since the user knows what they want
 * @param caseInsensitive
 * true if the resulting regex be should be case insensitive. This is ignored if the search string is a
 * regex.
 * @return a proper regex that represents the input and substring
 */
export function convertInputStringToRegex(search: string, asSubstring = true, caseInsensitive = true): RegExp {
  if (!search) {
    return new RegExp(asSubstring ? '.*' : '');
  }

  if (isRegexDelimited(search)) {
    return extractRegexBody(search, asSubstring);
  }

  let regex = escapeRegex(search).replaceAll('*', '.*').replaceAll('?', '.');
  if (!asSubstring) {
    regex = `^${regex}$`;
  }

  let flags = 's'; // all globs should match newline characters
  if (caseInsensitive) {
    flags += 'i';
  }

  return new RegExp(regex, flags);
}
