import { useState, useRef, MutableRefObject } from 'react';

/**
 * A hook that allows you have to have state in the component,
 * while also having the ability to access the current value at any time
 *
 * @param initialState - the initial value
 * @return [ref, set state function]
 */
export function useStateWithRef<T>(initialState: T): [MutableRefObject<T>, (state: T) => void] {
  const [, setState] = useState(initialState);
  const stateRef = useRef(initialState);
  return [
    stateRef,
    (state) => {
      stateRef.current = state;
      setState(state);
    },
  ];
}
