// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DURATION_TIME_UNITS, DurationTimeUnit, UnitWithLabel } from '@/main/app.constants';
import { FormError } from '@/core/FormError.atom';
import classNames from 'classnames';
import { IconSelect } from '@/core/IconSelect.molecule';
import { TextField } from '@seeqdev/qomponents';

export type ValueWithUnitsItem = { value: number; units: string };

interface ValueWithUnitsProps {
  onChange: (ValueWithUnitsItem, property: string) => void;
  min: number;
  /** Classnames for `<IconSelect />` */
  selectClassNames?: string;
  defaultValue: ValueWithUnitsItem | undefined;
  propName?: string;
  isValid?: boolean;
  minIsExclusive?: boolean;
  required?: boolean;
  availableUnits?: readonly (DurationTimeUnit | UnitWithLabel)[];
  unitOptionIndex?: number;
  customError?: boolean;
  disabled?: boolean;
  fromFormBuilder?: boolean;
  largeValueInput?: boolean;
  step?: number | string;
  appendToBody?: boolean;
  extraClassNames?: string;
  fullWidth?: boolean;
}

export const ValueWithUnits: React.FunctionComponent<ValueWithUnitsProps> = (props) => {
  const {
    propName,
    onChange,
    defaultValue,
    min,
    minIsExclusive,
    required,
    availableUnits,
    customError,
    disabled = false,
    largeValueInput = false,
    step = 'any',
    isValid,
    unitOptionIndex,
    fromFormBuilder = false,
    extraClassNames,
    fullWidth = false,
  } = props;

  const [selectedUnit, setSelectedUnit] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  const [valid, setValid] = useState(true);
  const [displayOptions, setDisplayOptions] = useState([]);
  const [pristine, setPristine] = useState(true);

  useEffect(() => {
    const availableOptions = availableUnits || DURATION_TIME_UNITS;
    const updatedOptions = _.map(availableOptions, (option: any) => ({
      value: _.isNil(unitOptionIndex) ? option.unit[0] : option.unit[unitOptionIndex],
      text: option.translationKey ? option.translationKey : option.shortLabel,
    }));
    setDisplayOptions(updatedOptions);
    if (defaultValue) {
      // sometimes the backend returns a different unit than we send along when we create a condition so we need to
      // do some extra work to ensure we select the correct unit
      let unit = _.find(updatedOptions, { value: defaultValue.units as any });
      if (!unit) {
        _.forEach(availableOptions, (option) => {
          if (_.includes(option.unit, defaultValue.units)) {
            unit = _.find(updatedOptions, { value: option.unit[0] as any });
          }
        });
      }
      setSelectedUnit(unit);

      if ((selectedValue as any) - defaultValue?.value !== 0 || !selectedValue) {
        setSelectedValue(_.trim(defaultValue?.value?.toString()) ?? '');
      }
    }
  }, [defaultValue, availableUnits]);

  const validate = ({ value, unit }) => {
    if (required || !_.isNil(unit)) {
      if (!_.isNil(min) || !_.isNil(min)) {
        return minIsExclusive ? value > min : value >= min;
      } else {
        return true;
      }
    }

    return true;
  };

  const triggerChange = (newValue, newUnit) => {
    let unit = selectedUnit;
    let value = selectedValue;
    setPristine(false);

    if (!_.isNaN(newValue) && !_.isNil(newValue)) {
      setSelectedValue(newValue);
      value = newValue;
    }

    if (!_.isNil(newUnit)) {
      setSelectedUnit(newUnit);
      unit = newUnit;
    }

    if (_.isFunction(onChange)) {
      const isValid = validate({ value, unit });
      setValid(isValid);

      onChange(
        _.assign(
          {},
          {
            value: value !== '' ? _.toNumber(value) : '',
            units: (unit as any)?.value,
            valid: isValid,
            propName,
          },
        ),
      );
    }
  };

  const renderErrorMessage = () => {
    if (pristine || customError || fromFormBuilder) {
      return null;
    } else if (required && (_.isNaN(selectedValue) || _.isNil(selectedUnit))) {
      return <FormError errorText="FORM.REQUIRED_FIELD" />;
    } else if (!valid) {
      return (
        <FormError
          errorText={minIsExclusive ? 'VALUE_WITH_UNITS.INVALID_MIN_EXCLUSIVE' : 'VALUE_WITH_UNITS.INVALID'}
          errorParameters={{ min }}
        />
      );
    }
  };

  return (
    <>
      <div
        className={classNames('flexColumnContainer specValueWithUnits', extraClassNames, { flexFill: fullWidth })}
        data-testid={propName}>
        <TextField
          extraClassNames={classNames('value-with-units-value-input', { flexFill: largeValueInput })}
          testId="valueWithUnitsInput"
          name="value"
          placeholder=""
          type="number"
          showError={_.isNil(isValid) ? !valid : !isValid}
          step={step}
          value={selectedValue}
          onChange={(e) => {
            e.stopPropagation();
            setSelectedValue(e.target.value);
            triggerChange(e.target.value, null);
          }}
          readonly={disabled}
          inputGroup="left"
        />
        <div className="width-100">
          <IconSelect
            disabled={disabled}
            name="valueWithUnitsUnits"
            value={selectedUnit}
            selectOptions={displayOptions}
            onChange={(val) => triggerChange(null, val)}
            inputGroup="right"
            showError={!valid}
          />
        </div>
      </div>
      {renderErrorMessage()}
    </>
  );
};
export default ValueWithUnits;
