import React from 'react';
import { Icon } from '@seeqdev/qomponents';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';
import { FormulaErrorInterface } from '@/formula/formula.types';
import _ from 'lodash';

export interface FormulaErrorMessageProps {
  error: FormulaErrorInterface;
  clearError: () => void;
}

export const FormulaErrorMessage: React.FunctionComponent<FormulaErrorMessageProps> = ({ error, clearError }) => {
  const arrowLine = `${_.repeat(' ', error.column - 1)}⬆\n`;
  return (
    <div>
      <div className="flexColumnContainer alert-danger pt5 pr5 pb5 pl5">
        <div className="flexFill">
          <div className="text-pre-wrap">{arrowLine}</div>
          <div className="sq-formula-error">
            <ContainerWithHTML content={error.message} />
          </div>
        </div>
        <div>
          <Icon icon="fa-close" extraClassNames="cursorPointer" type="danger" onClick={clearError} />
        </div>
      </div>
    </div>
  );
};
