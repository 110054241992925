export const SMOOTHING_ALGORITHMS = {
  SEEQ_AGILE: {
    TEXT: 'SIGNAL_SMOOTHING.ALGORITHM_NAMES.SEEQ_AGILE',
    VALUE: 'seeq-agile',
  },
  SAVITZKY: {
    TEXT: 'SIGNAL_SMOOTHING.ALGORITHM_NAMES.SAVITZKY',
    VALUE: 'savitzky-golay',
  },
  LOW_PASS: {
    TEXT: 'SIGNAL_SMOOTHING.ALGORITHM_NAMES.LOW_PASS',
    VALUE: 'low-pass',
  },
  MOVING_AVERAGE: {
    TEXT: 'SIGNAL_SMOOTHING.ALGORITHM_NAMES.MOVING_AVERAGE',
    VALUE: 'moving-average',
  },
  EXPONENTIAL: {
    TEXT: 'SIGNAL_SMOOTHING.ALGORITHM_NAMES.EXPONENTIAL',
    VALUE: 'exponential',
  },
  DOWNSAMPLE: {
    TEXT: 'SIGNAL_SMOOTHING.ALGORITHM_NAMES.DOWNSAMPLE',
    VALUE: 'downsample',
  },
};
export const SMOOTHING_DEFAULTS = {
  POLYNOMIAL_FACTOR: 2,
  TAPS: 33,
  // Set default cutoff to 10 x samplePeriod
  // 10x oversampling is a engineering rule of thumb for DSP. Brian P. found a few references that discuss the topic:
  // Introduction to Signal Processing, Instrumentation, and Control, ISBN 978-981-4733-12-0, pg 389 prob 2
  //  (and elsewhere, but couldn't find it in the text)
  //  http://www.wescottdesign.com/articles/Sampling/sampling.pdf, page 24 (10-20x)
  //  https://www.dsprelated.com/showthread/comp.dsp/29053-1.php says 10x max
  //  https://www.maximintegrated.com/en/app-notes/index.mvp/id/928, second paragraph of oversampling section (10x)
  //  http://www.electronicdesign.com/test-measurement/10x-oversampling-scopes-make-accurate-measurement-insanely-easy,
  //  product feature extolling the virtues of 10x oversampling
  CUTOFF_RATIO: 10,
  ALPHA: 0.9,
  TAU: { value: 10, units: 'min' },
};
export const SMOOTHING_LIMITS = {
  WINDOW_SIZE_MIN_RATIO: 3,
  WINDOW_SIZE_MAX_RATIO: 101,
  POLYNOMIAL_FACTOR_MIN: 1,
  POLYNOMIAL_FACTOR_MAX: 7,
  TAPS_MIN: 3,
  CUTOFF_MIN_RATIO: 5,
};
