import _ from 'lodash';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { DISPLAY_MODE } from '@/main/app.constants';
import { getMediumIdentifier, getShortIdentifier } from '@/utilities/utilities';
import { ITEM_DATA_STATUS, ITEM_TYPES, ITEM_TYPES_TO_API_TYPES } from '@/trendData/trendData.constants';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { decorate } from '@/trend/trendViewer/itemDecorator.utilities';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';

const PROPS_TO_OMIT_FROM_DEHYDRATION = ['operators', 'editor', 'navigationStack'];
export class FormulaToolStore extends BaseToolStore {
  static readonly storeName = 'sqFormulaToolStore';
  type = TREND_TOOLS.FORMULA;

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, {
        formula: '',
        localFormula: '',
        tabTransitioning: false,
        parameters: [],
        navigationStack: [],
        formulaFilter: undefined,
        operators: [],
        editor: null,
      }),
    );
  }

  get formula(): string {
    return this.state.get('formula');
  }

  get localFormula(): string {
    return this.state.get('localFormula');
  }

  get tabTransitioning(): boolean {
    return this.state.get('tabTransitioning');
  }

  get operators(): any[] {
    return this.state.get('operators');
  }

  get parameters(): any[] {
    return this.state.get('parameters');
  }

  get navigationStack(): any[] {
    return this.state.get('navigationStack');
  }

  getNextIdentifier() {
    let identifier;
    let i = 0;
    const parameters = this.state.get('parameters');

    while (!identifier) {
      identifier = getShortIdentifier(i++);
      if (_.some(parameters, ['identifier', identifier])) {
        identifier = undefined;
      }
    }

    return identifier;
  }

  get formulaFilter(): string {
    return this.state.get('formulaFilter');
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return _.omit(this.state.serialize(), PROPS_TO_OMIT_FROM_DEHYDRATION);
  }

  /**
   * Sets the powerSearch
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState: any) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    FORMULA_SET_FORMULA: this.setFormula,
    FORMULA_ADD_PARAMETER: this.addParameter,
    FORMULA_ADD_DETAILS_PANE_PARAMETERS: this.addDetailsPaneParameters,
    FORMULA_UPDATE_PARAMETER: this.updateParameter,
    FORMULA_REMOVE_PARAMETER: this.removeParameter,
    FORMULA_REMOVE_ALL_PARAMETERS: this.removeAllParameters,
    FORMULA_SET_NAVIGATION_STACK: this.setNavigationStack,
    INVESTIGATE_SET_DISPLAY_MODE: this.initializeParameters,
    FORMULA_SET_FILTER: this.setFilter,
    FORMULA_SET_OPERATORS: this.setOperators,
  };

  setOperators({ operators }: { operators: any[] | undefined }) {
    this.state.set('operators', operators);
  }

  /**
   * Sets the formula
   *
   * @param payload - Object container for arguments
   * @param payload.formula - the formula
   */
  setFormula(payload: { formula: string }) {
    this.state.set('formula', payload.formula);
  }

  /**
   * Adds a parameter that will be used as input for the formula.
   *
   * @param payload - Object container for arguments
   * @param payload.parameter - Parameter to add
   * @param payload.parameter.identifier - The symbolic identifier for the parameter
   * @param payload.parameter.item - Object containing item properties
   * @param payload.parameter.item.id - The id of the item referenced by the parameter
   * @param payload.parameter.item.name - The name of the item referenced by the parameter
   */
  addParameter(payload: { parameter: { identifier: string; item: { id: string; name: string } } }) {
    this.state.push('parameters', payload.parameter);
    this.addItemToOriginalParameters(payload.parameter.item);
  }

  /**
   * Adds all parameters from the details pane that don't already exist in the parameters list.
   */
  addDetailsPaneParameters() {
    this.state.set('parameters', this.parametersFromDetailsPane(this));
  }

  /**
   * Updates a parameter that will be used as input for the formula. If the parameter identifier changes it also
   * updates references in the formula.
   *
   * @param payload - Object container for arguments
   * @param payload.index - Index number of the parameter being updated
   * @param payload.parameter - Parameter being updated
   * @param payload.parameter.identifier - The symbolic identifier for the parameter
   * @param payload.parameter.item - Object containing item properties
   * @param payload.parameter.item.id - The id of the item referenced by the parameter
   * @param payload.parameter.item.name - The name of the item referenced by the parameter
   */
  updateParameter(payload: { index: number; parameter: { identifier: string; item: { id: string; name: string } } }) {
    const oldParameter = this.state.get(['parameters', payload.index]);
    this.state.set(['parameters', payload.index], payload.parameter);
    this.addItemToOriginalParameters(payload.parameter.item);
    if (oldParameter?.identifier !== payload.parameter.identifier) {
      const replaceRegex = new RegExp(`\\$${oldParameter?.identifier}\\b`, 'g');
      this.state.set('formula', this.state.get('formula').replace(replaceRegex, `$${payload.parameter.identifier}`));
    }
  }

  /**
   * Removes a parameter based on its identifier.
   *
   * @param payload - Object container for arguments
   * @param payload.identifier - Index number of the parameter being updated
   */
  removeParameter({ identifier }: { identifier: number }) {
    const index = _.findIndex(this.state.get('parameters'), { identifier });
    this.state.splice('parameters', [index, 1]);
  }

  /**
   * Removes all parameters.
   */
  removeAllParameters() {
    this.state.set('parameters', []);
  }

  /**
   * Sets the formula editor navigation stack
   *
   * @param payload - Object container for arguments
   * @param payload.navigationStack - the navigation stack
   */
  setNavigationStack(payload: { navigationStack: string }) {
    this.state.set('navigationStack', payload.navigationStack);
  }

  /**
   * Resets state and sets the parameters to be all items in the details pane when in NEW mode.
   *
   * @param payload - Object containing state information
   * @param payload.mode - The display mode being set, one of DISPLAY_MODE
   * @param payload.type - The name of the tool, one of TREND_TOOLS
   */
  initializeParameters(payload: { mode: string; type: string }) {
    this.reset(payload);
    if (payload.mode === DISPLAY_MODE.NEW && payload.type === TREND_TOOLS.FORMULA) {
      this.addDetailsPaneParameters();
    }
  }

  /**
   * Ensures that the list of original parameters contains the new item so that the sq-select-item list will have the
   * item in its list.
   *
   * @param item - The item to add
   */
  addItemToOriginalParameters(item: { id: string }) {
    if (!_.some(this.state.get('originalParameters'), ['id', item.id])) {
      this.state.push('originalParameters', item);
    }
  }

  /**
   * Adds the formula and parameters to the config as part of what gets rehydrated when the tool is loaded.
   * @param config - The UIConfig state to populate the form
   * @param parameters - The parameters used in the formula
   * @param formula - The formula for the calculated item
   * @returns The updated config
   */
  migrateSavedConfig(config: any, parameters: any[], formula: string) {
    config.formula = formula;
    config.parameters = _.map(parameters, (parameter: { name: string; item: any }) => ({
      identifier: parameter.name,
      item: parameter.item,
    }));
    return config;
  }

  /**
   * Removes properties from config which are stored as part of the formula.
   *
   * @param config - The state that will be saved to UIConfig
   * @return The modified config
   */
  modifyConfigParams(config: any) {
    return _.omit(config, PROPS_TO_OMIT_FROM_DEHYDRATION.concat(['formula', 'parameters']));
  }

  setFilter({ filter }: { filter: string }) {
    this.state.set('formulaFilter', filter);
  }

  /**
   * Transforms the items in the details pane into an array of simplified name parameters. Skips any details pane items
   * that already exist in a supplied array of existing parameters. Also skips the current formula.
   * Internally, it sorts the items by the length of their names before creating the simplified name in order to favor
   * keeping shorter names the same.
   *
   * @param store - Power search store object.
   */
  parametersFromDetailsPane(store: any) {
    let paramLetterCount = 0;
    const existingParams = store.state.get('parameters');
    const existingParamNames = _.map(existingParams, 'identifier');
    const formula = store.state.get('formula');
    const currentFormulaId = store.state.get('id');

    return _.chain(
      getAllItems({
        excludeDataStatus: [ITEM_DATA_STATUS.REDACTED],
        // Types that can be used as parameters
        itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.CONDITION, ITEM_TYPES.SCALAR],
      }),
    )
      .thru((items) => decorate({ items }))
      .concat(_.map(existingParams, 'item'))
      .sort((a: any, b: any) => a.name.length - b.name.length)
      .map((item: any) => {
        let paramName;
        const existingParam = _.find(existingParams, ['item.id', item.id]);

        // Skip item if it is the current formula
        if (item.id === currentFormulaId) {
          return;
        }

        // If the parameter already exists, then use it
        if (existingParam) {
          return existingParam;
        }

        // Otherwise, choose a parameter name
        const paramsInFormula = this.getParamsInFormula(formula);

        // First try, use a "friendly" name based on the actual text of the item
        const namesToAvoid = _.concat(paramsInFormula, existingParamNames);

        paramName = getMediumIdentifier(item.name, namesToAvoid);
        if (_.isEmpty(paramName)) {
          // We couldn't come up with a simple identifier from the item name.
          // It may have been too long, or all numbers and symbols.
          // Fall back the old naming scheme for parameters as $a, $b, $c.
          do {
            paramName = getShortIdentifier(paramLetterCount++);
          } while (_.includes(namesToAvoid, paramName));
        }

        // Remember this name so we don't use it again on a later parameter
        existingParamNames.push(paramName);

        // Get API type from item type
        item.type = ITEM_TYPES_TO_API_TYPES[item.itemType];
        return {
          identifier: paramName,
          item: _.pick(item, ['id', 'name', 'assets', 'type', 'contextConditionId']),
        };
      })
      .orderBy(['item.name'], ['asc'])
      .compact()
      .uniq()
      .value();
  }

  /**
   * Get array of parameter names (without $ prepended) used in formula text
   */
  getParamsInFormula(formula: string) {
    let param;
    const paramRegEx = /\$(\w+)/g;
    const paramsInFormula = [];

    while ((param = paramRegEx.exec(formula)) !== null) {
      paramsInFormula.push(param[1]);
    }

    return paramsInFormula;
  }
}
